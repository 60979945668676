import React from 'react';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import {
  getSdk,
  PromoCode,
  RedeemerRole,
  RedemptionHistory,
  RedemptionHistoryType,
  Reward,
} from '~/api';
import { useCreate, useGetIdentity, useNavigation } from '@refinedev/core';
import {
  dateFormatter,
  formatFullName,
  removeUnderscore,
  toCamelCaseWord,
} from '~/resources/helpers';
import { ImageViewer } from '@scalingworks/react-admin-ui';
import { numeralThousandFormat } from '~/config/helper';
import { resourceNames } from '../../resource-names';
import { RedeemForm } from './redeemForm';
import { renderTextWithPrefix } from '../helpers';
import { FullDateTimeFormat } from '~/config/constant';
import isEmpty from 'lodash/isEmpty';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<RedemptionHistory>({
    resourceName: resourceNames.redemptionHistory,
  });

const fields: ResourceField<RedemptionHistory>[] = [
  'id',
  'type',
  'pointCost',
  'usedDate',
  'createdAt',
  { reward: ['name', 'imageUrls'] },
  { membership: ['firstName', 'lastName'] },
];

export const redemptionHistoryResource = createResource({
  name: resourceNames?.redemptionHistory,
  label: 'Redemption History',
  fields: defineFields(fields),
  defaultValues: {} as any,
  defaultPageSize: 25,
  defaultSorter: [{ field: 'id', order: 'desc' }],
  allowCreate: true,
  allowSearch: true,
  filterControls: {
    createdAt: {
      type: 'daterange',
      config: {
        label: 'Redeemed at',
      },
    },
    usedDate: {
      type: 'daterange',
      config: {
        label: 'Used at',
      },
    },
  } as any,
  filterConfig: {
    alwaysExpanded: true,
  },
  columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
    return [
      {
        id: 'name',
        header: t('redemptionHistory.column.name', { fallback: 'Customer Name', ns: 'common' }),
        cell: (data) => {
          const { id, membership } = data.row.original;
          const fullName = formatFullName(membership?.firstName, membership?.lastName);
          return (
            <div>
              {isEmpty(fullName) ? (
                <span className="font-semibold text-error-300">Deleted User</span>
              ) : (
                <span>{fullName}</span>
              )}
            </div>
          );
        },
      },
      {
        id: 'rewards',
        header: t('redemptionHistory.column.rewards', { fallback: 'Rewards', ns: 'common' }),
        cell: (data) => {
          const { id, reward, promoCode } = data.row.original;
          const { name, imageUrls } = (reward || {}) as Reward;
          const { name: promoName, imageUrls: promoImages } = (promoCode || {}) as PromoCode;
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <ImageViewer
                src={imageUrls?.[0] || promoImages?.[0]}
                className="!w-14 !h-14 rounded-lg object-cover mr-2"
                alt="Reward"
              />
              <span>{name || promoName}</span>
            </div>
          );
        },
      },
      {
        id: 'type',
        header: t('redemptionHistory.column.type', { fallback: 'Type', ns: 'common' }),
        cell: (data) => {
          const { id, type } = data.row.original;
          return (
            <div>
              <span>
                {t(`redemptionHistory.type.${type}`, {
                  fallback: toCamelCaseWord(removeUnderscore(type)),
                  ns: 'common',
                })}
              </span>
            </div>
          );
        },
      },
      {
        id: 'createdAt',
        header: t('redemptionHistory.column.redeemedAt', { fallback: 'Redeemed At', ns: 'common' }),
        cell: (data) => {
          const { id, createdAt } = data.row.original;
          return (
            <div>
              <span>{dateFormatter(createdAt, FullDateTimeFormat)}</span>
            </div>
          );
        },
      },
      {
        id: 'usedAt',
        header: t('redemptionHistory.column.usedAt', { fallback: 'Used At', ns: 'common' }),
        cell: (data) => {
          const { id, usedDate } = data.row.original;
          return (
            <div>
              <span>{dateFormatter(usedDate, FullDateTimeFormat)}</span>
            </div>
          );
        },
      },
      {
        id: 'points',
        header: t('redemptionHistory.column.points', { fallback: 'Points', ns: 'common' }),
        cell: (data) => {
          const { id, pointCost } = data.row.original;
          return (
            <div>
              <span>
                {renderTextWithPrefix({
                  prefixText: 'PTS',
                  text: numeralThousandFormat(pointCost),
                  prefixPosition: 'right',
                })}
              </span>
            </div>
          );
        },
      },
    ];
  },
  dataProvider: {
    getList: ({ client, filters, metaData, pagination, sort }) => {
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 25;

      const tabListFilter = filters?.find((subItem: any) => subItem?.key === 'listingTab');
      const searchFilter = filters?.find((subItem: any) => subItem?.field === 'search');
      const createdAtBegin = filters?.find(
        (subItem: any) => subItem?.field === 'createdAt' && subItem?.operator === 'gte'
      );
      const createdAtEnd = filters?.find(
        (subItem: any) => subItem?.field === 'createdAt' && subItem?.operator === 'lte'
      );
      const usedAtBegin = filters?.find(
        (subItem: any) => subItem?.field === 'usedDate' && subItem?.operator === 'gte'
      );
      const usedAtEnd = filters?.find(
        (subItem: any) => subItem?.field === 'usedDate' && subItem?.operator === 'lte'
      );
      const type = filters?.find(
        (subItem: any) => subItem?.field === 'type' && subItem?.operator === 'eq'
      );

      const tabFilter =
        (tabListFilter?.value?.[0]?.value === 'ALL'
          ? undefined
          : tabListFilter?.value?.[0]?.value) || undefined;

      return getSdk(client)
        ?.GetRedemptionHistories({
          input: {
            type: tabFilter || type?.value,
            search: searchFilter?.value,
            beginDateRangeCreatedAt: createdAtBegin?.value,
            endDateRangeCreatedAt: createdAtEnd?.value,
            beginDateRangeUsedDate: usedAtBegin?.value,
            endDateRangeUsedDate: usedAtEnd?.value,
            membershipUserId: metaData?.userId,
          },
          options: {
            skip: (current - 1) * pageSize,
            take: pageSize,
            // TODO: backend handle sort & pagination in options
            // sort: generateSort(sort),
          },
        })
        ?.then((res) => ({
          data: res?.getRedemptionHistories?.items,
          total: res?.getRedemptionHistories?.totalItems,
        }));
    },
    create: ({ client, variables }) => {
      return getSdk(client)
        ?.RedeemForMultipleCustomer({
          input: {
            membershipUserIds: [...variables?.customer],
            redeemerId: variables?.redeemerId,
            redeemerRole: variables?.isAdmin ? RedeemerRole?.Admin : RedeemerRole?.Staff,
            promoCodeId:
              variables?.type === RedemptionHistoryType?.VoucherCode
                ? variables?.reward
                : undefined,
            rewardId:
              variables?.type !== RedemptionHistoryType?.VoucherCode
                ? variables?.reward
                : undefined,
          },
        })
        ?.then((res) => ({
          data: res?.redeemForMultipleCustomer as any,
        }));
    },
  },
  list: {
    tabs: {
      options: [
        {
          // @ts-ignore
          filterValue: { field: 'type', operator: 'eq', value: 'ALL' },
          label: ({ t }) => t('redemptionHistory.tabs.all', { fallback: 'All', ns: 'common' }),
        },
        {
          filterValue: { field: 'type', operator: 'eq', value: RedemptionHistoryType?.VoucherCode },
          label: ({ t }) =>
            t('redemptionHistory.tabs.voucherCode', { fallback: 'Voucher Code', ns: 'common' }),
        },
        {
          filterValue: { field: 'type', operator: 'eq', value: RedemptionHistoryType?.InStoreItem },
          label: ({ t }) =>
            t('redemptionHistory.tabs.inStoreItem', { fallback: 'In-store Reward', ns: 'common' }),
        },
        {
          filterValue: { field: 'type', operator: 'eq', value: RedemptionHistoryType?.InAppItem },
          label: ({ t }) =>
            t('redemptionHistory.tabs.inAppItem', { fallback: 'In-app Reward', ns: 'common' }),
        },
      ],
    },
  },
  createConfig: {
    title: ({ t }) =>
      t('redemptionHistory.redeem.name', {
        fallback: 'Redeem Rewards',
        ns: 'common',
      }),
  },
  create: {
    render: (helpers) => {
      const { mutate, isLoading } = useCreate();
      const { goBack } = useNavigation();

      const userIdentity = useGetIdentity() as any;
      const userData = userIdentity?.data?.me;
      const isAdmin = userData?.channels?.[0]?.permissions?.includes('SuperAdmin');

      const onSubmit = (data: any) => {
        mutate(
          {
            resource: resourceNames?.redemptionHistory,
            values: { ...data, isAdmin, redeemerId: userData?.id },
          },
          {
            onSuccess: () => {
              goBack();
            },
          }
        );
      };
      return <RedeemForm onPressSubmit={onSubmit} loading={isLoading} />;
    },
  },
});
