import React from 'react';
import SearchAndSelect, { SearchAndSelectProp } from '../base';

type Props = SearchAndSelectProp & {
  onSelect?: (id: string) => void;
  includePoints?: boolean;
};

export const RewardSelection: React.FC<Props> = (props) => {
  const { onSelect, queryKey, includePoints = true, ...restProps } = props;
  return (
    <SearchAndSelect
      {...restProps}
      useInputAsSearch
      onSelect={(id) => onSelect?.(id)}
      queryKey="getRewards"
      getValueAndLabel={({ name, id, pointCost }) => {
        let label = `${name}${includePoints ? ` (${pointCost} pts)` : ``}`.trim();
        return {
          value: `${id}-space-${pointCost}`,
          label: label,
        };
      }}
      placeholder="Search reward name"
    />
  );
};

export type RewardSelectionProps = Props;
