import type { DayFieldProps } from '@scalingworks/react-admin-ui';
import { DayField } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { getControlRules, useFormField } from '../form.lib';
import type { MakeFormFieldProps } from '../form.types';

export type FormDayFieldProps = MakeFormFieldProps<DayFieldProps>;

export const FormDayField = ({ defaultValue, label, hideLabel, ...props }: FormDayFieldProps) => {
	const controller = useFormField({
		name: props.name,
		rules: getControlRules({
			required: props.required,
		}),
		defaultValue,
	});
	const error = controller.fieldState.error;

	return (
		<DayField
			status={error ? 'danger' : undefined}
			{...props}
			{...(hideLabel
				? {
						'aria-label': label,
				  }
				: {
						label,
				  })}
			value={controller.field.value}
			name={controller.field.name}
			onValue={controller.field.onChange}
			ref={controller.field.ref}
		/>
	);
};
