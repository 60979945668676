import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Props } from './props';

export const Draggable: React.FC<Props> = (props) => {
  const { index, data, moveRow, children } = props;

  const [{ isDragging }, drag] = useDrag({
    type: 'collection',
    item: { index, data },
    collect: (monitor) => ({
      isDragging: monitor?.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'collection',
    drop: (draggedItem: any, monitor) => {
      if (draggedItem?.index !== index) {
        moveRow?.(draggedItem?.index, index, draggedItem?.data);
        draggedItem.index = index;
      }
    },
  });

  return <div ref={(node) => drag(drop(node))}>{children}</div>;
};

export default Draggable;
