import React, { useState } from 'react';
import { Props } from './props';
import { Button, Dialog } from '@scalingworks/react-admin-ui';
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import { LinkCustomerToOrderInput } from '~/api';
import { CustomerSelection } from '~/components/SearchAndSelect';

export const OrderAssignCustomer: React.FC<Props> = (props) => {
  const { open, setOpen, orderId, onCompleted } = props;

  // ==================== STATES
  const [customerId, setCustomerId] = useState('');

  // ==================== API
  const { mutate: assign, isLoading: assigning } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        setOpen(false);
        if (data?.data) {
          onCompleted?.(data);
        }
      },
    },
  });

  // ==================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();

  // ==================== EVENTS
  const onSubmit = () => {
    if (!customerId) {
      // not suppose to happen
      console.warn(`Customer ID not found`);
      return;
    }

    assign({
      method: 'post',
      url: apiUrl,
      meta: {
        operation: 'linkCustomerToOrder',
        fields: ['id'],
        variables: {
          input: {
            value: {
              customerId,
              orderId,
            } as LinkCustomerToOrderInput,
            type: 'LinkCustomerToOrderInput!',
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('order.edit.assignCustomerFailed', {}, 'Failed to assign customer'),
        type: 'error',
      },
      successNotification: {
        message: t('order.edit.assignCustomerSuccess', {}, 'Customer assigned'),
        type: 'success',
      },
    });
  };

  // ==================== VIEWS
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/2 z-20 w-1/2">
          <Dialog.Title>{t('order.edit.assignCustomer', {}, 'Assign Customer')}</Dialog.Title>
          <div className="py-4">
            <CustomerSelection onSelect={(id) => setCustomerId(id)} />
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button disabled={!customerId} loading={assigning} variant="solid" onClick={onSubmit}>
              Confirm
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
