import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { GQLClient } from '~/config/gql-client';
import {
  TextInputSelect,
  TextInputSelectField,
  TextInputSelectFieldProps,
  TextInputSelectProps,
} from '@scalingworks/react-admin-ui';
import { getSdk } from '~/api';
import { Props } from './props';
import isEmpty from 'lodash/isEmpty';

const SearchAndSelect: React.FC<Props> = (props) => {
  const {
    isField = false,
    fieldLabel,
    queryKey,
    placeholder = 'Search',
    onSelect,
    variables,
    getValueAndLabel,
    initialOption,
    useInputAsSearch,
    ...restProps
  } = props;

  const [selected, setSelected] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      fetchData(nextValue);
    }, 1000),
    [variables]
  );

  const handleChange = (value: string) => {
    setLoading(true);
    debouncedSave(value);
  };
  const handleFocus = (ev: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!ev.target.value) {
      fetchData();
    }
  };
  const handleSelect = (selected: string) => {
    setSelected(selected);
    onSelect(selected);
  };

  const fetchData = async (search = '') => {
    const client = GQLClient.getInstance();
    const currentVariables = useInputAsSearch
      ? { input: { ...variables, search } }
      : { ...variables, search };

    const data = await getSdk(client)[queryKey]({
      ...currentVariables,
      options: { ...(currentVariables?.options || {}), take: 25 },
    });

    // @ts-ignore
    const formattedOptions = data?.[queryKey]?.items.map(getValueAndLabel);
    setOptions(formattedOptions);
    setLoading(false);
  };

  const getOptions = () => {
    if (!isEmpty(options)) return options;

    if (initialOption) return [initialOption] as any[];

    return [];
  };

  const componentProps: TextInputSelectProps<string> | TextInputSelectFieldProps<string> = {
    placeholder: placeholder,
    onFocus: (ev) => handleFocus(ev),
    onValue: (value) => handleSelect(value),
    onChange: (ev) => {
      restProps?.onChange?.(ev);
      handleChange(ev.target.value);
    },
    options: getOptions(),
    value: selected || initialOption?.value || '',
    loading,
  };

  if (isField) {
    return <TextInputSelectField label={fieldLabel} {...restProps} {...componentProps} />;
  }
  return <TextInputSelect {...restProps} {...componentProps} />;
};

export default SearchAndSelect;
export type SearchAndSelectProp = Props;
