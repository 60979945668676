import { BaseRecord, useApiUrl, useCustom } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { GiftOption } from '~/api';

export const useGiftOptions = <T extends BaseRecord,>() => {
  const hook = useCustom<T>({
    method: 'get',
    url: useApiUrl(),
    metaData: {
      fields: ['id', 'name', 'price', 'label'] as ResourceField<GiftOption>[],
      operation: 'giftOptions',
    },
  });

  return hook;
};
