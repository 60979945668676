import { gql } from 'graphql-request';

export const requestMobileSigninDoc = gql`
  mutation requestMobileSignin($input: AdminRequestSigninInput!) {
    requestMobileSignin(input: $input)
  }
`;

export const setTokenDoc = gql`
  mutation setToken($token: String!) {
    setToken(token: $token)
  }
`;
