import { useApiUrl, useCustom, useCustomMutation, useTranslate } from '@refinedev/core';
import { Button, Card } from '@scalingworks/react-admin-ui';
import { ResourceField } from '@scalingworks/refine-react-admin';
import React, { useEffect, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { Channel, UpdateChannelInput, Wysiwyg, WysiwygUpdateInput } from '~/api';
import { Editor, Loading } from '~/components';
import { useActiveChannel } from '../hook';

type CardState = {
  tnc: boolean;
  help: boolean;
  faq: boolean;
  privacy: boolean;
  shipping: boolean;
  refund: boolean;
};

export const CompanySetting: React.FC = () => {
  // ======================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();

  // ======================== API
  const {
    data: channel,
    isLoading: loadingChannel,
    refetch: refetchChannel,
  } = useActiveChannel<Channel>();
  const { faq, tnc, help, privacy, shipping, refund } = channel?.data || {};
  const { mutate: upsertManyPolicyInfo, isLoading: updatingChannel } = useCustomMutation();

  // ======================== STATES
  const [cardState, setCardState] = useState<CardState>({
    tnc: true,
    help: false,
    faq: false,
    privacy: false,
    shipping: false,
    refund: false,
  });
  const [tncState, setTncState] = useState(tnc || '');
  const [helpState, setHelpState] = useState(help || '');
  const [faqState, setFaqState] = useState(faq || '');
  const [privacyState, setPrivacyState] = useState(privacy || '');
  const [shippingState, setShippingState] = useState(shipping || '');
  const [refundState, setRefundState] = useState(refund || '');

  // ======================== EVENTS
  const onUpdate = () => {
    upsertManyPolicyInfo(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          fields: ['__typename'],
          operation: 'upsertManyPolicyInfo',
          variables: {
            input: {
              value: [
                tncState,
                helpState,
                faqState,
                privacyState,
                shippingState,
                refundState,
              ] as WysiwygUpdateInput[],
              type: '[WysiwygUpdateInput!]',
            },
          },
        },
        successNotification: {
          message: t('policy.update.success'),
          type: 'success',
        },
        errorNotification: {
          message: t('policy.update.failed'),
          type: 'error',
        },
      },
      {
        onSuccess: () => refetchChannel(),
      }
    );
  };

  // ======================== EFFECTS
  useEffect(() => {
    if (channel) {
      const { faq, tnc, help, shipping, privacy, refund } = channel?.data || {};

      setTncState(tnc || '');
      setHelpState(help || '');
      setFaqState(faq || '');
      setPrivacyState(privacy || '');
      setShippingState(shipping || '');
      setRefundState(refund || '');
    }
  }, [channel]);

  // ======================== VIEWS
  if (loadingChannel) return <Loading />;
  return (
    <section className="overflow-y-scroll flex flex-col space-y-4">
      <section className="px-4 py-2">
        <div className="flex flex-row items-center justify-between w-full">
          <h3 className="font-bold text-lg">
            {t('policy.name.policySettings', 'Policy Settings')}
          </h3>
          <Button variant="solid" onClick={onUpdate} loading={updatingChannel}>
            {t('common.update', 'Update')}
          </Button>
        </div>
      </section>
      <Card>
        <Card.Header
          bordered
          className="cursor-pointer"
          onClick={() => setCardState((prev) => ({ ...prev, tnc: !prev.tnc }))}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="font-bold">{t('policy.column.tnc')}</h3>
            <BsChevronUp
              size={30}
              className={`transition-transform ${cardState.tnc ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
        </Card.Header>
        {cardState.tnc && (
          <Card.Body>
            <Editor
              placeholder={t('policy.placeholder.tnc')}
              defaultValue={tnc?.content || ''}
              onValue={(txt) => setTncState((prev) => ({ ...(prev as Wysiwyg), content: txt }))}
            />
          </Card.Body>
        )}
      </Card>
      <Card>
        <Card.Header
          bordered
          className="cursor-pointer"
          onClick={() => setCardState((prev) => ({ ...prev, help: !prev.help }))}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="font-bold">{t('policy.column.help', 'Help')}</h3>
            <BsChevronUp
              size={30}
              className={`transition-transform ${cardState.help ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
        </Card.Header>
        {cardState.help && (
          <Card.Body>
            <Editor
              placeholder={t('policy.placeholder.helpContent', 'Help Content')}
              defaultValue={help?.content || ''}
              onValue={(txt) => setHelpState((prev) => ({ ...(prev as Wysiwyg), content: txt }))}
            />
          </Card.Body>
        )}
      </Card>
      <Card>
        <Card.Header
          bordered
          className="cursor-pointer"
          onClick={() => setCardState((prev) => ({ ...prev, faq: !prev.faq }))}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="font-bold">{t('policy.column.faq', 'FAQ')}</h3>
            <BsChevronUp
              size={30}
              className={`transition-transform ${cardState.faq ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
        </Card.Header>
        {cardState?.faq && (
          <Card.Body>
            <Editor
              placeholder={t('policy.placeholder.faq', 'FAQ Content')}
              defaultValue={faq?.content || ''}
              onValue={(txt) => setFaqState((prev) => ({ ...(prev as Wysiwyg), content: txt }))}
            />
          </Card.Body>
        )}
      </Card>
      <Card>
        <Card.Header
          bordered
          className="cursor-pointer"
          onClick={() => setCardState((prev) => ({ ...prev, privacy: !prev.privacy }))}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="font-bold">{t('policy.column.privacy', 'Privacy Policy')}</h3>
            <BsChevronUp
              size={30}
              className={`transition-transform ${cardState.privacy ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
        </Card.Header>
        {cardState?.privacy && (
          <Card.Body>
            <Editor
              placeholder={t('policy.placeholder.privacy', 'Privacy Policy Content')}
              defaultValue={privacy?.content || ''}
              onValue={(txt) => setPrivacyState((prev) => ({ ...(prev as Wysiwyg), content: txt }))}
            />
          </Card.Body>
        )}
      </Card>
      <Card>
        <Card.Header
          bordered
          className="cursor-pointer"
          onClick={() => setCardState((prev) => ({ ...prev, shipping: !prev.shipping }))}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="font-bold">{t('policy.column.shipping', 'Shipping Policy')}</h3>
            <BsChevronUp
              size={30}
              className={`transition-transform ${cardState.shipping ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
        </Card.Header>
        {cardState?.shipping && (
          <Card.Body>
            <Editor
              placeholder={t('policy.placeholder.shipping', 'Shipping Policy Content')}
              defaultValue={shipping?.content || ''}
              onValue={(txt) =>
                setShippingState((prev) => ({ ...(prev as Wysiwyg), content: txt }))
              }
            />
          </Card.Body>
        )}
      </Card>
      <Card>
        <Card.Header
          bordered
          className="cursor-pointer"
          onClick={() => setCardState((prev) => ({ ...prev, refund: !prev.refund }))}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="font-bold">{t('policy.column.refund', 'Refund Policy')}</h3>
            <BsChevronUp
              size={30}
              className={`transition-transform ${cardState.refund ? 'rotate-180' : 'rotate-0'}`}
            />
          </div>
        </Card.Header>
        {cardState?.refund && (
          <Card.Body>
            <Editor
              placeholder={t('policy.placeholder.refund', 'Refund Policy Content')}
              defaultValue={refund?.content || ''}
              onValue={(txt) => setRefundState((prev) => ({ ...(prev as Wysiwyg), content: txt }))}
            />
          </Card.Body>
        )}
      </Card>
    </section>
  );
};
