import React from 'react';
import { PointTransactionAdminActionType } from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { FormBuilder, MultiCustomerSelection } from '~/components';
import { FormItemProps } from '~/components/FormBuilder/props';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';

export const PointAdjustForm: React.FC<Props> = (props) => {
  const { onPressSubmit, loading, onClose } = props;
  const t = useTranslate();

  return (
    <div>
      <FormBuilder
        resourceName={resourceNames.pointTransaction}
        title={t('pointTransactionHistories.adjust.adjust')}
        items={({ register, setValue, watch }) => {
          const finalOptions: FormItemProps[] = [
            {
              title: t('pointTransactionHistories.form.customer'),
              name: 'customer',
              type: 'data-selection',
              dataSelectionProps: {
                selectionComponent: MultiCustomerSelection,
                selectionProps: {
                  useUserId: true,
                },
              },
              placeholder: t('pointTransactionHistories.placeholder.customer'),
              required: true,
            },
            {
              title: t('pointTransactionHistories.form.action'),
              name: 'action',
              type: 'select',
              options: Object.keys(PointTransactionAdminActionType).map((key) => ({
                label: toCamelCaseWord(key),
                value:
                  PointTransactionAdminActionType[
                    key as keyof typeof PointTransactionAdminActionType
                  ],
              })),
              placeholder: t('pointTransactionHistories.placeholder.action'),
              required: true,
            },
            {
              title: t('pointTransactionHistories.form.points'),
              name: 'points',
              type: 'number',
              suffix: 'PTS',
              placeholder: '0',
              required: true,
            },
            {
              title: t('pointTransactionHistories.form.remarks'),
              name: 'remarks',
              multiline: true,
              placeholder: t('pointTransactionHistories.placeholder.remarks'),
            },
          ];

          return finalOptions;
        }}
        onSubmit={(data) => {
          onPressSubmit(data);
        }}
      />
    </div>
  );
};
