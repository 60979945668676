import routerProvider from '@refinedev/react-router-v6/legacy';
import {
  createRefineAdminApp,
  defineCustomPage,
  groupResources,
} from '@scalingworks/refine-react-admin';
import '@scalingworks/refine-react-admin/styles.css';
import ReactDOM from 'react-dom/client';
import 'tailwindcss/tailwind.css';
import { SlPresent } from 'react-icons/sl';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { BsGrid } from 'react-icons/bs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import logo from './assets/np-office-logo.png';
import { createAuthProvider, subscriberStorageKey } from './config/auth-provider';
import { LoginPage } from './config/Login/login-page';
import { productResource } from './resources/product/products/product-resource';
import { customerResource } from './resources/customer/customer-resource';
import { createDataProvider } from './config/data-provider';
import { reservationResource } from './resources/reservation/reservation-resource';
import { outletResource } from './resources/outlet-resource';
import { orderResource } from './resources/order/order-resource';
import { rewardResource } from './resources/loyalty/rewards/reward-resource';
import { promoCodeResource } from './resources/loyalty/promoCode/promo-code-resource-';
import { redemptionHistoryResource } from './resources/loyalty/redemption/redemption-resource';
import { membershipTierResource } from './resources/loyalty/membershipTier/membershiptier-resource';
import { createAccessControlProvider } from './config/access-provider';
import { createAccessConfig } from './config/access-config';
import { staffResource } from './resources/staff/staff-resource';
import { ErrorPage } from './config/Error';
import { pointAdjustmentResource } from './resources/loyalty/point-adjustment/point-adjustment-resource';
import { AiOutlineNotification } from 'react-icons/ai';
import { discountPromotionResource } from './resources/marketing';
import { bannerResource } from './resources/marketing/banner/banner-resource';
import { collectionResource } from './resources/product/collections/collection-resource';
import { settingResource } from './resources/setting/setting-resource';
import { notificationBlastResource } from './resources/marketing/notification-blast/notification-blast-resource';
import { auditLogResource } from './resources/audit/audit-resource';
import { modifierGroupResource } from './resources/product/modifiers/modifier-resource';
import { FiCalendar } from 'react-icons/fi';
import { reservationCalendarResource } from './resources/reservation/calendar/reservation-calendar-resource';
import { MaintenancePage } from './config/Maintenance';
import { profileResource } from './resources/profile';
import { QuickActions } from './config/QuickActions';
import { AdminNotificationPage } from './config/AdminNotification';
import { path } from './config/constant';
import { NovuProvider } from '@novu/notification-center';
import { NovuSubscriber } from './api';
import { reportingResource } from './resources/reporting/reporting-resource';

const authProvider = createAuthProvider();
const accessConfig = createAccessConfig();

const subscriberId = localStorage.getItem(subscriberStorageKey);

const pushNotification = () => {
  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      return;
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      return;
    }
  );
};

const RefineApp = createRefineAdminApp({
  graphqlUrl: `${import.meta.env.VITE_API_BASE_URL}`,
  authProvider,
  dataProvider: (resourceMap, loader) => createDataProvider(resourceMap, loader),
  sidebarHeader: (
    <div className="px-4 pb-3">
      <img src={logo} alt="NP" />
    </div>
  ),
  LoginPage: defineCustomPage({
    render: () => <LoginPage logo={logo} />,
  }),
  design: {
    sidebarVariant: 'gray',
  },
  i18n: {
    translationLoadPath: '/locales/{{lng}}/common.json',
    preferredLanguage: 'en',
    appNamespace: 'common',
    languages: [
      {
        label: 'English',
        code: 'en',
      },
      {
        label: 'Chinese',
        code: 'cn',
      },
    ],
  },
  legacyRouterProvider: {
    ...routerProvider,
    routes: [
      ...(routerProvider.routes || []),
      { element: <MaintenancePage />, path: path.maintenance },
      { element: <AdminNotificationPage />, path: path.notification },
    ],
  },
  accessControlProvider: createAccessControlProvider(authProvider, accessConfig),
  accessConfig,
  resources: [
    reportingResource,
    orderResource,
    ...groupResources({
      groupName: 'productCatalouge',
      icon: <BsGrid />,
      label: 'Product Catalogue',
      resources: [productResource, collectionResource, modifierGroupResource],
    }),
    ...groupResources({
      groupName: 'scheduling',
      icon: <FiCalendar />,
      label: 'Reservations',
      resources: [reservationResource, reservationCalendarResource],
    }),
    customerResource,
    outletResource,
    ...groupResources({
      groupName: 'loyalty',
      icon: <SlPresent />,
      label: 'Loyalty',
      resources: [
        membershipTierResource,
        rewardResource,
        promoCodeResource,
        pointAdjustmentResource,
        redemptionHistoryResource,
      ],
    }),
    ...groupResources({
      groupName: 'marketing',
      icon: <AiOutlineNotification />,
      label: 'Marketing',
      resources: [discountPromotionResource, bannerResource, notificationBlastResource],
    }),
    staffResource,
    auditLogResource,
    settingResource,
    profileResource,
  ],
  errorPage: <ErrorPage />,
  quickActions: <QuickActions />,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <DndProvider backend={HTML5Backend}>
    <NovuProvider
      subscriberId={subscriberId!}
      socketUrl={import.meta.env.VITE_NOVU_SOCKET_URL}
      backendUrl={import.meta.env.VITE_NOVU_BACKEND_URL}
      applicationIdentifier={import.meta.env.VITE_NOVU_APP_ID}
    >
      <RefineApp />
    </NovuProvider>
  </DndProvider>
);
{
  pushNotification();
}
