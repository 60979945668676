import React from 'react';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { getSdk, Asset, MembershipTier } from '~/api';
import { Loading, SomethingWentWrong } from '~/components';
import { useCreate, useNavigation, useOne, useUpdate } from '@refinedev/core';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { resourceNames } from '../../resource-names';
import { MembershipTierMain } from './dashboard';
import { MembershipTierCreate } from './create';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<MembershipTier>({
    resourceName: resourceNames.membershiptier,
  });

export const fields = ['id', 'level', 'imageUrl', 'minimumPoints', 'name'];

export const membershipTierResource = createResource({
  name: resourceNames?.membershiptier,
  label: 'Membership',
  fields: defineFields([]),
  allowCreate: true,
  allowSearch: false,
  defaultValues: {} as any,
  columns: () => [],
  dataProvider: {
    getOne: ({ client, id }) => {
      return getSdk(client)
        ?.MembershipTier({
          membershipTierId: id as string,
        })
        ?.then((res) => ({
          data: res?.membershipTier,
        }));
    },
    create: async ({ client, variables }) => {
      let assetsUrl;
      if (!isEmpty(variables?.image)) {
        assetsUrl = await getSdk(client)?.createAssets({
          input: variables?.image?.map((subItem: any) => {
            return {
              file: subItem,
            };
          }),
        });
      }
      const assetsData: Asset[] = assetsUrl?.createAssets as Asset[];
      return getSdk(client)
        ?.CreateMembershipTier({
          input: {
            level: parseInt(variables?.level),
            minimumPoints: parseFloat(variables?.pointsRequired),
            name: variables?.name,
            imageUrl: assetsData?.[0]?.source,
          },
        })
        ?.then((res) => ({
          data: res,
        }));
    },
    deleteOne: async ({ client, id }) => {
      return getSdk(client)
        ?.DeleteMembershipTier({
          id: id as string,
        })
        ?.then((res) => ({
          data: res,
        }));
    },
    update: async ({ client, id, variables }) => {
      const formData = variables?.data;
      const apiData = variables?.membershipTierData;
      let assetsUrl;
      if (!isEmpty(formData?.image) && typeof formData?.image?.[0] !== 'string') {
        assetsUrl = await getSdk(client)?.createAssets({
          input: formData?.image?.map((subItem: any) => {
            return {
              file: subItem,
            };
          }),
        });
      }
      const assetsData: Asset[] = assetsUrl?.createAssets as Asset[];
      return getSdk(client)
        ?.UpdateMembershipTier({
          input: {
            id: id as string,
            imageUrl: formData?.image ? assetsData?.[0]?.source : apiData?.image,
            level:
              parseInt(formData?.level) === apiData?.level
                ? undefined
                : parseInt(formData?.level || apiData?.level),
            minimumPoints:
              parseFloat(formData?.pointsRequired) === apiData?.minimumPoints
                ? undefined
                : parseFloat(formData?.pointsRequired || apiData?.minimumPoints),
            name: formData?.name === apiData?.name ? undefined : formData?.name || apiData?.name,
          },
        })
        ?.then((res) => ({
          data: res,
        }));
    },
  },
  list: {
    render: () => {
      return (
        <div>
          <MembershipTierMain />
        </div>
      );
    },
  },
  create: {
    render: (helpers) => {
      const navigation = useNavigation();
      const { mutate } = useCreate();

      const onSubmit = async (formData: any) => {
        await mutate(
          {
            resource: resourceNames.membershiptier,
            values: {
              ...formData,
            },
          },
          {
            onSuccess: () => {
              navigation?.goBack();
            },
          }
        );
      };
      return <MembershipTierCreate onSubmit={onSubmit} />;
    },
  },
  edit: {
    render: (helpers) => {
      const { id } = useParams();
      const navigation = useNavigation();
      const { mutate } = useUpdate();

      if (!id) return <SomethingWentWrong />;

      const { data, isLoading } = useOne({
        resource: resourceNames?.membershiptier,
        id,
        metaData: {
          fields,
        },
      });
      const membershipTierData = data?.data as MembershipTier;

      const onSubmit = async (formData: any) => {
        await mutate(
          {
            id,
            resource: resourceNames.membershiptier,
            values: {
              data: formData,
              membershipTierData,
            },
          },
          {
            onSuccess: () => {
              navigation?.goBack();
            },
          }
        );
      };

      if (isLoading) return <Loading />;
      return (
        <MembershipTierCreate
          onSubmit={onSubmit}
          membershipTierData={membershipTierData}
          isUpdate
        />
      );
    },
  },
});
