import { GraphQLClient } from 'graphql-request';
import { type PatchedRequestInit } from 'graphql-request/dist/types';

export class GQLClient extends GraphQLClient {
  private static instance: GQLClient | null = null;
  public static apiUrl = '';

  private constructor(url: string, options?: PatchedRequestInit) {
    super(url, options);
    GQLClient.apiUrl = url;
  }

  public static getInstance(): GQLClient {
    const url = import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000/admin-api';

    if (GQLClient.instance === null) {
      GQLClient.instance = new GQLClient(url);
    }

    return GQLClient.instance;
  }
}
