import { SelectField, type SelectFieldProps } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { getControlRules, useFormField } from '../form.lib';
import type { MakeFormFieldProps } from '../form.types';

export type FormSelectFieldProps<Value extends string | number> = MakeFormFieldProps<
	SelectFieldProps<Value>
>;

export const FormSelectField = <Value extends string | number>({
	defaultValue,
	label,
	hideLabel,
	...props
}: FormSelectFieldProps<Value>) => {
	const controller = useFormField({
		name: props.name,
		rules: getControlRules(props),
		defaultValue,
	});
	const error = controller.fieldState.error;

	return (
		<SelectField
			status={error ? 'danger' : undefined}
			{...props}
			{...controller.field}
			onValue={controller.field.onChange}
			{...(hideLabel
				? {
						'aria-label': label,
				  }
				: {
						label,
				  })}
		/>
	);
};
