import React, { useEffect, useState } from 'react';
import { Button, Card, TextField } from '@scalingworks/react-admin-ui';
import { Form } from '@scalingworks/refine-react-admin';
import { useForm } from '@refinedev/react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { Divider } from '~/components';
import differenceWith from 'lodash/differenceWith';
import { Channel, GiftOption, UpdateChannelInput } from '~/api';
import { useApiUrl, useCustomMutation, useNotification, useTranslate } from '@refinedev/core';
import { GQLClient } from '~/config/gql-client';
import {
  UpdateInputType,
  createGiftOptionsFunction,
  deleteGiftOptionsFunction,
  updateGiftOptionsFunction,
  useActiveChannel,
  useGetGiftOptions,
} from '../hook';
import { PackagingTable, PackagingTableProps } from './table';
import { Props } from './props';
import { Controller } from 'react-hook-form';

export const PackagingSetting: React.FC<Props> = () => {
  const form = useForm();

  // ======================== API
  const {
    data: giftOptions,
    isLoading: giftLoading,
    refetch: refetchGiftOptions,
  } = useGetGiftOptions();
  const {
    data: channel,
    refetch: refetchChannel,
    isLoading: loadingChannel,
  } = useActiveChannel<Channel>();
  const { giftOptionsLabel } = channel?.data?.customFields || {};
  const gqlClient = GQLClient?.getInstance();
  const apiUrl = useApiUrl();
  const { mutate: updateChannel, isLoading: updatingChannel } = useCustomMutation();
  const [updateLoading, setUpdateLoading] = useState(false);
  const { open } = useNotification();
  const t = useTranslate();

  useEffect(() => {
    if (!giftLoading && !isEmpty(giftOptions?.data?.items)) {
      const defaultValue = giftOptions?.data?.items?.map((giftData: GiftOption) => {
        return {
          id: giftData?.id,
          packageName: giftData?.name,
          label: giftData?.label,
          price: giftData?.price,
          enabled: giftData?.enabled,
        };
      });
      form?.setValue('packaging', defaultValue);
    }

    if (!loadingChannel && !isEmpty(giftOptionsLabel)) {
      form?.setValue('giftOptionsLabel', giftOptionsLabel);
    }
  }, [giftLoading, giftOptions, loadingChannel, giftOptionsLabel]);

  const onUpdate = async (data: { packaging: UpdateInputType[]; giftOptionsLabel: string }) => {
    setUpdateLoading(true);

    const dataWithoutId = data?.packaging?.filter((subItem) => !subItem?.id);
    const dataWithId = data?.packaging?.filter((subItem) => !!subItem?.id);
    const dataRemoved = differenceWith(
      giftOptions?.data?.items,
      data?.packaging,
      (obj1: any, obj2: any) => obj1?.id === obj2?.id
    );

    try {
      if (!isEmpty(dataWithoutId)) {
        await createGiftOptionsFunction({
          input: dataWithoutId,
          gqlClient,
        });
      }

      if (!isEmpty(dataRemoved)) {
        await deleteGiftOptionsFunction({
          input: dataRemoved,
          gqlClient,
        });
      }

      if (!isEmpty(dataWithId)) {
        await updateGiftOptionsFunction({
          input: dataWithId,
          gqlClient,
        });
      }
      open?.({
        type: 'success',
        message: 'Success',
        description: t('packaging.update.success'),
      });

      updateChannel({
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          fields: ['__typename'],
          operation: 'updateChannel',
          variables: {
            input: {
              value: {
                id: channel?.data?.id,
                customFields: {
                  giftOptionsLabel: data.giftOptionsLabel,
                },
              } as UpdateChannelInput,
              type: 'UpdateChannelInput!',
            },
          },
        },
      });
    } catch (err) {
      open?.({
        type: 'error',
        message: 'Error',
        description: t('packaging.update.failed'),
      });
    } finally {
      refetchGiftOptions();
      refetchChannel();
      setUpdateLoading(false);
    }
  };

  const handleAddPackaging = () => {
    const emptyVal = {
      id: undefined,
      packageName: '',
      label: '',
      price: null,
      enabled: true,
    };

    if (isEmpty(form?.watch('packaging'))) {
      form?.setValue('packaging', [emptyVal]);
    } else {
      const watchVal: any[] = cloneDeep(form?.watch('packaging'));
      const combinedVal = watchVal?.concat([emptyVal]);
      form?.setValue('packaging', combinedVal);
    }
  };

  const packagingTableProps: PackagingTableProps = {
    form,
  };

  return (
    <section className="flex flex-col space-y-4">
      <section className="px-4 py-2">
        <div className="flex flex-row items-center justify-between w-full">
          <h3 className="font-bold text-lg">{t('packaging.name.packagingSetting')}</h3>
          <Button
            variant="solid"
            onClick={form?.handleSubmit(onUpdate as any)}
            disabled={updateLoading || updatingChannel}
            loading={updateLoading || updatingChannel}
          >
            {t('actions.update')}
          </Button>
        </div>
      </section>

      <Card>
        <Form form={form} onSubmit={onUpdate as any}>
          <Card.Header bordered>
            <h3 className="font-bold">{t('packaging.form.label')}</h3>
          </Card.Header>
          <Card.Body>
            <Controller
              name="giftOptionsLabel"
              defaultValue={giftOptionsLabel}
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  required
                  placeholder={t('packaging.placeholder.giftOption')}
                />
              )}
            />
          </Card.Body>
        </Form>
      </Card>

      <Card>
        <Form form={form} onSubmit={onUpdate as any}>
          <Card.Header bordered>
            <h3 className="font-bold">{t('packaging.name.packaging')}</h3>
          </Card.Header>
          <Card.Body>
            <div className="flex-1">
              <Button
                // className={`w-full ${!isEmpty(productVariants) && 'mb-6'}`}
                className={`w-full`}
                onClick={handleAddPackaging}
              >
                {t('actions.addPackaging')}
              </Button>
            </div>

            {!isEmpty(form?.watch('packaging')) && (
              <div className="mt-4">
                <Divider />
                <PackagingTable {...packagingTableProps} />
              </div>
            )}
          </Card.Body>
        </Form>
      </Card>
    </section>
  );
};
