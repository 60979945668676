import { getSdk } from '~/api';
import { GQLClient } from '~/config/gql-client';

const client = GQLClient.getInstance();

export const adminEmailExist = async (email: string, administratorId?: string): Promise<boolean> => {
  const existEmail = await getSdk(client).getAdministrators({
    options: {
      filter: {
        id: administratorId ? { notEq: administratorId } : undefined,
        emailAddress: { eq: email },
      },
    },
  });

  return !!existEmail?.getAdministrators?.totalItems;
};

export const adminPhoneExist = async (phone: string, administratorId?: string): Promise<boolean> => {
  const existEmail = await getSdk(client).getAdministrators({
    options: {
      filter: {
        id: administratorId ? { notEq: administratorId } : undefined,
        phoneNumber: { eq: phone },
      },
    },
  });

  return !!existEmail?.getAdministrators?.totalItems;
};
