import React, { useState } from 'react';
import { Button, Dialog } from '@scalingworks/react-admin-ui';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';
import { MultiCustomerSelection } from '../MultiSearchAndSelect';

export const BlastUserModal: React.FC<Props> = (props) => {
  const { onConfirm, open, setOpen, initialUserIds = [], loading } = props;

  // =================== STATES
  const [userIds, setUserIds] = useState<string[]>([]);

  // =================== HOOKS
  const t = useTranslate();

  // =================== VIEWS
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 z-20 xl:w-1/2">
          <Dialog.Title>{t('notification.selectCustomer', 'Select Customer')}</Dialog.Title>
          <div className="p-4">
            <MultiCustomerSelection
              className="h-32"
              useUserId
              onSelect={(ids) => setUserIds(ids)}
              initialOption={initialUserIds?.map((userId) => ({ label: '', value: userId }))}
            />
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              {t('common.cancel', {}, 'Cancel')}
            </Button>
            <Button
              disabled={!userIds}
              loading={loading}
              variant="solid"
              onClick={() => onConfirm?.(userIds!)}
            >
              {t('common.confirm', 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
