import { BaseRecord, useCustom, useCustomMutation } from '@refinedev/core';
import { MutateOptions, UseQueryOptions } from '@tanstack/react-query';
import { ResourceField } from '@scalingworks/refine-react-admin';
import {
  AdminOrderProcess,
  Order,
  ProcessOrderInput,
  ProcessOrderResult,
  ProductList,
} from '~/api';
import { productFields } from '../add-item';
import VariableOptions from 'gql-query-builder/build/VariableOptions';
import { TranslateFunction } from '~/config/types';
import { GQLClient } from '~/config/gql-client';

type ProductInputOptions = {
  variables?: VariableOptions;
  queryOptions?: UseQueryOptions<any, any>;
};

const url = GQLClient.apiUrl;

export const useProducts = <T extends BaseRecord>(options?: ProductInputOptions) => {
  const hook = useCustom<T>({
    method: 'get',
    url,
    metaData: {
      fields: productFields as ResourceField<ProductList>[],
      operation: 'getProducts',
      variables: options?.variables,
    },
    queryOptions: options?.queryOptions,
  });

  return hook;
};

type OrderInputOptions = {
  id: string;
  queryOptions?: UseQueryOptions<any, any>;
};

export const useOrder = <T extends BaseRecord>(options: OrderInputOptions) => {
  const hook = useCustom<T>({
    method: 'get',
    url,
    metaData: {
      fields: [
        {
          lines: [
            'id',
            'quantity',
            'linePrice',
            'isAddOn',
            'linePriceWithAddon',
            'discountLinePriceWithAddon',
            { customFields: ['specialInstructions'] },
            {
              snapshot: [
                'productVariantId',
                'productVariantId',
                {
                  addons: [
                    'group',
                    {
                      modifiers: [
                        'modifierId',
                        { variants: ['name', 'price', 'quantity', 'variantId'] },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              productVariant: [
                'id',
                'name',
                'price',
                'productId',
                'currencyCode',
                { product: [{ assets: ['id', 'source'], featuredAsset: ['id', 'source'] }] },
              ],
            },
          ],
        },
      ] as ResourceField<Order>[],
      operation: 'order',
      variables: {
        id: {
          value: options.id,
          type: 'ID!',
        },
      },
    },
    queryOptions: options.queryOptions,
  });

  return hook;
};

type UseProcessOrderOptions = {
  orderId: string;
  paymentMethodCode: string;
  t: TranslateFunction;
  mutateOptions?: MutateOptions<any, any, any>;
};
export const useProcessOrder = () => {
  const { mutate: processOrder, isLoading } = useCustomMutation<ProcessOrderResult>();

  const processOrderPayment = (options: UseProcessOrderOptions) => {
    const { orderId, paymentMethodCode, t, mutateOptions = {} } = options;
    processOrder(
      {
        method: 'post',
        url,
        values: {},
        meta: {
          fields: ['key', 'value'] as ResourceField<ProcessOrderResult>[],
          operation: 'processOrder',
          variables: {
            input: {
              value: {
                orderId,
                types: [AdminOrderProcess.AddManualPayment],
                data: {
                  ADD_MANUAL_PAYMENT: {
                    method: paymentMethodCode,
                  },
                },
              } as ProcessOrderInput,
              type: 'ProcessOrderInput!',
            },
          },
        },
        successNotification: {
          message: t('order.show.paymentArranged', 'Payment Arranged'),
          type: 'success',
        },
        errorNotification: {
          message: t('order.show.paymentArranged', 'Payment Arranged'),
          type: 'success',
        },
      },
      mutateOptions
    );
  };

  return { processOrderPayment, isLoading };
};

export default useProcessOrder;
