import React from 'react';
import SearchAndSelect, { SearchAndSelectProp } from '../base';

type Props = SearchAndSelectProp & {
  onSelect?: (id: string) => void;
};

export const PromoCodeSelection: React.FC<Props> = (props) => {
  const { onSelect, queryKey, ...restProps } = props;
  return (
    <SearchAndSelect
      {...restProps}
      useInputAsSearch
      onSelect={(id) => onSelect?.(id)}
      queryKey="getPromoCodes"
      getValueAndLabel={({ name, id, pointCost }) => {
        let label = `${name} (${pointCost} pts)`.trim();
        return {
          value: `${id}-space-${pointCost}`,
          label: label,
        };
      }}
      placeholder="Search Promo Code"
    />
  );
};

export type PromoCodeSelectionProps = Props;
