import React, { useState } from 'react';
import { Props } from './props';
import { Loading, ModalWrapper } from '~/components';
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import { TextInput } from '@scalingworks/react-admin-ui';
import { CancelOrderInput } from '~/api';

export const OrderCancellationModal: React.FC<Props> = (props) => {
  const { open, setOpen, onCompleted, orderId, lines } = props;

  // ======================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();

  // ======================== API
  const { isLoading, mutate } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data?.id) {
          setOpen(false);
          onCompleted?.();
        }
      },
    },
  });

  const onCancelOrder = async () => {
    mutate({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: [
          {
            operation: 'Order',
            fields: ['id'],
            fragment: true,
          },
        ],

        operation: 'cancelOrder',
        variables: {
          input: {
            type: 'CancelOrderInput!',
            value: {
              orderId,
              lines: lines?.map(({ id: orderLineId, quantity }) => ({
                orderLineId,
                quantity,
              })),
              reason: cancellationRemark,
            } as CancelOrderInput,
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('order.notification.cancel.failed'),
        type: 'error',
      },
      successNotification: {
        message: t('order.notification.cancel.success'),
        type: 'success',
      },
    });
  };

  // ======================== STATES
  const [cancellationRemark, setCancellationRemark] = useState<string>('');

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      contentClassName="px-8 py-6"
      onConfirm={onCancelOrder}
      title={t(`order.warnings.cancelOrder`)}
    >
      <section className="py-4">
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <TextInput
              type="textarea"
              onChange={(ev) => setCancellationRemark(ev.target.value)}
              placeholder={t('order.placeholder.cancellationRemark', {}, 'Reason')}
            />
          </div>
        )}
      </section>
    </ModalWrapper>
  );
};
