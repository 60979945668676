import React, { useMemo, useState } from 'react';
import { Table } from '@scalingworks/react-admin-ui';
import { SearchInput } from '@scalingworks/refine-react-admin';
import debounce from 'lodash/debounce';
import { Loading } from '../Loading';
import { Props } from './props';

export const TableBuilder: React.FC<Props> = (props) => {
  const {
    columns,
    data,
    allowSearch = true,
    loading = false,
    searchInputProps,
    headerWrapperProps,
  } = props;
  const [searchText, setSearchText] = useState<string>();

  const debouncedEventHandler = useMemo(
    () =>
      debounce((value) => {
        searchInputProps?.onChange && searchInputProps?.onChange(value);
      }, 1200),
    []
  );

  return (
    <div>
      <section {...headerWrapperProps} className={`pb-4 ${headerWrapperProps?.className}`}>
        {allowSearch && (
          // @ts-ignore
          <SearchInput
            {...searchInputProps}
            value={searchText}
            onChange={(e) => {
              setSearchText(e?.target?.value);
              debouncedEventHandler(e?.target?.value);
            }}
            fullWidthWhenContentNarrow={false}
            containerType="fixed"
          />
        )}
      </section>
      <Table>
        <Table.Thead className={loading ? `mb-10` : ``}>
          {columns?.map((col) => {
            return (
              <Table.Th>
                <span>{col?.header}</span>
              </Table.Th>
            );
          })}
        </Table.Thead>
        {!loading && (
          <Table.Tbody>
            {data?.map((rowData: any, rowIndex: any) => (
              <Table.Tr key={rowIndex}>
                {columns.map((column, colIndex) => {
                  return <Table.Td key={colIndex}>{column?.cell(rowData)}</Table.Td>;
                })}
              </Table.Tr>
            ))}
          </Table.Tbody>
        )}
      </Table>
      {loading && (
        <div className="flex-1 w-full pt-5">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default TableBuilder;
