import React from 'react';
import { Props } from './props';
import { Content } from '@scalingworks/refine-react-admin';
import { IconButton } from '@scalingworks/react-admin-ui';
import { useNavigation, useTranslate } from '@refinedev/core';
import { HiArrowLeft } from 'react-icons/hi';

export const ShowPageWrapper: React.FC<Props> = (props) => {
  const {
    resourceName,
    title,
    titleClassName,
    loading,
    extra,
    showBackButton = true,
    children,
    onBack = () => navigateTo.list(resourceName),
  } = props;

  const navigateTo = useNavigation();
  const t = useTranslate();

  const displayTitle = (
    <div className="flex flex-row items-center">
      {showBackButton && (
        <IconButton className="mr-2" onClick={onBack}>
          <HiArrowLeft size={25} color="black" />
        </IconButton>
      )}
      <h1 className={`text-2xl ${titleClassName}`}>{title}</h1>
    </div>
  );

  return (
    <Content className="h-full" title={displayTitle} isLoading={loading} extra={extra}>
      {children}
    </Content>
  );
};

export type ShowPageWrapperProps = Props;
