import React, { useEffect } from 'react';
import { Form } from '@scalingworks/refine-react-admin';
import { Props } from './props';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { FormDateRangeField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-date-range-field';
import { FormSelectField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-select-field';
import { DiscountType, DiscountType as Type } from '~/api';
import { isBetweenRange, toCamelCaseWord } from '~/resources/helpers';
import { FormNumberField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-number-field';
import { useTranslate } from '@refinedev/core';
import { DefaultCurrency } from '~/config/constant';
import { Card, Tag } from '@scalingworks/react-admin-ui';
import { BsGrid } from 'react-icons/bs';
import {
  mapDiscountPromotionStatus,
  statusColor,
} from '~/resources/marketing/discount-promotion/discount-promotion-resource';

export const DiscountPromotionForm: React.FC<Props> = (props) => {
  const { form, actionType = 'create', initialValues } = props;
  const { name, startDate, endDate, type, amount, enabled } = initialValues || {};

  // =================== HOOKS
  const t = useTranslate();

  // =================== VARIABLES
  const createMode = actionType === 'create';
  const editMode = actionType === 'update';
  const typeWatch = form.watch('type');
  const isFixed = typeWatch === DiscountType.Fixed;

  const status = mapDiscountPromotionStatus(enabled!);

  // =================== EFFECTS
  // NOTE: default value is not setting value in
  useEffect(() => {
    if (!typeWatch) {
      form.setValue('type', type || DiscountType.Fixed);
    }
  }, [typeWatch]);

  // =================== VIEWS
  return (
    <section className="flex flex-col space-y-4">
      <Card>
        <Card.Header className="flex flex-row items-center justify-between font-bold border-b">
          <h3>{t('common.general', 'General')}</h3>
          {editMode && <Tag className={`${statusColor[`${enabled}`]}`}>{status}</Tag>}
        </Card.Header>
        <Card.Body>
          <Form form={form}>
            <section className="flex flex-col space-y-4 xl:w-2/3">
              <FormTextField
                label={t('discountPromotions.form.name', {}, 'Name')}
                placeholder={t('discountPromotions.placeholder.name')}
                name="name"
                defaultValue={name}
                required
              />
              <FormDateRangeField
                fromDate={new Date()}
                label={t('discountPromotions.form.period', {}, 'Period')}
                placeholder={t('discountPromotions.placeholder.period')}
                name="period"
                defaultValue={{ from: startDate, to: endDate }}
                required
                disabled={editMode && isBetweenRange(startDate, endDate)}
              />
              <FormSelectField
                label={t('discountPromotions.form.type', {}, 'Discount Type')}
                placeholder={t('discountPromotions.placeholder.discountType')}
                name="type"
                options={Object.keys(Type).map((key) => ({
                  label: t(`discountPromotions.type.${key.toLowerCase()}`),
                  value: Type[key as keyof typeof Type],
                }))}
                defaultValue={type || DiscountType.Fixed}
                required
                disabled={editMode}
              />
              <FormNumberField
                label={t('discountPromotions.form.value', {}, 'Value')}
                placeholder={'0'}
                name="amount"
                defaultValue={amount?.toString()}
                prefix={
                  isFixed ? <span className="text-smoke-500">{DefaultCurrency}</span> : undefined
                }
                suffix={!isFixed ? <span className="text-smoke-500">{'%'}</span> : undefined}
                required
                max={!isFixed ? 100 : undefined}
                disabled={editMode}
              />
            </section>
          </Form>
        </Card.Body>
      </Card>

      {createMode && (
        <section className="w-full text-center">
          <div className="p-8 flex flex-col items-center justify-center space-y-4">
            <BsGrid className="!text-primary-500" size={55} />
            <p className="text-smoke-600 w-96">{t('discountPromotions.hints.create')}</p>
          </div>
        </section>
      )}
    </section>
  );
};
