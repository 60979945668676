import { useNotification } from '@refinedev/core';
import { useCallback } from 'react';
import { authStorageKey } from '~/config/auth-provider';

/**
 *
 * @param headers other headers besides authorization (auto included)
 */
export const useDownload = (extraHeaders = {}) => {
  const notif = useNotification();
  const headers = {
    ...extraHeaders,
    authorization: `Bearer ${localStorage.getItem(authStorageKey)}`,
  };

  const downloadInvoice = useCallback(
    async (id: string, type?: string, url?: string) => {
      try {
        url = import.meta.env.VITE_ORDER_INVOICE_URL;
        if (!url) throw new Error('Invalid Url');

        let target = `${url}/${id}`;
        if (type) target += `/${type}`;

        const response = await fetch(target, { headers });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `order-invoice-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        notif?.open?.({ message: 'Failed to download invoice', type: 'error' });
        console.error('Fetch failed: ', e);
      }
    },
    [headers]
  );

  return { downloadInvoice };
};

// =================== Order Invoice
