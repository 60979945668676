import {
  type LogicalFilter,
  type CrudFilters,
  type CrudSorting,
  ConditionalFilter,
} from '@refinedev/core';
import startCase from 'lodash/startCase';
import numeral from 'numeral';
import { SortOrder } from '~/api';
import { dateFormatter } from '~/resources/helpers';

// ===================== INPUT TYPE
export const gqlInputName = {
  listOption: (name: string) => `${name}ListOptions`,
  create: (name: string) => `Create${startCase(name).replaceAll(' ', '')}Input`,
  createMany: (name: string) => `[Create${startCase(name).replaceAll(' ', '')}Input!]`,
  update: (name: string) => `Update${startCase(name).replaceAll(' ', '')}Input`,
  updateMany: (name: string) => `[Update${startCase(name).replaceAll(' ', '')}Input!]`,
};

// ===================== SORTING
export const SortDict: Record<string, string> = {
  asc: SortOrder.Asc,
  desc: SortOrder.Desc,
};

export const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    for (const key of sort) {
      const { field, order } = key;
      return {
        [field]: SortDict[order.toLowerCase()],
      };
    }
  }
};

// ===================== FILTERING
export const FilterDict: Record<string, string> = {
  // String OperatorsF
  contains: 'contains',
  containss: 'contains',
  eq: 'eq',
  in: 'in',
  null: 'isNull',
  ncontains: 'notContains',
  ncontainss: 'notContains',
  ne: 'notEq',
  nin: 'notIn',
  // Number Operators
  between: 'between',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  // Date Operators
  after: 'after',
  before: 'before',
  // for listingTab (it returns 'or' operator, but we'll use it
  // as 'and' in vendure)
  or: 'or',
  nbetween: '',
  nnull: '',
  startswith: '',
  nstartswith: '',
  startswiths: '',
  nstartswiths: '',
  endswith: '',
  nendswith: '',
  endswiths: '',
  nendswiths: '',
  and: '',
};

// number & date using the same 'gt' 'lt' operator
// but vendure using 'after' 'before' for date operator
const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.valueOf());
};

const getOperatorAndValue = (operator: string, value: any): { value: any; operator: string } => {
  if (isValidDate(new Date(value))) {
    // date will be start/end of day e.g. '2023-04-28T00:00:00.000Z'
    if (operator === 'gt') {
      return { operator: FilterDict.after, value };
    }
    if (operator === 'gte') {
      // e.g. after '2023-04-28T23:59:59.999Z'
      return {
        operator: FilterDict.after,
        value: new Date(new Date(value).valueOf() - 1).toISOString(),
      };
    }
    if (operator === 'lt') {
      return { operator: FilterDict.before, value };
    }
    if (operator === 'lte') {
      return { operator: FilterDict.before, value };
    }
  }

  return { operator: FilterDict[operator], value };
};

export const generateFilter = (filters?: CrudFilters) => {
  const formattedFilters: Record<string, any> = {};
  let search = '';

  filters?.forEach((filter) => {
    const {
      key,
      field,
      value: rawValue,
      operator: rawOperator,
    } = filter as LogicalFilter & ConditionalFilter;

    // Convert the raw operator and value to Vendure-compatible operator and value.
    const { operator: vendureOperator, value } = getOperatorAndValue(rawOperator, rawValue);

    if (vendureOperator) {
      // Handle listingTab (set under resource.list.tab)
      // Strictly having only either under tab or filterControl for
      // the specific field otherwise it'll be overwrite
      if (key === 'listingTab' && vendureOperator === 'or') {
        const innerValues = Array.isArray(value?.[0]) ? value?.[0] : [value?.[0]];

        (innerValues as LogicalFilter[])?.map((val) => {
          const { field, operator, value: tabValue } = val;

          const vendureOpt = FilterDict[operator];
          if (!vendureOpt) {
            console.error('Invalid filter operator: ', operator);
            return;
          }
          formattedFilters[field] = {
            [vendureOpt]: tabValue,
          };
        });
        return;
      }

      if (field === 'search') {
        search = rawValue;
        return;
      }
      // If the field doesn't exist in the formattedFilters, initialize it as an empty object.
      if (!formattedFilters[field]) {
        formattedFilters[field] = {};
      }

      // If the rawOperator is 'between' or 'nbetween', handle the 'between' or 'notBetween' logic.
      if (rawOperator === 'between' || rawOperator === 'nbetween') {
        // Set betweenKey based on whether the operator is 'between' or 'nbetween'.
        const betweenKey = rawOperator === 'between' ? 'between' : 'notBetween';

        // If the betweenKey doesn't exist in the formattedFilters for the given field, initialize it as an empty object.
        if (!formattedFilters[field][betweenKey]) {
          formattedFilters[field][betweenKey] = {};
        }

        // Determine if we should set the 'start' or 'end' value for the betweenKey.
        const subKey = formattedFilters[field][betweenKey].start ? 'end' : 'start';

        // Set the 'start' or 'end' value for the betweenKey.
        formattedFilters[field][betweenKey][subKey] = value;
      } else {
        // For other operators, simply set the vendureOperator and its value for the field.
        formattedFilters[field][vendureOperator] = value;
      }
    }
  });

  return { vendureFilter: formattedFilters, search };
};

/** numeral format to add `‘,’` as thousand separator */
export const numeralThousandFormat = (number: string | number, haveDecimal?: boolean): string => {
  const format = `0,0${haveDecimal && '.00'}`;
  return numeral(number)?.format(format);
};

export const renderActivePeriod = ({ startAt, endAt }: { startAt: string; endAt: string }) => {
  const firstMonth = dateFormatter(startAt, 'MMM');
  const secondMonth = dateFormatter(endAt, 'MMM');
  const firstYear = dateFormatter(startAt, 'YYYY');
  const secondYear = dateFormatter(endAt, 'YYYY');

  const isRenderFirstYear = firstYear !== secondYear;
  const isRenderFirstMonth = firstMonth !== secondMonth || isRenderFirstYear;

  return `${isRenderFirstMonth ? firstMonth + ' ' : ''}${isRenderFirstYear ? firstYear + ' ' : ''}${
    isRenderFirstMonth ? 'to ' : ''
  }${secondMonth} ${secondYear}`;
};
