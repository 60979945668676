import camelCase from 'lodash/camelCase';
import { PhoneField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const renderPhoneField = ({ input: { item, register, setValue, watch } }: InputProps) => {
  const { title, name, required, placeholder, defaultValue, props } = item;
  const formName = camelCase(name || title);
  const watchValue = watch(formName);

  /**
   * NOTE: took the ref & onChange
   * because we want the value with country code
   */
  const { onChange, ref, ...rest } = register(formName, { required });

  useEffect(() => {
    if (defaultValue && !watchValue) {
      setValue(formName, defaultValue);
    }
  }, [watchValue, defaultValue]);

  return (
    <PhoneField
      {...props}
      label={title}
      required={required}
      layout="responsive"
      placeholder={placeholder}
      defaultValue={defaultValue}
      onValue={(phone) => setValue(formName, phone)}
      value={watchValue}
      {...rest}
    />
  );
};
