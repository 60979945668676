import React, { useEffect, useState } from 'react';
import { Props } from './props';
import { AddressModalForm, DetailRenderer, TriggerConfirmModal } from '~/components';
import { joinAddress } from '~/resources/helpers';
import { useApiUrl, useCustomMutation, useNotification, useTranslate } from '@refinedev/core';
import { Button, IconButton, Tag } from '@scalingworks/react-admin-ui';
import { FiCopy, FiEdit3 } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';
import { AiOutlinePlus } from 'react-icons/ai';
import { Address, CreateAddressInput, UpdateAddressInput } from '~/api';
import isEmpty from 'lodash/isEmpty';
import { BsFillHouseAddFill } from 'react-icons/bs';

type FormState = {
  open: boolean;
  mode?: 'add' | 'edit';
  initialValue?: Partial<Address>;
};

type DeleteState = {
  open: boolean;
  addressId?: string;
};

export const AddressContent: React.FC<Props> = (props) => {
  const { customerId, sortedAddress, refetch, customerFullName, isLoading } = props;

  // ===================== HOOKS
  const t = useTranslate();
  const notification = useNotification();
  const apiUrl = useApiUrl();

  // ===================== STATES
  const [formState, setFormState] = useState<FormState>({
    open: false,
  });
  const [deleteState, setDeleteState] = useState<DeleteState>({ open: false });

  // ===================== API
  const onAddressMutated = () => {
    setFormState({ open: false });
    refetch();
  };
  const { mutate: addAddress, isLoading: addingAddress } = useCustomMutation();
  const { mutate: updateAddress, isLoading: updatingAddress } = useCustomMutation();
  const { mutate: deleteAddress } = useCustomMutation({
    mutationOptions: {
      onSettled: () => {
        setDeleteState({ open: false });
        refetch();
      },
    },
  });

  // ===================== EVENTS
  const onDeleteAddress = () => {
    const addressId = deleteState.addressId!;

    deleteAddress({
      method: 'post',
      url: apiUrl,
      values: {},
      metaData: {
        fields: ['success'],
        variables: {
          id: {
            value: addressId,
            type: 'ID!',
          },
        },
        operation: 'deleteCustomerAddress',
      },
      errorNotification: {
        message: t('customer.address.delete.failed', {}, 'Failed to delete address'),
        type: 'error',
      },
      successNotification: {
        message: t('customer.address.delete.success', {}, 'Address deleted'),
        type: 'success',
      },
    });
  };

  const onSubmit = (address: Partial<Address>) => {
    if (!formState.mode) {
      // not suppose to happen
      console.warn(`Invalid form state`);
      return;
    }

    if (formState.mode === 'add') {
      addAddress(
        {
          method: 'post',
          url: apiUrl,
          values: {},
          metaData: {
            fields: ['id'],
            operation: 'createCustomerAddress',
            variables: {
              input: {
                value: address as CreateAddressInput,
                type: 'CreateAddressInput!',
              },
              customerId: {
                value: customerId,
                type: 'ID!',
              },
            },
          },
          errorNotification: {
            message: t('customer.address.create.failed', {}, 'Failed to add address'),
            type: 'error',
          },
          successNotification: {
            message: t('customer.address.create.success', {}, 'Address added'),
            type: 'success',
          },
        },
        { onSuccess: () => onAddressMutated() }
      );
    } else if (formState.mode === 'edit') {
      const { __typename, country, id, ...restAddr } = address;

      updateAddress(
        {
          method: 'post',
          url: apiUrl,
          values: {},
          metaData: {
            fields: ['id'],
            operation: 'updateCustomerAddress',
            variables: {
              input: {
                value: {
                  id,
                  ...restAddr,
                } as UpdateAddressInput,
                type: 'UpdateAddressInput!',
              },
            },
          },
          errorNotification: {
            message: t('customer.address.update.failed', {}, 'Failed to update address'),
            type: 'error',
          },
          successNotification: {
            message: t('customer.address.update.success', {}, 'Address updated'),
            type: 'success',
          },
        },
        { onSuccess: () => onAddressMutated() }
      );
    }
  };

  // ===================== EFFECTS
  useEffect(() => {
    // reset when closed
    if (!formState.open) setFormState({ open: false });
  }, [formState.open]);

  // ===================== VIEWS
  const addressFormTitle = (mode: 'add' | 'edit') => {
    const dict = {
      add: {
        title: t('customer.address.name.add', {}, 'Add New Address'),
        confirmTitle: t('actions.add', {}, 'Add'),
      },
      edit: {
        title: t('customer.address.name.edit'),
        confirmTitle: t('actions.update', {}, 'Update'),
      },
    };
    return dict[mode];
  };
  return (
    <>
      <TriggerConfirmModal
        visible={deleteState.open}
        onOpenChange={(open) => setDeleteState((prev) => ({ ...prev, open }))}
        onPressConfirm={() => onDeleteAddress()}
        renderTrigger={() => <></>}
        description={
          <span>{t('warnings.deleteConfirmation', { resource: t('common.address') })}</span>
        }
      />
      {/* destroy on close */}
      {formState.open && (
        <AddressModalForm
          title={addressFormTitle(formState.mode!).title}
          confirmTitle={addressFormTitle(formState.mode!).confirmTitle}
          open={formState.open}
          setOpen={(open) => setFormState((prev) => ({ ...prev, open }))}
          onSubmit={(addr) => onSubmit(addr as Partial<Address>)}
          type="Address"
          buttonLoading={addingAddress || updatingAddress}
          initialValue={formState.initialValue}
        />
      )}
      <section className="flex flex-col space-y-4 my-2">
        {sortedAddress?.map((address, index) => {
          const {
            fullName,
            phoneNumber,
            defaultBillingAddress: defaultBill,
            defaultShippingAddress: defaultShip,
            ...restAdr
          } = address;
          return (
            <section key={index} className="bg-smoke-100 rounded-lg">
              <DetailRenderer
                title={
                  <section className="flex flex-row justify-between px-4 py-2">
                    <div className="flex flex-row items-center space-x-2">
                      {/* <h1 className="font-semibold">
                        {t('customer.details.address', undefined, `Address ${index + 1}`)}
                      </h1> */}
                      {defaultBill && (
                        <Tag color="purple" className="!text-sm">
                          {t(
                            'customer.details.address.defaultBilling',
                            undefined,
                            'Default Billing'
                          )}
                        </Tag>
                      )}
                      {defaultShip && (
                        <Tag color="purple" className="!text-sm">
                          {t(
                            'customer.details.address.defaultShipping',
                            undefined,
                            'Default Shipping'
                          )}
                        </Tag>
                      )}
                    </div>
                    <div className="flex flex-row space-x-2">
                      <IconButton
                        onClick={() =>
                          setFormState({ open: true, mode: 'edit', initialValue: address })
                        }
                      >
                        <FiEdit3 className="!text-primary-500" size={30} />
                      </IconButton>

                      <IconButton
                        onClick={() => setDeleteState({ open: true, addressId: address.id! })}
                      >
                        <HiOutlineTrash className="!text-primary-500" size={30} />
                      </IconButton>
                    </div>
                  </section>
                }
                resource="customer"
                loading={isLoading}
                data={{
                  fullName,
                  contact: phoneNumber,
                  address: joinAddress(restAdr),
                }}
                render={{
                  address: (adr) => (
                    <span className="font-medium">
                      {adr}{' '}
                      <span>
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(adr).then(() =>
                              notification?.open?.({
                                message: t('common.copy', undefined, 'Copied to clipboard'),
                                type: 'success',
                              })
                            );
                          }}
                        >
                          <FiCopy className="!text-primary-500" />
                        </IconButton>
                      </span>
                    </span>
                  ),
                }}
              />
            </section>
          );
        })}

        {isEmpty(sortedAddress) && (
          <div className="flex flex-col items-center justify-center space-y-2 rounded-lg bg-smoke-100 p-4 py-8">
            <BsFillHouseAddFill className="!text-primary-500" size={40} />
            <h3 className="text-smoke-700">
              {t('customer.placeholder.address', { customer: customerFullName })}
            </h3>
          </div>
        )}
        <Button
          className="border-primary-500"
          onClick={() => setFormState({ open: true, mode: 'add' })}
        >
          <div className="flex flex-row items-center justify-center space-x-2 text-primary-500">
            <AiOutlinePlus className="!text-primary-500" />
            <span>{t('customer.address.name.add', {}, 'Add New Address')}</span>
          </div>
        </Button>
      </section>
    </>
  );
};
