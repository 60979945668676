import React from 'react';
import { useNavigation, useTranslate } from '@refinedev/core';
import { Button } from '@scalingworks/react-admin-ui';
import { FaBoxOpen } from 'react-icons/fa';
import { resourceNames } from '~/resources/resource-names';
import { CiViewList } from 'react-icons/ci';
import { BsGrid } from 'react-icons/bs';

const Title: React.FC<any> = (props) => {
  return <h3 className="text-smoke-700 text-lg">{props.children}</h3>;
};

type NoProductProps = {
  collectionName: string;
};

const NoProductInCollection: React.FC<NoProductProps> = (props) => {
  const { collectionName = '' } = props;
  // ====================== HOOKS
  const t = useTranslate();
  const navigate = useNavigation();

  // ====================== VIEWS

  return (
    <div className="flex flex-col space-y-4 items-center justify-center">
      {/* getColor */}
      <FaBoxOpen size={60} color="#3E3E3E" />
      <Title>
        {t(
          'order.create.noProducts',
          `Looks like no products in ${collectionName} collection yet.`
        )}
      </Title>
      <Button onClick={() => navigate.list(resourceNames.product)} variant="solid">
        {t('order.create.addProductsNow', {}, 'Add Products Now')}
      </Button>
    </div>
  );
};

const NoCollection: React.FC = () => {
  const t = useTranslate();
  const navigate = useNavigation();

  return (
    <div className="flex flex-col space-y-4 items-center justify-center">
      <FaBoxOpen size={60} color="#3E3E3E" />
      <Title>{t('order.create.noCollection', {}, 'You have not create any collection yet.')}</Title>
      <Button onClick={() => navigate.list(resourceNames.product)} variant="solid">
        {t('order.create.addProductsNow', {}, 'Go Create Now')}
      </Button>
    </div>
  );
};

const NoSelected: React.FC<{ onBack: () => void }> = (props) => {
  const t = useTranslate();

  return (
    <div className="flex flex-col space-y-4 items-center justify-center py-8">
      {/* getColor */}
      <div className="flex flex-col items-center justify-center space-y-2">
        <BsGrid className="!text-primary-500" size={55} />
        <Title>{t('order.create.noSelected', {}, 'No Product Selected')}</Title>
      </div>
      <Button variant="solid" onClick={props.onBack}>
        {t('order.create.backToCollection', {}, 'Back to Collections')}
      </Button>
    </div>
  );
};

export const AddItemComponent = {
  NoProductInCollection,
  NoCollection,
  NoSelected,
};
