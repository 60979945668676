import React, { useState } from 'react';
import { PromoCode, PromoCodeStatus, PromoCodeType } from '~/api';
import { useDelete, useNavigation, useTranslate } from '@refinedev/core';
import { ActionButton, TriggerConfirmModal, ViewBuilder, flexRow } from '~/components';
import { FiChevronDown } from 'react-icons/fi';
import { resourceNames } from '~/resources/resource-names';
import { numeralThousandFormat, renderActivePeriod } from '~/config/helper';
import { renderStatusTag, renderTextWithAffixes } from '../../helpers';
import { Props } from './props';
import { ViewConfigProps } from '~/components/ViewBuilder/props';

export const PromoShow: React.FC<Props> = (props) => {
  const { helpers } = props;

  const promoData = helpers?.queryResult?.data?.data as PromoCode;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate } = useDelete();
  const { edit, list } = useNavigation();
  const t = useTranslate();

  return (
    <ViewBuilder
      {...helpers}
      title={t('promoCodes.details.name')}
      items={[
        {
          cardTitle: t('common.general'),
          cardHeaderExtra: () => {
            return (
              <div>
                <ActionButton
                  renderTrigger={() => {
                    return (
                      <button
                        className="border-primary-500 text-primary-500 rounded p-2"
                        style={{ borderWidth: 1, alignItems: 'center', ...flexRow }}
                        type="button"
                      >
                        {t('common.action').toUpperCase()}
                        <FiChevronDown size={20} className="ml-1" />
                      </button>
                    );
                  }}
                  actions={[
                    {
                      label: t('actions.edit'),
                      name: 'edit',
                      onAction: async () => {
                        await edit(resourceNames?.promoCode, promoData?.id);
                      },
                    },
                    {
                      label: t('actions.delete'),
                      name: 'delete',
                      onAction: async () => {
                        await mutate({
                          resource: resourceNames?.promoCode,
                          id: promoData?.id,
                        });
                        list(resourceNames?.promoCode);
                      },
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <TriggerConfirmModal
                              visible={showDeleteConfirmation}
                              onOpenChange={setShowDeleteConfirmation}
                              onPressConfirm={onAction}
                            />
                          </button>
                        );
                      },
                    },
                  ]}
                />
              </div>
            );
          },
          config: [
            {
              title: t('promoCodes.form.name'),
              data: [promoData?.name],
              type: 'text',
            },
            {
              title: t('promoCodes.form.code'),
              data: [promoData?.voucherCode],
              type: 'text',
            },
            {
              title: t('promoCodes.form.image'),
              data: promoData?.imageUrls || [],
              type: 'image',
            },
            {
              title: t('promoCodes.form.quantity'),
              data: [promoData?.quantity],
              type: 'text',
            },
            {
              title: t('promoCodes.form.type'),
              data: [t(`promoCodes.type.${promoData?.type}`)],
              type: 'text',
            },
            {
              title: t('promoCodes.form.voucher'),
              data: [
                renderTextWithAffixes({
                  text: numeralThousandFormat(promoData?.voucherValue, true),
                  affixText: promoData?.type === PromoCodeType?.FixedValue ? 'MYR ' : '%',
                  position: promoData?.type === PromoCodeType?.FixedValue ? 'left' : 'right',
                }),
              ],
              type: 'text',
            },
            ...(promoData?.type === PromoCodeType?.DiscountPercentage
              ? [
                  {
                    title: 'Capped At',
                    data: [`MYR ${numeralThousandFormat(promoData?.cappedDiscount ?? 0, true)}`],
                    type: 'text',
                  } as ViewConfigProps,
                ]
              : []),
            {
              title: t('promoCodes.form.minSpend'),
              data: [`MYR ${numeralThousandFormat(promoData?.minimumSpendAmount, true)}`],
              type: 'text',
            },
            {
              title: t('promoCodes.form.membershipTiers'),
              data: promoData?.membershipTiers?.map((subItem) => subItem?.name) as string[],
              type: 'text',
            },
            {
              title: t('promoCodes.form.status'),
              data: [promoData?.status],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      {renderStatusTag(
                        promoData?.status === PromoCodeStatus?.Active,
                        resourceNames.promoCode
                      )}
                    </div>
                  </div>
                );
              },
            },
            {
              title: t('promoCodes.form.activePeriod'),
              data: [
                `${renderActivePeriod({
                  endAt: promoData?.endAt,
                  startAt: promoData?.startAt,
                })}`,
              ],
              type: 'text',
            },
            {
              title: t('promoCodes.form.description'),
              data: [`${promoData?.description || '-'}`],
              type: 'text',
            },
            {
              title: t('promoCodes.form.criteria'),
              data: [`${promoData?.redeemCriteria || '-'}`],
              type: 'text',
            },
            {
              title: t('promoCodes.form.tnc'),
              data: [`${promoData?.termCondition || '-'}`],
              type: 'text',
            },
          ],
        },
      ]}
    />
  );
};
