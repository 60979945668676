import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import {
  Button,
  Card,
  NumberField,
  Switch,
  SwitchField,
  TextField,
} from '@scalingworks/react-admin-ui';
import head from 'lodash/head';
import React, { useEffect, useState } from 'react';
import {
  Channel,
  PaymentMethodsQuery,
  UpdatePaymentMethodInput,
  UpdatePaymentSettingInput,
  getSdk,
} from '~/api';
import { DetailRenderer, Loading } from '~/components';
import { useActiveChannel } from '../hook';
import { GQLClient } from '~/config/gql-client';
import { TaxConfig } from './props';

const CreditCardCode = import.meta.env.VITE_CREDIT_CARD_METHOD;

const TaxFields = {
  taxName: 'Tax Name',
  sst: 'Tax Rate',
  service: 'Service Charge Rate',
  regNo: 'Tax Reg. No.',
};

const extractChannelTaxInfo = (channel?: Channel) => {
  const { customFields, defaultTaxRates } = channel || {};
  const taxRate = head(defaultTaxRates);

  return {
    sst: taxRate?.value || 0,
    service: customFields?.serviceCharge || 0,
    enableService: customFields?.serviceChargeEnabled || false,
    regNo: taxRate?.customFields?.registrationNumber || undefined,
    taxName: taxRate?.name || undefined,
  };
};

export const PaymentSetting: React.FC = () => {
  // ========================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const gqlClient = GQLClient?.getInstance();
  const [paymentMethods, setPaymentMethods] =
    useState<PaymentMethodsQuery['paymentMethods']['items']>();
  const [loadingMethods, setLoadingMethods] = useState(true);
  const [refetchVal, setRefetchVal] = useState(false);
  // ========================== API
  const {
    data: channelData,
    isLoading: loadingChannel,
    refetch: refetchChannel,
  } = useActiveChannel<Channel>();
  useEffect(() => {
    setLoadingMethods(true);
    getSdk(gqlClient)
      ?.PaymentMethods({
        options: {
          filter: {
            code: { eq: CreditCardCode },
          },
          take: 1,
        },
      })
      ?.then((res) => {
        setPaymentMethods(res?.paymentMethods?.items);
        setLoadingMethods(false);
      });
  }, [refetchVal]);

  const creditCard = head(paymentMethods);
  const channel = channelData?.data;
  const { regNo, service, sst, enableService, taxName } = extractChannelTaxInfo(channel);

  const { mutate: updateSetting, isLoading: updatingSetting } = useCustomMutation();

  // ========================== STATES
  const [ccEnabled, setCCEnabled] = useState(creditCard?.enabled || false);
  const [taxConfig, setTaxConfig] = useState<TaxConfig>({
    sst,
    service,
    enableService,
    regNo,
    taxName,
  });

  // ========================== VARIABLES
  const creditCardName = creditCard?.name || 'Credit Card';

  // ========================== EVENTS
  // On update Methods & Tax Config
  const onUpdate = () => {
    updateSetting(
      {
        method: 'post',
        url: apiUrl,
        meta: {
          fields: ['id'],
          operation: 'updatePaymentSetting',
          variables: {
            input: {
              value: {
                registrationNumber: taxConfig?.regNo,
                serviceCharge: taxConfig?.service,
                taxRate: taxConfig?.sst,
                serviceChargeEnabled: taxConfig?.enableService,
                taxName: taxConfig?.taxName,
              } as UpdatePaymentSettingInput,
              type: 'UpdatePaymentSettingInput!',
            },
          },
        },
        values: {},
        errorNotification: {
          message: t('finance.update.tax.failed', 'Failed to update tax info'),
          type: 'error',
        },
        successNotification: {
          message: t('finance.update.tax.success', 'Tax info updated successfully'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchChannel();
          setRefetchVal((prev) => !prev);
        },
      }
    );

    updateSetting(
      {
        method: 'post',
        url: apiUrl,
        meta: {
          fields: ['id'],
          operation: 'updatePaymentMethod',
          variables: {
            input: {
              value: {
                id: creditCard?.id,
                enabled: ccEnabled,
              } as UpdatePaymentMethodInput,
              type: 'UpdatePaymentMethodInput!',
            },
          },
        },
        values: {},
        errorNotification: {
          message: t('finance.update.payment.failed', 'Failed to update payment method'),
          type: 'error',
        },
        successNotification: {
          message: t('finance.update.payment.success', 'Payment method updated'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchChannel();
          setRefetchVal((prev) => !prev);
        },
      }
    );
  };

  // ========================== EFFECTS
  useEffect(() => {
    const taxInfo = extractChannelTaxInfo(channel);
    setTaxConfig((prev) => ({
      ...prev,
      ...taxInfo,
    }));
  }, [channelData]);

  useEffect(() => {
    setCCEnabled(creditCard?.enabled || false);
  }, [paymentMethods]);

  // ========================== VIEWS
  if (loadingChannel || loadingMethods) return <Loading />;
  return (
    <section className="flex flex-col space-y-4">
      <section className="px-4 py-2">
        <div className="flex flex-row items-center justify-between w-full">
          <h3 className="font-bold text-lg">
            {t('finance.name.financeSetting', 'Finance Setting')}
          </h3>
          <Button variant="solid" onClick={onUpdate} loading={updatingSetting}>
            {t('common.update', 'Update')}
          </Button>
        </div>
      </section>
      <Card>
        <Card.Header bordered>
          <h3 className="font-bold">{t('finance.name.paymentMethod', 'Payment Method')}</h3>
        </Card.Header>
        <Card.Body>
          <DetailRenderer
            loading={loadingMethods}
            resource="finance"
            data={{ [`${creditCardName}`]: ccEnabled }}
            render={{
              [`${creditCardName}`]: () => (
                <Switch
                  className="mt-1"
                  isLoading={loadingMethods}
                  checked={ccEnabled}
                  onCheckedChange={(checked) => setCCEnabled(checked)}
                />
              ),
            }}
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header bordered>
          <h3 className="font-bold">{t('setting.tax', 'Tax')}</h3>
        </Card.Header>
        <Card.Body>
          <div className="flex flex-col space-y-4 2xl:w-1/2">
            <TextField
              label={t('finance.form.name')}
              placeholder={t('finance.placeholder.name', 'GST / SST')}
              value={taxConfig?.taxName}
              onValue={(newTaxName) => setTaxConfig((prev) => ({ ...prev, taxName: newTaxName }))}
            />

            <NumberField
              label={t('finance.form.rate')}
              value={taxConfig?.sst.toString() || '0'}
              onValue={(newSST) => setTaxConfig((prev) => ({ ...prev, sst: +newSST }))}
              maxLength={3}
              max={100}
              suffix="%"
            />

            <TextField
              label={t('finance.form.regNo')}
              placeholder={t('finance.placeholder.regNo')}
              value={taxConfig?.regNo}
              onValue={(newRegNo) => setTaxConfig((prev) => ({ ...prev, regNo: newRegNo }))}
            />

            <div className="flex flex-col py-4 space-y-4">
              <SwitchField
                label={t('finance.form.serviceCharge', 'Service Charge')}
                checked={taxConfig?.enableService}
                onCheckedChange={(check) => {
                  setTaxConfig((prev) => ({ ...prev, enableService: check }));
                }}
              />
              <NumberField
                label={t('finance.form.serviceChargeRate')}
                value={taxConfig?.service?.toString() || '0'}
                onValue={(newService) =>
                  setTaxConfig((prev) => ({ ...prev, service: +newService }))
                }
                maxLength={3}
                max={100}
                suffix="%"
                disabled={!taxConfig?.enableService}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
};
