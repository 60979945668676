import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormBuilder } from '~/components';
import { renderDataSelection } from '~/components/FormBuilder/DataSelection';
import { ProductVariantSelection } from '~/components/SearchAndSelect';
import { FormItemProps } from '~/components/FormBuilder/props';
import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import { MembershipTier, RewardType, getSdk } from '~/api';
import isEmpty from 'lodash/isEmpty';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';
import camelCase from 'lodash/camelCase';

export const RewardCreate: React.FC<Props> = (props) => {
  const { onSubmit, rewardData } = props;
  const [membershipTiersData, setMembershipTiersData] = useState<MembershipTier[]>([]);
  const gqlClient = useGraphQLClient();
  const isUpdate = !isEmpty(rewardData);
  const t = useTranslate();

  useEffect(() => {
    getSdk(gqlClient)
      ?.getMembershipTiers({
        options: {
          skip: 0,
          take: 50,
        },
      })
      ?.then((subItem) => {
        setMembershipTiersData(subItem?.getMembershipTiers?.items);
        return subItem;
      });
  }, []);

  return (
    <div>
      <FormBuilder
        resourceName={resourceNames.reward}
        title={t(`rewards.${isUpdate ? 'update' : 'create'}.withName`, {
          name: t(`rewards.name.reward`),
        })}
        isUpdate={isUpdate}
        items={({ register, setValue, watch }) => {
          const finalOptions: FormItemProps[] = [
            {
              name: 'name',
              title: t('rewards.form.name'),
              placeholder: t('rewards.placeholder.name'),
              required: true,
              defaultValue: rewardData?.name,
            },
            {
              name: 'pointCost',
              title: t('rewards.form.point'),
              type: 'number',
              placeholder: t('rewards.placeholder.pointCost'),
              required: true,
              suffix: 'PTS',
              defaultValue: rewardData?.pointCost ?? 0,
            },
            {
              name: 'quantity',
              title: t('rewards.form.quantity'),
              type: 'number',
              placeholder: t('rewards.placeholder.quantity'),
              required: true,
              defaultValue: rewardData?.quantity,
            },
            {
              name: 'image',
              title: t('rewards.form.image'),
              type: 'upload',
              placeholder: t('product.placeholder.images'),
              hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
              fileType: 'image',
              defaultValue: rewardData?.imageUrls,
            },
            {
              name: 'type',
              title: t('rewards.form.type'),
              type: 'select',
              defaultValue: rewardData?.type ?? RewardType.InStore,
              options: [RewardType.InStore, RewardType.InApp].map((value) => ({
                label: t(`rewards.type.${camelCase(value)}`),
                value,
              })),
            },
            {
              name: 'products',
              title: t('rewards.form.products'),
              render: () => {
                return (
                  <div className="mt-5">
                    {renderDataSelection({
                      input: {
                        item: {
                          title: t('rewards.form.products'),
                          name: 'variantId',
                          required: false,
                          defaultValue: rewardData?.productVariantId,
                        },
                        register,
                        setValue,
                        watch,
                      },
                      selectionComponent: ProductVariantSelection,
                      selectionProps: {
                        required: false,
                        defaultValue: rewardData?.productVariantId,
                        initialOption: {
                          label: rewardData?.productVariantName,
                          value: rewardData?.productVariantId,
                        },
                      },
                    })}
                  </div>
                );
              },
            },
            {
              name: 'membershipTiers',
              title: t('rewards.form.tiers'),
              type: 'select',
              multiSelect: true,
              options: membershipTiersData?.map((subItem) => ({
                label: subItem?.name,
                value: subItem?.id,
              })),
              placeholder: t('rewards.placeholder.tier'),
              defaultValue: rewardData?.membershipTiers?.map((subItem) => subItem?.id),
              required: true,
            },
            {
              name: 'startDate',
              title: t('rewards.form.start'),
              type: 'date-picker',
              required: true,
              defaultValue: rewardData?.startAt,
              placeholder: t('rewards.placeholder.start'),
            },
            {
              name: 'endDate',
              title: t('rewards.form.end'),
              type: 'date-picker',
              defaultValue: rewardData?.endAt,
              placeholder: t('rewards.placeholder.end'),
            },
            {
              name: 'description',
              title: t('rewards.form.description'),
              multiline: true,
              placeholder: t('rewards.placeholder.description'),
              required: true,
              defaultValue: rewardData?.description,
            },
            {
              name: 'redeemCriteria',
              title: t('rewards.form.criteria'),
              multiline: true,
              placeholder: t('rewards.placeholder.criteria'),
              required: true,
              defaultValue: rewardData?.redeemCriteria,
            },
            {
              name: 'termsConditions',
              title: t('rewards.form.tnc'),
              multiline: true,
              placeholder: t('rewards.placeholder.tnc'),
              required: true,
              defaultValue: rewardData?.termCondition,
            },
          ];

          return finalOptions;
        }}
        onSubmit={(data) => {
          onSubmit?.(data);
        }}
      />
    </div>
  );
};
