import React from 'react';
import SearchAndSelect, { MultiSearchAndSelectProp } from '../base';
import { ProductListOptions, ProductType } from '~/api';
import { useTranslate } from '@refinedev/core';

type Props = Omit<MultiSearchAndSelectProp, 'queryKey'> & {
  isWithName?: boolean;
  onSelect?: (id: string) => void;
};

export const MultiModifierSelection: React.FC<Props> = (props) => {
  const { onSelect, isWithName, ...restProps } = props;
  const t = useTranslate();

  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getProducts"
      getValueAndLabel={({ id, name }) => {
        let label = `${name}`.trim();
        return {
          value: isWithName ? `${id}-split-${label}` : `${id}`,
          label: label,
        };
      }}
      variables={{
        options: {
          filter: { type: { eq: ProductType.Modifier }, enabled: { eq: true } },
        } as ProductListOptions,
      }}
      placeholder={t('product.placeholder.modifiers')}
    />
  );
};

export type MultiModifierSelectionProps = Props;
