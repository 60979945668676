import { BaseRecord, useApiUrl, useCustom } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { Channel, GiftOptionList, UpdateGiftOptionInput, getSdk } from '~/api';
import { GQLClient } from '~/config/gql-client';

export const useActiveChannel = <T extends BaseRecord>() => {
  const apiUrl = useApiUrl();
  const hook = useCustom<T>({
    method: 'get',
    url: apiUrl,
    meta: {
      fields: [
        'id',
        'code',
        {
          tnc: ['content', 'id', 'type'],
          help: ['content', 'id', 'type'],
          faq: ['content', 'id', 'type'],
          privacy: ['content', 'id', 'type'],
          shipping: ['content', 'id', 'type'],
          refund: ['content', 'id', 'type'],
          customFields: ['serviceCharge', 'serviceChargeEnabled'],
          defaultTaxRates: [
            'id',
            'enabled',
            'name',
            'value',
            { customFields: ['registrationNumber'] },
          ],
        },
      ] as ResourceField<Channel>[],
      operation: 'activeChannel',
    },
  });

  return hook;
};

export const useGetGiftOptions = <T extends BaseRecord>() => {
  const apiUrl = useApiUrl();
  const hook = useCustom<T>({
    method: 'get',
    url: apiUrl,
    meta: {
      fields: [
        { items: ['id', 'name', 'label', 'price', 'enabled'] },
      ] as ResourceField<GiftOptionList>[],
      operation: 'getGiftOptions',
      variables: {
        option: {
          value: {
            sort: {
              name: 'DESC',
            },
          },
          type: 'GiftOptionListOptions',
        },
      },
    },
  });

  return hook;
};

export type UpdateInputType = Omit<UpdateGiftOptionInput, 'price'> & {
  price?: string;
  packageName: string;
};

export const createGiftOptionsFunction = async ({
  input,
  gqlClient,
}: {
  input: UpdateInputType[];
  gqlClient: GQLClient;
}) => {
  const promises = [];
  for (let inputItem of input) {
    const { enabled, packageName, price } = inputItem;
    const promise = await getSdk(gqlClient)?.createGiftOption({
      input: {
        enabled,
        label: { en: packageName },
        name: packageName,
        price: parseFloat(price || ''),
        description: packageName,
      },
    });
    promises?.push(promise);
  }

  return Promise.all(promises);
};

export const updateGiftOptionsFunction = async ({
  input,
  gqlClient,
}: {
  input: UpdateInputType[];
  gqlClient: GQLClient;
}) => {
  const promises = [];
  for (let inputItem of input) {
    const { id, enabled, packageName, price } = inputItem;
    const promise = await getSdk(gqlClient)?.updateGiftOption({
      input: {
        id,
        enabled,
        label: { en: packageName },
        name: packageName,
        price: parseFloat(price || ''),
      },
    });
    promises?.push(promise);
  }

  return Promise.all(promises);
};

export const deleteGiftOptionsFunction = async ({
  input,
  gqlClient,
}: {
  input: UpdateInputType[];
  gqlClient: GQLClient;
}) => {
  const promises = [];
  for (let inputItem of input) {
    const { id } = inputItem;
    const promise = await getSdk(gqlClient)?.deleteGiftOption({
      deleteGiftOptionId: id,
    });
    promises?.push(promise);
  }

  return Promise.all(promises);
};
