import camelCase from 'lodash/camelCase';
import { TextField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const renderTextField = ({ input: { item, register, setValue, watch } }: InputProps) => {
  const { title, name, required, multiline, placeholder, prefix, defaultValue, props, suffix } =
    item;
  const formName = camelCase(name || title);

  useEffect(() => {
    if (defaultValue) setValue(formName, defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      {...props}
      required={required}
      prefix={prefix}
      label={title}
      layout="responsive"
      placeholder={placeholder}
      type={multiline ? 'textarea' : undefined}
      defaultValue={defaultValue}
      value={watch(formName)}
      suffix={suffix}
      {...register(formName, { required })}
      className={multiline ? 'h-24' : ''}
    />
  );
};
