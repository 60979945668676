import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CustomerSelection,
  PromoCodeSelection,
  RewardSelection,
} from '~/components/SearchAndSelect';
import { NumberField, SelectField } from '@scalingworks/react-admin-ui';
import { Controller } from 'react-hook-form';
import { Membership, RedemptionHistoryType, RewardType, getSdk } from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { FormBuilder, FormBuilderRefProps, MultiCustomerSelection } from '~/components';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';

export type MembershipCombine = Membership & {
  customerId: string;
  isPointEnough?: boolean;
};

export const RedeemForm: React.FC<Props> = (props) => {
  const { onPressSubmit, loading } = props;

  const [pointCost, setPointCost] = useState();
  const [membershipData, setMembershipData] = useState<MembershipCombine[]>([]);
  const [customerIds, setCustomerIds] = useState<any[]>([]);
  const [rewardType, setRewardType] = useState<RedemptionHistoryType>();
  const formBuilderRef = useRef<FormBuilderRefProps>();
  const t = useTranslate();
  const gqlClient = useGraphQLClient();
  const useRefFormHook = formBuilderRef?.current?.useFormHook;

  useEffect(() => {
    if (!isEmpty(customerIds)) {
      for (let customerId of customerIds) {
        if (!isEmpty(membershipData?.find((item) => item?.customerId === customerId))) continue;

        getSdk?.(gqlClient)
          ?.getMembership({
            userId: customerId,
          })
          ?.then((res) => {
            setMembershipData((prev) => {
              const combinedData = [...prev, { ...(res?.getMembership as Membership), customerId }];
              return combinedData;
            });
          });
      }
    }
  }, [customerIds]);

  const allowSubmit =
    !isEmpty(membershipData) &&
    !isEmpty(pointCost) &&
    isEmpty(membershipData?.find((subItem) => !subItem?.isPointEnough));

  const renderFields = ({
    title,
    render,
    marginTop = true,
  }: {
    title: string;
    render: React.ReactElement;
    marginTop?: boolean;
  }) => {
    return (
      <div className={`flex flex-1 flex-row items-center ${marginTop && 'mt-5'}`}>
        <p style={{ flex: 1 }}>{title}</p>
        <div style={{ flex: 3 }}>{render}</div>
      </div>
    );
  };

  const renderRewardsList = () => {
    const checkPointValid = (point: number) => {
      setMembershipData((prev) => {
        const clonePrev = cloneDeep(prev);
        const newArr = clonePrev?.map((subItem) => {
          return {
            ...subItem,
            isPointEnough: subItem?.pointBalance > point,
          };
        });
        return newArr;
      });
    };

    switch (rewardType) {
      case RedemptionHistoryType?.VoucherCode:
        return renderFields({
          title: t('redemptionHistory.form.voucherCode'),
          render: (
            <Controller
              control={useRefFormHook?.control}
              key={rewardType}
              name={'reward'}
              render={({ field: { onChange, value } }) => {
                return (
                  <PromoCodeSelection
                    onSelect={(result) => {
                      const splitVal = result?.split('-space-');
                      const idVal = splitVal?.[0];
                      const pointVal = splitVal?.[1];
                      useRefFormHook?.setValue('pointCost', pointVal);
                      setPointCost(pointVal);
                      checkPointValid(pointVal);
                      onChange(idVal);
                    }}
                    value={value}
                    queryKey="getPromoCodes"
                  />
                );
              }}
            />
          ),
        });
      case RedemptionHistoryType?.InStoreItem:
      case RedemptionHistoryType?.InAppItem:
        return renderFields({
          title: t('redemptionHistory.form.rewardItem'),
          render: (
            <Controller
              control={useRefFormHook?.control}
              key={rewardType}
              name={'reward'}
              render={({ field: { onChange, value } }) => {
                return (
                  <RewardSelection
                    onSelect={(result) => {
                      const splitVal = result?.split('-space-');
                      const idVal = splitVal?.[0];
                      const pointVal = splitVal?.[1];
                      useRefFormHook?.setValue('pointCost', pointVal);
                      setPointCost(pointVal);
                      checkPointValid(pointVal);
                      onChange(idVal);
                    }}
                    includePoints={false}
                    value={value}
                    queryKey="getRewards"
                    variables={{
                      type:
                        rewardType === RedemptionHistoryType?.InStoreItem
                          ? RewardType?.InStore
                          : RewardType?.InApp,
                    }}
                  />
                );
              }}
            />
          ),
        });
      default:
        return undefined;
    }
  };

  return (
    <FormBuilder
      resourceName={resourceNames.redemptionHistory}
      ref={formBuilderRef as any}
      title={t('redemptionHistory.redeem.name')}
      disableSubmit={!allowSubmit}
      loading={loading}
      onSubmit={onPressSubmit}
      renderBody={({ control, watch, setValue }) => {
        return (
          <div>
            {renderFields({
              title: t('redemptionHistory.form.customer'),
              render: (
                <div>
                  <Controller
                    control={control}
                    name={'customer'}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <MultiCustomerSelection
                          value={value}
                          max={5}
                          className="mb-1"
                          onSelect={(val) => {
                            setCustomerIds(val);
                            setMembershipData((prev) => {
                              return prev.filter((item) => val.includes(item.customerId));
                            });
                            onChange(val);
                          }}
                          useUserId
                        />
                      );
                    }}
                  />
                  <span className="m-1">{t('redemptionHistory.max')}</span>
                </div>
              ),
            })}

            {!isEmpty(watch('customer')) &&
              renderFields({
                title: t('redemptionHistory.form.availablePoints'),
                render: (
                  <div>
                    {membershipData?.map((subItem, index) => {
                      return (
                        <div style={{ marginTop: index !== 0 ? 20 : 0 }}>
                          <p>{`${subItem?.firstName} ${subItem?.lastName}`}</p>
                          <p style={{ color: '#777986' }} className="text-sm">
                            {`${subItem?.pointBalance} pts`}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ),
              })}

            {renderFields({
              title: t('redemptionHistory.form.type'),
              render: (
                <Controller
                  control={control}
                  name={'type'}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SelectField
                        {...props}
                        label={''}
                        options={Object.keys(RedemptionHistoryType).map((key) => ({
                          label: t(
                            `redemptionHistory.type.${
                              RedemptionHistoryType[key as keyof typeof RedemptionHistoryType]
                            }`
                          ),
                          value: RedemptionHistoryType[key as keyof typeof RedemptionHistoryType],
                        }))}
                        placeholder={t('redemptionHistory.placeholder.type')}
                        value={value}
                        onValue={(val) => {
                          setPointCost(undefined);
                          setValue('reward', undefined);
                          setRewardType(val);
                          onChange?.(val);
                        }}
                      />
                    );
                  }}
                />
              ),
            })}

            {renderRewardsList()}

            {pointCost &&
              renderFields({
                title: t('redemptionHistory.form.pointCost'),
                render: (
                  <Controller
                    control={control}
                    name={'pointCost'}
                    render={({ field: { value } }) => {
                      return (
                        <NumberField
                          value={value}
                          disabled
                          suffix={'PTS'}
                          renderError={
                            allowSubmit ? (
                              <div />
                            ) : (
                              <div>
                                {membershipData
                                  ?.filter((subItem) => !subItem?.isPointEnough)
                                  ?.map((subItem) => {
                                    return (
                                      <p className="text-error-300 text-sm mt-3">{`${subItem?.firstName} ${subItem?.lastName} have insufficient points`}</p>
                                    );
                                  })}
                              </div>
                            )
                          }
                        />
                      );
                    }}
                  />
                ),
              })}
          </div>
        );
      }}
    />
  );
};
