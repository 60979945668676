import camelCase from 'lodash/camelCase';
import { FieldGroup, Label } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { DateTextInput } from '~/components/DateTextInput';
import { useEffect, useState } from 'react';

export const renderDateTextInput = ({ input: { item, setValue, register, watch } }: InputProps) => {
  const { title, name, required, defaultValue, props } = item;
  const formName = camelCase(name || title);

  useEffect(() => {
    if (defaultValue && !watch(formName)) setValue(formName, defaultValue);
  }, [watch(formName), defaultValue]);

  return (
    <FieldGroup layout="responsive">
      <Label showRequiredIndicator={required}>{title}</Label>
      <DateTextInput
        {...props}
        onValue={(value) => setValue(formName, value)}
        defaultValue={defaultValue}
        {...register(formName, { required })}
      />
    </FieldGroup>
  );
};
