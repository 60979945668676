import React from 'react';
import { Props } from './props';
import { AlertDialog, Button } from '@scalingworks/react-admin-ui';

export const ActionModalWithTrigger: React.FC<Props> = (props) => {
  const {
    triggerText,
    visible,
    title,
    confirmButtonText,
    onOpenChange,
    onPressConfirm,
    onPressCancel,
    renderBody,
    renderTrigger,
  } = props;

  const PADDING_SIZE = 20;

  const renderTriggerLogic = () => {
    if (renderTrigger) return renderTrigger(triggerText);
    return <span className={'flex-1'}>{triggerText || `Action`}</span>;
  };

  return (
    <AlertDialog.Root open={visible} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger asChild>{renderTriggerLogic()}</AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content style={{ padding: 0 }}>
          {/* HEADER */}
          <div style={{ padding: PADDING_SIZE }}>
            <AlertDialog.Title style={{ padding: 0 }}>{title || `Modal Action`}</AlertDialog.Title>
          </div>
          <div className="w-full h-0.5 bg-gray-200" />

          {/* BODY */}
          <div style={{ padding: PADDING_SIZE }}>
            <AlertDialog.Description style={{ padding: 0 }}>
              {renderBody && renderBody()}
            </AlertDialog.Description>
          </div>

          {/* FOOTER */}
          <div
            style={{
              paddingLeft: PADDING_SIZE,
              paddingRight: PADDING_SIZE,
              paddingBottom: PADDING_SIZE,
            }}
          >
            <AlertDialog.Footer className="justify-end">
              <AlertDialog.Cancel asChild className="border-primary-500">
                <Button className="text-primary-500" onClick={onPressCancel}>
                  {'Cancel'}
                </Button>
              </AlertDialog.Cancel>
              <Button
                variant={'solid'}
                onClick={() => {
                  onOpenChange(false);
                  onPressConfirm && onPressConfirm();
                }}
              >
                {confirmButtonText || 'Confirm'}
              </Button>
            </AlertDialog.Footer>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
