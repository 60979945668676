import React, { useState } from 'react';
import { useCreate, useNavigation, useNotification, useTranslate } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button } from '@scalingworks/react-admin-ui';

import { BannerForm, ShowPageWrapper } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { Asset, CreateBannerInput, getSdk } from '~/api';
import { GQLClient } from '~/config/gql-client';

export const BannerCreatePage: React.FC = () => {
  // ========================= HOOKS
  const t = useTranslate();
  const form = useForm();
  const navigateTo = useNavigation();
  const notif = useNotification();

  // ========================= HOOKS
  const [handlingAsset, setHandlingAsset] = useState(false);

  // ========================= API
  const { mutate: createBanner, isLoading: creating } = useCreate({
    mutationOptions: { onSettled: () => setHandlingAsset(false) },
  });
  const client = GQLClient.getInstance();

  // ========================= EVENTS
  const onCreate = async (data: any) => {
    const { name, period, type, description, _url, rewardId, productId, image } = data;
    const startDate = new Date(period?.from).toISOString();
    const endDate = period?.to ? new Date(period.to).toISOString() : undefined;

    setHandlingAsset(true);
    const assetRes = await getSdk(client).createAssets({ input: [{ file: image?.[0] }] });
    const assetsData = assetRes?.createAssets as Asset[];

    if (!assetsData?.[0]?.id) {
      console.warn(`[Failed to get asset]`, assetsData);
      notif?.open?.({
        message: t('common.somethingWrong', 'Something went wrong, Please try again'),
        type: 'error',
      });
      setHandlingAsset(false);
      return;
    }

    createBanner(
      {
        resource: resourceNames.banner,
        values: {
          name,
          assetId: assetsData?.[0]?.id,
          startDate,
          endDate,
          type,
          description,
          url: _url,
          rewardId,
          productIds: productId ? [productId] : undefined,
        } as CreateBannerInput,
        errorNotification: {
          message: t('banner.create.failed', {}, 'Failed to create banner'),
          type: 'error',
        },
        successNotification: {
          message: t('banner.create.success', {}, 'Banner created'),
          type: 'success',
        },
      },
      {
        onSuccess: (data) => {
          navigateTo.list(resourceNames.banner);
        },
      }
    );
  };

  // ========================= VIEWS
  return (
    <div className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.banner}
        title={t('banners.create.name', {}, 'Create Banner')}
        extra={
          <Button
            loading={creating || handlingAsset}
            onClick={form.handleSubmit(onCreate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.create', {}, 'Create')}
          </Button>
        }
      >
        <BannerForm form={form} />
      </ShowPageWrapper>
    </div>
  );
};
