import { Button, Dialog, Label } from '@scalingworks/react-admin-ui';
import React, { useEffect, useState } from 'react';
import {
  CreateOutletFulfillmentInput,
  DayType,
  OutletFulfillmentType,
  OutletFulfillmentStatus as StatusType,
} from '~/api';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import { RadioGroup } from '~/components';
import {
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useNotification,
  useTranslate,
} from '@refinedev/core';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useForm } from '@refinedev/react-hook-form';
import { FormItemProps } from '~/components/FormBuilder/props';
import { Props } from './props';
import { OperationTimeRange } from '~/components/outlet/OperationTimeRange';
import dayjs from 'dayjs';
import { sortByTimeOutletOperation } from '~/resources/helpers';
import { TimeFormat24Hours, days } from '~/config/constant';

export const OutletEditModal: React.FC<Props> = (props) => {
  const { open, setOpen, initialStatus, onCompleted, data } = props;
  const navigateTo = useNavigation();
  const notification = useNotification();
  const t = useTranslate();
  const useFormHook = useForm({ mode: 'onChange' });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
    getValues,
    control,
    clearErrors,
    setError,
  } = useFormHook;
  const {} = control;
  const apiUrl = useApiUrl();

  const [selected, setSelected] = useState<boolean>();
  const status = [StatusType.Active, StatusType.Inactive];
  const { isLoading, mutate: updateOutletFulfillments } = useCustomMutation({
    mutationOptions: {
      onSettled: (data, error) => {
        setOpen(false);
        if (error) {
          notification?.open?.({ message: 'Fulfillment update failed', type: 'error' });
        } else {
          notification?.open?.({ message: 'Fulfillment Updated', type: 'success' });
        }
        onCompleted?.();
      },
    },
  });

  useEffect(() => {
    setSelected(initialStatus === StatusType.Active);
  }, [open]);

  const onUpdateStatus = async () => {
    const result = getValues();
    const transformedData: { day: DayType; openingTime: string; closingTime: string }[] = [];

    for (const key in result) {
      const day = key.replace(/[0-9]$/, ''); // Remove the numeric suffix from the key
      const [openingTime, closingTime] = result[key];
      const dayType = () => {
        switch (day) {
          case 'monday':
            return DayType.Monday;
          case 'tuesday':
            return DayType.Tuesday;
          case 'wednesday':
            return DayType.Wednesday;
          case 'thursday':
            return DayType.Thursday;
          case 'friday':
            return DayType.Friday;
          case 'saturday':
            return DayType.Saturday;
          case 'sunday':
          default:
            return DayType.Sunday;
        }
      };
      transformedData.push({
        day: dayType(),
        openingTime: openingTime && dayjs(openingTime).format(TimeFormat24Hours),
        closingTime: closingTime && dayjs(closingTime).format(TimeFormat24Hours),
      });
    }

    updateOutletFulfillments({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: ['id'],
        operation: 'updateOutletFulfillments',
        variables: {
          input: {
            type: 'CreateOutletFulfillmentInput!',
            value: {
              status: selected ? StatusType.Active : StatusType.Inactive,
              type: data?.type as OutletFulfillmentType,
              operations: transformedData?.filter((e) => e?.openingTime && e?.closingTime),
            } as CreateOutletFulfillmentInput,
          },
        },
      },
      values: {},
    });
  };

  const renderChildComponent = () => {
    if (selected) {
      return (
        <div className="flex flex-row items-start mt-3">
          <Label>{t(`outlet.form.hours`)}</Label>
          <div className="ml-4">
            {/* <form> */}
            <div className="mb-3">
              {map(days, (e, index) => {
                const filteredData = data?.operatingTimes?.find(
                  (time: any) => time?.day?.toUpperCase() === e?.toUpperCase()
                );
                const inputVal = {
                  input: {
                    item: {
                      defaultValue:
                        filteredData?.times && sortByTimeOutletOperation(filteredData?.times),
                      name: e,
                    } as FormItemProps,
                    register,
                    watch,
                    setValue,
                    control,
                    setError,
                    formState,
                    clearErrors,
                  },
                };
                return <OperationTimeRange key={e + index} inputProps={inputVal} />;
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  const renderContent = () => {
    return (
      <div>
        <RadioGroup
          isField
          label={t('common.status')}
          defaultValue={initialStatus}
          options={status.map((status) => ({
            label: t(`outlet.status.${status.toLowerCase()}`),
            value: status,
            props: {
              className: 'border-primary',
            },
          }))}
          onValueChange={(status: StatusType) => {
            setSelected(StatusType.Active === status);
          }}
        />
        {renderChildComponent()}
      </div>
    );
  };
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="z-10 absolute h-full w-full" />
        <Dialog.Content className="fixed top-1/3 z-20 w-2/3">
          <Dialog.Title>{t('actions.updateStatus')}</Dialog.Title>
          {selected !== undefined && <div className="p-4">{renderContent()}</div>}

          <div className="flex flex-row justify-end space-x-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              disabled={!isEmpty(formState?.errors)}
              loading={isLoading}
              variant="solid"
              className={'bg-success-400'}
              onClick={onUpdateStatus}
            >
              {t('actions.confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
