import { type UseControllerProps, useController } from 'react-hook-form';
import { isDefined, isNil, isObject, isString } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { inferLabelFromName } from '../../lib/inference';
import type { ConcreteControlsOverride, ControlSection, CreateDataInput } from '../../types';
import type {
	CommonControlConfig,
	ControlComponent,
	ControlConfig,
	ControlItem,
	ControlRecord,
	FormRenderItem,
	FormSectionRenderItem,
} from './form.types';

export const inferControlConfig = (
	defaultValue: unknown,
	name: string,
	componentConfigDefaults: CommonControlConfig
): ControlConfig | undefined => {
	if (isNil(defaultValue)) {
		return;
	}

	switch (typeof defaultValue) {
		case 'string':
			return {
				type: 'text',
				config: {
					...componentConfigDefaults,
					label: inferLabelFromName(name),
				},
			};

		case 'boolean':
			return {
				type: 'switch',
				config: {
					...componentConfigDefaults,
					label: inferLabelFromName(name),
				},
			};

		case 'number':
			return {
				type: 'number',
				config: {
					...componentConfigDefaults,
					label: inferLabelFromName(name),
				},
			};

		default:
			break;
	}

	return;
};

export const deriveControls = <Values extends object>(
	defaultValues: CreateDataInput<Values>,
	controlsOverride: ConcreteControlsOverride<Values> = {},
	inferSections: boolean,
	forceHideLabel?: boolean
): {
	renderItems: Array<FormRenderItem<Values[keyof Values]>>;
	controls: Array<ControlItem<Values[keyof Values]>>;
} => {
	const {
		components = {},
		componentConfigDefaults: globalConfigDefaults = {},
		sections,
	} = controlsOverride;

	const controls: Array<ControlItem<Values[keyof Values]>> = [];
	const seenControls = new Set<string>();

	const groupMap = new Map<ControlSection<any>, Array<FormSectionRenderItem>>();
	const addToGroup = (group: ControlSection<any>, item: FormSectionRenderItem) => {
		const currentItems = groupMap.get(group);

		if (currentItems) {
			currentItems.push(item);
		} else {
			groupMap.set(group, [item]);
		}
	};

	const ungroupedItems: Array<FormSectionRenderItem> = [];

	function computeSectionGroupAndConfig(
		fieldName: string
	):
		| undefined
		| { group: ControlSection<any>; getItem: (item: ControlItem<any>) => FormSectionRenderItem } {
		if (sections) {
			for (const group of sections) {
				const matchedConfig = group.items.find((sectionItem) =>
					isString(sectionItem) ? sectionItem === fieldName : sectionItem.field === fieldName
				);

				if (matchedConfig) {
					return {
						group,
						getItem: (item) =>
							isString(matchedConfig)
								? {
										control: item,
										span: 1,
								  }
								: {
										control: item,
										span: matchedConfig.span,
								  },
					};
				}
			}
		}

		return undefined;
	}

	function computeControl(name: string, defaultValue: any, fieldPrefixes: string[]) {
		const fieldName = [...fieldPrefixes, name].join('.');
		seenControls.add(fieldName);
		const providedComponent = components[fieldName as keyof typeof components];

		const groupAndConfig = computeSectionGroupAndConfig(fieldName);

		const componentConfigDefaults =
			groupAndConfig?.group.componentConfigDefaults ?? globalConfigDefaults;

		if (!isNil(providedComponent)) {
			if (providedComponent !== false) {
				if (
					!Array.isArray(providedComponent) &&
					isObject(providedComponent) &&
					'component' in providedComponent
				) {
					const customControl: ControlItem<Values[keyof Values]> = {
						name: fieldName,
						definition: {
							required: componentConfigDefaults.required,
							...(providedComponent as any),
						},
						defaultValue,
						label: providedComponent.label,
					};

					controls.push(customControl);

					if (groupAndConfig) {
						addToGroup(groupAndConfig.group, groupAndConfig.getItem(customControl));
					} else {
						ungroupedItems.push({
							control: customControl,
							span: 1,
						});
					}
				} else if (Array.isArray(providedComponent)) {
					const [
						innerControls,
						{ label = '', required = componentConfigDefaults.required, ...validation } = {},
					] = providedComponent;
					const [defaultInnerValues] = Array.isArray(defaultValue) ? defaultValue : [];

					controls.push({
						name: fieldName,
						validation: {
							...validation,
							required,
						},
						label,
						defaultValue: defaultInnerValues,
						children: deriveControls(
							defaultInnerValues ?? {},
							{
								components: innerControls as ControlRecord<any>,
								componentConfigDefaults: componentConfigDefaults,
							},
							inferSections,
							true
						).controls as Array<ControlItem<any>>,
					});
				} else {
					const control = providedComponent as ControlConfig | ControlComponent<any>;

					const item: ControlItem<any> = {
						name: fieldName,
						definition:
							'component' in control
								? {
										required: componentConfigDefaults.required,
										...control,
								  }
								: ({
										...control,
										config: {
											hideLabel: forceHideLabel,
											...componentConfigDefaults,
											...control.config,
										},
								  } as ControlConfig),
						defaultValue: defaultValue as any,
						label: 'component' in control ? control.label : control.config.label,
					};

					controls.push(item);

					if (groupAndConfig) {
						addToGroup(groupAndConfig.group, groupAndConfig.getItem(item));
					} else {
						ungroupedItems.push({
							control: item,
							span: 1,
						});
					}
				}
			}
		} else {
			if (isObject(defaultValue) && !Array.isArray(defaultValue)) {
				Object.entries(defaultValue).forEach(([innerField, innerDefaultValue]) =>
					computeControl(innerField, innerDefaultValue, fieldPrefixes.concat(name))
				);
			} else {
				const nameParts = name.split('.');

				const inferredControl = inferControlConfig(defaultValue, nameParts[nameParts.length - 1], {
					hideLabel: forceHideLabel,
					...componentConfigDefaults,
				});

				if (inferredControl) {
					const item = {
						name: fieldName,
						definition: inferredControl,
						label: inferredControl.config.label,
						defaultValue: defaultValue as any,
					};

					controls.push(item);

					if (groupAndConfig) {
						addToGroup(groupAndConfig.group, groupAndConfig.getItem(item));
					} else {
						ungroupedItems.push({
							control: item,
							span: 1,
						});
					}
				}
			}
		}
	}

	Object.entries(defaultValues).forEach(([name, defaultValue]) =>
		computeControl(name, defaultValue, [])
	);

	Object.entries(components).forEach(([name, providedConfig]) => {
		if (seenControls.has(name)) {
			return;
		}

		const controlConfig = providedConfig as ControlConfig | ControlComponent<any> | false;

		if (controlConfig) {
			const groupAndConfig = computeSectionGroupAndConfig(name);

			const componentConfigDefaults =
				groupAndConfig?.group.componentConfigDefaults ?? globalConfigDefaults;

			const item = {
				name,
				definition:
					'component' in controlConfig
						? {
								required: componentConfigDefaults.required,
								...controlConfig,
						  }
						: ({
								...controlConfig,
								config: {
									hideLabel: forceHideLabel,
									...componentConfigDefaults,
									...controlConfig.config,
								},
						  } as ControlConfig),
				label: 'component' in controlConfig ? controlConfig.label : controlConfig.config.label,
			};

			controls.push(item);

			if (groupAndConfig) {
				addToGroup(groupAndConfig.group, groupAndConfig.getItem(item));
			} else {
				ungroupedItems.push({
					control: item,
					span: 1,
				});
			}
		}
	});

	const groups: Array<FormRenderItem<Values[keyof Values]> & { index?: number }> = Array.from(
		groupMap,
		([group, items]) => ({
			items,
			title: group.title,
			index: sections && sections.indexOf(group),
		})
	).sort((a, b) => {
		if (a.index && b.index) {
			return a.index - b.index;
		}

		return 0;
	});

	return sections || inferSections
		? {
				renderItems:
					ungroupedItems.length > 0
						? groups.concat({
								items: ungroupedItems,
						  })
						: groups,
				controls,
		  }
		: {
				renderItems: controls,
				controls,
		  };
};

export const getControlRules = (props: {
	required?: boolean;
	minLength?: number;
	max?: number | string;
	min?: number | string;
	pattern?: RegExp | string;
}) => ({
	required: props.required,
	minLength: props.minLength && {
		value: props.minLength,
		message: `must be at least ${props.minLength} character`,
	},
	max: isDefined(props.max)
		? {
				value: props.max,
				message: `must be less than ${props.max}`,
		  }
		: undefined,
	min: isDefined(props.min)
		? {
				value: props.min,
				message: `must be at least ${props.min}`,
		  }
		: undefined,
	pattern:
		isDefined(props.pattern) && !isString(props.pattern)
			? {
					value: props.pattern,
					message: `is not following required format`,
			  }
			: undefined,
});

type ArrayFieldContextType = { name: string; index: number };

export const ArrayFieldContext = React.createContext<ArrayFieldContextType | undefined>(undefined);
ArrayFieldContext.displayName = 'ArrayFieldContext';

export const useFormField = (options: UseControllerProps) => {
	const arrayField = React.useContext(ArrayFieldContext);
	return useController({
		...options,
		name: arrayField ? `${arrayField.name}.${arrayField.index}.${options.name}` : options.name,
	});
};
