import React from 'react';
import { Button, Dialog } from '@scalingworks/react-admin-ui';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';

export const ModalWrapper: React.FC<Props> = (props) => {
  const {
    children,
    title = '',
    open,
    setOpen,
    loading,
    onConfirm,
    disabledConfirm,
    hideButton = false,
    contentClassName,
    confirmButtonTitle,
    cancelButtonTitle,
  } = props;

  // ===================== HOOKS
  const t = useTranslate();

  // ===================== VIEWS
  return (
    <Dialog.Root modal open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className={`fixed top-1/3 z-20 ${contentClassName}`}>
          <Dialog.Title>{title}</Dialog.Title>
          {children}

          {!hideButton && (
            <div className="flex flex-row justify-end space-x-4">
              <Button
                variant="default"
                className="text-error-400 border-error-400"
                onClick={() => setOpen(false)}
              >
                {cancelButtonTitle || t('common.cancel', 'Cancel')}
              </Button>
              <Button
                disabled={disabledConfirm}
                loading={loading}
                variant="solid"
                onClick={() => onConfirm?.()}
              >
                {confirmButtonTitle || t('common.confirm', 'Confirm')}
              </Button>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export type ModalWrapperProps = Props;
