import React from 'react';
import SearchAndSelect, { SearchAndSelectProp } from '../base';
import { ProductListOptions } from '~/api';
import { useTranslate } from '@refinedev/core';

type Props = SearchAndSelectProp & {
  onSelect?: (id: string) => void;
};

export const ProductSelection: React.FC<Props> = (props) => {
  const { onSelect, ...restProps } = props;
  const t = useTranslate();

  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getProducts"
      getValueAndLabel={({ name, id }) => {
        let label = `${name}`.trim();
        return {
          value: `${id}`,
          label: label,
        };
      }}
      variables={{
        options: {
          filter: { type: { eq: 'PRODUCT' }, enabled: { eq: true } },
        } as ProductListOptions,
      }}
      placeholder={t('placeholder.search.product')}
    />
  );
};

export type ProductSelectionProps = Props;
