import { useEffect } from 'react';
import { Administrator, Role, RoleList, RoleListOptions } from '~/api';
import { FormItemProps } from '~/components/FormBuilder/props';
import { UseFormReturnType } from '@refinedev/react-hook-form';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { format } from 'date-fns';
import { dateFormatter } from '~/resources/helpers';
import { FullDateFormat } from '../constant';

export const staffFormBuilder = (
  form: UseFormReturnType,
  staff?: Administrator
): FormItemProps[] => {
  const { id, firstName, lastName, emailAddress, user, customFields } = staff || {};
  const { phoneNumber, verificationNumber, dateOfBirth } = customFields || {};
  const { roles, verified } = user || {};
  const t = useTranslate();
  const status = verified ? 'ACTIVE' : 'INACTIVE';
  const apiUrl = useApiUrl();

  const { data: roleOptions } = useCustom<RoleList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      operation: 'roles',
      fields: [{ items: ['id', 'description'] }] as ResourceField<RoleList>[],
      variables: {
        options: {
          value: {
            filter: {
              code: { notIn: ['__customer_role__', '__super_admin_role__'] },
            },
          } as RoleListOptions,
          type: 'RoleListOptions',
        },
      },
    },
  });

  return [
    {
      title: t('staff.form.firstName'),
      name: 'firstName',
      defaultValue: firstName,
      placeholder: t('staff.placeholder.firstName'),
      required: true,
    },
    {
      title: t('staff.form.lastName'),
      name: 'lastName',
      defaultValue: lastName,
      placeholder: t('staff.placeholder.lastName'),
      required: true,
    },
    {
      title: t('staff.form.identification'),
      name: 'ic',
      defaultValue: verificationNumber,
      placeholder: t('staff.placeholder.identification'),
      required: true,
    },
    {
      title: t('staff.form.dateOfBirth'),
      name: 'birthDate',
      placeholder: t('staff.placeholder.dateOfBirth'),
      type: 'date-text-input',
      defaultValue: dateOfBirth,
      required: true,
    },
    {
      title: t('staff.form.email'),
      name: 'emailAddress',
      defaultValue: emailAddress,
      placeholder: t('staff.placeholder.email'),
      required: true,
    },
    {
      title: t('staff.form.contact'),
      name: 'phoneNumber',
      type: 'phone',
      defaultValue: phoneNumber,
      placeholder: t('staff.placeholder.contact'),
      required: true,
    },
    {
      title: t('staff.form.role'),
      name: 'role',
      type: 'select',
      placeholder: t('staff.placeholder.role'),
      defaultValue: roles?.[0]?.id,
      required: true,
      options: roleOptions?.data?.items.map((role: Role) => ({
        label: role.description,
        value: role.id,
      })),
    },
    {
      title: t('staff.form.joinedDate'),
      name: 'joinedDate',
      defaultValue: dateFormatter(new Date(), FullDateFormat),
      props: {
        disabled: true,
      },
    },
    ...(!!id
      ? [
          {
            title: t(`staff.form.status`),
            name: 'status',
            type: 'radio',
            option: [
              ['ACTIVE', 'INACTIVE'].map((status) => ({
                label: t(`staff.status.${[status.toLocaleLowerCase()]}`),
                value: status,
              })),
            ],
            defaultValue: status || 'ACTIVE',
            required: true,
          } as any,
        ]
      : []),
  ];
};
