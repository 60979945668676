// @ts-ignore
import { Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { FiBox } from 'react-icons/fi';

import { resourceNames } from '../resource-names';
import {
  AdminOrderProcess,
  MetabaseIframeUrlOptions,
  ProcessOrderResult,
  type Order,
  ProcessOrderInput,
} from '~/api';
import { ActionButton, Loading, PaymentMethodModal, SomethingWentWrong } from '~/components';
import {
  useApiUrl,
  useCreate,
  useCustom,
  useCustomMutation,
  useNavigation,
  useTranslate,
} from '@refinedev/core';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  parsePrice,
  getOrderStatusTag,
  getOrderStatus,
  getOrderStateByStatus,
  toCamelCaseWord,
  dateFormatter,
  canDownloadInvoice,
  getOrderFulfillmentSlot,
  getOrderActions,
  RefactoredOrderStates,
} from '../helpers';
import { OrderStates, OrderStatus } from '~/config/types';
import { FulfillmentMethodType } from '~/api';
import { OrderStatusUpdate } from '~/components/Order';
import { Action } from '~/components/ActionButton/props';
import startCase from 'lodash/startCase';
import head from 'lodash/head';
import { OrderShowPage } from './show';
import { OrderDashboard } from './dashboard';
import { OrderCreatePage } from './create';
import { useDownload } from '../helpers';
import { DineInPaymentMethodCode, FullDateTimeFormat } from '~/config/constant';
import useProcessOrder from './show/hooks';

const DASHBOARD_ID = import.meta.env.VITE_ORDER_DASHBOARD_ID;

const { defineFields, defineShowPage } = createHelpers<Order>({
  resourceName: resourceNames.order,
});

export const orderFields: ResourceField<Order>[] = [
  'id',
  'code',
  'createdAt',
  'updatedAt',
  'currencyCode',
  // delivery fee
  'shipping',
  'totalLinePrice',
  'orderPlacedAt',
  'state',
  'total',
  'totalWithTax',
  'subTotal',
  'couponCodes',
  { surcharges: ['price', 'sku'] },
  { taxSummary: ['taxTotal'] },
  { promotions: ['couponCode'] },
  { customerSnapshot: ['name', 'contact'] },
  {
    fulfillments: [
      'id',
      'method',
      {
        additionalProperties: ['courier', 'driverContact', 'driverName', 'pickUpTime'],
      },
    ],
  },
  {
    dineInMetadata: ['adultPax', 'kidPax', 'table'],
  },
  { orderPlacementMetadata: ['date', 'isPlacedNow', 'timeSlot'] },
  {
    customFields: [
      'remark',
      'cancellationRemark',
      'shippingMethodCode',
      'giftFee',
      { giftOption: ['label'] },
      'pointAwarded',
    ],
  },
  { customer: ['id', 'firstName', 'lastName'] },
  { shippingAddress: ['streetLine1', 'streetLine2', 'city', 'province', 'postalCode', 'country'] },
  { billingAddress: ['streetLine1', 'streetLine2', 'city', 'province', 'postalCode', 'country'] },
  { discounts: ['amount'] },
  {
    variants: [{ items: ['productId'] }],
  },
  {
    shippingLines: [{ shippingMethod: ['id', 'code', 'fulfillmentHandlerCode'] }],
  },
  { payments: ['id', 'method'] },
];

export const orderResource = createResource({
  name: resourceNames.order,
  label: 'Orders',
  icon: <FiBox />,
  fields: defineFields(orderFields),
  defaultValues: {},
  defaultPageSize: 25,
  defaultSorter: [{ field: 'orderPlacedAt', order: 'desc' }],
  allowSearch: true,
  searchConfig: {
    placeholder: ({ t }) =>
      t('order.placeholder.search', {
        ns: 'common',
        fallback: "Search by Order ID or Customer's Last Name",
      }),
  },
  createConfig: {
    title: ({ t }) =>
      t('order.create.name', {
        ns: 'common',
        fallback: 'Create Order',
      }),
  },
  filterControls: {
    orderPlacedAt: {
      type: 'daterange',
      config: {
        label: 'Order Date',
      },
    },
    // @ts-ignore
    fulfillmentMethod: {
      type: 'select',
      operator: 'eq',
      config: {
        options: Object.keys(FulfillmentMethodType).map((key) => ({
          label: startCase(key),
          value: FulfillmentMethodType[key as keyof typeof FulfillmentMethodType],
        })),
        label: 'Fulfillment method',
        placeholder: 'Select method',
      },
    },
  },
  filterConfig: {
    alwaysExpanded: true,
  },
  allowDelete: false,
  headerContent: () => {
    return <OrderDashboard />;
  },
  list: {
    tabs: {
      options: (
        [
          'All',
          'Draft',
          'To Pay',
          'Paid',
          'Preparing',
          'Shipped',
          'Delivered',
          'Cancelled',
        ] as Array<RefactoredOrderStates>
      ).map((key) => ({
        label: ({ t }) => t(`order.tabs.${key.toLowerCase()}`, { ns: 'common', fallback: key }),
        filterValue: {
          field: 'state',
          operator: 'in',
          value: getOrderStateByStatus(key),
        },
      })),
    },
  },
  columns: ({ LinkToDetails, refetchData, t }) => [
    {
      id: 'id',
      header: t('order.columns.orderId', { ns: 'common', fallback: 'Order ID' }),
      cell: (data) => {
        const { id, code } = data.row.original;

        return <LinkToDetails resourceId={id}>#{code}</LinkToDetails>;
      },
    },
    {
      id: 'deliveryMethod',
      header: t('order.columns.fulfillment', { ns: 'common', fallback: 'Fulfillment' }),
      cell: (data) => {
        const { id, customFields } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            {toCamelCaseWord(customFields?.shippingMethodCode ?? '-')}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'orderPlacedAt',
      header: t('order.columns.date', { ns: 'common', fallback: 'Order Date' }),
      cell: (data) => {
        const { id, orderPlacedAt } = data.row.original;
        const display = dateFormatter(orderPlacedAt, FullDateTimeFormat);

        return (
          <LinkToDetails resourceId={id}>
            <span>{display}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'timeSlot',
      header: t('order.columns.slot', { ns: 'common', fallback: 'Fulfillment Slot' }),
      cell: (data) => {
        const { id, orderPlacementMetadata, orderPlacedAt } = data.row.original;
        const { date, isPlacedNow, timeSlot } = orderPlacementMetadata || {};
        const display = isPlacedNow
          ? dateFormatter(orderPlacedAt, FullDateTimeFormat)
          : getOrderFulfillmentSlot(timeSlot, date);

        return <LinkToDetails resourceId={id}>{display}</LinkToDetails>;
      },
    },
    {
      id: 'customer',
      header: t('order.columns.customer', { ns: 'common', fallback: 'Customer' }),
      cell: (data) => {
        const { id, customer, customerSnapshot } = data?.row?.original;
        const { firstName = '', lastName = '' } = customer || {};
        let display = firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
        if (!display) {
          display = customerSnapshot?.name || '';
        }

        return <LinkToDetails resourceId={id}>{display || '-'}</LinkToDetails>;
      },
    },
    {
      id: 'orderState',
      header: t('order.columns.status', { ns: 'common', fallback: 'Status' }),
      cell: (data) => {
        const { id, state } = data.row.original;
        const simplified = getOrderStatus(state) as OrderStatus;
        const t = useTranslate();

        return (
          <LinkToDetails resourceId={id}>
            <Tag className={getOrderStatusTag(simplified)}>
              {t(`order.status.${simplified.toLowerCase()}`).toUpperCase()}
            </Tag>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'totalWithTax',
      header: t('order.columns.amount', { ns: 'common', fallback: 'Amount (RM)' }),
      cell: (data) => {
        const { id, totalWithTax, currencyCode = 'MYR' } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            <span>
              {totalWithTax ? (
                <span>
                  {/* <span className="font-medium">{currencyCode} </span> */}
                  {parsePrice(totalWithTax)}
                </span>
              ) : (
                '-'
              )}
            </span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'actions',
      header: () => <div />,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const order = data.row.original;
        const { id, state, fulfillments } = order;

        // ====================== HOOKS
        const t = useTranslate();
        const navigateTo = useNavigation();

        return (
          <ActionButton
            actions={[
              {
                label: t('actions.edit', 'Edit'),
                name: 'update',
                onAction: () => navigateTo.edit(resourceNames.order, id),
              },
            ]}
          />
        );
      },
    },
  ],
  // dataProvider: {}
  create: {
    render: (helpers) => {
      const navigation = useNavigation();
      const { mutate } = useCreate({
        mutationOptions: {
          onSettled: () => {
            navigation?.goBack();
          },
        },
      });

      return <OrderCreatePage />;
    },
  },
  edit: {
    render(helpers) {
      const { id } = useParams();
      const navigation = useNavigation();

      if (!id) return <SomethingWentWrong />;
      // go to detail instead; cant edit
      navigation.show(resourceNames.order, id);

      return <Loading />;
    },
  },
  show: defineShowPage({
    component: (props) => {
      return <OrderShowPage queryResult={props.queryResult} />;
    },
  }),
});
