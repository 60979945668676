import React from 'react';
import { Props } from './props';
import { FiExternalLink } from 'react-icons/fi';

export const Link: React.FC<Props> = (props) => {
  const { href, onClick, children } = props;

  const hasOnClick = typeof onClick === 'function';
  return (
    <div onClick={onClick} className="flex flex-row items-center space-x-2 cursor-pointer">
      <a target='_blank' rel='noreferrer' href={!hasOnClick ? href : undefined} className="underline text-primary-500">
        {children || 'Link'}
      </a>
      <FiExternalLink className="!text-primary-500" size={25} />
    </div>
  );
};
