import camelCase from 'lodash/camelCase';
import { SwitchField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';

export const renderSwitchField = ({ input: { item, register, setValue, watch } }: InputProps) => {
  const { title, name, required, placeholder, defaultValue, props, hint, onChange } = item;
  const formName = camelCase(name || title);

  register(formName, { required });

  return (
    <SwitchField
      {...props}
      required={required}
      label={title}
      layout="responsive"
      placeholder={placeholder}
      defaultChecked={defaultValue as boolean}
      checked={watch(formName)}
      value={watch(formName)}
      hint={hint}
      onCheckedChange={(val) => {
        setValue(formName, val);
        onChange && onChange(val);
      }}
    />
  );
};
