import { gql } from 'graphql-request';
import { GQLClient } from './gql-client';
import { AuthBindings } from '@refinedev/core';
import { getSdk } from '~/api';

export const authStorageKey = 'auth';
export const subscriberStorageKey = 'subscriber_id';

export const createAuthProvider = (): AuthBindings => {
  const gqlClient = GQLClient.getInstance();
  return {
    login: async ({ phoneNumber, otp, type }) => {
      try {
        const query = gql`
          mutation verifyMobileOtp($input: VerifyOTPVerifyInput!) {
            verifyMobileOtp(input: $input) {
              status
              token
            }
          }
        `;

        const variables = {
          phoneNumber,
          type,
          otp,
        };

        const rawResponse = await gqlClient.rawRequest(query, { input: variables });
        if (!rawResponse.data?.verifyMobileOtp) {
          throw new Error(`Something went wrong`);
        }
        if (rawResponse.data?.verifyMobileOtp?.status === 'INVALID') {
          throw new Error(`Invalid OTP`);
        }
        let authToken = rawResponse?.data?.verifyMobileOtp?.token || '';

        if (!authToken) {
          authToken = rawResponse.headers.get('vendure-auth-token') || '';
        }
        
        gqlClient.setHeaders({
          authorization: `Bearer ${authToken}`,
        });
        
        const res = await getSdk(gqlClient).getAdminSubscriberProfile();
        localStorage.setItem(authStorageKey, authToken);
        localStorage.setItem(subscriberStorageKey, res.getAdminSubscriberProfile?.subscriberId || '');

        return Promise.resolve({ success: true });
      } catch (error: any) {
        console.log('login ERROR', error);
        return Promise.resolve({ success: false, error });
      }
    },
    logout: () => {
      localStorage.removeItem(authStorageKey);
      localStorage.removeItem(subscriberStorageKey);
      setTimeout(() => window.location.reload(), 500);
      return Promise.resolve({ success: true, redirectTo: '/login' });
    },
    onError: (error) => {
      if (error?.message?.includes?.('not currently authorized')) {
        const token = localStorage.getItem(authStorageKey);
        if (!token) {
          return Promise.reject('/login');
        }
      }
      return Promise.resolve({});
    },
    check: () => {
      const token = localStorage.getItem(authStorageKey);

      if (token) {
        return Promise.resolve({ authenticated: true });
      }

      return Promise.reject({ redirectPath: '/login' });
    },
    getPermissions: () => Promise.resolve(),
    getIdentity: async () => {
      const token = localStorage.getItem(authStorageKey);

      if (!token) {
        return Promise.reject();
      }
      gqlClient.setHeaders({
        authorization: `Bearer ${token}`,
      });

      try {
        const query = gql`
          {
            me {
              id
              identifier
              channels {
                id
                token
                code
                permissions
              }
            }
          }
        `;

        const response = await gqlClient.request(query);

        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject();
      }
    },
  };
};
