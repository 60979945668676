import { Spinner } from '@scalingworks/react-admin-ui';
import { Content } from '@scalingworks/refine-react-admin';
import React from 'react';

export const Loading: React.FC = () => {
  return (
    <Content title="" className="w-full h-full flex items-center justify-center">
      <Spinner size={30} />
    </Content>
  );
};
