import React, { useEffect, useState } from 'react';
import { Form } from '@scalingworks/refine-react-admin';
import { Props } from './props';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { FormDateRangeField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-date-range-field';
import { FormSelectField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-select-field';
import {
  RedemptionHistoryType as RedemptionType,
  RewardOrPromoCodeType,
  RewardType,
  BannerType as Type,
} from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { useTranslate } from '@refinedev/core';
import { Card, SelectField, Tag } from '@scalingworks/react-admin-ui';
import { BannerStatusColor } from '~/resources/marketing/banner/banner-resource';
import { renderUploadInput } from '../FormBuilder/UploadInput';
import {
  ProductSelection,
  ProductSelectionProps,
  PromoCodeSelection,
  RewardSelection,
  RewardSelectionProps,
} from '../SearchAndSelect';
import { Controller } from 'react-hook-form';
import { Editor } from '../Editor';
import head from 'lodash/head';
import { REGEX_HTTP_URL } from '~/config/constant';

const RewardMapRedemption: Record<RewardOrPromoCodeType, RedemptionType> = {
  IN_STORE: RedemptionType.InStoreItem,
  IN_APP: RedemptionType.InAppItem,
  DISCOUNT_PERCENTAGE: RedemptionType.VoucherCode,
  FIXED_VALUE: RedemptionType.VoucherCode,
};

export const BannerForm: React.FC<Props> = (props) => {
  const { form, actionType = 'create', initialValues } = props;
  const {
    id: bannerId,
    asset,
    name,
    startDate,
    endDate,
    type,
    description,
    rewardId,
    reward,
    products,
    status,
    url,
  } = initialValues || {};
  const product = head(products);

  // =================== HOOKS
  const t = useTranslate();

  // =================== STATES
  const [rewardType, setRewardType] = useState(RewardMapRedemption[reward?.type!] || '');

  // =================== VARIABLES
  const createMode = actionType === 'create';
  const editMode = actionType === 'update';
  const typeWatch = form.watch('type') || type;

  // =================== EFFECTS
  useEffect(() => {
    form.resetField('description');
    form.resetField('url');
    form.resetField('productId');
    form.resetField('rewardId');
  }, [typeWatch]);

  // =================== VIEWS
  const renderRewardSection = () => {
    return (
      <div className="flex flex-col space-y-4">
        <SelectField
          required
          label={t('banners.form.rewardType', {}, 'Reward Type')}
          placeholder={t('banners.placeholder.rewardType', {}, 'Please select reward type')}
          options={Object.keys(RedemptionType).map((key) => ({
            label: toCamelCaseWord(key),
            value: RedemptionType[key as keyof typeof RedemptionType],
          }))}
          value={rewardType || ''}
          onValue={(type) => {
            if (type) setRewardType(type);
          }}
        />

        {!!rewardType && (
          <>
            {rewardType === RedemptionType.VoucherCode && (
              <Controller
                control={form.control}
                key={rewardType}
                name={'voucherId'}
                render={({ field: { onChange, value } }) => {
                  return (
                    <PromoCodeSelection
                      isField
                      required
                      fieldLabel="Voucher Code"
                      onSelect={(result) => {
                        const [idVal] = result?.split('-space-');
                        onChange(idVal);
                      }}
                      initialOption={{
                        label: `${reward?.name} (${reward?.pointCost} pts)`,
                        value: reward?.id!,
                      }}
                      value={value}
                      queryKey="getPromoCodes"
                    />
                  );
                }}
              />
            )}

            {(rewardType === RedemptionType.InStoreItem ||
              rewardType === RedemptionType.InAppItem) && (
              <Controller
                control={form.control}
                key={rewardType}
                name={'rewardId'}
                render={({ field: { onChange, value } }) => {
                  return (
                    <RewardSelection
                      isField
                      fieldLabel="Reward"
                      required
                      onSelect={(result) => {
                        const [idVal] = result?.split('-space-');
                        onChange(idVal);
                      }}
                      initialOption={{
                        label: `${reward?.name} (${reward?.pointCost} pts)`,
                        value: reward?.id!,
                      }}
                      value={value}
                      variables={{
                        type:
                          rewardType === RedemptionType.InStoreItem
                            ? RewardType?.InStore
                            : RewardType?.InApp,
                      }}
                      queryKey="getRewards"
                    />
                  );
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <section className="flex flex-col space-y-4 overflow-y-scroll min-h-screen">
      <Card>
        <Card.Header className="flex flex-row items-center justify-between font-bold border-b">
          <h3>{t('common.general', 'General')}</h3>
          {editMode && <Tag color={BannerStatusColor[status!] as any}>{status}</Tag>}
        </Card.Header>
        <Card.Body>
          <Form form={form}>
            <section className="flex flex-col space-y-4 xl:w-2/3">
              <FormTextField
                label={t('banners.form.name', {}, 'Name')}
                placeholder={t('banners.placeholder.name', {}, "Enter banner's name")}
                name="name"
                defaultValue={name}
                required
              />

              {renderUploadInput({
                input: {
                  item: {
                    title: t('banners.form.image', {}, 'Image'),
                    type: 'upload',
                    name: 'image',
                    required: true,
                    placeholder: t('product.placeholder.images'),
                    hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
                    defaultValue: asset?.source ? [asset.source] : undefined,
                  },
                  ...form,
                },
              })}

              <FormDateRangeField
                fromDate={new Date()}
                label={t('banners.form.period', {}, 'Period')}
                placeholder={t('banners.placeholder.period', {}, 'Please select banner period')}
                name="period"
                defaultValue={{ from: startDate, to: endDate }}
                required
                // disabled={editMode && isBetweenRange(startDate, endDate)}
              />
              <FormSelectField
                label={t('banners.form.type', 'Banner Type')}
                placeholder={t('banners.placeholder.type', 'Please select banner type')}
                name="type"
                options={Object.keys(Type).map((key) => ({
                  label: toCamelCaseWord(key),
                  value: Type[key as keyof typeof Type],
                }))}
                defaultValue={type}
                disabled={editMode}
                required
              />

              {/* NOTE: can't dynamically render as
               * react error on more hooks rendered than previous render
               */}
              {typeWatch === Type.Product && (
                <Controller
                  control={form.control}
                  name={'productId'}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ProductSelection
                        initialOption={{ label: product?.name!, value: product?.id! }}
                        defaultValue={product?.id}
                        isField
                        fieldLabel={t('banners.form.product', 'Product')}
                        queryKey="getProducts"
                        onSelect={onChange}
                        value={value}
                        required
                      />
                    );
                  }}
                />
              )}

              {typeWatch === Type.Reward && renderRewardSection()}

              {typeWatch === Type.Url && (
                <FormTextField
                  label={t('banners.form.url', 'URL')}
                  name="_url"
                  type="url"
                  placeholder={t('banners.placeholder.url', 'Address starts with https://')}
                  defaultValue={url || 'https://'}
                  required
                  pattern={REGEX_HTTP_URL}
                />
              )}

              {typeWatch === Type.Editor && (
                <Controller
                  control={form.control}
                  name="description"
                  render={({ field: { onChange } }) => (
                    <Editor
                      isField
                      required
                      label={t('banners.form.description', 'Description')}
                      defaultValue={description || ''}
                      onValue={onChange}
                      placeholder={t(
                        'banners.placeholder.description',
                        'Please enter a description for the banner'
                      )}
                    />
                  )}
                />
              )}
            </section>
          </Form>
        </Card.Body>
      </Card>
    </section>
  );
};
