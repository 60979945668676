import { useTranslate } from '@refinedev/core';
import { Button } from '@scalingworks/react-admin-ui';
import { Content } from '@scalingworks/refine-react-admin';
import React from 'react';

export const SomethingWentWrong: React.FC = () => {
  const t = useTranslate();

  return (
    <Content
      title={t('common.somethingWrong')}
      className="w-full h-full flex flex-col items-center justify-center"
    >
      <section className="flex flex-col items-center justify-center space-y-2">
        <h1 className="text-xl">Refresh the page and try again</h1>
        <Button
          variant="plain"
          className="text-primary-500 text-center"
          onClick={() => window.location.reload()}
        >
          {t('actions.refresh')}
        </Button>
      </section>
    </Content>
  );
};
