import {
  useApiUrl,
  useCreate,
  useCustom,
  useNavigation,
  useNotification,
  useTranslate,
} from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button } from '@scalingworks/react-admin-ui';
import { ResourceField } from '@scalingworks/refine-react-admin';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import {
  ConfigurableOperationDefinition,
  CreateShippingMethodInput,
  FulfillmentMethodType,
  LanguageCode,
  getSdk,
} from '~/api';
import {
  ConflictCodeState,
  ConflictPostCodeModal,
  DeliveryFeeType,
  ShippingMethodForm,
  ShowPageWrapper,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { Post_Code_Arg } from '../setting-resource';
import { GQLClient } from '~/config/gql-client';
import difference from 'lodash/difference';

const Checker_Code = import.meta.env.VITE_POSTAL_CODE_CHECKER;
const Flat_Calc = import.meta.env.VITE_FLAT_RATE_CALC;

export const ShippingMethodCreatePage: React.FC = () => {
  // ======================== HOOKS
  const notif = useNotification();
  const client = GQLClient.getInstance();
  const navigateTo = useNavigation();
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const form = useForm<any>({
    defaultValues: {
      feeType: DeliveryFeeType.FLAT,
    },
  });

  // ======================== API
  const { data: fulfillmentHandlers } = useCustom<ConfigurableOperationDefinition[]>({
    method: 'get',
    url: apiUrl,
    metaData: {
      fields: ['code'] as ResourceField<ConfigurableOperationDefinition>[],
      operation: 'fulfillmentHandlers',
    },
  });
  const { mutate: createMethod, isLoading: creating } = useCreate();

  // ======================= STATES
  const [conflictCodeState, setConflictCodeState] = useState<ConflictCodeState>({
    open: false,
    conflictCodes: [],
  });

  // ======================== EVENTS
  const haveConflictCode = async (postalCode: string[]): Promise<boolean> => {
    // Check conflicting postcode
    const conflict = await getSdk(client).CheckConflictingPostcodes({ postcodes: postalCode });
    const conflictCodes = conflict.checkConflictingPostcodes;
    if (!isEmpty(conflictCodes)) {
      notif?.open?.({
        message: t('shippingMethods.warnings.conflict'),
        type: 'error',
      });
      setConflictCodeState({ open: true, conflictCodes });
      return true;
    }
    return false;
  };

  const onCreate = async (data: any) => {
    const { name, postalCode } = data;
    // NOTE: on create shipping method will always be default flat rate 0

    if (isEmpty(fulfillmentHandlers?.data)) {
      console.warn(`Failed to find fulfillment handler`);
      return;
    }

    const isConflict = await haveConflictCode(postalCode);
    if (isConflict) return;

    createMethod(
      {
        resource: resourceNames.shippingMethod,
        values: {
          code: FulfillmentMethodType.Delivery,
          checker: {
            arguments: [{ name: Post_Code_Arg, value: JSON.stringify(postalCode) }],
            code: Checker_Code,
          },
          calculator: {
            arguments: [
              { name: 'rate', value: '0' },
              { name: 'includesTax', value: 'exclude' },
              { name: 'taxRate', value: '0' },
            ],
            code: Flat_Calc,
          },
          fulfillmentHandler: head(fulfillmentHandlers?.data)?.code,
          translations: [{ languageCode: LanguageCode.En, name }],
        } as CreateShippingMethodInput,
        successNotification: {
          message: t('shippingMethods.create.success'),
          type: 'success',
        },
        errorNotification: {
          message: t('shippingMethods.create.failed'),
          type: 'error',
        },
      },
      {
        onSuccess: (data) => {
          const id = data?.data?.id;
          if (id) navigateTo.show(resourceNames.shippingMethod, id);
          else navigateTo.list(resourceNames.shippingMethod);
        },
      }
    );
  };

  // ======================== VIEWS
  return (
    <section className="overflow-y-scroll">
      {conflictCodeState.open && (
        <ConflictPostCodeModal
          code={conflictCodeState?.conflictCodes}
          onOpenChange={(open) => setConflictCodeState((prev) => ({ ...prev, open }))}
          onClose={() => setConflictCodeState({ open: false, conflictCodes: [] })}
          onConfirm={() => {
            // remove the conflicting codes
            const codes = form.getValues('postalCode') as string[];
            const cleaned = difference(codes, conflictCodeState.conflictCodes);
            form.setValue('postalCode', cleaned);
            setConflictCodeState({ open: false, conflictCodes: [] });
          }}
        />
      )}
      <ShowPageWrapper
        resourceName={'deliveryZone'}
        title={t('shippingMethods.create.name')}
        extra={
          <Button
            loading={creating}
            onClick={form.handleSubmit(onCreate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.create', {}, 'Create')}
          </Button>
        }
      >
        <ShippingMethodForm form={form} />
      </ShowPageWrapper>
    </section>
  );
};
