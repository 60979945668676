import React from 'react';
import { Button, Dialog, Tag } from '@scalingworks/react-admin-ui';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';

export const ConflictPostCodeModal: React.FC<Props> = (props) => {
  const { onOpenChange, code, onClose, onConfirm } = props;

  // =========================== HOOKS
  const t = useTranslate();

  // =========================== VIEWS
  return (
    <Dialog.Root open onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 z-20 xl:w-1/3">
          <Dialog.Title>{t('setting.conflictCode', 'Postal Code Existed')}</Dialog.Title>
          <section className="flex flex-col space-y-4 py-4 text-smoke-700">
            <p>{t('shippingMethods.warnings.existing')}</p>
            <div className="flex-wrap space-y-2 items-center justify-start space-x-2">
              {code?.map((code) => (
                <Tag key={code} color="purple">
                  {code}
                </Tag>
              ))}
            </div>
          </section>

          <div className="flex flex-row justify-end space-x-4">
            <Button variant="default" className="text-error-400 border-error-400" onClick={onClose}>
              {t('actions.cancel', 'Cancel')}
            </Button>
            <Button variant="solid" onClick={onConfirm}>
              {t('actions.remove', 'Remove')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export type ConflictPostCodeModalProps = Props;
