import { Tag } from '@scalingworks/react-admin-ui';
import { ResourceField, createResourceListingPage } from '@scalingworks/refine-react-admin';
import isEmpty from 'lodash/isEmpty';
import { ReactNode, useState } from 'react';
import { FulfillmentMethodType, ShippingMethod, ShippingMethodListOptions } from '~/api';
import { ActionButton, TriggerConfirmModal } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { shippingMethodFields } from '../../setting-resource';
import { useTranslate } from '@refinedev/core';

export const ShippingMethodListing = () => {
  // ===================== HOOKS
  const shipMethodList = createResourceListingPage({
    resourceName: resourceNames.shippingMethod,
    title: 'Delivery Zone',
    allowCreate: true,
    fields: shippingMethodFields,
    customHelmet: 'Setting',
    defaultPageSize: 25,
    // NOTE: This will override any filter control's list options value
    // We didn't use any filter control so overriding is not an issue currently
    // Else just use tab filter as default filter
    extraMeta: {
      defaultFilter: {
        options: {
          value: {
            filter: {
              code: { in: [FulfillmentMethodType.Delivery] },
            },
          } as ShippingMethodListOptions,
          type: `ShippingMethodListOptions`,
        },
      },
    },
    columns: ({ LinkToDetails, navigateToDetails, invokeDelete, t }) => [
      {
        id: 'name',
        header: t('shippingMethods.column.name', { fallback: 'Name', ns: 'common' }),
        cell: (data) => {
          const { id, name } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <div>
                <span>{name}</span>
              </div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'postalCode',
        header: t('shippingMethods.column.postalCode', { fallback: 'Postal Code', ns: 'common' }),
        cell: (data) => {
          const MAX_DISPLAY = 17;
          const { id, checker } = data.row.original;
          const postCodes = checker.args.find((x) => x.name === 'postCode');
          let display: ReactNode | string = '-';
          if (!isEmpty(postCodes) && !!postCodes.value) {
            const postcode = JSON.parse(postCodes.value) as [];
            display = (
              <span>
                {postcode.slice(0, MAX_DISPLAY).map((code) => (
                  <Tag key={code} color="purple" className="m-1">
                    {code}
                  </Tag>
                ))}
                {postcode.length > MAX_DISPLAY && (
                  <Tag color="purple" className="m-1">
                    ...
                  </Tag>
                )}
              </span>
            );
          }

          return (
            <LinkToDetails resourceId={id}>
              <div>{display}</div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        cell: (data) => {
          const { id, name } = data.row.original;
          const [showDel, setShowDel] = useState(false);
          const t = useTranslate();

          return (
            <ActionButton
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateToDetails({ id }),
                },
                {
                  label: t('actions.delete'),
                  name: 'delete',
                  onAction: () => invokeDelete({ id }),
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          visible={showDel}
                          onOpenChange={setShowDel}
                          onPressConfirm={onAction}
                          description={
                            <span>
                              {t('warnings.deleteConfirmation', {
                                resource: t('shippingMethods.name.zone').toLocaleLowerCase(),
                              })}{' '}
                              <span className="font-semibold text-error-300">{`${name}`}</span>?{' '}
                              {t('warnings.cannotUndo')}
                            </span>
                          }
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('shippingMethods.placeholder.search', { fallback: 'Search by Name', ns: 'common' }),
    },
    createConfig: {
      title: ({ t }) =>
        t('shippingMethods.create.name', { fallback: 'Create Delivery Zone', ns: 'common' }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
  })();

  // ===================== VIEWS
  return shipMethodList;
};
