import camelCase from 'lodash/camelCase';
import { NumberField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';

export const renderNumberField = ({ input: { item, register, watch } }: InputProps) => {
  const { title, name, required, placeholder, prefix, props, defaultValue, suffix } = item;
  const formName = camelCase(name || title);

  return (
    <NumberField
      {...props}
      useFormatNumber={false}
      required={required}
      formattedToThousand={false}
      prefix={prefix}
      suffix={suffix}
      value={props?.value || watch(formName) || defaultValue}
      defaultValue={defaultValue}
      style={
        prefix && {
          textAlign: 'end',
        }
      }
      label={title}
      layout="responsive"
      placeholder={placeholder}
      {...(register(formName, { required }) as any)}
    />
  );
};
