import { Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';

import { NotificationBlast } from '~/api';
import { ActionButton, TriggerConfirmModal } from '~/components';
import { useState } from 'react';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';
import { toCamelCaseWord } from '~/resources/helpers';
import { Action } from '~/components/ActionButton/props';
import { NotificationCreatePage } from './create';
import { NotificationShowPage } from './show';

const { defineFields, defineShowPage } = createHelpers<NotificationBlast>({
  resourceName: resourceNames.notificationBlast,
});

export const notificationBasicFields: ResourceField<NotificationBlast>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'link',
  'message',
  'channels',
  'subject',
  'type',
  'receiverType',
  {
    queues: ['isSent'],
  },
];

export const notificationBlastResource = createResource({
  name: resourceNames.notificationBlast,
  label: 'Broadcasts',
  fields: defineFields(notificationBasicFields),
  defaultValues: {} as any,
  defaultPageSize: 25,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowSearch: true,
  searchConfig: {
    placeholder: ({ t }) =>
      t('notificationBlasts.placeholder.search', {
        fallback: `Search by Title or Message Content`,
        ns: 'common',
      }),
  },
  filterControls: {
    // @ts-ignore
    queuesTriggers: {
      type: 'daterange',
      config: {
        label: 'Send Date',
      },
    },
  },
  createConfig: {
    title: ({ t }) =>
      t('notificationBlasts.create.name', { fallback: 'Create Broadcasts', ns: 'common' }),
  },
  filterConfig: {
    alwaysExpanded: true,
  },
  allowDelete: true,
  columns: ({ LinkToDetails, navigateToDetails, invokeDelete, t }) => [
    {
      id: 'name',
      header: t('notificationBlasts.column.name', { fallback: 'Name', ns: 'common' }),
      cell: (data) => {
        const { id, name } = data.row.original;

        return <LinkToDetails resourceId={id}>{name}</LinkToDetails>;
      },
    },
    {
      id: 'subject',
      header: t('notificationBlasts.column.title', { fallback: 'Title', ns: 'common' }),
      cell: (data) => {
        const { id, subject } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            <span>{subject}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'channel',
      header: t('notificationBlasts.column.channel', { fallback: 'Channel', ns: 'common' }),
      cell: (data) => {
        const { id, channels } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            <div className="flex-wrap space-x-1">
              {channels?.map((name) => (
                <Tag color="purple">
                  {t(`notificationBlasts.channel.${name.toLowerCase()}`, {
                    fallback: toCamelCaseWord(name),
                    ns: 'common',
                  })}
                </Tag>
              ))}
            </div>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'type',
      header: t('notificationBlasts.column.type', { fallback: 'Type', ns: 'common' }),
      cell: (data) => {
        const { id, type } = data?.row?.original;

        return (
          <LinkToDetails resourceId={id}>
            <span>
              {t(`notificationBlasts.type.${type.toLowerCase()}`, {
                fallback: toCamelCaseWord(type),
                ns: 'common',
              })}
            </span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'actions',
      header: () => <div />,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const { id: notificationId, name, queues } = data.row.original;
        const isSent = queues?.some((q) => !!q.isSent);

        const t = useTranslate();
        const [showDel, setShowDel] = useState(false);

        return (
          <>
            <ActionButton
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateToDetails({ id: notificationId }),
                },
                ...(!isSent
                  ? [
                      {
                        label: t('action.delete'),
                        name: 'delete',
                        onAction: () => invokeDelete({ id: notificationId }),
                        render: (onAction) => {
                          return (
                            <button type="button">
                              <TriggerConfirmModal
                                title={t('notificationBlasts.delete', { name }, `Delete ${name}`)}
                                visible={showDel}
                                onOpenChange={setShowDel}
                                onPressConfirm={onAction}
                                description={
                                  <span>
                                    {t('warnings.deleteConfirmation', {
                                      resource: t('notificationBlasts.name').toLocaleLowerCase(),
                                    })}
                                    <span className="font-semibold text-error-300">{` ${name}`}</span>
                                    {'? '}
                                    {t('warnings.cannotUndo')}
                                  </span>
                                }
                              />
                            </button>
                          );
                        },
                      } as Action,
                    ]
                  : []),
              ]}
            />
          </>
        );
      },
    },
  ],
  create: {
    render: (helpers) => {
      return <NotificationCreatePage />;
    },
  },
  show: defineShowPage({
    component: (props) => {
      return <NotificationShowPage queryResult={props.queryResult} />;
    },
  }),
});
