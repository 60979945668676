import React from 'react';
import { NumberInput } from '@scalingworks/react-admin-ui';
import { Props } from './props';
import { HiOutlineChevronUp } from 'react-icons/hi';

export const NumberToggle: React.FC<Props> = (props) => {
  const {
    onToggleUp,
    onToggleDown,
    hideToggle = false,
    wrapperClassName,
    ...numberInputProps
  } = props;

  const MiniIconButton = (props: React.ComponentPropsWithoutRef<'button'>) => (
    <button
      type="button"
      tabIndex={-1}
      aria-hidden
      {...props}
      className={`flex-1 px-2 focus:outline-none ${props.disabled && 'active:bg-gray-100'}`}
    />
  );

  const component = <NumberInput {...numberInputProps} />;

  if (hideToggle) return component;

  return (
    <div className={`flex flex-row items-center justify-start ${wrapperClassName}`}>
      {component}
      <div className="flex flex-col">
        <MiniIconButton onClick={onToggleUp}>
          <HiOutlineChevronUp className="text-lightgrey w-4 h-4" />
        </MiniIconButton>

        <MiniIconButton onClick={onToggleDown}>
          <HiOutlineChevronUp className="text-lightgrey w-4 h-4 transform rotate-180" />
        </MiniIconButton>
      </div>
    </div>
  );
};
