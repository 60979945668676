import React from 'react';
import SearchAndSelect, { SearchAndSelectProp } from '../base';
import { ProductVariantListOptions } from '~/api';
import { useTranslate } from '@refinedev/core';

type Props = SearchAndSelectProp & {
  onSelect?: (id: string) => void;
};

export const ProductVariantSelection: React.FC<Props> = (props) => {
  const { onSelect, ...restProps } = props;
  const t = useTranslate();
  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getProductVariants"
      getValueAndLabel={({ name, id }) => {
        let label = `${name}`.trim();
        return {
          value: `${id}`,
          label: label,
        };
      }}
      variables={{ options: { filter: { enabled: { eq: true } } } } as ProductVariantListOptions}
      placeholder={t('placeholder.search.productVariant')}
    />
  );
};

export type ProductVariantSelectionProps = Props;
