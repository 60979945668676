import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { GQLClient } from '~/config/gql-client';
import {
  TextInputMultiSelect,
  TextInputMultiSelectField,
  TextInputMultiSelectProps,
  TextInputMultiSelectFieldProps,
} from '@scalingworks/react-admin-ui';
import { getSdk } from '~/api';
import isEmpty from 'lodash/isEmpty';
import { Props } from './props';

const MultiSearchAndSelect: React.FC<Props> = (props) => {
  const {
    isField = false,
    fieldLabel,
    queryKey,
    placeholder = 'Search',
    onSelect,
    variables,
    getValueAndLabel,
    initialOption = [],
    prefix,
    accessingKey = 'items',
    ...restProps
  } = props;
  const [selected, setSelected] = useState<any[]>(
    !isEmpty(initialOption)
      ? initialOption?.map((subItem) => {
          return subItem?.value;
        })
      : ['']
  );

  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      fetchData(nextValue);
    }, 1000),
    []
  );

  const handleChange = (value: string) => {
    setLoading(true);
    debouncedSave(value);
  };
  const handleFocus = (ev: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!ev.target.value) {
      fetchData();
    }
  };
  const handleSelect = (selected: string[]) => {
    setSelected(selected);
    onSelect(selected);
  };

  const fetchData = async (search = '') => {
    const client = GQLClient.getInstance();
    const currentVariables = { ...variables, search };

    const data = await getSdk(client)[queryKey]({
      ...currentVariables,
      options: { ...(currentVariables?.options || {}), take: 25 },
    });
    // @ts-ignore
    const formattedOptions = data?.[queryKey]?.[accessingKey].map(getValueAndLabel);
    setOptions(formattedOptions);
    setLoading(false);
  };

  const getOptions = () => {
    if (!isEmpty(options)) return options;

    if (initialOption) return [...initialOption] as any[];

    return [];
  };

  const componentProps: TextInputMultiSelectProps<string> | TextInputMultiSelectFieldProps<string> =
    {
      placeholder: placeholder,
      onFocus: (ev) => handleFocus(ev),
      onValue: (value) => handleSelect(value),
      onChange: (ev) => {
        restProps?.onChange?.(ev);
        handleChange(ev.target.value);
      },
      options: getOptions(),
      value: selected,
      loading,
    };

  if (isField) {
    return (
      <TextInputMultiSelectField
        label={fieldLabel}
        {...restProps}
        {...componentProps}
      />
    );
  }
  return <TextInputMultiSelect {...restProps} {...componentProps} />;
};

export default MultiSearchAndSelect;
export type MultiSearchAndSelectProp = Props;
