import camelCase from 'lodash/camelCase';
import { TimeInput } from '@scalingworks/react-admin-ui';
import { useEffect } from 'react';
import { isEmpty, last, size } from 'lodash';
import dayjs from 'dayjs';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';

export const TimeRangePicker: React.FC<Props> = (props) => {
  const { inputProps, disabled, isRow } = props;
  const {
    input: { item, register, setValue, watch },
  } = inputProps;
  const { title, name, required, placeholder, props: itemProps, defaultValue, value } = item;
  const formName = camelCase(name || title);
  const t = useTranslate();
  const initial = watch(formName) || defaultValue;
  const lastFormNameIndex = +(last(formName) || '1');
  const previousFormName = formName.replace(
    last(formName) || '',
    (lastFormNameIndex - 1).toString()
  );
  const handleChange = (
    index: number,
    value?: { hours: number; minutes: number; seconds?: number }
  ) => {
    const date1 = index === 1 ? new Date() : new Date(initial?.[0]);
    const date2 = index === 0 ? new Date() : new Date(initial?.[1]);
    if (value) {
      const { hours, minutes } = value;
      if (index === 1) date1.setHours(hours, minutes, 0, 0);
      else date2.setHours(hours, minutes, 0, 0);
    } else {
      console.warn(`Couldn't get the time, getting`, date1, ` & `, date2, ` instead.`);
    }
    setValue(formName, [date1.toISOString(), date2.toISOString()]);
  };
  useEffect(() => {
    if (!watch(formName)) {
      if (defaultValue && defaultValue?.[0] !== undefined)
        setValue(formName, [
          new Date(defaultValue?.[0]).toISOString(),
          new Date(defaultValue?.[1]).toISOString(),
        ]);
      else {
        if (watch(previousFormName)) {
          const previousDate = dayjs(watch(previousFormName)?.[1]).add(1, 'hour').toISOString();
          const date = new Date(previousDate);
          date.setHours(date.getHours() + 1);
          setValue(formName, [previousDate, date.toISOString()]);
        } else
          setValue(formName, [
            new Date(new Date().setHours(10, 0, 0, 0)).toISOString(),
            new Date(new Date().setHours(20, 0, 0, 0)).toISOString(),
          ]);
      }
    }
  }, [watch(formName)]);
  return (
    <div
      id="mydiv"
      style={{
        display: isRow ? 'flex' : undefined,
        justifyContent: isRow ? 'space-between' : undefined,
        alignItems: 'center',
        width: '80%',
      }}
    >
      <TimeInput
        disabled={disabled}
        layout="horizontal"
        required={required}
        placeholder={placeholder}
        hours={dayjs(initial[0])?.hour()}
        minutes={dayjs(initial[0])?.minute()}
        onValue={(e) => handleChange(1, e)}
        {...(register(formName, { required }) as any)}
      />

      <span style={{ marginRight: 30, marginLeft: 30 }}>
        {t('outlet.to', {
          fallback: 'to',
          ns: 'common',
        }).toLowerCase()}{' '}
      </span>

      <TimeInput
        disabled={disabled}
        layout="horizontal"
        required={required}
        placeholder={placeholder}
        hours={dayjs(initial[1])?.hour()}
        minutes={dayjs(initial[1])?.minute()}
        onValue={(e) => handleChange(2, e)}
        {...(register(formName, { required }) as any)}
      />
    </div>
  );
};
