import React, { Fragment, useState } from 'react';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import { useNavigation, useUpdate, useCustom, useApiUrl, useTranslate } from '@refinedev/core';
import { Table } from '@scalingworks/react-admin-ui';
import {
  ActionButton,
  FormBuilder,
  Loading,
  OutletSettingsModal,
  SomethingWentWrong,
  ViewBuilder,
  flexRow,
} from '~/components';
import dayjs from 'dayjs';
import reduce from 'lodash/reduce';
import upperFirst from 'lodash/upperFirst';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { MdStore } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { OutletEditModal } from '~/components/outlet';
import { type Outlet, OutletStatus, OutletFulfillment, getSdk, AdministratorList } from '~/api';
import { formBuilder } from '~/config/OutletResource';
import { GQLClient } from '~/config/gql-client';
import { resourceNames } from './resource-names';
import {
  dateTimeString,
  formatFullName,
  joinOutletAddress,
  sortByDayOutletOperation,
  sortByTimeOutletOperation,
} from './helpers';
import { renderOutletStatusTag } from './helpers/outlet-helper';
import { FullDateTimeWithSecondsFormat24H, TimeFormat2Digit } from '~/config/constant';
import { Helmet } from 'react-helmet';
const { defineFields, defineShowPage } = createHelpers<Outlet>({
  resourceName: resourceNames.outlet,
});

const defaultValues = {
  name: '',
  description: '',
};

export type FormInputs = {
  name: string;
  contactNo: string;
  personInCharge: string;
  status: OutletStatus;
  outletFulfillments: any;
};

const outletFields: ResourceField<Outlet>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'contactNumber',
  'managerId',
  'status',
  {
    address: ['addressLineOne', 'addressLineTwo', 'city', 'country', 'postalCode', 'province'],
  },
  { fulfillments: ['id', 'openingTime', 'closingTime', 'day', 'type', 'status'] },
  { manager: ['firstName', 'id', 'lastName'] },
];

export const outletResource = createResource({
  name: resourceNames.outlet,
  label: 'Outlet',
  icon: <MdStore />,
  fields: defineFields(outletFields),
  defaultValues,
  columns: ({ LinkToDetails, navigateToEdit, refetchData, invokeDelete, t }) => [
    {
      id: 'name',
      header: t('outlet.column.name', { fallback: 'Name', ns: 'common' }),
      cell: (data) => {
        const { id, name } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            <span>{name}</span>
          </LinkToDetails>
        );
      },
    },
  ],
  dataProvider: {
    // @ts-ignore
    update: async ({ client, variables, id }) => {
      const { data } = variables as any;
      const { contactNo, personInCharge, status, name, ...address } = data as any;
      return getSdk(client)
        .UpdateOutlet({
          input: {
            id: id as string,
            address,
            contactNumber: contactNo,
            managerId: personInCharge,
            name,
            status,
          },
        })
        .then((res) => {
          return {
            data: res.updateOutlet,
          };
        });
    },
  },
  show: defineShowPage({
    component: (helpers) => {
      const outletData = helpers?.queryResult?.data?.data as Outlet;
      const { edit } = useNavigation();
      const t = useTranslate();

      if (helpers?.queryResult?.isLoading) return <Loading />;
      return (
        <ViewBuilder
          {...helpers}
          hideBackButton
          title={outletData?.name}
          items={[
            {
              cardTitle: t('common.general'),
              cardHeaderExtra: () => {
                return (
                  <div>
                    <ActionButton
                      customTitle={t('common.action').toUpperCase()}
                      actions={[
                        {
                          label: t('actions.edit'),
                          name: 'edit',
                          onAction: () => {
                            edit(resourceNames?.product, outletData?.id);
                          },
                        },
                      ]}
                    />
                    <Helmet>
                      <title>{t('outlet.name')}</title>
                    </Helmet>
                  </div>
                );
              },
              config: [
                {
                  title: t('outlet.column.name'),
                  data: [outletData?.name],
                  type: 'text',
                },
                {
                  title: t('outlet.column.address'),
                  data: [joinOutletAddress(outletData?.address)],
                  type: 'text',
                },
                {
                  title: t('outlet.column.personInCharge'),
                  data: [
                    formatFullName(outletData?.manager?.firstName, outletData?.manager?.lastName),
                  ],
                  type: 'text',
                },
                {
                  title: t('outlet.column.contact'),
                  data: [outletData?.contactNumber],
                  type: 'text',
                },
                {
                  title: t('outlet.column.fulfillmentMethod'),
                  data:
                    outletData?.fulfillments?.map((e) => {
                      return e?.type?.toString();
                    }) || [],
                  render: ({ data, flex, title }) => {
                    return (
                      <div style={{ ...flexRow }}>
                        {t(`outlet.fulfillmentMethod.${title}`)}
                        <div style={{ flex }}>{renderOutletStatusTag(data?.[0], t)}</div>
                      </div>
                    );
                  },
                },
                {
                  title: t('outlet.column.status'),
                  data: [outletData?.status],
                  render: ({ data, flex, title }) => {
                    return (
                      <div style={{ ...flexRow }}>
                        {title}
                        <div style={{ flex }}>{renderOutletStatusTag(data?.[0], t)}</div>
                      </div>
                    );
                  },
                },
              ],
            },
          ]}
          extra={[
            {
              cardTitle: t('outlet.column.operation'),
              render: () => {
                const renderText = (text: string) => {
                  return (
                    <p style={{ fontWeight: 'normal', fontSize: 14 }}>{`${text || '-'}`.trim()}</p>
                  );
                };
                return (
                  <Table>
                    <Table.Thead>
                      <Table.Tr>
                        {['type', 'status']?.map((subItem) => (
                          <Table.Th>{t(`outlet.column.${subItem}`)}</Table.Th>
                        ))}
                      </Table.Tr>
                    </Table.Thead>

                    {outletData?.fulfillments?.map((subItem, index) => {
                      return (
                        <Table.Tbody>
                          <Table.Tr key={`${subItem?.type}_${index}`}>
                            <Table.Td>{renderText(subItem?.type?.toString())}</Table.Td>
                            <Table.Td width={'35%'}>
                              {renderOutletStatusTag(subItem?.status, t)}
                            </Table.Td>
                            <Table.Td width={'10%'}>
                              <p style={{ fontWeight: 'normal', fontSize: 14, color: '#8E24AA' }}>
                                {t('actions.edit')}
                              </p>
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr key={`${subItem?.type}_${index}`}>
                            <Table.Tr key={`${subItem?.type}_${index}`}>
                              <Table.Td>{renderText('Hours')}</Table.Td>
                              <Table.Td width={'20%'}>
                                {renderOutletStatusTag(subItem?.status, t)}
                              </Table.Td>
                            </Table.Tr>
                          </Table.Tr>
                        </Table.Tbody>
                      );
                    })}
                  </Table>
                );
              },
            },
          ]}
        />
      );
    },
  }),

  edit: {
    render({ t }) {
      const { id } = useParams();
      const navigation = useNavigation();
      if (!id) return <SomethingWentWrong />;

      const { data, isLoading } = useCustom<Outlet>({
        method: 'get',
        url: useApiUrl(),
        metaData: {
          fields: outletFields,
          operation: 'getLatestOutlet',
        },
      });
      const mainData = data?.data as Outlet;

      const { data: staffData } = useCustom<AdministratorList>({
        method: 'get',
        url: GQLClient.apiUrl,
        metaData: {
          operation: 'getAdministrators',
          fields: [
            {
              items: ['id', 'firstName', 'lastName'],
            },
          ] as ResourceField<AdministratorList>[],
          variables: {
            search: 'Store Manager',
          },
        },
      });
      const staff = staffData?.data?.items;

      const { mutate } = useUpdate();
      if (isLoading) return <div>Loading...</div>;
      return (
        <FormBuilder
          isUpdate
          resourceName={resourceNames.outlet}
          title={t('outlet.edit.withName', {
            fallback: `Update ${mainData?.name}`,
            ns: 'common',
            data: { name: mainData?.name },
          })}
          items={(formHook) => formBuilder({ outlet: mainData, formHook, staff })}
          onSubmit={async (data) => {
            try {
              mutate({
                id,
                resource: resourceNames.outlet,
                values: {
                  data,
                },
              });
              navigation?.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
        />
      );
    },
  },
  list: {
    render: ({ t }) => {
      const { edit, list } = useNavigation();
      const [openSettings, setOpenSettings] = useState(false);
      const { data, refetch, isLoading } = useCustom<Outlet>({
        method: 'get',
        queryOptions: {
          networkMode: 'online',
        },
        url: useApiUrl(),
        metaData: {
          fields: outletFields,
          operation: 'getLatestOutlet',
        },
      });
      const outletData = data?.data;
      const [expandedRows, setExpandedRows] = useState<Array<string>>([]);
      const toggleRowGroup = (groupId: string) => {
        const isGroupExpanded = expandedRows.includes(groupId);
        if (isGroupExpanded) {
          setExpandedRows(expandedRows.filter((id) => id !== groupId));
        } else {
          setExpandedRows([...expandedRows, groupId]);
        }
      };
      return (
        <div>
          {/** handle header name */}
          <Helmet>
            <title>{t('outlet.name', { fallback: 'Outlet', ns: 'common' })}</title>
          </Helmet>
          {/* @ts-ignore*/}
          <ViewBuilder
            hideBackButton
            title={outletData?.name}
            items={[
              {
                cardTitle: t('common.general', { fallback: 'General', ns: 'common' }),
                cardHeaderExtra: () => {
                  return (
                    <div>
                      <ActionButton
                        customTitle={t('common.action', {
                          fallback: 'Action',
                          ns: 'common',
                        }).toUpperCase()}
                        actions={[
                          {
                            label: t('actions.edit', { fallback: 'Edit', ns: 'common' }),
                            name: 'edit',
                            onAction: () => {
                              edit(resourceNames?.outlet, outletData?.id || '');
                            },
                          },
                        ]}
                      />
                    </div>
                  );
                },
                config: [
                  {
                    title: t('outlet.column.name', { fallback: 'Name', ns: 'common' }),
                    data: [outletData?.name || ''],
                    type: 'text',
                  },
                  {
                    title: t('outlet.column.address', { fallback: 'Address', ns: 'common' }),
                    data: [joinOutletAddress(outletData?.address)],
                    type: 'text',
                  },
                  {
                    title: t('outlet.column.personInCharge', {
                      fallback: 'Person In Charge',
                      ns: 'common',
                    }),
                    data: [
                      (outletData?.manager?.firstName || '') +
                        ' ' +
                        (outletData?.manager?.lastName || ''),
                    ],
                    type: 'text',
                  },
                  {
                    title: t('outlet.column.contact', {
                      fallback: 'Contact No.',
                      ns: 'common',
                    }),
                    data: [outletData?.contactNumber || ''],
                    type: 'text',
                  },

                  {
                    title: t('outlet.column.status', {
                      fallback: 'Status',
                      ns: 'common',
                    }),
                    data: [outletData?.status || ''],
                    render: ({ data, flex, title }) => {
                      return (
                        <div style={{ ...flexRow }}>
                          {title}
                          <div style={{ flex }}>{renderOutletStatusTag(data?.[0], t)}</div>
                        </div>
                      );
                    },
                  },
                ],
              },
            ]}
            extra={[
              {
                cardTitle: t('outlet.column.operation', { fallback: 'Operations', ns: 'common' }),
                render: () => {
                  const [open, setOpen] = useState(false);
                  const [data, setData] = useState<OutletFulfillment>();
                  const renderText = (text: string) => {
                    return (
                      <p style={{ fontWeight: 'normal', fontSize: 14 }}>
                        {`${text || '-'}`.trim()}
                      </p>
                    );
                  };
                  const sortedData = Object.entries(
                    reduce(
                      outletData?.fulfillments,
                      (acc: any, item) => {
                        const { day, type, openingTime, closingTime, status, id } = item;
                        if (!acc[type]) {
                          acc[type] = { id, status, [day]: [{ openingTime, closingTime }] };
                        } else {
                          if (!acc[type][day]) {
                            acc[type][day] = [{ openingTime, closingTime }];
                          } else {
                            acc[type][day].push({ openingTime, closingTime });
                          }
                        }
                        return acc;
                      },
                      {}
                    )
                  ).map(([type, value]: any) => {
                    const { status, id, ...days } = value;
                    return {
                      status,
                      id,
                      type,
                      operatingTimes: Object.entries(days).map(([day, times]) => ({ day, times })),
                    };
                  });
                  return (
                    <>
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th />
                            {['type', 'status']?.map((subItem) => (
                              <>
                                <Table.Th>
                                  {t(`outlet.column.${subItem}`, {
                                    fallback: subItem.toUpperCase(),
                                    ns: 'common',
                                  })}
                                </Table.Th>
                                <Table.Th />
                                <Table.Th />
                              </>
                            ))}
                            <Table.Th />
                          </Table.Tr>
                        </Table.Thead>
                        {sortedData
                          ?.sort((a, b) => a.type.localeCompare(b.type))
                          ?.map((subItem: any, index: number) => {
                            const isExpanded = expandedRows.includes(subItem?.type);
                            return (
                              <Table.Tbody>
                                <Table.Tr key={`${subItem?.type}_${index}`}>
                                  <Table.Td
                                    width={'5%'}
                                    style={{ alignItems: 'center', justifyContent: 'center' }}
                                  >
                                    {isExpanded ? (
                                      <AiOutlineUp
                                        onClick={() => toggleRowGroup(subItem?.type)}
                                        size={22}
                                      />
                                    ) : (
                                      <AiOutlineDown
                                        onClick={() => toggleRowGroup(subItem?.type)}
                                        size={22}
                                      />
                                    )}
                                  </Table.Td>
                                  <Table.Td width={'50%'} colSpan={3}>
                                    {renderText(
                                      t(
                                        `outlet.fulfillmentMethod.${subItem?.type
                                          ?.toString()
                                          ?.toLowerCase()
                                          ?.replace('_', ' ')}`,
                                        {
                                          fallback: upperFirst(
                                            subItem?.type
                                              ?.toString()
                                              ?.toLowerCase()
                                              ?.replace('_', ' ')
                                          ),
                                          ns: 'common',
                                        }
                                      )
                                    )}
                                  </Table.Td>

                                  <Table.Td width={'45%'}>
                                    {renderOutletStatusTag(subItem?.status, t)}
                                  </Table.Td>
                                  <Table.Td width={'10%'}>
                                    <ActionButton
                                      customTitle={t('common.action', {
                                        fallback: 'Action',
                                        ns: 'common',
                                      }).toUpperCase()}
                                      actions={[
                                        {
                                          label: t('outlet.edit.operatingHours', {
                                            fallback: 'Edit',
                                            ns: 'common',
                                          }),
                                          name: 'edit',
                                          onAction: () => {
                                            setData(subItem), setOpen(true);
                                          },
                                        },
                                        {
                                          label: t('outlet.edit.settings', {
                                            fallback: 'Settings',
                                            ns: 'common',
                                          }),
                                          name: 'setting',
                                          onAction: () => {
                                            setData(subItem), setOpenSettings(true);
                                          },
                                        },
                                      ]}
                                    />
                                  </Table.Td>
                                </Table.Tr>
                                {isExpanded && (
                                  <Table.Tr key={`${subItem?.type}_${index}`}>
                                    <Table.Td />
                                    <Table.Td colSpan={4}>
                                      {sortByDayOutletOperation(subItem?.operatingTimes)?.map(
                                        (e: any, index: number) => {
                                          const sortTime = sortByTimeOutletOperation(e?.times);
                                          return (
                                            <div
                                              className="flex flex-row items-center"
                                              key={e?.day + index}
                                            >
                                              <Table.Td width={512}>
                                                {t(`outlet.openingDay.${e?.day.toLowerCase()}`, {
                                                  fallback: e?.day,
                                                  ns: 'common',
                                                }).toUpperCase()}
                                              </Table.Td>
                                              <div className="w-full my-2 mr-5">
                                                {sortTime?.map((i: any) => (
                                                  <div className="bg-gray-100 rounded-full my-1 px-2 inline-block">
                                                    {dayjs(
                                                      dateTimeString(i?.openingTime),
                                                      FullDateTimeWithSecondsFormat24H
                                                    ).format(TimeFormat2Digit)}{' '}
                                                    {t('outlet.to', {
                                                      fallback: 'to',
                                                      ns: 'common',
                                                    }).toLowerCase()}{' '}
                                                    {dayjs(
                                                      dateTimeString(i?.closingTime),
                                                      FullDateTimeWithSecondsFormat24H
                                                    ).format(TimeFormat2Digit)}
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Table.Td>
                                  </Table.Tr>
                                )}
                              </Table.Tbody>
                            );
                          })}
                      </Table>
                      {openSettings && (
                        <OutletSettingsModal
                          open={openSettings}
                          setOpen={setOpenSettings}
                          type={data?.type}
                        />
                      )}
                      <OutletEditModal
                        open={open}
                        setOpen={setOpen}
                        initialStatus={data?.status}
                        onCompleted={() => refetch()}
                        data={data}
                      />
                    </>
                  );
                },
              },
            ]}
          />
        </div>
      );
    },
  },
});
