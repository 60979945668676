import camelCase from 'lodash/camelCase';
import { TimeInputField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const renderTimePicker = ({ input: { item, register, setValue, watch } }: InputProps) => {
  const { title, name, required, placeholder, props, defaultValue } = item;
  const formName = camelCase(name || title);

  const handleChange = (value?: { hours: number; minutes: number; seconds?: number }) => {
    const date = new Date();

    if (value) {
      const { hours, minutes } = value;
      date.setHours(hours, minutes, 0, 0);
    } else {
      console.warn(`Couldn't get the time, getting`, date, ` instead.`);
    }

    setValue(formName, date.toISOString());
  };

  useEffect(() => {
    if (!watch(formName)) {
      if (defaultValue) setValue(formName, new Date(defaultValue as Date).toISOString());
      else setValue(formName, new Date(new Date().setHours(10, 0, 0, 0)).toISOString());
    }
  }, [watch(formName)]);

  return (
    <TimeInputField
      {...props}
      required={required}
      label={title}
      placeholder={placeholder}
      hours={new Date(watch(formName))?.getHours()}
      minutes={new Date(watch(formName))?.getMinutes()}
      onValue={handleChange}
      {...(register(formName, { required }) as any)}
    />
  );
};
