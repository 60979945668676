import { createHelpers, createResource } from '@scalingworks/refine-react-admin';

import { resourceNames } from '../resource-names';
import { MetabaseIframeUrlOptions, ShippingMethod } from '~/api';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { Loading, ShowPageWrapper } from '~/components';
import { MdOutlineManageAccounts, MdRefresh } from 'react-icons/md';
import { AiOutlineBarChart } from 'react-icons/ai';
import { IconButton } from '@scalingworks/react-admin-ui';

const GENERAL_REPORT_DB_ID = import.meta.env.VITE_REPORT_DASHBOARD_ID;
const ORDER_DB_ID = import.meta.env.VITE_ORDER_DASHBOARD_ID;
const SALES_DB_ID = import.meta.env.VITE_SALES_DASHBOARD_ID;
const SKU_DB_ID = import.meta.env.VITE_SKU_DASHBOARD_ID;
const DEMOGRAPHIC_DB_ID = import.meta.env.VITE_DEMOGRAPH_DASHBOARD_ID;
const REDEMPTION_DB_ID = import.meta.env.VITE_REDEMPTION_DASHBOARD_ID;

const { defineShowPage } = createHelpers<ShippingMethod>({
  resourceName: resourceNames.profile,
});

const Reporting: React.FC = () => {
  // ======================= HOOKS
  const url = useApiUrl();
  const t = useTranslate();

  // ======================= API
  // TODO: refactor the api call into a HOOK
  const {
    data: salesReportUrl,
    isLoading: loadingSalesReport,
    refetch: refetchSalesReport,
  } = useCustom<String>({
    method: 'get',
    url,
    meta: {
      fields: [],
      variables: {
        options: {
          value: {
            dashboardId: +SALES_DB_ID,
          } as MetabaseIframeUrlOptions,
          type: 'MetabaseIframeUrlOptions',
        },
      },
      operation: 'getMetabaseIframeUrl',
    },
    queryOptions: {
      enabled: !!SALES_DB_ID,
    },
  });

  const {
    data: skuReportUrl,
    isLoading: loadingSkuReport,
    refetch: refetchSkuReport,
  } = useCustom<String>({
    method: 'get',
    url,
    meta: {
      fields: [],
      variables: {
        options: {
          value: {
            dashboardId: +SKU_DB_ID,
          } as MetabaseIframeUrlOptions,
          type: 'MetabaseIframeUrlOptions',
        },
      },
      operation: 'getMetabaseIframeUrl',
    },
    queryOptions: {
      enabled: !!SKU_DB_ID,
    },
  });

  const {
    data: demographicUrl,
    isLoading: loadingDemographicReport,
    refetch: refetchDemographicReport,
  } = useCustom<String>({
    method: 'get',
    url,
    meta: {
      fields: [],
      variables: {
        options: {
          value: {
            dashboardId: +DEMOGRAPHIC_DB_ID,
          } as MetabaseIframeUrlOptions,
          type: 'MetabaseIframeUrlOptions',
        },
      },
      operation: 'getMetabaseIframeUrl',
    },
    queryOptions: {
      enabled: !!DEMOGRAPHIC_DB_ID,
    },
  });

  const {
    data: redemptionReportUrl,
    isLoading: loadingRedemptionReport,
    refetch: refetchRedemptionReport,
  } = useCustom<String>({
    method: 'get',
    url,
    meta: {
      fields: [],
      variables: {
        options: {
          value: {
            dashboardId: +REDEMPTION_DB_ID,
          } as MetabaseIframeUrlOptions,
          type: 'MetabaseIframeUrlOptions',
        },
      },
      operation: 'getMetabaseIframeUrl',
    },
    queryOptions: {
      enabled: !!REDEMPTION_DB_ID,
    },
  });

  const {
    data: dashboardUrl,
    isLoading: loadingDashboard,
    refetch: refetchDashboard,
  } = useCustom<String>({
    method: 'get',
    url,
    meta: {
      fields: [],
      variables: {
        options: {
          value: {
            dashboardId: +ORDER_DB_ID,
          } as MetabaseIframeUrlOptions,
          type: 'MetabaseIframeUrlOptions',
        },
      },
      operation: 'getMetabaseIframeUrl',
    },
    queryOptions: {
      enabled: !!ORDER_DB_ID,
    },
  });

  // ======================= EVENTS
  const refetchAll = () => {
    refetchSalesReport();
    refetchSkuReport();
    refetchDemographicReport();
    refetchRedemptionReport();
    refetchDashboard();
  };

  // ======================= VIEWS
  return (
    <section className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.report}
        title={t('dashboard.name', {}, 'Dashboard')}
        showBackButton={false}
        extra={
          <IconButton className="flex flex-row items-center" onClick={() => refetchAll()}>
            <h3 className="mx-2">{t('actions.refresh', {}, 'Refresh')}</h3>
            <MdRefresh size={30} className="!text-smoke-600" />
          </IconButton>
        }
      >
        <section className="flex flex-col space-y-8">
          <div className="flex flex-col space-y-4">
            <h3 className="text-xl">{t('dashboard.section.order', {}, 'Order')}</h3>
            {loadingDashboard ? (
              <Loading />
            ) : (
              <iframe className="w-full min-h-screen" src={dashboardUrl?.data as string}></iframe>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-xl">{t('dashboard.section.sales', {}, 'Sales Report')}</h3>
            {loadingSalesReport ? (
              <Loading />
            ) : (
              <iframe className="w-full" height={600} src={salesReportUrl?.data as string}></iframe>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-xl">{t('dashboard.section.salesSku', {}, 'Sales SKU Report')}</h3>
            {loadingSkuReport ? (
              <Loading />
            ) : (
              <iframe className="w-full" height={600} src={skuReportUrl?.data as string}></iframe>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-xl">
              {t('dashboard.section.demographic', {}, 'Demographic Report')}
            </h3>
            {loadingDemographicReport ? (
              <Loading />
            ) : (
              <iframe className="w-full" height={600} src={demographicUrl?.data as string}></iframe>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-xl">
              {t('dashboard.section.redemption', {}, 'Redemption Report')}
            </h3>
            {loadingRedemptionReport ? (
              <Loading />
            ) : (
              <iframe
                className="w-full"
                height={600}
                src={redemptionReportUrl?.data as string}
              ></iframe>
            )}
          </div>
        </section>
      </ShowPageWrapper>
    </section>
  );
};

export const reportingResource = createResource({
  name: resourceNames.report,
  label: 'Dashboard',
  icon: <AiOutlineBarChart />,
  defaultValues: {},
  fields: ['id'],
  columns: () => [],
  allowCreate: false,
  allowDelete: false,
  allowEdit: false,
  allowSearch: false,
  list: {
    render: () => {
      return <Reporting />;
    },
  },
  show: defineShowPage({
    component: () => {
      return <Reporting />;
    },
  }),
});
