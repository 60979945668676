import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Props } from './props';
import { FieldGroup, Label } from '@scalingworks/react-admin-ui';

export const Editor: React.FC<Props> = (props) => {
  const {
    isField = false,
    label,
    required,
    disabled,
    defaultValue = '',
    onValue,
    placeholder = 'Description',
  } = props;

  // =================== STATES
  const [value, setValue] = useState(defaultValue);

  // =================== EFFECTS
  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  // =================== VIEWS
  const Quill = (
    <ReactQuill
      theme="snow"
      readOnly={disabled}
      value={value}
      onChange={(value) => {
        setValue(value);
        onValue?.(value);
      }}
      placeholder={placeholder}
      className="w-full"
    />
  );

  if (isField) {
    return (
      <FieldGroup fieldId={'editor'}>
        {label && <Label showRequiredIndicator={required}>{label}</Label>}
        {Quill}
      </FieldGroup>
    );
  }
  return Quill;
};
