import React, { useState } from 'react';
import { useDelete, useNavigation, useNotification, useTranslate } from '@refinedev/core';
import { Card, Tag } from '@scalingworks/react-admin-ui';
import {
  ActionButton,
  DetailRenderer,
  ShowPageWrapper,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { Action } from '~/components/ActionButton/props';
import { dateFormatter, formatFullName } from '~/resources/helpers';
import { statusColor } from '../staff-resource';
import { Props } from './props';
import { FullDateFormat } from '~/config/constant';

export const StaffShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  // ======================= HOOKS
  const { data, isLoading } = queryResult;
  const { id, emailAddress, firstName, lastName, user, customFields, createdAt } = data?.data || {};
  const fullName = formatFullName(firstName, lastName);
  const { phoneNumber, dateOfBirth, verificationNumber } = customFields || {};
  const { roles, verified } = user || {};

  // ======================= HOOKS
  const t = useTranslate();
  const navigateTo = useNavigation();
  const notif = useNotification();

  // ======================= API
  const { mutate: deleteStaff } = useDelete();

  // ======================= STATES
  const [open, setOpen] = useState(false);

  // ======================= EVENTS
  const onDelete = () => {
    if (!id) {
      // not suppose to happen
      console.warn(`[WARN]: Not getting staff id`);
      return;
    }

    deleteStaff(
      { resource: resourceNames.staff, id },
      {
        onSuccess: () => {
          notif?.open?.({
            message: t('staff.delete.success', {}, 'Staff deleted'),
            type: 'success',
          });
          navigateTo.list(resourceNames.staff);
        },
        onError: (err) => {
          console.warn(`Failed to delete staff`, err);
          notif?.open?.({
            message: t('staff.delete.failed', {}, 'Failed to delete staff'),
            type: 'error',
          });
        },
      }
    );
  };

  // ======================= VIEWS
  const actions: Action[] = [
    {
      label: 'Edit',
      name: 'edit',
      onAction: () => navigateTo.edit(resourceNames.staff, id!),
    },
    {
      label: 'Delete',
      name: 'delete',
      onAction: onDelete,
      render: (onAction) => {
        const [show, setShow] = useState(false);
        return (
          <button type="button">
            <TriggerConfirmModal
              visible={show}
              onOpenChange={setShow}
              onPressConfirm={onAction}
              description={
                <span>
                  Are you sure to delete staff{' '}
                  <span className="font-semibold text-error-300">{`${fullName}`}</span>? This action
                  cannot be undone.
                </span>
              }
            />
          </button>
        );
      },
    },
  ];

  if (!isLoading && !id) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <ShowPageWrapper resourceName={resourceNames.staff} loading={isLoading} title={fullName}>
        <Card className="relative z-0">
          <Card.Header className="font-bold" bordered>
            <section className="flex flex-row items-center justify-between">
              <h1>{t('common.general', undefined, 'General')}</h1>
              <ActionButton
                customTitle={t('common.action', undefined, 'ACTION')}
                actions={actions}
              />
            </section>
          </Card.Header>
          <Card.Body>
            <DetailRenderer
              data={{
                name: fullName,
                'IC / Passport': verificationNumber,
                dateOfBirth,
                contact: phoneNumber,
                role: roles?.[0].description || '',
                joinedDate: createdAt,
                status: verified ? 'ACTIVE' : 'INACTIVE',
              }}
              resource={'staff'}
              render={{
                dateOfBirth: (date) => <span>{dateFormatter(date, FullDateFormat)}</span>,
                joinedDate: (date) => <span>{dateFormatter(date, FullDateFormat)}</span>,
                status: (status) => <Tag className={`${statusColor[`${verified}`]}`}>{status}</Tag>,
              }}
            />
          </Card.Body>
        </Card>
      </ShowPageWrapper>
    </main>
  );
};
