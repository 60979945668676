import { useState } from 'react';
import { Props } from './props';
import { Checkbox, Table, Tag } from '@scalingworks/react-admin-ui';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { getCities, getPostcodes } from 'malaysia-postcodes';

export const StatePostalCodeSelector: React.FC<Props> = (props) => {
  const { state, onUpdateSelectedStates } = props;

  // ======================= VARIABLES
  const cities = getCities(state);
  const postalCodes = cities.map((city) => getPostcodes(state, city)).flat();

  // ======================= STATES
  const [expand, setExpand] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // ======================= VIEWS
  return (
    <Table>
      <Table.Tbody>
        <Table.Tr key={state}>
          <Table.Td width={'20%'}>
            <Checkbox
              checked={isChecked}
              onCheckedChange={(check: boolean) => {
                setIsChecked(check);
                onUpdateSelectedStates(check, state);
              }}
            />
          </Table.Td>
          <Table.Td width={'50%'}>{state}</Table.Td>
          <Table.Td align="right" width={'30%'}>
            {expand ? (
              <AiOutlineUp onClick={() => setExpand(false)} size={22} />
            ) : (
              <AiOutlineDown onClick={() => setExpand(true)} size={22} />
            )}
          </Table.Td>
        </Table.Tr>
        {expand && (
          <Table.Tr key={`${state}_postal_code`}>
            <Table.Td colSpan={5}>
              {postalCodes.map((postalCode) => (
                <Tag children={postalCode} key={postalCode} className="mr-2 mb-1" />
              ))}
            </Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  );
};
