import React, { useEffect, useRef, useState } from 'react';
import { Rifm } from 'rifm';
import { Props } from './props';
import { format } from 'date-fns';
import { TextInput } from '@scalingworks/react-admin-ui';

// without mask
// const formatDate = (val: string) => {
//   const digits = val.replace(/\D/g, '');
//   const chars = digits.split('');

//   // if (chars[4]) chars.splice(4, 0, '/');
//   // if (chars[7]) chars.splice(7, 0, '/');
//   if (chars[3]) chars.splice(4, 0, '/');
//   if (chars[6]) chars.splice(7, 0, '/');

//   return chars.join('');
// };

// with mask
const formatDate = (val: string) => {
  const digits = val.replace(/\D/g, '');
  if (!digits) return '';

  const year = digits.slice(0, 4);
  let month = digits.slice(4, 6);
  let day = digits.slice(6, 8);

  // Validate month and day ranges
  if (parseInt(month) > 12) month = '';
  if (parseInt(day) > 31) day = '';

  const filledYear = year.padEnd(4, 'Y');
  const filledMonth = month.padEnd(2, 'M');
  const filledDay = day.padEnd(2, 'D');

  return `${filledYear} / ${filledMonth} / ${filledDay}`;
};

export const DateTextInput: React.FC<Props> = (props) => {
  const { defaultValue, onValue } = props;
  const [value, setValue] = useState('');
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if(defaultValue) setValue(format(new Date(defaultValue), 'yyyy/MM/dd'))
  },[defaultValue])

  useEffect(() => {
    if (ref.current) {
      ref.current?.setSelectionRange?.(0, 0);
    }
  }, [ref]);

  return (
    <Rifm
      value={value}
      onChange={(val) => {
        setValue(val);
        onValue?.(val.replaceAll(' ', ''));
      }}
      format={formatDate}
      accept={/\d/g}
    >
      {(props) => (
        <TextInput
          ref={ref}
          {...props}
          onFocus={() => ref?.current?.setSelectionRange(0, 0)}
          placeholder="YYYY / MM / DD"
        />
      )}
    </Rifm>
  );
};
