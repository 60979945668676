import React, { useEffect, useState } from 'react';
import { Props } from './props';
import { Button, Checkbox, ImageViewer, Label } from '@scalingworks/react-admin-ui';
import head from 'lodash/head';
import * as Radio from '@radix-ui/react-radio-group';
import { DiscountType, ModifierGroup, ProductVariant } from '~/api';
import { parsePrice } from '~/resources/helpers';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { CiSquareRemove } from 'react-icons/ci';
import { NumberToggle } from '../NumberToggle';
import { useTranslate } from '@refinedev/core';
import { AiOutlinePlus } from 'react-icons/ai';

// for variant configurations that yet
// to be added into selection
export type AddingAddon = { groupName: string; modifierIds: string[] };
export type AddingVariant = Record<
  string, // product id
  {
    variantId: string; // product variant id,
    quantity: number; // product variant quantity
    // groupName: facetValue name
    // modifierIds: prod variant ids
    addon?: AddingAddon[];
  }
>;
// currently for component <AddVariantItem> only
export type DiscountConfig = { type: DiscountType; value: number };

export const AddProductItem: React.FC<Props> = (props) => {
  const {
    products,
    mode = 'create',
    currentlySelected = {},
    onAdding,
    allowRemoved = true,
  } = props;

  // ======================== HOOKS
  const t = useTranslate();

  // ======================== STATES
  // Dictionary of { productId: variantId }
  const [addingVariant, setAddingVariant] = useState<AddingVariant>(currentlySelected);
  const [showAddon, setShowAddon] = useState<Record<string, boolean>>({});

  // ======================== EVENTS
  const handleRemoveProduct = (productId: string) => {
    // discard the variant
    /**
     * Note: careful we dont want to assign `undefined`
     * to addingVariant bcuz the keys still there.
     * we are using the object key to know which products is in adding state
     */
    const { [productId]: removed, ...rest } = addingVariant;
    setAddingVariant(rest);
  };

  // ======================== EFFECTS
  useEffect(() => {
    // fire callback when configuring
    if (addingVariant) {
      // only back those are actually having variant id and valid quantity
      const cleaned = pickBy(addingVariant, (item) => item.variantId && item.quantity > 0);
      onAdding?.(cleaned);

      // Handle deafult open addon
      const prodIds = Object.keys(addingVariant);
      for (const prodId of prodIds) {
        if (addingVariant[prodId]?.addon?.length) {
          setShowAddon((prev) => ({ ...prev, [prodId]: true }));
        }
      }
    }
  }, [addingVariant]);

  // ======================== VIEWS
  const renderPrice = (amt: number) => (
    <span className="text-smoke-700">{amt ? parsePrice(amt) : ''}</span>
  );

  const renderVariantSelect = (productId: string, variants: ProductVariant[]) => {
    const addingProducts = Object.keys(addingVariant);

    // Render available variant only if checked checkbox
    if (addingProducts.includes(productId) && variants?.length) {
      return (
        <Radio.Root
          onValueChange={(value) =>
            setAddingVariant({
              ...addingVariant,
              [productId]: { quantity: addingVariant[productId]?.quantity || 1, variantId: value },
            })
          }
          value={addingVariant[productId]?.variantId}
          className="w-full"
        >
          {variants.map((v) => {
            const selected = addingVariant[productId]?.variantId === v.id;
            return (
              <div
                key={v.id}
                className="flex flex-row items-center justify-between space-x-2 w-full mb-1"
              >
                <div className="flex flex-row items-center space-x-2">
                  <Radio.Item
                    value={v.id}
                    id={`variant-${v.id}`}
                    className={`rounded-full h-6 w-6 ${
                      selected ? '!border-primary-500' : 'border-gray-300'
                    }`}
                    style={{
                      borderWidth: selected ? 5 : 1,
                    }}
                  >
                    <Radio.Indicator />
                  </Radio.Item>
                  <Label className="cursor-pointer" htmlFor={`variant-${v.id}`}>
                    {v.name}
                  </Label>
                </div>
                <span className="text-smoke-700">
                  {v.currencyCode || 'MYR'} {renderPrice(v.price)}
                </span>
              </div>
            );
          })}
        </Radio.Root>
      );
    }

    return null;
  };

  const renderAddon = (productId: string, addons: ModifierGroup[]) => {
    // Addon panel not open yet
    const opened = showAddon[productId];
    const isAddingProduct = !!addingVariant[productId].variantId;

    if (!opened) {
      return (
        <Button
          disabled={!isAddingProduct}
          size="sm"
          className="max-w-min"
          onClick={() => setShowAddon({ [productId]: true })}
        >
          <div className="flex flex-row items-center w-full space-x-2">
            <AiOutlinePlus className="!text-primary-500" />
            <span className="!text-primary-500">{t('order.create.addons', {}, 'Add ons')}</span>
          </div>
        </Button>
      );
    }

    return (
      <div className="flex flex-col space-y-2">
        {addons.map((addon) => {
          const { group, modifiers } = addon;

          const handleCheckAddon = (selected: boolean, variantId: string) => {
            const clonedAddons = [...(addingVariant[productId].addon || [])];

            // get the current addon group record
            // if no: fallback to the latest index we wanna push into
            const tempIndex = clonedAddons?.findIndex?.((addon) => addon.groupName === group);
            const modifyingIndex = tempIndex > -1 ? tempIndex : clonedAddons.length;

            // The exact piece of addon user is modifying/adding
            const modifyingAddon = {
              // @ts-ignore magic ts dun understand
              groupName: group,
              // @ts-ignore magic ts dun understand
              modifierIds: [],
              ...clonedAddons[modifyingIndex],
            };

            // Checked: add variant id inside addon under this group
            if (selected) {
              modifyingAddon.modifierIds.push(variantId);
            }
            // Unchecked: filter out the selected addon
            else {
              modifyingAddon.modifierIds = modifyingAddon.modifierIds.filter(
                (id) => id !== variantId
              );
            }
            // update to the cloned
            clonedAddons[modifyingIndex] = modifyingAddon;
            const cleaned = clonedAddons.filter((item) => !isEmpty(item.modifierIds));

            setAddingVariant((prev) => ({
              ...prev,
              [productId]: { ...prev[productId], addon: [...cleaned] },
            }));
          };

          return (
            <React.Fragment key={group}>
              <h3>
                {group}{' '}
                <span className="text-smoke-700">
                  ({t('order.create.optional', {}, 'Optional')})
                </span>
              </h3>

              {modifiers?.map((mod) =>
                mod.variants.map((variant) => {
                  const { id, name, price, currencyCode = 'MYR' } = variant;
                  const identifier = `${id}-${name}`;

                  return (
                    <section className="flex flex-row items-center justify-between">
                      <div className="flex flex-row items-center space-x-2">
                        <Checkbox
                          id={identifier}
                          checked={
                            addingVariant[productId].addon
                              ?.find((addon) => addon.groupName === group)
                              ?.modifierIds.includes(id) || false
                          }
                          onCheckedChange={(chk: boolean) => handleCheckAddon(chk, id)}
                        />
                        <label htmlFor={identifier}>{name}</label>
                      </div>

                      <span className="text-smoke-700">
                        + {currencyCode} {renderPrice(price)}
                      </span>
                    </section>
                  );
                })
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const Divider = <div className="border border-smoke-100 w-full my-4" />;
  return (
    <section className="">
      {products?.map((product, index) => {
        const {
          id = '',
          name,
          featuredAsset,
          assets,
          variants = [],
          modifierGroups = [],
        } = product;
        const img = featuredAsset?.source || head(assets)?.source || '';

        const addingProducts = Object.keys(addingVariant);
        const isChecked = addingProducts.includes(id);

        return (
          <React.Fragment key={id}>
            <div className="flex flex-row items-start w-full">
              <div className="flex flex-row items-start space-x-2">
                {mode === 'create' && (
                  <Checkbox
                    checked={isChecked}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        // when checked on product
                        // setup the dictionary value

                        setAddingVariant({
                          ...addingVariant,
                          [id]: { variantId: '', quantity: 0 },
                        });
                      } else {
                        handleRemoveProduct(id);
                      }
                    }}
                  />
                )}
                {addingProducts.includes(id) && (
                  <NumberToggle
                    value={addingVariant[id]?.quantity.toString()}
                    disabled
                    onValue={(value) =>
                      setAddingVariant({
                        ...addingVariant,
                        [id]: { ...addingVariant[id], quantity: +value as number },
                      })
                    }
                    onToggleUp={() =>
                      setAddingVariant({
                        ...addingVariant,
                        [id]: { ...addingVariant[id], quantity: addingVariant[id]?.quantity + 1 },
                      })
                    }
                    onToggleDown={() => {
                      const count = addingVariant[id]?.quantity || 0;
                      setAddingVariant({
                        ...addingVariant,
                        [id]: {
                          ...addingVariant[id],
                          quantity: count - 1 <= 0 ? 1 : count - 1,
                        },
                      });
                    }}
                    min={1}
                    className="!w-12 text-center !text-black !placeholder-opacity-100 !bg-white"
                  />
                )}
                {img && (
                  <div className="w-20 flex items-center justify-center !-ml-2">
                    <ImageViewer
                      src={img}
                      className="rounded-lg object-cover !w-14 !h-14"
                      alt="Product"
                    />
                  </div>
                )}
              </div>
              <section className="flex flex-col space-y-2 w-full">
                <div className="flex flex-row items-start justify-between w-full">
                  <h3 className="font-semibold">{name}</h3>
                  {mode === 'edit' && allowRemoved && (
                    // getColor
                    <CiSquareRemove
                      size={35}
                      color="#D60345"
                      className="cursor-pointer self-end -mr-1"
                      onClick={() => handleRemoveProduct(id)}
                    />
                  )}
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  {renderVariantSelect(id, variants)}
                </div>
                {isChecked && modifierGroups.length > 0 && (
                  <div className="">{renderAddon(id, modifierGroups)}</div>
                )}
              </section>
            </div>
            {index <= products.length && Divider}
          </React.Fragment>
        );
      })}
    </section>
  );
};
