import React, { useState } from 'react';
import { useDelete, useNavigation, useTranslate } from '@refinedev/core';
import { FiChevronDown } from 'react-icons/fi';
import { Reward, RewardOrPromoCodeType, RewardStatus } from '~/api';
import { ActionButton, TriggerConfirmModal, ViewBuilder, flexRow } from '~/components';
import { dateFormatter } from '~/resources/helpers';
import { resourceNames } from '~/resources/resource-names';
import { numeralThousandFormat, renderActivePeriod } from '~/config/helper';
import { renderStatusTag } from '../../helpers';
import { Props } from './props';
import { camelCase } from 'lodash';

export const RewardShow: React.FC<Props> = (props) => {
  const { helpers } = props;
  const rewardData = helpers?.queryResult?.data?.data as Reward;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate } = useDelete();
  const { edit, list } = useNavigation();
  const t = useTranslate();

  const title = t(`rewards.details`, {
    type: t(`rewards.type.${camelCase(rewardData?.type)}`),
  });

  return (
    <ViewBuilder
      {...helpers}
      title={title}
      items={[
        {
          cardTitle: t('common.general'),
          cardHeaderExtra: () => {
            return (
              <div>
                <ActionButton
                  renderTrigger={() => {
                    return (
                      <button
                        className="border-primary-500 text-primary-500 rounded p-2"
                        style={{ borderWidth: 1, alignItems: 'center', ...flexRow }}
                        type="button"
                      >
                        {t('common.action').toUpperCase()}
                        <FiChevronDown size={20} className="ml-1" />
                      </button>
                    );
                  }}
                  actions={[
                    {
                      label: t('actions.edit'),
                      name: 'edit',
                      onAction: async () => {
                        await edit(resourceNames?.reward, rewardData?.id);
                      },
                    },
                    {
                      label: t('actions.delete'),
                      name: 'delete',
                      onAction: async () => {
                        await mutate({
                          resource: resourceNames?.reward,
                          id: rewardData?.id,
                        });
                        list(resourceNames?.reward);
                      },
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <TriggerConfirmModal
                              visible={showDeleteConfirmation}
                              onOpenChange={setShowDeleteConfirmation}
                              onPressConfirm={onAction}
                            />
                          </button>
                        );
                      },
                    },
                  ]}
                />
              </div>
            );
          },
          config: [
            {
              title: t('rewards.form.name'),
              data: [rewardData?.name],
              type: 'text',
            },
            {
              title: t('rewards.form.point'),
              data: [`${numeralThousandFormat(rewardData?.pointCost)} PTS`],
              type: 'text',
            },
            {
              title: t('rewards.form.quantity'),
              data: [`${numeralThousandFormat(rewardData?.quantity)}`],
              type: 'text',
            },
            {
              title: t('rewards.form.redeemedQuantity'),
              data: [`${numeralThousandFormat(rewardData?.redemptionCount)}`],
              type: 'text',
            },
            {
              title: t('rewards.form.usedQuantity'),
              data: [`${numeralThousandFormat(rewardData?.rewardUsedCount)}`],
              type: 'text',
            },
            {
              title: t('rewards.form.image'),
              data: rewardData?.imageUrls || [],
              type: 'image',
            },
            {
              title: t('rewards.form.products'),
              data: [rewardData?.productVariantName || '-'],
              type: 'text',
            },
            {
              title: t('rewards.form.variantImage'),
              data: [rewardData?.productVariantImage || ''] || [],
              type: 'image',
            },
            {
              title: t('rewards.form.tiers'),
              data: rewardData?.membershipTiers?.map((subItem) => subItem?.name) as string[],
              type: 'text',
            },
            {
              title: t('rewards.form.status'),
              data: [rewardData?.status],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      {renderStatusTag(
                        rewardData?.status === RewardStatus?.Active,
                        resourceNames.reward
                      )}
                    </div>
                  </div>
                );
              },
            },
            {
              title: t('rewards.form.activePeriod'),
              data: [
                `${renderActivePeriod({
                  endAt: rewardData?.endAt,
                  startAt: rewardData?.startAt,
                })}`,
              ],
              type: 'text',
            },
            {
              title: t('rewards.form.description'),
              data: [`${rewardData?.description || '-'}`],
              type: 'text',
            },
            {
              title: t('rewards.form.criteria'),
              data: [`${rewardData?.redeemCriteria || '-'}`],
              type: 'text',
            },
            {
              title: t('rewards.form.tnc'),
              data: [`${rewardData?.termCondition || '-'}`],
              type: 'text',
            },
          ],
        },
      ]}
    />
  );
};
