import React from 'react';
import { IconButton, ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { useTranslate } from '@refinedev/core';
import { CiSquareRemove } from 'react-icons/ci';
import { Product } from '~/api';
import { parsePrice } from '~/resources/helpers';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import { NumberToggle } from '../NumberToggle';
import { Props } from './props';

export const OrderLineProduct: React.FC<Props> = (props) => {
  const { lines = [], lineProducts = [], canModify = false, onDelete } = props;

  // ======================= HOOKS
  const t = useTranslate();

  // ======================= VARIABLES
  const getProduct = (productId: string): Product | undefined => {
    return lineProducts.find((product) => product.id === productId);
  };

  // ======================= VIEWS
  if (isEmpty(lines) || isEmpty(lineProducts)) return null;

  const Divider: React.FC<any> = (props) => (
    <div className={`border border-smoke-100 my-4 ${props.className}`} />
  );
  return (
    <section>
      {lines?.map((line, index) => {
        const { id, quantity, snapshot, linePrice, productVariant, customFields } = line || {};
        const { specialInstructions } = customFields || {};
        const { id: variantId, productId, currencyCode = 'MYR' } = productVariant || {};
        const { variants, assets, featuredAsset } = getProduct(productId) || {};
        const img = featuredAsset?.source || head(assets)?.source || '';
        const { name: variantName } = variants?.find((variant) => variant.id === variantId) || {};

        return (
          <React.Fragment key={id}>
            <div className="flex flex-row items-start w-full">
              <div className="flex flex-row items-start space-x-2">
                <span className="w-12 font-semibold text-lg">{quantity.toString()}×</span>
                {img && (
                  <div className="w-20 flex items-center justify-center !-ml-1">
                    <ImageViewer
                      src={img}
                      className="rounded-lg object-cover !w-14 !h-14"
                      alt="Product"
                    />
                  </div>
                )}
              </div>
              {/* Name */}
              <section className="flex flex-col w-full">
                <div className="flex flex-row items-start justify-between w-full px-4">
                  <h3 className="font-semibold text-lg">{variantName}</h3>

                  {/* Action Panel */}
                  <div className="flex flex-row space-x-1 items-center">
                    <span>
                      {currencyCode} {parsePrice(linePrice)}
                    </span>
                    {canModify && (
                      <IconButton onClick={() => onDelete?.(id)}>
                        <CiSquareRemove size={30} className="!text-error-300" />
                      </IconButton>
                    )}
                  </div>
                </div>
                {!isEmpty(snapshot?.addons) && (
                  <>
                    <Divider className="!my-2" />
                    <h3 className="font-semibold text-primary-500 text-lg">
                      {t('order.addOns.name', {}, 'Add Ons')}
                    </h3>

                    {snapshot?.addons?.map(({ group, modifiers }) => {
                      return (
                        <section className="!my-2">
                          <div className="flex flex-col space-y-1">
                            <h3 className="font-medium text-smoke-700">{group}: </h3>
                            {modifiers.map((modifier) => {
                              return modifier.variants.map((modVariant) => {
                                const { name, price, variantId } = modVariant || {};
                                return (
                                  <div
                                    key={name + variantId}
                                    className="flex flex-row items-center justify-between"
                                  >
                                    <Tag
                                      className="max-w-min !text-sm !text-primary-500"
                                      color="purple"
                                    >
                                      {name}
                                    </Tag>
                                    <span className="text-smoke-700">
                                      + {currencyCode} {parsePrice(price)}
                                    </span>
                                  </div>
                                );
                              });
                            })}
                          </div>
                        </section>
                      );
                    })}

                    {}
                  </>
                )}

                {specialInstructions && (
                  <section className="my-2">
                    <h3 className="font-semibold text-primary-500 text-lg">
                      {t('order.create.instructions', {}, 'Instructions')}
                    </h3>
                    <span className="text-smoke-700">{specialInstructions}</span>
                  </section>
                )}
              </section>
            </div>
            {index <= lines.length && <Divider />}
          </React.Fragment>
        );
      })}
    </section>
  );
};
