import React, { useEffect, useState } from 'react';
import { Button, Dialog, Label } from '@scalingworks/react-admin-ui';
import { MultiProductSelection } from '~/components';
import { Controller } from 'react-hook-form';
import { Props } from './props';

export const ProductModal: React.FC<Props> = (props) => {
  const { open, setOpen, form } = props;

  // ====================== STATES
  const [products, setProducts] = useState([]);
  useEffect(() => {
    setProducts(form?.watch('products'));
  }, [form?.watch, open]);
  // ====================== VIEWS
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/2 z-20 w-2/3">
          <Dialog.Title className="flex flex-row items-center justify-between">
            {`Add Product(s)`}
          </Dialog.Title>

          <section className="my-4 flex flex-row items-center space-x-2">
            <Label>{`Product(s)`}</Label>
            <div className="flex-1">
              <Controller
                name="products"
                control={form?.control}
                render={() => {
                  return (
                    <MultiProductSelection
                      isWithName
                      initialOption={form?.watch('products')?.map((subItem) => {
                        return {
                          label: subItem?.split('-split-')?.[1],
                          value: subItem,
                        };
                      })}
                      onSelect={(val) => {
                        setProducts(val);
                      }}
                      value={products}
                    />
                  );
                }}
              />
            </div>
          </section>

          <section className="mt-10 flex flex-row items-center justify-end space-x-2">
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >{`Cancel`}</Button>
            <Button
              onClick={() => {
                setProducts([]);
                form?.setValue('products', products);
                setOpen(false);
              }}
              variant="solid"
            >{`Confirm`}</Button>
          </section>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
