import dayjs from 'dayjs';

export const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.valueOf());
};

export const dateTimeString = (e: string) => {
  const defaultDate = dayjs().format('YYYY-MM-DD');
  return `${defaultDate} ${e}`;
};

export const sortByDayOutletOperation = (data: any) => {
  const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  return data.sort((a: any, b: any) => {
    const indexA = daysOfWeek.indexOf(a?.day?.toUpperCase());
    const indexB = daysOfWeek.indexOf(b?.day?.toUpperCase());

    if (indexA < indexB) return -1;
    if (indexA > indexB) return 1;

    return 0;
  });
};

export const sortByTimeOutletOperation = (data: any) => {
  return data?.sort((a: any, b: any) => {
    const openingTimeA = new Date(`2000-01-01T${a?.openingTime}`);
    const openingTimeB = new Date(`2000-01-01T${b?.openingTime}`);

    if (openingTimeA < openingTimeB) return -1;
    if (openingTimeA > openingTimeB) return 1;

    return 0;
  });
};
/**
 * @description
 * determine now() is between a date range OR
 * after startDate if endDate is not provided
 */
export const isBetweenRange = (startDate: Date, endDate?: Date) => {
  const now = dayjs();
  const isSameOrAfterStart = now.isSame(startDate) || now.isAfter(startDate);

  if (endDate) {
    return isSameOrAfterStart && now.isBefore(endDate);
  }

  return isSameOrAfterStart;
};
