import React from 'react';
import { AlertDialog, Button } from '@scalingworks/react-admin-ui';
import { Props } from './props';
import isString from 'lodash/isString';
import { useTranslate } from '@refinedev/core';

export const TriggerConfirmModal: React.FC<Props> = (props) => {
  const {
    triggerText,
    visible,
    type,
    title,
    description,
    confirmButtonText,
    onOpenChange,
    onPressConfirm,
    renderTrigger,
    loadingConfirm = false,
  } = props;

  const PADDING_SIZE = 20;
  const isDanger = type === 'danger';
  const t = useTranslate();

  const renderTriggerLogic = () => {
    if (renderTrigger) return renderTrigger(triggerText);
    return <span className={'text-red-500 flex-1'}>{t('actions.delete')}</span>;
  };

  return (
    <AlertDialog.Root open={visible} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger asChild>{renderTriggerLogic()}</AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay />
        <AlertDialog.Content style={{ padding: 0 }}>
          <div style={{ padding: PADDING_SIZE }}>
            <AlertDialog.Title style={{ padding: 0 }}>
              {title || t('actions.deleteItem', { fallback: 'Delete Item' })}
            </AlertDialog.Title>
          </div>
          <div className="w-full h-0.5 bg-gray-200" />
          <div style={{ padding: PADDING_SIZE }}>
            <AlertDialog.Description style={{ padding: 0 }}>
              {description ? (
                isString(description) ? (
                  <span>{description}</span>
                ) : (
                  description
                )
              ) : (
                <span>
                  {t('warnings.deleteConfirmation', {
                    resource: t('common.item').toLocaleLowerCase(),
                  })}
                  {t('warnings.cannotUndo')}
                </span>
              )}
            </AlertDialog.Description>
          </div>
          <div
            style={{
              paddingLeft: PADDING_SIZE,
              paddingRight: PADDING_SIZE,
              paddingBottom: PADDING_SIZE,
            }}
          >
            <AlertDialog.Footer className="justify-end">
              <AlertDialog.Cancel asChild className="border-primary-500">
                <Button className="text-primary-500">{t('actions.cancel')}</Button>
              </AlertDialog.Cancel>
              <Button
                variant={isDanger ? 'danger' : 'solid'}
                onClick={() => {
                  onOpenChange(false);
                  onPressConfirm && onPressConfirm();
                }}
                className={isDanger ? 'bg-error-300 text-white border-error-300' : ''}
                loading={loadingConfirm}
              >
                {confirmButtonText || t('actions.confirm')}
              </Button>
            </AlertDialog.Footer>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
