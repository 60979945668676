import lowerCase from 'lodash/lowerCase';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import { PointTransactionSourceType } from '~/api';

export const toCamelCaseWord = (value: string) => {
  return startCase(lowerCase(value));
};

export const removeUnderscore = (value: string) => {
  return replace(value, /_/g, ' ');
};

export const dateFormatter = (date?: any, format?: string, placeholder?: string) => {
  const useFormat = format || 'DD MMM YYYY';
  const usePlaceholder = placeholder || '-';
  if (dayjs(date).isValid()) return dayjs(date).format(useFormat);
  else return usePlaceholder;
};

export const formatFullName = (firstName?: string, lastName?: string) => {
  return firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
};

export const adjustmentSource = (source: PointTransactionSourceType) => {
  const adjustmentTranslation = {
    [PointTransactionSourceType?.Deduct]: 'Deduction',
    [PointTransactionSourceType?.Grant]: 'Granted',
    [PointTransactionSourceType?.Earned]: 'Earned',
    [PointTransactionSourceType?.Spent]: 'Spent',
  };
  return adjustmentTranslation[source];
};

/**
 * @description Dynamically display date range based on differents
 * @example 
 * "From 13 July 2023"
 * "13 - 28 July 2023"
 * "13 Jan - 28 Feb 2023"
 * "31 Dec 2022 - 10 Jan 2023"
 */
export const formatDateRange = (startDate: Date, endDate?: Date): string => {
  const sameMonthFormat = 'DD';
  const differentMonthFormat = 'DD MMM';
  const defaultDateFormat = 'DD MMM YYYY';

  if (startDate && !endDate) {
    return `From ${dayjs(startDate).format(defaultDateFormat)}`
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  let formatted = '';

  if (start.isSame(end, 'month') && start.isSame(end, 'year')) {
    // Same month and year
    formatted = `${start.format(sameMonthFormat)} - ${end.format(sameMonthFormat)} ${end.format(
      'MMM YYYY'
    )}`;
  } else if (start.isSame(end, 'year')) {
    // Different month, same year
    formatted = `${start.format(differentMonthFormat)} - ${end.format(
      differentMonthFormat
    )} ${end.format('YYYY')}`;
  } else {
    // Different year
    formatted = `${start.format(defaultDateFormat)} - ${end.format(
      defaultDateFormat
    )}`;
  }

  return formatted;
};
