import { Button, Dialog, TextField, TextInput } from '@scalingworks/react-admin-ui';
import React, { useEffect, useState } from 'react';
import { Props } from './props';
import { ReservationStatusType as StatusType, UpdateReservationStatusInput } from '~/api';
import camelCase from 'lodash/camelCase';
import { RadioGroup } from '~/components';
import { useApiUrl, useCustomMutation, useNavigation, useTranslate } from '@refinedev/core';
import { resourceNames } from '~/resources/resource-names';

export const ReservationStatusUpdate: React.FC<Props> = (props) => {
  const { open, setOpen, reservationId, initialStatus, onCompleted } = props;

  // ========================= HOOKS
  const navigateTo = useNavigation();
  const apiUrl = useApiUrl();
  const t = useTranslate();

  const status = [StatusType.Pending, StatusType.Accepted, StatusType.Cancelled];
  const isAccepted = initialStatus === StatusType.Accepted;

  const [selected, setSelected] = useState<StatusType>(initialStatus);
  const [cancellationRemark, setCancellationRemark] = useState<string>();
  const { isLoading, mutate } = useCustomMutation();

  const onUpdateStatus = async () => {
    mutate(
      {
        url: apiUrl,
        method: 'post',
        metaData: {
          fields: ['id'],
          operation: 'updateReservationStatus',
          variables: {
            input: {
              required: true,
              type: 'UpdateReservationStatusInput',
              value: {
                id: reservationId,
                status: isAccepted ? StatusType.Cancelled : selected,
                cancellationRemark:
                  isAccepted || selected === StatusType.Cancelled ? cancellationRemark : undefined,
              } as UpdateReservationStatusInput,
            },
          },
        },
        values: {},
        errorNotification: {
          message: t('reservations.edit.statusFailed', {}, 'Failed to update reservation status'),
          type: 'error',
        },
        successNotification: {
          message: t('reservations.edit.statusSuccess', {}, 'Reservation status updated'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
          navigateTo.list(resourceNames.reservation);
          onCompleted?.();
        },
      }
    );
  };

  const renderContent = () => {
    // Pending
    if (!isAccepted)
      return (
        <RadioGroup
          isField
          label={t('common.status')}
          value={selected}
          options={status.map((status) => ({
            label: t(`reservations.status.${camelCase(status)}`),
            value: status,
            props: { disabled: status === StatusType.Pending },
            extra:
              status === StatusType.Cancelled && selected === StatusType.Cancelled ? (
                <TextInput
                  type="textarea"
                  onChange={(ev) => setCancellationRemark(ev.target.value)}
                  placeholder={t('reservations.placeholder.cancellationRemark')}
                />
              ) : null,
          }))}
          onValueChange={(status: StatusType) => setSelected(status)}
        />
      );
    // Accepted
    else {
      return (
        <div className="mb-10">
          <TextField
            layout="horizontal"
            label={t('reservations.form.remark')}
            value={cancellationRemark}
            type="textarea"
            placeholder={t('reservations.placeholder.cancellationReason')}
            onChange={(ev) => setCancellationRemark(ev.target.value)}
            className="h-24"
          />
        </div>
      );
    }
  };

  useEffect(() => {
    // manual "destroy on close"
    if (!open) setSelected(initialStatus);
  }, [open]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 z-20">
          <Dialog.Title>
            {' '}
            {t(`actions.${isAccepted ? 'cancelReservation' : 'updateStatus'}`)}
          </Dialog.Title>
          <div className="p-4">{renderContent()}</div>

          <div className="flex flex-row justify-end space-x-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              disabled={!isAccepted && initialStatus === selected}
              loading={isLoading}
              variant="solid"
              onClick={onUpdateStatus}
            >
              {t(`actions.${isAccepted ? 'cancelReservation' : 'confirm'}`)}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
