import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import { useApiUrl, useDelete, useNavigation, useTranslate } from '@refinedev/core';

import {
  ActionButton,
  DetailRenderer,
  Editor,
  Link,
  Loading,
  ShowPageWrapper,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { BannerStatus, BannerType, Product, Reward } from '~/api';
import { Card, ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { formatDateRange, toCamelCaseWord } from '~/resources/helpers';
import { Props } from './props';
import { BannerStatusColor } from '../banner-resource';
import head from 'lodash/head';
import { Action } from '~/components/ActionButton/props';

export const BannerShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  const { data, isLoading, refetch: refetchBanner } = queryResult;
  const {
    id: bannerId,
    name: bannerName,
    startDate,
    endDate,
    type,
    asset,
    status,
    url,
    reward,
    rewardId,
    description,
    products,
    name,
  } = data?.data || {};

  // ======================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const form = useForm();
  const navigateTo = useNavigation();

  // ======================= API
  const { mutate: deleteBanner, isLoading: deleting } = useDelete();

  // ======================= STATES
  const [showDel, setShowDel] = useState(false);

  // ======================= VARIABLES
  const actions: Action[] = [
    {
      label: t('actions.edit'),
      name: 'edit',
      onAction: () => navigateTo.edit(resourceNames.banner, bannerId!),
    },
    {
      label: t('actions.delete'),
      name: 'delete',
      onAction: () =>
        deleteBanner(
          { resource: resourceNames.banner, id: bannerId! },
          { onSuccess: () => navigateTo.list(resourceNames.banner) }
        ),
      render: (onAction) => {
        return (
          <button type="button">
            <TriggerConfirmModal
              title={t('banners.delete', { name }, `Delete ${bannerName}`)}
              visible={showDel}
              onOpenChange={setShowDel}
              onPressConfirm={onAction}
              description={
                <span>
                  {t('warnings.deleteConfirmation', {
                    resource: t('banners.name.banner').toLowerCase(),
                  })}
                  <span className="font-semibold text-error-300">{` ${name}`}</span>
                  {'? '}
                  {t('warnings.cannotUndo')}
                </span>
              }
            />
          </button>
        );
      },
    },
  ];

  // ======================= EVENTS

  // ======================= EFFECTS

  // ======================= VIEWS
  if (isLoading) return <Loading />;
  if (!isLoading && !bannerId) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <ShowPageWrapper resourceName={resourceNames.banner} loading={isLoading} title={bannerName}>
        <section className="flex flex-col space-y-4">
          <Card className="z-0">
            <Card.Header className="font-bold" bordered>
              <section className="flex flex-row items-center justify-between">
                <h1>{t('common.general', undefined, 'General')}</h1>
                <ActionButton
                  customTitle={t('common.action', undefined, 'ACTION').toUpperCase()}
                  actions={actions}
                />
              </section>
            </Card.Header>
            <Card.Body>
              <DetailRenderer
                loading={isLoading}
                resource={resourceNames.banner}
                data={{
                  name: bannerName,
                  image: asset?.source,
                  period: { startDate, endDate },
                  status,
                  type,
                  ...(type === BannerType.Product ? { product: head(products) } : {}),
                  ...(type === BannerType.Url ? { URL: url } : {}),
                  ...(type === BannerType.Reward ? { reward } : {}),
                }}
                render={{
                  image: (src) => (
                    <ImageViewer className="!w-32 !h-20 object-cover rounded-lg" src={src} />
                  ),
                  status: (status: BannerStatus) => (
                    <Tag color={BannerStatusColor[status] as any}>{status}</Tag>
                  ),
                  period: ({ startDate, endDate }) => formatDateRange(startDate, endDate),
                  type: (display) => toCamelCaseWord(display),
                  product: (product: Product) => (
                    <Link onClick={() => navigateTo.show(resourceNames.product, product.id)}>
                      <span>{product.name}</span>
                    </Link>
                  ),
                  URL: (url: string) => (
                    <Link href={url}>
                      <span>{url}</span>
                    </Link>
                  ),
                  reward: (reward: Reward) => (
                    <span>{`${reward?.name} (${reward?.pointCost} pts)`}</span>
                  ),
                }}
              />
            </Card.Body>
          </Card>

          {type === BannerType.Editor && !!description && (
            <Card>
              <Card.Header className="font-bold" bordered>
                <h1>{t('banners.form.preview', 'Preview')}</h1>
              </Card.Header>
              <Card.Body>
                <Editor defaultValue={description} disabled />
              </Card.Body>
            </Card>
          )}
        </section>
      </ShowPageWrapper>
    </main>
  );
};
