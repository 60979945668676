import React, { useState } from 'react';
import { Props } from './props';
import { ModalWrapper } from '../ModalWrapper';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { PaymentMethod, PaymentMethodList, PaymentMethodListOptions } from '~/api';
import { DineInPaymentMethodCode } from '~/config/constant';
import { Loading } from '../Loading';
import { RadioGroup } from '../RadioGroup';

const excludedMethodCodes = [DineInPaymentMethodCode];

export const PaymentMethodModal: React.FC<Props> = (props) => {
  const { open, setOpen, onConfirm } = props;

  // ========================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();

  // ========================= API
  const { data: methods, isLoading: loadingMethods } = useCustom<PaymentMethodList>({
    method: 'get',
    url: apiUrl,
    meta: {
      fields: [{ items: ['id', 'code', 'name'] }] as ResourceField<PaymentMethodList>[],
      variables: {
        options: {
          value: {
            filter: {
              code: { notIn: excludedMethodCodes },
            },
          } as PaymentMethodListOptions,
          type: 'PaymentMethodListOptions',
        },
      },
      operation: 'paymentMethods',
    },
  });

  // ========================= STATES
  // NOTE: payment method code
  const [selected, setSelected] = useState<string>('');

  // ========================= EVENTS
  const onConfirmSelection = () => {
    if (!selected) return;
    onConfirm?.(selected);
  };
  // ========================= VIEWS
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      disabledConfirm={!selected}
      onConfirm={onConfirmSelection}
      title={t('paymentMethod.select', 'Select Payment Method')}
    >
      <section className="p-4">
        {loadingMethods ? (
          <Loading />
        ) : (
          <RadioGroup
            options={
              methods?.data?.items?.map((method: PaymentMethod) => ({
                label: method.name,
                value: method.code,
              })) || []
            }
            onValueChange={(code) => setSelected(code)}
          />
        )}
      </section>
    </ModalWrapper>
  );
};
