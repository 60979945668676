import { useForm } from '@refinedev/react-hook-form';
import {
  Button,
  Card,
  Checkbox,
  CheckboxField,
  DateTimeField,
  DayField,
  DaySelector,
  Dialog,
  FieldGroup,
  IconButton,
  Label,
  SelectField,
  TextInput,
} from '@scalingworks/react-admin-ui';
import { Form, ResourceField } from '@scalingworks/refine-react-admin';
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useGetLocale,
  useNavigation,
  useNotification,
  useOne,
  useTranslate,
} from '@refinedev/core';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import {
  FulfillmentMethodType,
  ProductList,
  Customer,
  Address,
  Order,
  PlaceOrderInput,
  CreateOrderLineInput,
  OrderAddress,
  AdditionalPropertiesInput,
  CreateAddressInput,
  GiftOption,
  LanguageCode,
  GetValidPostCodesQuery,
  getSdk,
  LogicalOperator,
  TimeSlot,
  GetTimeSlotInput,
  DineInMetadataInput,
} from '~/api';
import {
  AddProductItem,
  AddingVariant,
  AddressModalForm,
  Loading,
  ModalWrapper,
  RadioGroup,
  ShowPageWrapper,
  SomethingWentWrong,
  ViewProductItem,
} from '~/components';
import { CustomerSelection, CustomerSelectionProps } from '~/components/SearchAndSelect';
import { resourceNames } from '~/resources/resource-names';
import { renderDataSelection } from '~/components/FormBuilder/DataSelection';
import { FormSelectField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-select-field';
import { ImSpoonKnife } from 'react-icons/im';
import { AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { AddItemModal, productFields } from '../add-item';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { FormNumberField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-number-field';
import {
  custAddrToCreateInput,
  formatFullName,
  joinAddress,
  parsePrice,
  sortUserAddress,
  toCamelCaseWord,
} from '~/resources/helpers';
import { FormPhoneField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-phone-field';
import { parseNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useGiftOptions } from './hook';
import { first, join } from 'lodash';
import { Controller } from 'react-hook-form';
import { GQLClient } from '~/config/gql-client';
import { FullDateFormat, OrderSlotMaxDay } from '~/config/constant';
import dayjs from 'dayjs';
import { FormDayField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-day-field';

type EditState = {
  open: boolean;
  editing: AddingVariant; // the item currently prompted for editing
  updated?: AddingVariant; // the current updates for editing item
};

type CustomerType = 'existing' | 'guest';
type FoundCustomerState = {
  open: boolean;
  id?: string;
  fullName?: string;
  email?: string;
  phone?: string;
};

const parseCase = (str: string) => startCase(camelCase(str));

export const OrderCreatePage: React.FC = () => {
  // ====================== HOOKS
  const t = useTranslate();
  const navigateTo = useNavigation();
  const form = useForm();
  const notif = useNotification();

  // ====================== VARIABLES
  const client = GQLClient.getInstance();
  const apiUrl = useApiUrl();
  const lang = useGetLocale();
  const formDeliveryMethod = form.watch('deliveryMethod');
  const formCustomerId = form.watch('customerId') as string;
  const formPreferDate = form.watch('preferDate');
  const formSlot = form.watch('slot');
  const formGiftOptions = form.watch('giftOptions');

  // ====================== STATES
  const [customerType, setCustomerType] = useState<CustomerType>('existing');
  const [openProduct, setOpenProduct] = useState(false);
  // NOTE: billing use shipping address
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [openCustomerAddrForm, setOpenCustomerAddrForm] = useState(false);
  const [guestAddrState, setGuestAddrState] = useState<{
    open: boolean;
    editing?: 'billingAddress' | 'shippingAddress'; // this is form field name
    initialValue?: OrderAddress;
  }>({ open: false });
  const [editState, setEditState] = useState<EditState>({
    open: false,
    editing: {},
  });
  // NOTE: product item array each element should contains only
  // 1 AddingVariant
  const [orderItem, setOrderItem] = useState<AddingVariant[]>([]);
  const productIds = uniq(orderItem.map((item) => head(Object.keys(item))));
  // NOTE: for handling existing customer in db
  const [useCustomerState, setUseCustomerState] = useState<FoundCustomerState>({ open: false });

  // ====================== API
  const { data: selectedProducts, isLoading: loadingOrderItem } = useCustom<ProductList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      operation: 'getProducts',
      fields: productFields,
      variables: {
        options: {
          value: {
            filter: {
              id: {
                in: productIds,
              },
            },
          },
          type: 'ProductListOptions',
        },
      },
    },
    queryOptions: {
      enabled: !!productIds && !isEmpty(productIds),
    },
  });

  const { data: { data: validPostalCode } = { data: [] } } = useCustom<GetValidPostCodesQuery>({
    method: 'get',
    url: apiUrl,
    meta: {
      operation: 'getValidPostCodes',
    },
  }) as unknown as { data: { data: string[] } };

  const {
    data: customerAddress,
    isLoading: loadingCustomerAddress,
    refetch: refetchCustAddr,
  } = useOne<Customer>({
    resource: resourceNames.customer,
    id: formCustomerId,
    metaData: {
      variables: {
        id: {
          value: formCustomerId,
          type: 'ID!',
        },
      },
      fields: [
        'id',
        {
          addresses: [
            'id',
            'streetLine1',
            'streetLine2',
            'city',
            'province',
            'postalCode',
            'defaultBillingAddress',
            'defaultShippingAddress',
            'fullName',
            'phoneNumber',
            { country: ['code', 'name'] },
          ],
        },
      ] as ResourceField<Customer>[],
    },
    queryOptions: { enabled: !!formCustomerId },
  });

  const {
    data: slots,
    isLoading: loadingSlots,
    refetch: refetchSlots,
  } = useCustom<TimeSlot[]>({
    method: 'get',
    url: apiUrl,
    meta: {
      fields: ['date', 'slots'] as ResourceField<TimeSlot>[],
      operation: 'getFulfillmentTimeSlotsByType',
      variables: {
        input: {
          value: {
            currentTime: formPreferDate,
            type: formDeliveryMethod,
            availableDays: OrderSlotMaxDay,
            dateFormat: FullDateFormat,
          } as GetTimeSlotInput,
          type: 'GetTimeSlotInput!',
        },
      },
    },
    queryOptions: {
      enabled: !!formPreferDate,
    },
  });
  const selectedDateSlots = head(
    slots?.data?.filter((slot) => dayjs(slot.date).isSame(dayjs(formPreferDate)))
  );

  const { data: giftOptions } = useGiftOptions<GiftOption[]>();

  const { mutate: createCustomerAddr } = useCustomMutation<Address>({
    mutationOptions: {
      onSettled: () => {
        setOpenCustomerAddrForm(false);
        refetchCustAddr();
      },
    },
  });
  const { mutate: placeOrder, isLoading: placingOrder } = useCustomMutation<Order>({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data?.id) {
          navigateTo.show(resourceNames.order, data?.data?.id!);
        } else console.warn('[WARN]: Not getting order id', data?.data);
      },
    },
  });

  // ====================== EVENTS
  const handleBeforeSubmit = async (data: any) => {
    const { customerId, guestPhone, guestName, guestEmail } = data;

    // No customer or guest info provided
    if (!customerId && !guestPhone && !guestName && !guestEmail) {
      notif?.open?.({
        message: t('order.warnings.customerInfo'),
        type: 'error',
      });
      return;
    }

    if (customerType === 'guest') {
      const parsedPhone = parsePhoneNumber(guestPhone);
      const validPhone = parsedPhone.isValid();
      if (!validPhone) {
        notif?.open?.({
          message: t('messages.invalidPhone', 'Invalid Phone Number'),
          type: 'error',
        });
        return;
      }

      try {
        const customers = await getSdk(client).getCustomers({
          options: {
            filter: { phoneNumber: { eq: parsedPhone.number }, emailAddress: { eq: guestEmail } },
            filterOperator: LogicalOperator.Or,
            take: 1,
          },
        });

        const existed = customers.getCustomers.totalItems > 0;
        if (existed) {
          const customer = head(customers?.getCustomers?.items);
          setUseCustomerState({
            open: true,
            id: customer?.id,
            fullName: formatFullName(customer?.firstName, customer?.lastName),
            email: customer?.emailAddress,
            phone: customer?.phoneNumber || '-',
          });
          return;
        } else {
          onSubmit(data);
        }
      } catch (err) {
        console.log('Failed to fetch customers:', err);
        notif?.open?.({
          message: t('order.create.failedGetCustomer', 'Failed to check customer'),
          type: 'error',
        });
      }
    }
    // Existing Customer
    else {
      onSubmit(data);
    }
  };

  const onSubmit = (data: any) => {
    const {
      customerId,
      guestPhone,
      guestName,
      guestEmail,
      adultPax,
      kidPax,
      table,
      preferDate,
      slot,
      deliveryMethod,
      shippingAddress: rawShipping,
      billingAddress: rawBilling,
      remark,
      giftOption,
    } = data;
    let parsedNumber;
    let shippingAddress = rawShipping;
    let billingAddress = rawBilling;

    const isDelivery = deliveryMethod === FulfillmentMethodType.Delivery;
    const isPickup = deliveryMethod === FulfillmentMethodType.Pickup;
    const isDinein = deliveryMethod === FulfillmentMethodType.DineIn;
    const haveAllGuestInfo = !!guestPhone && !!guestEmail;

    // =========== Guest
    if (customerType === 'guest') {
      // Validate Phone
      if (guestPhone && parsePhoneNumber(guestPhone).isValid()) {
        parsedNumber = parsePhoneNumber(guestPhone).number;
      }

      // Assign billing if using shipping
      if (sameAsBilling) {
        billingAddress = shippingAddress;
      }
    }
    // =========== Existing Customer
    else if (customerType === 'existing') {
      /**
       * Note: for pickup backend will assign address accordingly
       */
      if (isDelivery) {
        /**
         * Note: handle the address here because address value is actually
         * customer address id since we cannot put the entire address object
         * into select component
         * rawShipping & rawBilling is customer address id
         */
        const addressList = customerAddress?.data?.addresses;
        const customerShipping = addressList?.find((addr) => addr.id === rawShipping);
        const customerBilling = addressList?.find((addr) => addr.id === rawBilling);

        if (!customerShipping || !customerBilling) {
          // not suppose to happen but need abort;
          console.warn('Failed to get customer address');
          console.warn('[SHIPPING]', customerShipping);
          console.warn('[BILLING]', customerBilling);
          return;
        }
        shippingAddress = custAddrToCreateInput(customerShipping!);
        billingAddress = custAddrToCreateInput(customerBilling!);
      }
    }

    // Prepare Order Info (Product Variant)
    const orderLines: CreateOrderLineInput[] = orderItem.map((item) => {
      const productId = head(Object.keys(item)) || '';
      const { variantId: productVariantId, quantity, addon } = item[productId];

      return {
        item: { productVariantId, quantity },
        addons: addon?.map((extra) => ({
          modifierGroup: extra.groupName,
          modifierVariantIds: extra.modifierIds,
        })),
      };
    });

    // Extra Order info based on delivery method
    const dineInProps = (): Partial<DineInMetadataInput> => {
      const res: Partial<DineInMetadataInput> = {};

      if (isDinein) {
        res.adultPax = +adultPax || undefined;
        res.kidPax = +kidPax || undefined;
        res.table = table;
      }

      return res;
    };

    const finalValue = {
      customerId,
      fulfillment: {
        dineInMetadata: { ...dineInProps() },
        method: deliveryMethod,
      },
      ...(deliveryMethod === FulfillmentMethodType.Delivery ||
      deliveryMethod === FulfillmentMethodType.Pickup
        ? {
            timeSlot: {
              date: preferDate,
              isPlacedNow:
                slot === head(selectedDateSlots?.slots) ||
                (slot as string).toLowerCase().includes('now'),
              timeSlot: slot,
            },
          }
        : {}),
      customerSnapshot:
        customerType === 'guest' && haveAllGuestInfo
          ? { name: guestName, contact: parsedNumber, emailAddress: guestEmail }
          : undefined,
      billingAddress,
      shippingAddress,
      orderLines,
      remark,
      giftOption: giftOption ? { giftOptionId: giftOption } : undefined,
    } as PlaceOrderInput;

    placeOrder({
      method: 'post',
      url: apiUrl,
      values: {},
      metaData: {
        fields: ['id'],
        operation: 'placeOrder',
        variables: {
          input: {
            value: finalValue,
            type: 'PlaceOrderInput!',
          },
        },
      },
      successNotification: {
        message: t('order.create.success', {}, 'Order created successfully'),
        type: 'success',
      },
      errorNotification: {
        message: t('order.create.failed', {}, 'Failed to create order'),
        type: 'error',
      },
    });
  };

  const clearEditState = () => setEditState({ open: false, editing: {}, updated: {} });

  const handleEditItem = () => {
    const original = editState.editing;
    const updated = editState.updated;

    if (!updated) {
      console.warn('[No Update Found]', JSON.stringify(editState));
      return;
    }
    const index = orderItem.findIndex((item) => isEqual(item, original));

    const cloned = [...orderItem];
    cloned[index] = updated;
    setOrderItem(cloned);

    clearEditState();
  };

  const handleAddAddress = (addr: Partial<Address> | Partial<OrderAddress>) => {
    /**
     * Note: this function only handle add customer address
     * for guest do another component for now since the core logic
     * is different (adding into either shipping or billing)
     */
    if (customerType === 'existing') {
      if (!form.getValues('customerId')) {
        // not suppose to happen
        console.warn('[Error]: no customer selected');
        return;
      }

      // TODO: handle create customer address api
      createCustomerAddr({
        method: 'post',
        url: apiUrl,
        metaData: {
          fields: ['id'],
          operation: 'createCustomerAddress',
          variables: {
            customerId: { value: form.getValues('customerId'), type: 'ID!' },
            input: { value: addr as CreateAddressInput, type: 'CreateAddressInput!' },
          },
        },
        successNotification: {
          message: t('order.create.customerAddress.created', {}, 'Customer address created'),
          type: 'success',
        },
        errorNotification: {
          message: t(
            'order.create.customerAddress.createFailed',
            {},
            'Failed to create customer address'
          ),
          type: 'error',
        },
        values: {},
      });
    }
  };

  const handleGuestAddress = (addr: Partial<OrderAddress> | Partial<Address>) => {
    if (!guestAddrState?.editing) {
      // not suppose to happen
      console.warn('[Error]: no editing state');
      return;
    }
    /**
     * Note: this function only handle the
     * assignation of order address
     */
    form.setValue(guestAddrState.editing, addr);

    // clear state
    setGuestAddrState({ open: false, editing: undefined });
  };

  // ====================== EFFECTS
  useEffect(() => {
    form.reset();
    // NOTE: need to set to empty string in order to clear the value properly
    form.setValue('deliveryMethod', '');
    setSameAsBilling(false);
    setGuestAddrState({ open: false, editing: undefined, initialValue: undefined });
  }, [customerType]);

  useEffect(() => {
    // Changed fulfilment method
    // reset all info regarding fulfilment method
    // ref: renderExtraInfo()
    const fields = ['table', 'adultPax', 'kidPax', 'billingAddress', 'shippingAddress'];
    fields.map((field) => form.resetField(field));
    // For select component need to set to ''; due to external lib issue
    form.setValue('preferDate', '');
  }, [formDeliveryMethod]);

  // ====================== VIEWS
  const renderItems = () => {
    if (isEmpty(orderItem)) {
      return (
        <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
          {/* getColor */}
          <div className="flex flex-col items-center justify-center space-y-2">
            <ImSpoonKnife color="#B3B5BD" size={55} />
            <span className="text-smoke-600">
              {t('order.create.noItems', {}, 'There are no items yet for this order.')}
            </span>
          </div>
          <Button className="border-primary-500" onClick={() => setOpenProduct(true)}>
            <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
              <AiOutlinePlus className="!text-primary-500" />
              <span>{t('actions.addItems', {}, 'Add Items')}</span>
            </div>
          </Button>
        </section>
      );
    }
    if (loadingOrderItem) return <Loading />;
    if (!isEmpty(orderItem) && !selectedProducts?.data?.totalItems) return <SomethingWentWrong />;

    // render order items
    return (
      <React.Fragment>
        <ViewProductItem
          products={selectedProducts?.data?.items}
          currentlyInCart={orderItem}
          onDelete={(addingVariant) => {
            const filtered = orderItem.filter((item) => !isEqual(item, addingVariant));
            setOrderItem(filtered);
          }}
          onEdit={(editing) => {
            setEditState({
              open: true,
              editing,
            });
          }}
        />
      </React.Fragment>
    );
  };

  // extra info input based on fulfilment method
  const renderExtraInfo = () => {
    if (formDeliveryMethod === FulfillmentMethodType.DineIn) {
      return (
        <div className="flex flex-col space-y-4">
          <FormTextField
            required
            label={t('order.create.table', {}, 'Table')}
            name="table"
            placeholder={t('order.placeholder.tableNumber', {}, 'Please enter table number')}
          />

          <FormNumberField
            required
            label={t('order.create.kidPax', {}, 'Adult Pax')}
            name="adultPax"
            placeholder={t('order.placeholder.adultPax', {}, 'Please enter number of adult(s)')}
          />
          <FormNumberField
            label={t('order.create.kidPax', {}, 'Kid Pax')}
            name="kidPax"
            placeholder={t('order.placeholder.kidPax', {}, 'Please enter number of kid(s)')}
          />
        </div>
      );
    }

    const slotInput = () => {
      const renderSlots = () => {
        if (!formPreferDate) return null;
        if (loadingSlots) return <Loading />;
        if (!selectedDateSlots || isEmpty(selectedDateSlots?.slots)) {
          return (
            <div className="bg-secondary-100 p-4 flex flex-col items-center justify-center rounded-lg">
              {t('order.create.noSlot', 'No Slots Available')}
            </div>
          );
        }

        return (
          <FormSelectField
            required
            label={t('order.create.slot', 'Slot')}
            placeholder={t('order.create.selectSlot', 'Select slot')}
            name="slot"
            options={selectedDateSlots?.slots?.map((slot) => ({ value: slot, label: slot }))}
          />
        );
      };
      return (
        <div className="flex flex-col space-y-4 w-full">
          <FormDayField
            required
            min={new Date()}
            max={dayjs().add(OrderSlotMaxDay, 'days').toDate()}
            label={t(
              `order.create.${camelCase(formDeliveryMethod)}Date`,
              `${toCamelCaseWord(formDeliveryMethod)} date`
            )}
            name="preferDate"
            // onValue={(date) =>
            //   form.setValue('preferDate', date ? new Date(date).toISOString() : null)
            // }
            // the component doesn't handle iso string (need strict Date instance)
            // value={formPreferDate ? new Date(formPreferDate) : undefined}
          />
          {renderSlots()}
        </div>
      );
    };
    if (formDeliveryMethod === FulfillmentMethodType.Pickup) {
      return <div className="flex flex-col space-y-4 w-full">{slotInput()}</div>;
    }

    if (formDeliveryMethod === FulfillmentMethodType.Delivery) {
      /**
       * Note:
       * if existing customer:
       * condition: (!loadingCustomerAddress && customerId)
       * - fetch address, select from address
       * - allow create new customer address
       *
       * if guest:
       * condition: (!loadingCustomerAddress)
       * - manual input order address
       */
      if (customerType === 'existing' && !form.getValues('customerId')) {
        return (
          <div className="text-error-300">
            <h3>{t('order.create.selectCustomer', {}, 'Please select a customer')}</h3>
          </div>
        );
      }

      if (customerType === 'existing' && form.getValues('customerId')) {
        const createNewAddress = (buttonClassName?: string) => (
          <Button className={`${buttonClassName}`} onClick={() => setOpenCustomerAddrForm(true)}>
            <div className="flex flex-row items-center justify-start space-x-2">
              <AiOutlinePlus color="#8E24AA" />
              <span className="text-primary-500">
                {t('order.create.createNewAddress', {}, 'Create New Address')}
              </span>
            </div>
          </Button>
        );

        const sorted = sortUserAddress(customerAddress?.data?.addresses || []);

        const getLabel = (addr: Partial<Address>) => {
          const { fullName, phoneNumber, ...restAddr } = addr;
          let final = joinAddress(restAddr);

          if (addr.defaultBillingAddress) {
            final += ` (Default billing)`;
          }

          if (addr.defaultShippingAddress) {
            final += ` (Default shipping)`;
          }

          return final;
        };

        return (
          <div className="flex flex-col space-y-4">
            {slotInput()}
            {isEmpty(sorted) && (
              <FieldGroup>
                <Label showRequiredIndicator>{t('order.create.address', {}, 'Address')}</Label>
                {createNewAddress()}
              </FieldGroup>
            )}
            {!isEmpty(sorted) && (
              <Controller
                name="shippingAddress"
                control={form.control}
                // NOTE: temp disable, value not working
                // defaultValue={first(sorted)?.id}
                rules={{
                  validate: (value) => {
                    const postalCode = sorted.find((sort) => sort.id === value)?.postalCode;
                    const postalCodeInvalid = postalCode && !validPostalCode.includes(postalCode);
                    if (postalCodeInvalid) {
                      return t(
                        'order.create.address.invalidPostCode',
                        {},
                        'Postal Code is not within delivery range'
                      );
                    }
                    return true;
                  },
                }}
                render={({ field: { name }, fieldState }) => {
                  return (
                    <>
                      <FormSelectField
                        required
                        label={t('order.create.shippingAddress', {}, 'Shipping Address')}
                        name={name}
                        className="h-20"
                        placeholder={t(
                          'order.create.selectCustomerAddress',
                          {},
                          `Select customer's address`
                        )}
                        options={sorted.map((addr) => ({
                          label: getLabel(addr),
                          value: addr.id!,
                        }))}
                        footer={createNewAddress('border-none')}
                      />
                      {fieldState.error && (
                        <p className="text-error-300 text-right text-sm mt-3">
                          {fieldState.error.message}
                        </p>
                      )}
                    </>
                  );
                }}
              />
            )}

            {!isEmpty(sorted) && (
              <FormSelectField
                required
                label={t('order.create.billingAddress', {}, 'Billing Address')}
                name="billingAddress"
                className="h-20"
                placeholder={t(
                  'order.create.selectCustomerAddress',
                  {},
                  `Select customer's address`
                )}
                options={sorted.map((addr) => ({
                  label: getLabel(addr),
                  value: addr.id!,
                }))}
                footer={createNewAddress('border-none')}
              />
            )}
          </div>
        );
      }

      if (customerType === 'guest') {
        // Guest, assign address for this order only
        const formShipping = form.getValues('shippingAddress') as OrderAddress;
        const formBilling = form.getValues('billingAddress') as OrderAddress;

        return (
          <section className="flex flex-col space-y-4">
            {slotInput()}
            <FieldGroup layout="horizontal">
              <Label showRequiredIndicator className="mt-2">
                {t('order.create.shippingAddress', {}, 'Shipping Address')}
              </Label>
              {formShipping ? (
                <div className="flex flex-col space-y-4 w-full">
                  <div className="flex flex-row items-center space-x-2">
                    <TextInput className="w-full" value={joinAddress(formShipping)} disabled />
                    <IconButton
                      onClick={() =>
                        setGuestAddrState({
                          open: true,
                          editing: 'shippingAddress',
                          initialValue: form.getValues('shippingAddress'),
                        })
                      }
                    >
                      <AiOutlineEdit size={30} className="text-black" />
                    </IconButton>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <Checkbox
                      id="sameAsBilling"
                      checked={sameAsBilling}
                      onCheckedChange={(same: boolean) => setSameAsBilling(same)}
                    />
                    <label htmlFor="sameAsBilling" className="cursor-pointer">
                      {t('order.create.sameAsBilling', {}, 'Same as billing address')}
                    </label>
                  </div>
                </div>
              ) : (
                // Assign SHIPPING
                <Button
                  onClick={() => setGuestAddrState({ open: true, editing: 'shippingAddress' })}
                >
                  <div className="flex flex-row items-center justify-start space-x-2">
                    <AiOutlinePlus className="!text-primary-500" />
                    <span className="text-primary-500">
                      {t('order.create.assignAddress', {}, 'Assign Address')}
                    </span>
                  </div>
                </Button>
              )}
            </FieldGroup>

            {!sameAsBilling && (
              <FieldGroup>
                <Label showRequiredIndicator>
                  {t('order.create.billingAddress', {}, 'Billing Address')}
                </Label>
                {formBilling ? (
                  <div className="flex flex-row items-center space-x-2 w-full">
                    <TextInput value={joinAddress(formBilling)} disabled />
                    <IconButton
                      onClick={() =>
                        setGuestAddrState({
                          open: true,
                          editing: 'billingAddress',
                          initialValue: form.getValues('billingAddress'),
                        })
                      }
                    >
                      <AiOutlineEdit size={30} className="text-black" />
                    </IconButton>
                  </div>
                ) : (
                  <Button
                    onClick={() => setGuestAddrState({ open: true, editing: 'billingAddress' })}
                  >
                    <div className="flex flex-row items-center justify-start space-x-2">
                      <AiOutlinePlus className="!text-primary-500" />
                      <span className="text-primary-500">
                        {t('order.create.assignAddress', {}, 'Assign Address')}
                      </span>
                    </div>
                  </Button>
                )}
              </FieldGroup>
            )}
          </section>
        );
      }
    }

    return null;
  };

  return (
    <div className="overflow-y-scroll">
      {useCustomerState.open && (
        <ModalWrapper
          open
          setOpen={(open) => setUseCustomerState({ open })}
          title={t('order.create.customerExisted', 'Customer Existed')}
          onConfirm={() => {
            const values = form.getValues();
            onSubmit({
              ...values,
              customerId: useCustomerState.id,
              guestEmail: undefined,
              guestName: undefined,
              guestPhone: undefined,
            });
          }}
          confirmButtonTitle={t('order.create.createWithExistingCust', 'Create Order')}
          cancelButtonTitle="Review Again"
          loading={placingOrder}
        >
          <div className="flex flex-col space-y-2 mb-8">
            <p>{t('order.create.foundCustomerInfo', 'We found an existing customer with info:')}</p>
            <div className="flex flex-col space-y-1 p-4 rounded-lg bg-secondary-100">
              <p>Full Name: {useCustomerState.fullName || ''}</p>
              <p>Email: {useCustomerState.email || ''}</p>
              <p>Contact: {useCustomerState.phone || ''}</p>
            </div>
            <p>
              {t(
                'order.create.proceedExistingCust',
                'Proceed with creating the order for this customer ?'
              )}
            </p>
          </div>
        </ModalWrapper>
      )}
      {openCustomerAddrForm && (
        <AddressModalForm
          open={openCustomerAddrForm}
          setOpen={setOpenCustomerAddrForm}
          onSubmit={handleAddAddress}
          availablePostCodes={validPostalCode as unknown as string[]}
          // buttonLoading={} // TODO: mutation loading
        />
      )}
      {guestAddrState?.open && (
        <AddressModalForm
          open={guestAddrState.open}
          setOpen={(open) => setGuestAddrState((prev) => ({ ...prev, open }))}
          onSubmit={handleGuestAddress}
          type="OrderAddress"
          initialValue={guestAddrState.initialValue}
          availablePostCodes={validPostalCode as unknown as string[]}
        />
      )}
      <ShowPageWrapper
        resourceName={resourceNames.order}
        title={t('order.create.name', {}, 'Create Order')}
        extra={
          <Button
            disabled={isEmpty(orderItem)}
            onClick={form.handleSubmit(handleBeforeSubmit)}
            variant="solid"
            size="md"
            className="mx-5"
            loading={placingOrder}
          >
            {t('order.create.create', {}, 'Create')}
          </Button>
        }
      >
        {/* Editing Cart Item */}
        {/* To allow destroy on close */}
        {editState.open && (
          <Dialog.Root
            open={editState.open}
            onOpenChange={(open) => setEditState((prev) => ({ ...prev, open }))}
          >
            <Dialog.Portal>
              <Dialog.Overlay className="z-10 absolute h-full w-full" />
              <Dialog.Content className="fixed top-1/3 z-20 w-10/12">
                <Dialog.Title className="flex flex-row items-center justify-between">
                  {t('order.create.editItem', {}, 'Edit Item')}
                </Dialog.Title>
                <div className="flex flex-col py-4 space-y-2">
                  <AddProductItem
                    mode="edit"
                    allowRemoved={false}
                    currentlySelected={editState.editing}
                    onAdding={(updated) => setEditState((prev) => ({ ...prev, updated }))}
                    products={selectedProducts?.data?.items?.filter(
                      (prod) => prod.id === Object.keys(editState.editing)[0]
                    )}
                  />
                </div>

                <section className="my-4 flex flex-row items-center justify-end space-x-2">
                  <Button
                    variant="plain"
                    onClick={() => setEditState({ open: false, editing: {} })}
                  >
                    {t('actions.cancel', {}, 'Cancel')}
                  </Button>
                  <Button onClick={handleEditItem} variant="solid">
                    {t('actions.confirm', {}, 'Confirm')}
                  </Button>
                </section>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        )}

        {/* Adding Item To Cart */}
        {/* To allow destroy on close */}
        {openProduct && (
          <AddItemModal
            open={openProduct}
            setOpen={setOpenProduct}
            title={t('actions.addItems')}
            onAddItem={(selected) => {
              // selected is object of unique products
              // map into array to allow duplicated
              const mapped = Object.entries(selected).map(([key, value]) => ({ [key]: value }));
              setOrderItem([...orderItem, ...mapped]);

              setOpenProduct(false); // destroyed on close
            }}
          />
        )}
        <Form form={form} onSubmit={onSubmit}>
          <div className="flex flex-col space-y-4">
            <Card>
              <Card.Header className="font-bold" bordered>
                <h3>{t('order.create.orderDetails', {}, 'Order Details')}</h3>
              </Card.Header>
              <Card.Body>
                <section className="flex flex-col space-y-4 2xl:w-1/2">
                  <RadioGroup
                    required
                    isField
                    label={t('order.create.customerType.name', {}, 'Customer Type')}
                    value={customerType}
                    options={['existing', 'guest'].map((type) => ({
                      label: t(`order.create.customerType.${[type]}`, {}, startCase(type)),
                      value: type,
                    }))}
                    onValueChange={(type: CustomerType) => setCustomerType(type)}
                  />
                  {/* NOTE: Conditional rendering cause less hook rendered error */}
                  <div className={`${customerType === 'guest' && 'hidden'}`}>
                    {renderDataSelection({
                      input: {
                        item: {
                          title: t('order.create.customer', {}, 'Customer'),
                          name: 'customerId',
                        },
                        register: form.register,
                        setValue: form.setValue,
                        watch: form.watch,
                      },
                      selectionComponent: CustomerSelection,
                      selectionProps: { required: true } as CustomerSelectionProps,
                    })}
                  </div>
                  <div className={`${customerType === 'existing' && 'hidden'} space-y-4`}>
                    <FormTextField
                      required={customerType === 'guest'}
                      name="guestName"
                      label={t('order.create.guestName', {}, 'Guest Name')}
                      placeholder={t(
                        'order.placeholder.guestName',
                        {},
                        `Please enter guest's full name`
                      )}
                    />

                    <FormPhoneField
                      required={customerType === 'guest'}
                      label={t('order.create.guestContact', {}, 'Guest Contact')}
                      name="guestPhone"
                      placeholder={t(
                        'order.placeholder.guestContact',
                        {},
                        `Please enter guest's contact number`
                      )}
                    />

                    <FormTextField
                      required={customerType === 'guest'}
                      label={t('order.create.guestEmail', {}, 'Guest Email')}
                      name="guestEmail"
                      placeholder={t(
                        'order.create.guestEmailPlaceholder',
                        {},
                        `Enter Guest's email address`
                      )}
                    />
                  </div>

                  <FormSelectField
                    name="deliveryMethod"
                    label={t('order.create.fulfillmentMethod.name', {}, 'Fulfillment Method')}
                    placeholder={t('order.create.selectMethod', {}, 'Select Method')}
                    options={Object.keys(FulfillmentMethodType).map((key) => ({
                      label: t(
                        `order.create.fulfillmentMethod.${[key.toLowerCase()]}`,
                        {},
                        parseCase(key)
                      ),
                      value: FulfillmentMethodType[key as keyof typeof FulfillmentMethodType],
                    }))}
                    required
                  />

                  {renderExtraInfo()}

                  <FormTextField
                    name="remark"
                    label={t('order.create.remark', {}, 'Remark')}
                    placeholder={t(
                      'order.placeholder.remark',
                      {},
                      'Please add a remark for this order'
                    )}
                    type="textarea"
                  />

                  {!isEmpty(giftOptions?.data) && (
                    <RadioGroup
                      name="giftOption"
                      isField
                      label={t('order.form.packagingOption', {}, 'Packaging Options')}
                      value={formGiftOptions}
                      defaultValue="none"
                      onValueChange={(id) => {
                        let giftId: null | string = id;
                        if (giftId === 'none') giftId = null;
                        form.setValue('giftOption', giftId);
                      }}
                      options={[{} as any, ...(giftOptions?.data || [])].map?.(
                        (gift: GiftOption, index) => {
                          if (index === 0) {
                            return { label: t('common.none', 'None'), value: 'none' };
                          }
                          const { id, label: labelDict, price } = gift;
                          const l = lang()?.toLowerCase() || LanguageCode.En;
                          const label = labelDict?.[l] + ` (+MYR ${parsePrice(price)})` || '';

                          return {
                            label,
                            value: id,
                          };
                        }
                      )}
                    />
                  )}
                </section>
              </Card.Body>
            </Card>

            {/* Items */}
            <Card>
              <Card.Header className="flex flex-row items-center justify-between w-full" bordered>
                <h3 className="font-bold">{t('order.create.items', {}, 'Items')}</h3>
                {!isEmpty(orderItem) && (
                  <Button
                    onClick={() => {
                      setOpenProduct(true);
                    }}
                    size="sm"
                    className="!px-4 border-primary-500"
                  >
                    {/* getColor */}
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus color="#8E24AA" />
                      <span>{t('order.create.addMore', {}, 'Add More')}</span>
                    </div>
                  </Button>
                )}
              </Card.Header>
              <Card.Body>{renderItems()}</Card.Body>
            </Card>
          </div>
        </Form>
      </ShowPageWrapper>
    </div>
  );
};
