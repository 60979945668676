import { Button, Dialog, TextInput } from '@scalingworks/react-admin-ui';
import React, { useEffect, useState } from 'react';
import { Props } from './props';
import { RadioGroup } from '~/components';
import {
  useApiUrl,
  useCustomMutation,
  useNavigation,
  useNotification,
  useTranslate,
} from '@refinedev/core';
import { OrderStatus } from '~/config/types';
import { UpdateOrderStatusInput } from '~/api';

export const OrderStatusUpdate: React.FC<Props> = (props) => {
  const { open, setOpen, orderId, initialStatus, orderDeliveryMethod, onCompleted } = props;

  // ======================== HOOKS
  const navigateTo = useNavigation();
  const t = useTranslate();
  const notif = useNotification();
  const apiUrl = useApiUrl();

  /**
   * Note:
   * Order Status enum is arrange accordingly with flow
   * hence can assume that mapped index can only increase in updating status
   * rules:
   * 1. cant go back: (target > current)
   * 2. cant skip: (current === target - 1)
   * 3. can skip to cancelled (last index): (target === length - 1)
   */

  // ======================== API
  const { isLoading, mutate } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data?.id) {
          setOpen(false);
          onCompleted?.(data);
        }
      },
    },
  });

  // ======================== STATES
  const [selected, setSelected] = useState<OrderStatus>(initialStatus);
  const [paymentRemark, setPaymentRemark] = useState<string>();

  const onUpdateStatus = () => {
    mutate({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: ['id'],
        operation: 'updateOrderState',
        variables: {
          input: {
            type: 'UpdateOrderStatusInput!',
            value: {
              orderId,
              status: selected.toUpperCase(),
              paymentMetadata: {
                remarks: paymentRemark,
              },
            } as UpdateOrderStatusInput,
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('order.edit.failed', {}, 'Failed to update order status'),
        type: 'error',
      },
      successNotification: {
        message: t('order.edit.success', {}, 'Order Status Updated'),
        type: 'success',
      },
    });
  };

  const renderContent = () => {
    const statusKeys = Object.keys(OrderStatus).filter((key) =>
      [
        OrderStatus.Draft,
        OrderStatus.Pending,
        OrderStatus.Confirmed,
        OrderStatus.Preparing,
      ].includes(key as OrderStatus)
    );

    const renderExtra = (orderStatus: OrderStatus) => {
      if (
        initialStatus !== OrderStatus.Confirmed &&
        orderStatus === OrderStatus.Confirmed &&
        selected === OrderStatus.Confirmed
      ) {
        return (
          <TextInput
            type="textarea"
            onChange={(ev) => setPaymentRemark(ev.target.value)}
            placeholder={t('order.form.remark', {}, 'Remark')}
          />
        );
      }

      return null;
    };

    const shouldDisabled = (target: number): boolean => {
      const statusValues = Object.values(OrderStatus);
      const current = statusValues.findIndex((value) => value === initialStatus);
      const previousState = target <= current;
      // NOTE: allow skip state since dine-in order would just go completed from draft/pending
      const toCancel = target === statusValues.findIndex((e) => e === OrderStatus.Cancelled);

      if (toCancel) return false;
      // disabled
      if (previousState) return true;
      return false;
    };

    return (
      <RadioGroup
        isField
        label={t('common.status', {}, 'Status')}
        value={selected}
        options={statusKeys.map((key, pos) => ({
          label: t(`order.status.${key.toLowerCase()}`),
          value: key,
          props: { disabled: shouldDisabled(pos) },
          extra: renderExtra(key as OrderStatus),
        }))}
        onValueChange={(status: OrderStatus) => setSelected(status)}
      />
    );
  };

  useEffect(() => {
    // manual "destroy on close"
    if (!open) setSelected(initialStatus);
  }, [open]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/2 z-20 w-1/2">
          <Dialog.Title>{t('actions.updateStatus', {}, 'Update Status')}</Dialog.Title>
          <div className="p-4">{renderContent()}</div>

          <div className="flex flex-row justify-end space-x-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              {t('actions.cancel', {}, 'Cancel')}
            </Button>
            <Button
              disabled={initialStatus === selected}
              loading={isLoading}
              variant="solid"
              onClick={onUpdateStatus}
            >
              {t('actions.confirm', {}, 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
