import React, { useState } from 'react';
import { Dialog, NumberInput } from '@scalingworks/react-admin-ui';
import { useForm } from '@refinedev/react-hook-form';
import { flexRow } from '~/components';
import { Controller } from 'react-hook-form';
import { GQLClient } from '~/config/gql-client';
import { PointSettingType, getSdk } from '~/api';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';

export const PointSettingsForm: React.FC<Props> = (props) => {
  const { pointsData, setForceRender } = props;

  const { handleSubmit, control, reset } = useForm<any>({
    refineCoreProps: {
      dataProviderName: undefined,
      resource: 'no-resource',
    },
    defaultValues: {},
    values: {},
  });
  const gqlClient = GQLClient?.getInstance();

  const [open, setOpen] = useState(false);
  const t = useTranslate();

  const spendingData = pointsData?.find((subItem) => subItem?.type === PointSettingType?.Spending);
  const reviewData = pointsData?.find((subItem) => subItem?.type === PointSettingType?.Review);

  const onSubmitForm = async (data: any) => {
    const spendingForm = data?.spending;
    const reviewForm = data?.review;
    try {
      await getSdk(gqlClient)?.updatePointSetting({
        input: [
          {
            id: spendingData?.id || '',
            awardPointPerUnit: parseFloat(spendingForm?.awardPointPerUnit),
            unitValue: spendingForm?.unitValue?.toString(),
          },
          {
            id: reviewData?.id || '',
            awardPointPerUnit: parseFloat(reviewForm?.awardPointPerUnit),
            unitValue: reviewForm?.unitValue?.toString(),
          },
        ],
      });
      setForceRender &&
        setForceRender((prev) => {
          return !prev;
        });
      setOpen(false);
    } catch (err) {
      return;
    }
  };

  const renderButton = ({
    title,
    type = 'button',
    isSolid = false,
  }: {
    title: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    isSolid?: boolean;
  }) => {
    return (
      <button
        className={`${
          isSolid ? 'bg-primary-500 text-white ' : 'text-primary-500 '
        } border-primary-500 rounded py-2 px-4`}
        style={{ borderWidth: 1, alignItems: 'center', ...flexRow }}
        type={type}
      >
        {title}
      </button>
    );
  };

  const renderFormInput = ({
    name,
    title,
    showPrefix = true,
    suffix,
    decimalPlaces = 0,
  }: {
    name: string;
    title: string;
    showPrefix?: boolean;
    suffix?: string;
    decimalPlaces?: number;
  }) => {
    return (
      <div className="flex flex-row items-center">
        <div style={{ display: 'flex', flex: 1 }}>
          <p>{title}</p>
        </div>
        <div className="flex flex-row items-center gap-5" style={{ display: 'flex', flex: 2 }}>
          <Controller
            control={control}
            name={`${name}.unitValue`}
            defaultValue={name === 'spending' ? spendingData?.unitValue : reviewData?.unitValue}
            render={({ field: { onChange, value } }) => {
              const formattedValue = value.split('.')[0];

              return (
                <NumberInput
                  value={formattedValue}
                  onValue={onChange}
                  useFormatNumber={false}
                  prefix={<p className="text-gray-400">{showPrefix && 'MYR'}</p>}
                  suffix={
                    <p className="text-gray-400" style={{ marginTop: 1 }}>
                      {suffix}
                    </p>
                  }
                  decimalPlaces={decimalPlaces}
                  style={{
                    textAlign: 'right',
                  }}
                />
              );
            }}
          />
          <p>for</p>
          <Controller
            control={control}
            name={`${name}.awardPointPerUnit`}
            defaultValue={
              name === 'spending' ? spendingData?.awardPointPerUnit : reviewData?.awardPointPerUnit
            }
            render={({ field: { onChange, value } }) => {
              return (
                <NumberInput
                  value={value}
                  onValue={onChange}
                  useFormatNumber={false}
                  suffix={
                    <p className="text-gray-400" style={{ marginTop: 1 }}>
                      {t('membershipTiers.unit.points')}
                    </p>
                  }
                  style={{
                    textAlign: 'right',
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(val) => {
        setOpen(val);
        reset();
      }}
    >
      <Dialog.Trigger asChild>
        {renderButton({ title: t('actions.edit').toUpperCase() })}
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Title>
            <p className="my-2">
              {t('actions.edit')} {t('membershipTiers.pointSettings.name')}
            </p>
          </Dialog.Title>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="mt-4">
              {renderFormInput({
                name: 'spending',
                title: t('membershipTiers.pointSettings.spending'),
              })}
              <div className="mt-6">
                {renderFormInput({
                  name: 'review',
                  title: t('membershipTiers.unit.post'),
                  showPrefix: false,
                  suffix: t('membershipTiers.pointSettings.review'),
                })}
              </div>
            </div>

            <div style={{ ...flexRow }} className="flex-1 items-end justify-end mt-6 gap-5">
              <Dialog.Close asChild>{renderButton({ title: t('actions.cancel') })}</Dialog.Close>
              {renderButton({ title: t('actions.submit'), type: 'submit', isSolid: true })}
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
