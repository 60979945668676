import React from 'react';
import {
  IconButton,
  NumberField,
  SwitchField,
  Table,
  TextField,
} from '@scalingworks/react-admin-ui';
import { Controller, ControllerProps } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { Props, TableCells } from './props';
import { useTranslate } from '@refinedev/core';

export type PackagingTableProps = Props;

export const PackagingTable: React.FC<Props> = (props) => {
  const { form } = props;
  const { control, watch, setValue } = form;
  const t = useTranslate();

  const packagingList: any[] = watch('packaging');

  const cells: TableCells[] = [
    {
      title: t('packaging.column.name'),
      key: 'packageName',
      type: 'text',
      width: '40%',
    },
    {
      title: t('packaging.column.price'),
      key: 'price',
      type: 'number',
      width: '40%',
      prefix: 'MYR',
    },
    {
      title: t('packaging.column.enabled'),
      key: 'enabled',
      type: 'switch',
      width: '10%',
    },
    {
      title: t('common.action'),
      key: 'action',
      type: 'action',
      width: '10%',
    },
  ];

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          {cells.map((item) => (
            <Table.Th>{item.title}</Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {packagingList?.map((packagingItem: Record<any, any>, index) => {
          const controlKey = `packaging.${index}`;

          const renderInput = (item: TableCells) => {
            const { type, key: itemKey } = item;
            const key = itemKey as string;
            const controllerProps: Omit<ControllerProps, 'render'> = {
              control,
              name: `${controlKey}.${key}`,
              defaultValue: packagingItem[key],
              rules: {
                required: !['action', 'switch']?.includes?.(type || ''),
              },
            };

            const onPressDelete = () => {
              const clonePackagingList = packagingList;
              clonePackagingList?.splice(index, 1);
              setValue('packaging', clonePackagingList);
            };

            return (
              <div>
                <Controller
                  {...controllerProps}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    const commonStyle = error ? { borderColor: 'red' } : {};

                    switch (type) {
                      case 'number':
                        return (
                          <NumberField
                            prefix={item?.prefix}
                            value={packagingItem[key]}
                            onChange={onChange}
                            style={commonStyle}
                            required
                          />
                        );
                      case 'switch':
                        return (
                          <SwitchField
                            value={value}
                            defaultChecked={value}
                            onCheckedChange={onChange}
                          />
                        );

                      case 'action':
                        return (
                          <IconButton onClick={onPressDelete}>
                            <BsTrash />
                          </IconButton>
                        );

                      default:
                        return (
                          <TextField
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            style={commonStyle}
                            required
                          />
                        );
                    }
                  }}
                />
              </div>
            );
          };
          return (
            <Table.Tr key={`${index}_${packagingItem?.name}`}>
              {cells.map((item, innerIndex) => {
                return (
                  <Table.Td
                    width={item?.width}
                    key={`${innerIndex}_${item?.key}_${index}_${packagingItem?.name}`}
                  >
                    {renderInput(item)}
                  </Table.Td>
                );
              })}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};
