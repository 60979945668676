import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  MultiTextField,
  Table,
  TextInputMultiSelect,
} from '@scalingworks/react-admin-ui';
import { Form } from '@scalingworks/refine-react-admin';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { Controller } from 'react-hook-form';
import { Props } from './props';
import uniq from 'lodash/uniq';
import { useTranslate } from '@refinedev/core';
import { Post_Code_Arg } from '~/resources/setting/setting-resource';
import { FaLocationDot } from 'react-icons/fa6';
import { ModalWrapper } from '../ModalWrapper';
import { findPostcode, getCities, getPostcodes, getStates } from 'malaysia-postcodes';
import { StatePostalCodeSelector } from '../StatePostalCode';

export type ConflictCodeState = {
  open: boolean;
  conflictCodes: string[];
};

export enum DeliveryFeeType {
  'FLAT' = 'FLAT',
  'TIER' = 'TIER',
}

export type TierRule = {
  min: number;
  fee: number;
};

export const ShippingMethodForm: React.FC<Props> = (props) => {
  const { form, actionType = 'create', initialValues } = props;
  const { setValue, getValues } = form;
  const { name, checker } = initialValues || {};
  const postCodeString = checker?.args.find((arg) => arg.name === Post_Code_Arg)?.value || '[]';
  const postalCodes = JSON.parse(postCodeString);
  const states = getStates();

  // ========================= HOOKS
  const t = useTranslate();

  // ======================= STATES
  const [openSelector, setOpenSelector] = useState(false);
  const [selectedStates, setSelectedStates] = useState<Array<string>>([]);

  // ========================= VARIABLES
  const createMode = actionType === 'create';

  // ========================= HELPERS
  const onUpdateSelectedStates = (checked: boolean, state: string) => {
    setSelectedStates(
      checked
        ? Array.from(new Set([...selectedStates, state]))
        : selectedStates.filter((selectedState) => selectedState !== state)
    );
  };

  const onConfirmStateSelection = () => {
    const postCodes = selectedStates
      .map((state) => {
        const cities = getCities(state);
        return cities.map((city) => getPostcodes(state, city)).flat();
      })
      .flat();

    setValue('postalCode', postCodes);
    setOpenSelector(false);
  };

  // ========================= VIEWS
  return (
    <Form form={form}>
      <section className="flex flex-col space-y-8">
        <Card>
          <Card.Header bordered>
            <section className="font-bold">{t('shippingMethods.details')}</section>
          </Card.Header>
          <Card.Body>
            <div className="flex flex-col space-y-4 xl:w-2/3">
              <FormTextField
                name="name"
                label={t('shippingMethods.form.name')}
                required
                placeholder={t('shippingMethods.placeholder.name')}
                defaultValue={name}
              />

              <Controller
                name="postalCode"
                defaultValue={postalCodes}
                render={({ field: { name, onChange, value } }) => (
                  <MultiTextField
                    name={name}
                    value={uniq(value) || []}
                    onValue={(postCodes) => {
                      const uniquePostCodes = uniq(
                        (postCodes ?? []).map((code) => code?.toString())
                      );
                      onChange(uniquePostCodes);
                    }}
                    required
                    label={t('shippingMethods.form.postalCode')}
                    placeholder={t(
                      'shippingMethods.placeholder.postalCode',
                      'Click to select postal code'
                    )}
                    className="h-64"
                  />
                )}
              />

              <div className="flex flex-row justify-end">
                <Button className="text-primary-500 " onClick={() => setOpenSelector(true)}>
                  {t('setting.selectStatePostalCode', 'Select postal code by state')}
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        {openSelector && (
          <ModalWrapper
            open
            setOpen={setOpenSelector}
            onConfirm={onConfirmStateSelection}
            title={t('setting.statesName', 'States')}
          >
            {states?.map((state) => (
              <StatePostalCodeSelector
                state={state}
                onUpdateSelectedStates={onUpdateSelectedStates}
              />
            ))}
          </ModalWrapper>
        )}

        {createMode && (
          <section className="w-full text-center">
            <div className="p-8 flex flex-col items-center justify-center space-y-4">
              <FaLocationDot className="!text-primary-500" size={55} />
              <p className="text-smoke-600 w-96">{t('shippingMethods.create.hints')}</p>
            </div>
          </section>
        )}
      </section>
    </Form>
  );
};

export type ShippingMethodProps = Props;
