import React from 'react';
import { useCreate, useNavigation, useTranslate } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button } from '@scalingworks/react-admin-ui';

import { DiscountPromotionForm, ShowPageWrapper } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { CreateDiscountPromotionInput } from '~/api';

export const DiscountPromotionCreatePage: React.FC = () => {
  // ========================= HOOKS
  const t = useTranslate();
  const form = useForm();
  const navigateTo = useNavigation();

  // ========================= API
  const { mutate: createDiscountPromotion } = useCreate();

  // ========================= EVENTS
  const onCreate = (data: any) => {
    const { name, amount, period, type } = data;
    const startDate = new Date(period?.from).toISOString();
    const endDate = period?.to ? new Date(period.to).toISOString() : undefined;

    createDiscountPromotion(
      {
        resource: resourceNames.discountPromotion,
        values: {
          name,
          amount: +amount,
          startDate,
          endDate,
          type,
          enabled: true,
        } as CreateDiscountPromotionInput,
        errorNotification: {
          message: t('discountPromotions.create.failed'),
          type: 'error',
        },
        successNotification: {
          message: t('discountPromotions.create.success'),
          type: 'success',
        },
      },
      {
        onSuccess: (data) => {
          const id = data?.data?.id;
          if (id) navigateTo.show(resourceNames.discountPromotion, id);
          else navigateTo.list(resourceNames.discountPromotion);
        },
      }
    );
  };

  // ========================= VIEWS
  return (
    <div className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.discountPromotion}
        title={t('discountPromotions.create.name', {}, 'Create Discount Promotions')}
        extra={
          <Button
            // disabled={true}
            onClick={form.handleSubmit(onCreate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.create', {}, 'Create')}
          </Button>
        }
      >
        <DiscountPromotionForm form={form} />
      </ShowPageWrapper>
    </div>
  );
};
