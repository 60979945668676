import React, { useEffect, useRef, useState } from 'react';
import { IMessage, useFetchNotifications, useNotifications } from '@novu/notification-center';
import { ActionButton, Loading } from '~/components';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { FullDateTimeFormat } from '../constant';
import { Button, IconButton, Switch, Tag } from '@scalingworks/react-admin-ui';
import { useNavigation, useTranslate } from '@refinedev/core';
import { HiArrowLeft } from 'react-icons/hi';
import { resourceNames } from '~/resources/resource-names';
import flatMap from 'lodash/flatMap';
import { MdRefresh } from 'react-icons/md';

export const AdminNotificationPage: React.FC = () => {
  const { markAllNotificationsAsRead, removeAllMessages, removeMessage, unseenCount } =
    useNotifications();

  const [isReadOnly, setIsReadOnly] = useState(false);

  const {
    data: rawFetchedNotifications,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useFetchNotifications(
    { query: { read: isReadOnly ? false : undefined, limit: 10 } },
    { networkMode: 'online' }
  );
  const grouped = rawFetchedNotifications?.pages?.flatMap((page) => page.data);

  // ========================= HOOKS
  const nav = useNavigation();
  const t = useTranslate();

  // ========================= STATES
  const containerRef = useRef<HTMLDivElement>(null);
  const [notifications, setNotifications] = useState<IMessage[]>(grouped || []);

  // ========================= VARIABLES

  const handleScroll = () => {
    const containerScroll =
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight - 20;

    if (containerScroll && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  // ========================= EVENTS
  const handleActionClick = (notif: IMessage) => {
    const url = notif?.cta?.data?.url;
    if (url) {
      // navigation keep appending url as path even with https://
      window.location.href = url;
    }
  };

  // ========================= EFFECTS
  useEffect(() => {
    containerRef.current?.addEventListener('scroll', handleScroll);
    const grouped = flatMap(rawFetchedNotifications?.pages.flatMap((page) => page.data));
    setNotifications(grouped);
    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [rawFetchedNotifications]);

  useEffect(() => {
    refetch();
  }, [isReadOnly]);

  return (
    <div
      className="max-w-screen-md mx-auto p-4 h-screen"
      ref={containerRef}
      style={{ overflowY: 'auto' }}
    >
      <div className="flex flex-row items-center justify-between mb-2 pb-2 border-b border-solid border-gray-200">
        <div className="flex flex-row items-center space-x-2">
          <IconButton onClick={() => nav.goBack()}>
            <HiArrowLeft size={25} color="black" />
          </IconButton>
          <h1 className="text-2xl font-semibold">{t('notificationBlasts.notification.name')}</h1>
          {unseenCount > 0 && <Tag color="red">{unseenCount > 99 ? '99+' : unseenCount}</Tag>}
        </div>
        <div className="flex flex-row items-center space-x-2">
          <ActionButton
            actions={[
              {
                label: t('notificationBlasts.notification.mark'),
                name: 'mark-as-read',
                onAction: () => {
                  markAllNotificationsAsRead();
                  setIsReadOnly(false);
                },
              },
            ]}
            customTitle="Actions"
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-end space-x-2 mb-4">
        <IconButton onClick={() => refetch()}>
          <MdRefresh size={30} className="!text-smoke-600" />
        </IconButton>
        <h3 className="text-smoke-600">{t('notificationBlasts.notification.unread')}</h3>
        <Switch checked={isReadOnly} onCheckedChange={(checked) => setIsReadOnly(checked)} />
      </div>
      <ul className="space-y-4">
        {isLoading || isRefetching ? (
          <Loading />
        ) : !isEmpty(notifications) ? (
          notifications?.map((notification) => {
            const { cta, seen } = notification;
            const ctaButtonContent = cta.action?.buttons?.[0]?.content || '';
            return (
              <li
                key={notification._id}
                className={`bg-white border p-4 rounded-md shadow-md ${!seen && '!bg-red-100'}`}
              >
                <div className="flex justify-between items-center mb-8">
                  <h3>{typeof notification.content === 'string' ? notification.content : ''}</h3>
                  {!notification.read && <div className="w-4 h-4 rounded-full bg-red-500" />}
                </div>
                {cta?.data?.url && ctaButtonContent && (
                  <Button
                    size="sm"
                    variant="solid"
                    className="w-full mb-2"
                    onClick={() => handleActionClick(notification)}
                  >
                    {cta.action?.buttons?.[0]?.content || ''}
                  </Button>
                )}
                <div className="text-xs text-gray-400 mt-2">
                  {dayjs(notification.createdAt).format(FullDateTimeFormat)}
                </div>
              </li>
            );
          })
        ) : (
          <div className="w-full h-screen flex flex-col items-center justify-center">
            <div className="text-lg">{t('notificationBlasts.notification.no')}</div>
          </div>
        )}
      </ul>
      {isFetchingNextPage && <Loading />}
    </div>
  );
};

export default AdminNotificationPage;
