import React, { useState } from 'react';
import { Props } from './props';
import { useNavigation, useTranslate } from '@refinedev/core';
import { Card, Tag } from '@scalingworks/react-admin-ui';
import {
  ActionButton,
  DetailRenderer,
  ReservationStatusUpdate,
  ShowPageWrapper,
  SomethingWentWrong,
} from '~/components';
import { format } from 'date-fns';
import { resourceNames } from '~/resources/resource-names';
import { OccasionType, ReservationStatusType } from '~/api';
import { FullDateFormat, TimeFormat } from '~/config/constant';
import { dateFormatter } from '~/resources/helpers';

export const ReservationShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  // ======================= HOOKS
  const { data, isLoading } = queryResult;
  const {
    id,
    status,
    guestName,
    phoneNumber,
    diningDate,
    occasion,
    remark,
    cancellationRemark,
    adultPax = 0,
    kidPax = 0,
  } = data?.data || {};

  // ======================= HOOKS
  const t = useTranslate();
  const navigateTo = useNavigation();

  // ======================= STATES
  const [open, setOpen] = useState(false);

  // ======================= VIEWS
  const actions = [
    {
      label: t('actions.edit', undefined, 'Edit'),
      name: 'edit',
      onAction: () => navigateTo.edit(resourceNames.reservation, id!),
    },
  ];

  if (status !== ReservationStatusType.Cancelled) {
    actions.push({
      label:
        status === ReservationStatusType.Accepted
          ? t('reservations.cancel.name')
          : t('actions.updateStatus'),
      name: 'update-status',
      onAction: () => setOpen(true),
    });
  }

  if (!isLoading && !id) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <ReservationStatusUpdate
        open={open}
        setOpen={setOpen}
        reservationId={id!}
        initialStatus={status!}
      />
      <ShowPageWrapper
        resourceName={resourceNames.reservation}
        loading={isLoading}
        onBack={() => navigateTo.goBack()}
        title={t('reservations.details.name', undefined, 'Reservation Details')}
      >
        <Card className="relative z-0">
          <Card.Header className="font-bold" bordered>
            <section className="flex flex-row items-center justify-between">
              <h1>{t('common.general', undefined, 'General')}</h1>
              <ActionButton
                customTitle={t('common.action', undefined, 'Action')}
                actions={actions}
              />
            </section>
          </Card.Header>
          <Card.Body>
            <DetailRenderer
              data={{
                guestName,
                contact: phoneNumber,
                date: diningDate,
                time: diningDate,
                occasion,
                adultPax: adultPax,
                kidPax: kidPax,
                remark,
                cancellationRemark,
              }}
              resource={resourceNames.reservation}
              render={{
                date: (date) => <span>{dateFormatter(date, FullDateFormat)}</span>,
                time: (date) => <span>{dateFormatter(date, TimeFormat)}</span>,
                occasion: (occasions: OccasionType[] = []) => {
                  return (
                    <div className="space-x-1">
                      {occasions.map((occasion) => (
                        <Tag color="purple">
                          {t(
                            `reservations.form.occasionSelection.${occasion.toLowerCase()}`
                          ).toUpperCase()}
                        </Tag>
                      ))}
                    </div>
                  );
                },
              }}
            />
          </Card.Body>
        </Card>
      </ShowPageWrapper>
    </main>
  );
};
