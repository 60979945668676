import React, { useEffect } from 'react';
import SearchAndSelect, { MultiSearchAndSelectProp } from '../base';
import { useTranslate } from '@refinedev/core';

type Props = Omit<MultiSearchAndSelectProp, 'queryKey'> & {
  isWithName?: boolean;
  onSelect?: (id: string) => void;
};

export const MultiCollectionSelection: React.FC<Props> = (props) => {
  const { onSelect, isWithName, ...restProps } = props;
  const t = useTranslate();

  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getAssigningFacetCollection"
      accessingKey="values"
      getValueAndLabel={({ id, name }) => {
        let label = `${name}`.trim();
        return {
          value: isWithName ? `${id}-split-${label}` : `${id}`,
          label: label,
        };
      }}
      placeholder={t('product.placeholder.collection')}
    />
  );
};

export type CollectionSelectionProps = Props;
