import {
  createHelpers,
  createResource,
  defineCustomPage,
  ResourceField,
} from '@scalingworks/refine-react-admin';
import { FiUsers } from 'react-icons/fi';
import {
  ReservationStatusType as StatusType,
  type Reservation,
  ReservationSummary,
  ReservationSummaryInput,
} from '~/api';

import { resourceNames } from '~/resources/resource-names';
import { reservationFields, reservationStatusColor } from '../reservation-resource';
import {
  Card,
  DateRangeSelector,
  DayPicker,
  DaySelector,
  Table,
  Tag,
} from '@scalingworks/react-admin-ui';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import { Loading, ShowPageWrapper } from '~/components';
import { useApiUrl, useCustom, useNavigation, useTranslate } from '@refinedev/core';
import { DayDateFormat, DayFormat, ShortDateFormat, TimeFormat } from '~/config/constant';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

dayjs.extend(weekOfYear);
dayjs.extend(isToday);

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Reservation>({
    resourceName: resourceNames.reservationCalendar,
  });

const CalendarView: React.FC = () => {
  const weekNow = dayjs().week();
  const today = dayjs().format(DayDateFormat);

  // =========================== HOOKS
  const t = useTranslate();
  const url = useApiUrl();
  const navigateTo = useNavigation();

  // =========================== STATES
  const [selectedRange, setSelectedRange] = useState<Date[]>([]);

  // =========================== API
  const { data: summary, isLoading: loadingSummary } = useCustom<ReservationSummary[]>({
    method: 'get',
    url,
    meta: {
      fields: [
        'date',
        {
          reservations: [
            'id',
            'guestName',
            'status',
            'phoneNumber',
            'adultPax',
            'kidPax',
            'diningDate',
          ],
        },
      ] as ResourceField<ReservationSummary>[],
      operation: 'getReservationSummary',
      variables: {
        input: {
          value: {
            startDate: dayjs(selectedRange[0]).startOf('day'),
            endDate: dayjs(selectedRange[selectedRange.length - 1]).endOf('day'),
          } as ReservationSummaryInput,
          type: 'ReservationSummaryInput!',
        },
      },
    },
    queryOptions: {
      enabled: !!setSelectedRange.length,
    },
  });

  // =========================== EFFECTS
  // Default Dates
  useEffect(() => {
    if (weekNow) {
      const startDate = dayjs().week(weekNow).startOf('week');
      const daysArray = Array.from({ length: 7 }, (_, i) => startDate.add(i, 'day').toDate());

      setSelectedRange(daysArray);
    }
  }, [weekNow]);

  const tableData: Record<string, any> = {};
  summary?.data.forEach((s) => {
    // if (!tableData[s.date]) {
    //   tableData[s.date] = [];
    // }
    tableData[s.date] = s.reservations;
  });

  // =========================== VIEWS
  return (
    <div className="overflow-y-scroll">
      <ShowPageWrapper
        title={t('reservations.weekView.name')}
        resourceName={resourceNames.reservationCalendar}
        showBackButton={false}
      >
        <section className="min-h-screen">
          <span>{t('reservations.weekView.dateToday', { date: today })}</span>
          <div className="flex flex-col space-y-4">
            <DateRangeSelector
              onValue={() => null} // Only utilize the week
              value={{ from: selectedRange[0], to: selectedRange[selectedRange.length - 1] }}
              showWeekNumber
              onWeekNumberClick={(weekNum, dates) => {
                setSelectedRange(dates);
              }}
              footer={<span className="text-primary-500">{t('reservations.weekView.select')}</span>}
            />
            {loadingSummary ? (
              <Loading />
            ) : (
              <Table>
                <Table.Thead className="!w-96">
                  <Table.Tr>
                    {Object.keys(tableData).map((date) => {
                      const isToday = dayjs(date).isToday();

                      return (
                        <Table.Th key={date}>
                          <div className="flex flex-col space-y-2 items-center justify-center">
                            {isToday ? <Tag>{t('reservations.weekView.today')}</Tag> : null}
                            <span>{dayjs(date).format(DayFormat)}</span>
                            <span>{dayjs(date).format(ShortDateFormat)}</span>
                          </div>
                        </Table.Th>
                      );
                    })}
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr className="">
                    {Object.keys(tableData).map((date, colIndex) => {
                      const reservations = tableData[date] as Reservation[];
                      const notEmpty = !isEmpty(reservations);
                      const sortedReservations = orderBy(reservations, ['diningDate'], 'asc');
                      return (
                        <Table.Td
                          style={{ minWidth: notEmpty ? 400 : undefined, maxWidth: 450 }}
                          key={colIndex}
                          className="overflow-y-scroll"
                        >
                          {notEmpty ? (
                            <div className="flex flex-col !min-h-screen justify-start">
                              {sortedReservations.map((reservation: Reservation) => {
                                const {
                                  status,
                                  adultPax = 0,
                                  kidPax = 0,
                                  guestName,
                                  phoneNumber,
                                  diningDate,
                                } = reservation;
                                let displayStatus = status;
                                if (status === StatusType.Modified) {
                                  displayStatus = StatusType.Pending;
                                }

                                return (
                                  <Card
                                    className="my-2 p-3 cursor-pointer"
                                    onClick={() =>
                                      navigateTo.show(resourceNames.reservation, reservation.id)
                                    }
                                  >
                                    <div
                                      className="flex flex-col items-start justify-start space-y-2"
                                      key={reservation.id}
                                    >
                                      <div className="flex flex-row w-full items-center justify-between">
                                        <Tag color="green" className="!text-base">
                                          {dayjs(diningDate).format(TimeFormat)}
                                        </Tag>
                                        <Tag color="purple">
                                          <div className="flex flex-row items-center space-x-2">
                                            <FiUsers />
                                            <span>{adultPax + kidPax || 0}</span>
                                          </div>
                                        </Tag>
                                      </div>
                                      <div className="flex flex-row space-x-2">
                                        <span>{guestName}</span>
                                        <span>{phoneNumber}</span>
                                      </div>
                                      <Tag
                                        className={`rounded-lg`}
                                        color={reservationStatusColor[status] as any}
                                      >
                                        {t(
                                          `reservations.status.${displayStatus.toLowerCase()}`
                                        ).toLocaleUpperCase()}
                                      </Tag>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Table.Td>
                      );
                    })}
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            )}
          </div>
        </section>
      </ShowPageWrapper>
    </div>
  );
};

export const reservationCalendarResource = createResource({
  defaultValues: {},
  name: resourceNames.reservationCalendar,
  label: 'Calendar',
  // icon: <FiCalendar />,
  fields: defineFields(reservationFields),
  defaultPageSize: 25,
  allowCreate: false,
  allowSearch: false,
  allowEdit: false,
  allowDelete: false,
  filterConfig: {
    alwaysExpanded: true,
  },
  columns: () => [],
  formatBeforeSubmit: (data) => {
    return data;
  },
  list: {
    render: () => {
      return <CalendarView />;
    },
  },
  show: defineShowPage({
    component: (props) => {
      return <CalendarView />;
    },
  }),
});
