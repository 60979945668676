import { getSdk } from '~/api';
import { GQLClient } from '~/config/gql-client';

const client = GQLClient.getInstance();

export const customerEmailExist = async (email: string, customerId?: string): Promise<boolean> => {
  const existEmail = await getSdk(client).getCustomers({
    options: {
      filter: {
        id: customerId ? { notEq: customerId } : undefined,
        emailAddress: { eq: email },
      },
    },
  });

  return !!existEmail?.getCustomers?.totalItems;
};

export const customerPhoneExist = async (phone: string, administratorId?: string): Promise<boolean> => {
  const existEmail = await getSdk(client).getCustomers({
    options: {
      filter: {
        id: administratorId ? { notEq: administratorId } : undefined,
        phoneNumber: { eq: phone },
      },
    },
  });

  return !!existEmail?.getCustomers?.totalItems;
};