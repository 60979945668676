import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import {
  useApiUrl,
  useCustom,
  useCustomMutation,
  useNavigation,
  useTranslate,
} from '@refinedev/core';

import {
  DiscountPromotionForm,
  Loading,
  ShowPageWrapper,
  SomethingWentWrong,
  TriggerConfirmModal,
  VariantItem,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { Collection, CollectionList, ProductVariant, UpdateDiscountPromotionInput } from '~/api';

import isEmpty from 'lodash/isEmpty';
import { AddItemModal } from '~/resources/order/add-item';
import { Button, Card, IconButton, ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { ResourceField, createResourceListingPage } from '@scalingworks/refine-react-admin';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { BsGrid } from 'react-icons/bs';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import {
  getDiscountPrice,
  getProductImageSrc,
  parsePrice,
  renderStatusTag,
} from '~/resources/helpers';
import { Props } from './props';
import difference from 'lodash/difference';

export const DiscountPromotionShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  const { data, isLoading, refetch: refetchDiscountPromotion } = queryResult;
  const {
    id,
    name: discountPromotionName,
    enabled,
    amount,
    startDate,
    endDate,
    type,
    promotion,
    productVariants = [],
  } = data?.data || {};
  const initVariantIds = productVariants?.map((variant: ProductVariant) => variant.id) || [];

  // ======================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const form = useForm();
  const navigateTo = useNavigation();

  // ======================= API
  const { mutate: updateDiscountPromotion, isLoading: updating } = useCustomMutation();
  const { data: collection } = useCustom<CollectionList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      fields: [{ items: ['id', 'name'] }] as ResourceField<CollectionList>[],
      operation: 'collections',
    },
  });

  // ======================= STATES
  const [openProduct, setOpenProduct] = useState(false);
  // reusable for bulk / single; rmb to clear after processed
  const [deleteVariantIds, setDeleteVariantIds] = useState<string[]>([]);
  const refetchRef = useRef<() => void>(() => null);

  // ======================= VARIABLES
  const noProducts = isEmpty(productVariants);

  // ======================= EVENTS
  const onUpdateInfo = (data: any) => {
    const { amount, name, period } = data;
    const startDate = new Date(period?.from).toISOString();
    const endDate = period?.to ? new Date(period.to).toISOString() : undefined;

    updateDiscountPromotion(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          operation: 'updateDiscountPromotion',
          fields: ['id'],
          variables: {
            input: {
              value: {
                id,
                amount: +amount,
                name,
                startDate,
                endDate,
              } as UpdateDiscountPromotionInput,
              type: 'UpdateDiscountPromotionInput!',
            },
          },
        },
        errorNotification: {
          message: t('discountPromotions.edit.failed'),
          type: 'error',
        },
        successNotification: {
          message: t('discountPromotions.edit.success'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchDiscountPromotion();
          navigateTo.list(resourceNames.discountPromotion);
        },
      }
    );
  };

  const onAddProduct = (variantIds: string[]) => {
    updateDiscountPromotion(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        metaData: {
          fields: ['id'],
          operation: 'updateDiscountPromotion',
          variables: {
            input: {
              value: {
                id: id,
                productVariantIds: compact(uniq([...initVariantIds, ...variantIds])),
              } as UpdateDiscountPromotionInput,
              type: 'UpdateDiscountPromotionInput!',
            },
          },
        },
        errorNotification: {
          message: t('discountPromotions.edit.productFailed'),
          type: 'error',
        },
        successNotification: {
          message: t('discountPromotions.edit.productSuccess'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          setOpenProduct(false);
          refetchDiscountPromotion();
        },
      }
    );
  };

  const onRemoveProduct = () => {
    updateDiscountPromotion(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        metaData: {
          fields: ['id'],
          operation: 'updateDiscountPromotion',
          variables: {
            input: {
              value: {
                id: id,
                productVariantIds: difference(initVariantIds, deleteVariantIds),
              } as UpdateDiscountPromotionInput,
              type: 'UpdateDiscountPromotionInput!',
            },
          },
        },
        errorNotification: {
          message: t('discountPromotions.edit.removeFailed'),
          type: 'error',
        },
        successNotification: {
          message: t('discountPromotions.edit.removeSuccess'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          setDeleteVariantIds([]);
          refetchDiscountPromotion();
        },
      }
    );
  };

  // ======================= EFFECTS
  useEffect(() => {
    refetchRef.current();
  }, [updating]);

  // ======================= VIEWS
  const listing = createResourceListingPage<ProductVariant>({
    showTitle: false,
    customHelmet: 'Discount Promotions',
    resourceName: 'discountPromotionProductVariants',
    fields: [
      'id',
      'name',
      'price',
      'enabled',
      { facetValues: ['id', 'code', 'name'] },
      { assets: ['source'], featuredAsset: ['source'] },
      { product: [{ assets: ['source'] }, { featuredAsset: ['source'] }] },
      { collections: [{ items: ['id', 'name'] }] },
    ],
    allowCreate: false,
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('discountPromotions.placeholder.searchProduct', {
          fallback: 'Search by Product Name',
          ns: 'common',
        }),
    },
    filterConfig: { alwaysExpanded: true },
    defaultPageSize: 10,
    defaultSorter: [{ field: 'name', order: 'asc' }],
    extraMeta: {
      defaultFilter: {
        discountPromotionId: {
          type: 'String!',
          value: id,
        },
      },
    },
    bulkConfig: {
      allowBulkSelection: true,
      bulkActions: [{ key: 'remove', label: 'Remove', buttonProps: { variant: 'danger' } }],
      onBulkAction: (action, rows) => {
        if (action === 'remove') {
          setDeleteVariantIds(rows?.map((row) => row.id));
        }
      },
    },
    filterControls: {
      // @ts-ignore
      collectionId: {
        type: 'select',
        operator: 'eq',
        config: {
          label: 'Collection',
          options: (collection?.data?.items || []).map(({ id, name }: Collection) => ({
            label: name,
            value: id,
          })),
          placeholder: 'Select collection',
        },
      },
    },
    // NOTE: functions in columns no longer valid because
    // we didn't register a resource call `discountPromotionProductVariants`
    // this is a hack to quickly display the table with so much ease;
    // but we still can use to hook to navigate to product resource
    columns: ({ refetchData, t }) => [
      {
        id: 'product',
        header: t('discountPromotions.product.name', {
          fallback: 'Product',
          ns: 'common',
        }),
        cell: (data) => {
          const {
            id,
            name,
            assets: variantAssets,
            featuredAsset: variantFeatured,
            product,
          } = data.row.original;
          const index = data.cell.row.index;
          const { assets: prodAssets, featuredAsset: prodFeatured } = product || {};
          const img = getProductImageSrc({
            prodFeatured,
            prodAssets,
            variantAssets,
            variantFeatured,
          });

          // a bit hacky way to refetchData
          // only process on first render first index
          useEffect(() => {
            if (index === 0) {
              refetchRef.current = refetchData;
            }
          }, []);

          return (
            <div className="flex flex-row items-center space-x-2">
              {img && <ImageViewer src={img} className="!w-14 !h-14 rounded-lg object-cover" />}
              <span>{name}</span>
            </div>
          );
        },
      },
      {
        id: 'category',
        header: t('discountPromotions.product.category', {
          fallback: 'Category',
          ns: 'common',
        }),
        cell: (data) => {
          const { items = [] } = data.row.original.collections || {};

          return items.map((col: Collection) => (
            <span key={col.id} className="mx-0.5">
              <Tag color="purple">{col.name}</Tag>
            </span>
          ));
        },
      },
      {
        id: 'status',
        header: t('discountPromotions.product.status', {
          fallback: 'Status',
          ns: 'common',
        }),
        cell: (data) => {
          const { enabled } = data.row.original;

          return <div>{renderStatusTag(enabled)}</div>;
        },
      },
      {
        id: 'price',
        header: t('discountPromotions.product.price', {
          fallback: 'Normal Price (MYR)',
          ns: 'common',
        }),
        cell: (data) => {
          const { price } = data.row.original;

          return <div>{parsePrice(price)}</div>;
        },
      },
      {
        id: 'discounted',
        header: t('discountPromotions.product.discount', {
          fallback: 'Discounted Price (MYR)',
          ns: 'common',
        }),
        cell: (data) => {
          const { price } = data.row.original;
          const discounted = getDiscountPrice(price, type!, amount!);

          return <div>{parsePrice(discounted)}</div>;
        },
      },

      {
        id: 'actions',
        header: () => <div />,
        enableSorting: false,
        cell: (data) => {
          const { id } = data.row.original;

          return (
            <IconButton onClick={() => setDeleteVariantIds([id])}>
              <HiOutlineTrash size={25} className="text-error-300" />
            </IconButton>
          );
        },
      },
    ],
  })();
  if (isLoading) return <Loading />;
  if (!isLoading && !id) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.discountPromotion}
        loading={isLoading}
        title={discountPromotionName}
        extra={
          <Button
            loading={updating}
            onClick={form.handleSubmit(onUpdateInfo)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.update', {}, 'Update')}
          </Button>
        }
      >
        {/* Product Configuration */}
        {openProduct && (
          <AddItemModal
            open
            setOpen={setOpenProduct}
            onAddItem={(items) =>
              onAddProduct?.((items as VariantItem[])?.map((variant) => variant.variantId))
            }
            useVariantOnly
            discount={{ type: type!, value: amount! }}
          />
        )}
        <TriggerConfirmModal
          renderTrigger={() => <></>}
          visible={!!deleteVariantIds.length}
          onOpenChange={(open) => {
            if (!open) setDeleteVariantIds([]);
          }}
          onPressConfirm={onRemoveProduct}
          description={
            <span>
              {t('discountPromotions.warnings.removeProducts', {
                determiner: (deleteVariantIds.length > 1
                  ? t('common.these')
                  : t('common.this')
                ).toLowerCase(),
                quantifier: (deleteVariantIds.length > 1
                  ? t('product.name.products')
                  : t('product.name.product')
                ).toLowerCase(),
              })}
              <span className="font-semibold">{discountPromotionName}</span>{' '}
              {t('discountPromotions.name').toLowerCase()}?
            </span>
          }
        />
        <section className="flex flex-col space-y-8">
          {/* General Info Form */}
          <DiscountPromotionForm actionType="update" initialValues={data?.data} form={form} />

          <Card>
            <Card.Header className="flex flex-row justify-between items-center font-bold border-b">
              <div className="flex flex-row items-center space-x-2">
                <h3>{t('product.name.products', 'Products')}</h3>
                <Tag color="purple">{productVariants?.length || 0}</Tag>
              </div>
              {!noProducts && (
                <Button
                  onClick={() => {
                    setOpenProduct(true);
                  }}
                  size="sm"
                  className="!px-4 border-primary-500"
                >
                  <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                    <AiOutlinePlus className="!text-primary-500" />
                    <span>{t('actions.addMore', {}, 'Add More')}</span>
                  </div>
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              {noProducts ? (
                <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <BsGrid className="!text-primary-500" size={55} />
                    <span className="text-smoke-600">
                      {t('discountPromotions.edit.noProducts', {
                        fallback: `Add Products to ${
                          discountPromotionName || ''
                        } Discount Promotion`,
                        discountPromotion: `${discountPromotionName} ` || '',
                      })}
                    </span>
                  </div>
                  <Button className="border-primary-500" onClick={() => setOpenProduct(true)}>
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus className="!text-primary-500" />
                      <span>{t('actions.addProducts', 'Add Products')}</span>
                    </div>
                  </Button>
                </section>
              ) : (
                listing
              )}
            </Card.Body>
          </Card>
        </section>
      </ShowPageWrapper>
    </main>
  );
};
