import React from 'react';
import { useCreate, useGetLocale, useNavigation, useTranslate } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button } from '@scalingworks/react-admin-ui';

import { ModifierGroupForm, ShowPageWrapper } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { CreateModifierGroupInput, LanguageCode } from '~/api';
import kebabCase from 'lodash/kebabCase';

export const ModifierGroupCreatePage: React.FC = () => {
  // ========================= HOOKS
  const t = useTranslate();
  const form = useForm();
  const navigateTo = useNavigation();
  const lang = useGetLocale()() || LanguageCode.En;

  // ========================= API
  const { mutate: createModifierGroup, isLoading: creating } = useCreate();

  // ========================= EVENTS
  const onCreate = (data: any) => {
    const { name } = data;

    createModifierGroup(
      {
        resource: resourceNames.modifierGroup,
        values: {
          code: kebabCase(name),
          translations: [{ languageCode: lang, name }],
        } as CreateModifierGroupInput,
        errorNotification: {
          message: t('modifier.create.failed', {}, 'Failed to create modifier'),
          type: 'error',
        },
        successNotification: {
          message: t('modifier.create.success', {}, 'Modifier created'),
          type: 'success',
        },
      },
      {
        onSuccess: (data) => {
          const id = data?.data?.id;
          if (id) navigateTo.show(resourceNames.modifierGroup, id);
          else navigateTo.list(resourceNames.modifierGroup);
        },
      }
    );
  };

  // ========================= VIEWS
  return (
    <div className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.modifierGroup}
        title={t('modifier.create.name', {}, 'Create Modifier Group')}
        extra={
          <Button
            loading={creating}
            onClick={form.handleSubmit(onCreate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.create', {}, 'Create')}
          </Button>
        }
      >
        <ModifierGroupForm form={form} />
      </ShowPageWrapper>
    </div>
  );
};
