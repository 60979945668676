import React from 'react';
import SearchAndSelect, { SearchAndSelectProp } from '../base';
import { useTranslate } from '@refinedev/core';

type Props = Omit<SearchAndSelectProp, 'queryKey' | 'getValueAndLabel'> & {
  useUserId?: boolean;
  onSelect?: (id: string) => void;
};

export const CustomerSelection: React.FC<Props> = (props) => {
  const { onSelect, useUserId = false, ...restProps } = props;
  const t = useTranslate();
  return (
    <SearchAndSelect
      {...restProps}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getCustomers"
      getValueAndLabel={(data) => {
        const { id, firstName, lastName, phoneNumber, user = {} } = data;
        // Destructure like this since user might be null;
        const { id: userId } = user || {};

        let label = `${firstName} ${lastName}`.trim();

        if (phoneNumber) {
          label = `${label} (${phoneNumber})`;
        }

        return {
          value: useUserId ? `${userId}` : `${id}`,
          label: label,
        };
      }}
      variables={{ containUserRelation: useUserId }}
      placeholder={t('reservations.placeholder.reservedBy')}
    />
  );
};

export type CustomerSelectionProps = Props;
