import type { DateRangeFieldProps } from '@scalingworks/react-admin-ui';
import { DateRangeField } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { getControlRules, useFormField } from '../form.lib';
import type { MakeFormFieldProps } from '../form.types';

export type FormDateRangeFieldProps = MakeFormFieldProps<DateRangeFieldProps>;

export const FormDateRangeField = ({
	defaultValue,
	label,
	hideLabel,
	...props
}: FormDateRangeFieldProps) => {
	const controller = useFormField({
		name: props.name,
		rules: getControlRules(props),
		defaultValue,
	});
	const error = controller.fieldState.error;

	return (
		<DateRangeField
			status={error ? 'danger' : undefined}
			{...props}
			{...(hideLabel
				? {
						'aria-label': label,
				  }
				: {
						label,
				  })}
			value={controller.field.value}
			name={controller.field.name}
			onValue={controller.field.onChange}
			ref={controller.field.ref}
		/>
	);
};
