import React, { useEffect, useState } from 'react';
import { useLogin, useNotification, useTranslate } from '@refinedev/core';
import { IoArrowBack } from 'react-icons/io5';
import { useForm } from '@refinedev/react-hook-form';
import { Button, IconButton, PasswordField, TextField } from '@scalingworks/react-admin-ui';
import { Helmet } from 'react-helmet';
import { parsePhoneNumber } from 'libphonenumber-js';
import { GQLClient } from '../gql-client';
import OtpInput from 'react-otp-input';
import { MobileVerificationType } from '~/api';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { requestMobileSigninDoc, setTokenDoc } from './login.graphql';

export interface IRequestOtp {
  phoneNumber: string;
}

export interface IVerifyOtp {
  phoneNumber: string;
  type: MobileVerificationType;
  otp: string;
}

export interface CreateLoginPageOptions {
  header?: React.ReactNode;
  defaultUsername?: string;
  defaultPassword?: string;
}

const OTP_NUM = 6;
const Default_Country = '+60';
const Resend_Seconds = 30;
export const LoginPage = (options: { logo: string }) => {
  const client = GQLClient.getInstance();

  // =================== HOOKS
  const translate = useTranslate();
  const { register, handleSubmit, setValue, getValues, watch } = useForm<IRequestOtp>({
    defaultValues: {
      phoneNumber: Default_Country,
    },
  });
  const loginMutation = useLogin<IVerifyOtp>({
    mutationOptions: {
      onSuccess: async () => {
        if (Capacitor.getPlatform() !== 'web') {
          const permissionResult = await PushNotifications.requestPermissions();
          if (permissionResult?.receive === 'granted') {
            await PushNotifications.register();
            const fcmToken = await FCM.getToken();
            await client.request(setTokenDoc, {
              token: fcmToken?.token,
            });
          }
        }
        location.href = '/';
      },
    },
  });
  const notification = useNotification();

  // =================== STATES
  const [show, setShow] = useState<'login' | 'otp'>('login');
  const [otp, setOtp] = useState('');
  const [seconds, setSeconds] = useState(0);

  // =================== EVENTS
  const onSubmitPhone = async (data: IRequestOtp) => {
    const { phoneNumber } = data;
    const parsed = parsePhoneNumber(phoneNumber);

    if (!parsed?.isValid()) {
      notification?.open?.({
        message: translate('pages.login.invalidPhoneFormat', 'Invalid Phone Format'),
        type: 'error',
      });
    }
    setValue('phoneNumber', parsed.number);

    const result = await client.request(requestMobileSigninDoc, {
      input: { phoneNumber: parsed.number },
    });

    if (result?.requestMobileSignin) {
      setShow('otp');
    } else {
      notification?.open?.({
        message: translate('pages.login.invalidPhoneNumber', 'Invalid Phone Number'),
        type: 'error',
      });
    }
  };

  const onVerifyOtp = async () => {
    await loginMutation.mutate({
      phoneNumber: getValues('phoneNumber'),
      type: MobileVerificationType.VerifySignIn,
      otp,
    });
  };

  const onResendOtp = async () => {
    setSeconds(Resend_Seconds);
    const result = await client.request(requestMobileSigninDoc, {
      input: { phoneNumber: getValues('phoneNumber') },
    });

    if (!result?.requestMobileSignin) {
      notification?.open?.({
        message: translate('pages.login.invalidPhoneNumber', 'Invalid Phone Number'),
        type: 'error',
      });
    }
  };

  // =================== EFFECTS
  useEffect(() => {
    if (show === 'login') {
      setOtp('');
    }
    if (show === 'otp') {
      setSeconds(Resend_Seconds);
    }
  }, [show]);

  useEffect(() => {
    let intervalId: any;

    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  const [otpInputSize, setOtpInputSize] = useState(75);

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setOtpInputSize(100);
    }
    if (window.innerWidth >= 1920) {
      setOtpInputSize(125);
    } else setOtpInputSize(75);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // =================== VIEWS
  const showLogin = () => {
    return (
      <section>
        <div className="flex flex-col items-start gap-3 pb-3">
          <img src={options.logo} className="w-1/3" />
          <h1 className="text-2xl text-center font-bold mb-4">
            {translate('pages.login.welcome.title', 'Login to NP portal')}
            {/* {translate('pages.login.welcome.title', 'Welcome to Backoffice')} */}
          </h1>
        </div>
        <form
          className="flex flex-col gap-3"
          onSubmit={handleSubmit((data) => onSubmitPhone(data as any))}
        >
          <TextField
            label={translate('pages.login.fields.phoneNumber', 'Phone Number')}
            autoComplete="phone-number"
            {...register('phoneNumber', { required: true })}
            // layout="horizontal"
          />
          {/* <PasswordField
            label={translate('pages.login.fields.password', 'Password')}
            disabled={loginMutation.isLoading}
            layout="vertical"
            autoComplete="current-password"
            {...register('password', { required: true })}
          /> */}
          <Button
            type="submit"
            variant="solid"
            className="w-full"
            disabled={(watch('phoneNumber') as string).length < 12}
          >
            {translate('pages.login.buttons.submit', 'Login')}
          </Button>
        </form>
      </section>
    );
  };

  const showOtp = () => {
    return (
      <section>
        <div className="flex flex-col items-start gap-3 pb-3">
          <img src={options.logo} className="w-1/3" />
          <IconButton className="-mx-2" onClick={() => setShow('login')}>
            <IoArrowBack size={35} />
          </IconButton>
          <h1 className="text-2xl text-start font-bold">
            {translate(
              'pages.login.enterOtp',
              `Enter the ${OTP_NUM} digits passcode sent to your phone number (${watch(
                'phoneNumber'
              )})`
            )}
          </h1>
        </div>
        <div className="">
          <div className="flex items-center justify-center mb-8">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={OTP_NUM}
              shouldAutoFocus
              // renderSeparator={() => <div className="mx-1 md:mx-2 xl:mx-4" />}
              renderInput={(props) => {
                // todo: fix the focus border color
                return <input {...props} className="border focus:border-primary-500" />;
              }}
              containerStyle={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              inputStyle={{ width: otpInputSize, height: otpInputSize, fontSize: '1.5rem' }}
            />
          </div>

          <button className="p-0 m-0 mb-8" disabled={seconds > 0} onClick={() => onResendOtp()}>
            <span className="text-black">
              {translate('pages.login.buttons.resend', 'Resend')} {seconds ? `(${seconds})` : ''}
            </span>
          </button>

          <Button
            onClick={onVerifyOtp}
            variant="solid"
            className="w-full"
            disabled={otp?.length < OTP_NUM}
          >
            {translate('pages.login.buttons.continue', 'Continue')}
          </Button>
        </div>
      </section>
    );
  };

  return (
    <div className="flex items-center justify-center px-3 py-12 bg-plain-100 h-screen w-screen">
      <Helmet>
        <title>{translate('pages.login.title', 'Sign in to your account')}</title>
      </Helmet>

      <main className="w-full xl:w-2/3 2xl:w-1/2 mx-auto p-12 bg-white shadow rounded-lg">
        {show === 'login' ? showLogin() : showOtp()}
      </main>
    </div>
  );
};
