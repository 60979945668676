import { BaseRecord, HttpError, useTranslate } from '@refinedev/core';
import { UseFormReturnType } from '@refinedev/react-hook-form';
import { FieldValues } from 'react-hook-form';
import { type Outlet, OutletStatus, type Administrator } from '~/api';
import { FormItemProps } from '~/components/FormBuilder/props';
import { ProductVariantFormInput } from '~/components/VariantForm/props';
import { formatFullName } from '~/resources/helpers';

export const formBuilder = ({
  outlet,
  formHook,
  staff,
}: {
  outlet?: Outlet;
  formHook: UseFormReturnType<BaseRecord, HttpError, FieldValues, {}>;
  staff?: Administrator[];
  setProductVariants?: React.Dispatch<React.SetStateAction<ProductVariantFormInput[] | undefined>>;
}): FormItemProps[] => {
  const { name, managerId, contactNumber, status, address } = outlet || {};
  const t = useTranslate();

  return [
    {
      title: t('outlet.form.name'),
      placeholder: t('outlet.placeholder.name'),
      defaultValue: name,
      name: 'name',
      required: true,
    },
    {
      title: t('outlet.form.addressLineOne'),
      placeholder: t('outlet.placeholder.addressLineOne'),
      defaultValue: address?.addressLineOne,
      name: 'addressLineOne',
      required: true,
    },
    {
      title: t('outlet.form.addressLineTwo'),
      placeholder: t('outlet.placeholder.addressLineTwo'),
      defaultValue: address?.addressLineTwo,
      name: 'addressLineTwo',
    },
    {
      title: t('outlet.form.city'),
      placeholder: t('outlet.placeholder.city'),
      defaultValue: address?.city,
      name: 'city',
      required: true,
    },
    {
      title: t('outlet.form.postalCode'),
      placeholder: t('outlet.placeholder.postalCode'),
      defaultValue: address?.postalCode,
      name: 'postalCode',
      required: true,
    },
    {
      title: t('outlet.form.country'),
      placeholder: t('outlet.placeholder.country'),
      defaultValue: address?.country,
      name: 'country',
      required: true,
    },
    {
      title: t('outlet.form.province'),
      placeholder: t('outlet.placeholder.province'),
      defaultValue: address?.province,
      name: 'province',
      required: true,
    },
    {
      title: t('outlet.form.personInCharge'),
      placeholder: t('outlet.placeholder.personInCharge'),
      type: 'select',
      required: true,
      defaultValue: managerId,
      options: staff?.map((e) => ({
        label: formatFullName(e?.firstName, e?.lastName),
        value: e?.id,
      })),
    },
    {
      title: t('outlet.form.contact'),
      defaultValue: contactNumber,
      placeholder: t('outlet.placeholder.contact'),
      required: true,
      type: 'phone',
    },
    {
      title: t('outlet.form.status'),
      required: true,
      type: 'radio',
      defaultValue: status,
      options: [OutletStatus.Active, OutletStatus.Inactive].map((status) => ({
        label: t(`outlet.status.${status.toLowerCase()}`),
        value: status,
      })),
    },
  ];
};
