import type { NumberFieldProps } from '@scalingworks/react-admin-ui';
import { NumberField } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { getControlRules, useFormField } from '../form.lib';
import type { MakeFormFieldProps } from '../form.types';

export type FormNumberFieldProps = MakeFormFieldProps<NumberFieldProps>;

export const FormNumberField = ({
	defaultValue,
	label,
	hideLabel,
	...props
}: FormNumberFieldProps) => {
	const controller = useFormField({
		name: props.name,
		rules: getControlRules(props),
		defaultValue,
	});
	const error = controller.fieldState.error;

	return (
		<NumberField
			status={error ? 'danger' : undefined}
			{...props}
			{...controller.field}
			{...(hideLabel
				? {
						'aria-label': label,
				  }
				: {
						label,
				  })}
			value={controller.field.value || ''}
		/>
	);
};
