import { createResourceListingPage } from '@scalingworks/refine-react-admin';
import React from 'react';
import {
  PointSignType,
  PointTransactionHistory,
  PointTransactionSourceType as SourceType,
} from '~/api';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';
import { Loading } from '~/components';
import { adjustmentSource, dateFormatter } from '~/resources/helpers';
import { FullDateTimeFormat } from '~/config/constant';

export const CustomerPointTransactions: React.FC<Props> = (props) => {
  const { loading, userId } = props;

  // ==================== HOOKS
  /**
   * NOTE: This table utilize the point adjustment resource's
   * data provider overrided config
   */
  const listing = createResourceListingPage<PointTransactionHistory>({
    showTitle: false,
    customHelmet: 'Customer',
    resourceName: resourceNames.pointTransaction,
    fields: ['id', 'createdAt', 'createdBy', 'point', 'description', 'remarks', 'source'],
    allowCreate: false,
    allowSearch: false,
    filterConfig: { alwaysExpanded: true },
    defaultPageSize: 10,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    extraMeta: {
      userId,
    },
    filterControls: {
      source: {
        // @ts-ignore
        type: 'select',
        operator: 'eq',
        config: {
          label: 'Filter by',
          placeholder: 'Select action',
          // @ts-ignore
          options: Object.keys(SourceType).map((key) => ({
            label: key,
            value: SourceType[key as keyof typeof SourceType],
          })),
        },
      },
      createdAt: {
        type: 'daterange',
        config: {
          label: 'Created at',
        },
      },
    },
    columns: () => [
      {
        id: 'id',
        header: 'Actions',
        cell: (data) => {
          const { source } = data.row.original;

          return <span>{adjustmentSource(source)}</span>;
        },
      },
      {
        id: 'remark',
        header: 'Remarks',
        cell: (data) => {
          const { remarks = '-' } = data.row.original;

          return <span>{remarks}</span>;
        },
      },
      {
        id: 'createdAt',
        header: 'Date and time',
        cell: (data) => {
          const { createdAt } = data.row.original;

          return <span>{dateFormatter(createdAt, FullDateTimeFormat)}</span>;
        },
      },
      {
        id: 'points',
        header: 'Points',
        cell: (data) => {
          const { point, pointSign } = data.row.original;
          const isPositive = pointSign === PointSignType?.Positive;
          return (
            <div className={`${isPositive ? 'text-success-300' : 'text-error-300'}`}>
              <span>{`${isPositive ? '+' : '-'} ${point.toLocaleString()} pts`}</span>
            </div>
          );
        },
      },
    ],
  });
  // ==================== VIEWS
  if (loading) return <Loading />;
  if (!userId) return null;
  return listing();
};
