import React, { useEffect } from 'react';
import { Form } from '@scalingworks/refine-react-admin';
import { Props } from './props';
import { AiOutlineNotification } from 'react-icons/ai';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { FormSelectField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-select-field';
import {
  BlastableChannel,
  BlastableReceiverType as ReceiverType,
  BlastContentType as Type,
} from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { useTranslate } from '@refinedev/core';
import { Controller } from 'react-hook-form';
import { Card, MultiSelectField } from '@scalingworks/react-admin-ui';
import { REGEX_HTTP_URL } from '~/config/constant';
import head from 'lodash/head';

export const NotificationChannel: (keyof typeof BlastableChannel)[] = ['Email', 'Sms', 'Push'];
export const NotificationContentType: (keyof typeof Type)[] = [
  'Announcement',
  'Promotion',
  'Update',
];

export const NotificationForm: React.FC<Props> = (props) => {
  const { form, actionType = 'create', initialValues } = props;
  const { name, channels, type, link, subject, message, receiverType, queues } =
    initialValues || {};

  // =================== HOOKS
  const t = useTranslate();

  // =================== VARIABLES
  const createMode = actionType === 'create';
  const editMode = actionType === 'update';

  // =================== EFFECTS

  // =================== VIEWS
  return (
    <section className="flex flex-col space-y-4">
      <Card>
        <Card.Header className="flex flex-row items-center justify-between font-bold border-b">
          <h3>{t('common.general', 'General')}</h3>
        </Card.Header>
        <Card.Body>
          <Form form={form}>
            <section className="flex flex-col space-y-4 xl:w-2/3">
              <FormTextField
                label={t('notificationBlasts.form.name')}
                placeholder={t('notificationBlasts.placeholder.name')}
                name="name"
                defaultValue={name}
                required
              />
              <FormTextField
                label={t('notificationBlasts.form.title')}
                placeholder={t('notificationBlasts.placeholder.title')}
                name="subject"
                defaultValue={subject}
                required
              />
              <FormTextField
                label={t('notificationBlasts.form.content')}
                placeholder={t('notificationBlasts.placeholder.content')}
                name="message"
                defaultValue={message}
                required
                type="textarea"
                className="h-24"
              />

              <FormSelectField
                label={t('notificationBlasts.form.channel', {}, 'Channel')}
                placeholder={t('notification.placeholder.channel', {}, 'Select target channel')}
                name="channels"
                required
                defaultValue={head(channels)}
                options={NotificationChannel.map((key) => ({
                  label: t(`notificationBlasts.channel.${key.toLowerCase()}`),
                  value: BlastableChannel[key as keyof typeof BlastableChannel],
                }))}
              />

              <FormSelectField
                label={t('notificationBlasts.form.type', {}, 'Content Type')}
                placeholder={t('notificationBlasts.placeholder.type')}
                name="type"
                options={NotificationContentType.map((key) => ({
                  label: t(`notificationBlasts.type.${key.toLowerCase()}`),
                  value: Type[key as keyof typeof Type],
                }))}
                defaultValue={type}
                required
                disabled={editMode}
              />

              <FormTextField
                label={t('notificationBlasts.form.link', {}, 'Link')}
                placeholder={t('notificationBlasts.placeholder.link', 'https://')}
                name="link"
                defaultValue={link}
                pattern={REGEX_HTTP_URL}
              />

              {editMode && (
                <FormSelectField
                  label={t('notificationBlasts.form.recipient')}
                  placeholder={t('notificationBlasts.placeholder.receiverType')}
                  name="receiverType"
                  options={Object.keys(ReceiverType).map((key) => ({
                    label: toCamelCaseWord(key),
                    value: ReceiverType[key as keyof typeof ReceiverType],
                  }))}
                  defaultValue={receiverType}
                  required
                />
              )}
            </section>
          </Form>
        </Card.Body>
      </Card>

      {createMode && (
        <section className="w-full text-center">
          <div className="p-8 flex flex-col items-center justify-center space-y-4">
            <AiOutlineNotification className="!text-primary-500" size={55} />
            <p className="text-smoke-600 w-96">{t('notificationBlasts.create.hints')}</p>
          </div>
        </section>
      )}
    </section>
  );
};
