import React, { useState } from 'react';
import { Button, Dialog } from '@scalingworks/react-admin-ui';
import { useTranslate } from '@refinedev/core';
import { Props } from './props';
import { RadioGroup } from '../RadioGroup';

export type GeneralStatus = 'ACTIVE' | 'INACTIVE';

export const UpdateGeneralStatus: React.FC<Props> = (props) => {
  const { open, setOpen, initialStatus, onConfirm, loading } = props;

  // ========================= HOOKS
  const t = useTranslate();

  // ========================= STATES
  const [selected, setSelected] = useState<GeneralStatus>(initialStatus);

  // ======================== VIEWS
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 z-20">
          <Dialog.Title>Update Status</Dialog.Title>
          <div className="p-4">
            <RadioGroup
              isField
              label="Status"
              value={selected}
              options={[
                { label: 'Active', value: 'ACTIVE' },
                { label: 'Inactive', value: 'INACTIVE' },
              ]}
              onValueChange={(status: GeneralStatus) => setSelected(status)}
            />
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              disabled={initialStatus === selected}
              loading={loading}
              variant="solid"
              onClick={() => onConfirm?.(selected)}
            >
              {t('common.confirm', 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
