import { Card } from '@scalingworks/react-admin-ui';
import React from 'react';
import { FiSettings } from 'react-icons/fi';

export const MaintenancePage: React.FC = () => {
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-start p-8">
      <Card className="w-full min-h-full bg-gray-600 flex flex-col items-center justify-center p-12 space-y-2">
        <FiSettings size={60} className="!text-smoke-500" />
        <h5>Under maintenance</h5>
        <p className="text-smoke-500">
          We are currently performing maintenance. Please check back soon
        </p>
      </Card>
    </div>
  );
};
