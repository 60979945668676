import type { TextFieldProps } from '@scalingworks/react-admin-ui';
import { TextField } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { getControlRules, useFormField } from '../form.lib';
import type { MakeFormFieldProps } from '../form.types';

export type FormTextFieldProps = MakeFormFieldProps<TextFieldProps>;

export const FormTextField = ({ defaultValue, label, hideLabel, ...props }: FormTextFieldProps) => {
	const controller = useFormField({
		name: props.name,
		rules: getControlRules(props),
		defaultValue,
	});
	const error = controller.fieldState.error;

	return (
		<TextField
			status={error ? 'danger' : undefined}
			{...props}
			{...controller.field}
			{...(hideLabel
				? {
						'aria-label': label,
				  }
				: {
						label,
				  })}
			value={controller.field.value || ''}
		/>
	);
};
