import { useEffect, useRef, useState } from 'react';
import { Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { FiUsers } from 'react-icons/fi';
import {
  Administrator,
  CreateAdministratorInput,
  UpdateAdministratorInput,
  UpdateAdministratorAccountStatusInput,
  getSdk,
} from '~/api';
import {
  ActionButton,
  ActionButtonRefProps,
  ActionModalWithTrigger,
  FormBuilder,
  Loading,
  RadioGroup,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import {
  useApiUrl,
  useCreate,
  useCustomMutation,
  useNavigation,
  useNotification,
  useOne,
  useTranslate,
  useUpdate,
} from '@refinedev/core';
import { useParams } from 'react-router-dom';
import head from 'lodash/head';
import { FullDateFormat } from '~/config/constant';
import { GQLClient } from '~/config/gql-client';
import { staffFormBuilder } from '~/config/Staff/form';
import { parsePhoneNumber } from 'libphonenumber-js';
import { resourceNames } from '../resource-names';
import { dateFormatter, formatFullName } from '../helpers';
import { adminEmailExist, adminPhoneExist } from '../helpers/admin';
import { StaffShowPage } from './show';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Administrator>({
    resourceName: resourceNames.staff,
  });

const fields: ResourceField<Administrator>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'firstName',
  'lastName',
  'emailAddress',
  { user: ['verified', { roles: ['id', 'code', 'description'] }] },
  { customFields: ['phoneNumber', 'verificationNumber', 'dateOfBirth'] },
];

export const statusColor: Record<string, string> = {
  true: 'text-success-500 bg-success-100 border-success-500',
  false: 'text-gray-500 bg-gray-100 border-gray-500',
};

const formatData = (data: any) => {
  const { joinedDate, ic, role, birthDate, phoneNumber, status, ...rest } = data;

  return {
    ...rest,
    roleIds: [role],
    customFields: {
      dateOfBirth: birthDate ? new Date(birthDate) : undefined,
      phoneNumber,
      verificationNumber: ic,
    },
  };
};

export const staffResource = createResource({
  name: resourceNames.staff,
  label: 'Staff',
  icon: <FiUsers />,
  defaultValues: {},
  fields: defineFields(fields),
  defaultPageSize: 25,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowCreate: true,
  allowSearch: true,
  createConfig: {
    title: ({ t }) =>
      t('staff.create.name', {
        fallback: 'Add Staff',
        ns: 'common',
      }),
  },
  searchConfig: {
    placeholder: ({ t }) =>
      t('staff.placeholder.search', {
        fallback: 'Search by Name, IC, Role or Contact',
        ns: 'common',
      }),
  },
  filterConfig: {
    alwaysExpanded: true,
  },
  allowDelete: false,
  columns: ({ LinkToDetails, navigateToEdit, refetchData, invokeDelete, t }) => [
    {
      id: 'name',
      header: t('staff.columns.name', {
        fallback: 'Name',
        ns: 'common',
      }),
      cell: (data) => {
        const { id, firstName, lastName } = data.row.original;
        const display = formatFullName(firstName, lastName);

        return (
          <LinkToDetails resourceId={id}>
            <span>{display}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'role',
      header: t('staff.columns.role', {
        fallback: 'Role',
        ns: 'common',
      }),
      cell: (data) => {
        const { id, user } = data.row.original;
        const { roles } = user || {};

        if (!roles || !roles.length) return null;
        return (
          <LinkToDetails resourceId={id}>
            {/* <Tag color="purple">{head(roles)?.description}</Tag> */}
            <span>{head(roles)?.description}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'contact',
      header: t('staff.columns.contact', {
        fallback: 'Contact',
        ns: 'common',
      }),
      cell: (data) => {
        const { id, customFields } = data.row.original;
        const { phoneNumber } = customFields || {};

        return (
          <LinkToDetails resourceId={id}>
            <span>{phoneNumber || '-'}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'status',
      header: t('staff.columns.status', {
        fallback: 'Status',
        ns: 'common',
      }),
      cell: (data) => {
        const { id, user } = data?.row?.original;
        const { verified } = user || {};

        return (
          <LinkToDetails resourceId={id}>
            <Tag className={`${statusColor[`${verified}`]}`}>
              {verified ? 'ACTIVE' : 'INACTIVE'}
            </Tag>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'createdAt',
      header: t('staff.columns.createdAt', {
        fallback: 'Join Date',
        ns: 'common',
      }),
      cell: (data) => {
        const { id, createdAt } = data?.row?.original;

        return (
          <LinkToDetails resourceId={id}>
            <span>{dateFormatter(createdAt, FullDateFormat)}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'actions',
      header: () => <div />,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const { firstName, lastName, user } = data.row.original;
        const { verified } = user || {};
        const actionButtonRef = useRef<ActionButtonRefProps>(null);
        const fullName = formatFullName(firstName, lastName) || '-';
        const id = data.cell.getValue<string>();
        const t = useTranslate();
        const navigateTo = useNavigation();
        const [statusVal, setStatusVal] = useState<string>('');
        const [showUpdateModal, setShowUpdateModal] = useState(false);
        const gqlClient = GQLClient?.getInstance();
        const { mutate } = useUpdate();

        useEffect(() => {
          setStatusVal(verified ? 'ACTIVE' : 'INACTIVE');
        }, [data?.row?.original]);

        const updateStatus = async () => {
          await getSdk(gqlClient)?.UpdateAdministratorAccountStatus({
            input: {
              id,
              verified: statusVal === 'ACTIVE',
            },
          });
          // Called update mutate here just to force refetch
          await mutate({ id, resource: resourceNames?.staff, values: {} });
        };

        return (
          <>
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateTo.edit(resourceNames.staff, id),
                },
                {
                  label: t('actions.updateStatus'),
                  name: 'updateStatus',
                  onAction: () => {
                    return;
                  },
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <ActionModalWithTrigger
                          triggerText="Update status"
                          visible={showUpdateModal}
                          title={t('actions.updateStatus')}
                          renderBody={() => {
                            return (
                              <div>
                                <RadioGroup
                                  isField
                                  label="Status"
                                  options={['ACTIVE', 'INACTIVE'].map((status) => ({
                                    label: t(`staff.status.${[status.toLocaleLowerCase()]}`),
                                    value: status,
                                  }))}
                                  value={statusVal}
                                  onValueChange={setStatusVal}
                                />
                              </div>
                            );
                          }}
                          onOpenChange={(val) => {
                            const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                            actionButtonSetOpen?.(val);
                            setShowUpdateModal(val);
                          }}
                          onPressCancel={() => setStatusVal(status || '')}
                          onPressConfirm={updateStatus}
                        />
                      </button>
                    );
                  },
                },
                {
                  label: t('actions.delete'),
                  name: 'delete',
                  onAction: () => invokeDelete({ id }),
                  render: (onAction) => {
                    const [show, setShow] = useState(false);
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          visible={show}
                          onOpenChange={setShow}
                          onPressConfirm={onAction}
                          description={
                            <span>
                              {t('warnings.deleteConfirmation', {
                                resource: t('staff.name').toLocaleLowerCase(),
                              })}{' '}
                              <span className="font-semibold text-error-300">{`${fullName}`}</span>?{' '}
                              {t('warnings.cannotUndo')}
                            </span>
                          }
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          </>
        );
      },
    },
  ],
  create: {
    render: (helpers) => {
      const navigateTo = useNavigation();
      const t = useTranslate();
      const notif = useNotification();
      const { mutate: createStaff } = useCreate();

      return (
        <FormBuilder
          resourceName={resourceNames.staff}
          title={t('staff.create.name', 'Add Staff')}
          onSubmit={async (data) => {
            const { phoneNumber } = data;
            const parsed = parsePhoneNumber(phoneNumber);
            if (!parsed.isValid()) {
              notif?.open?.({
                message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
                type: 'error',
              });
              return;
            }
            const cleaned = formatData({ ...data, phoneNumber: parsed.number });

            const emailExisted = await adminEmailExist(cleaned.emailAddress);
            if (emailExisted) {
              notif?.open?.({
                message: t('messages.emailExisted', 'Email address already existed'),
                type: 'error',
              });
              return;
            }

            const phoneExisted = await adminPhoneExist(parsed.number);
            if (phoneExisted) {
              notif?.open?.({
                message: t('messages.phoneExisted', 'Phone number already existed'),
                type: 'error',
              });
              return;
            }

            createStaff(
              {
                resource: resourceNames.staff,
                values: {
                  ...cleaned,
                  password: import.meta.env.VITE_STAFF_PW,
                } as CreateAdministratorInput,
              },
              {
                onSuccess: () => navigateTo.list(resourceNames.staff),
              }
            );
          }}
          items={(form) => staffFormBuilder(form)}
        />
      );
    },
  },
  edit: {
    render(helpers) {
      const { id } = useParams();
      const navigation = useNavigation();
      const notif = useNotification();
      const apiUrl = useApiUrl();
      const t = useTranslate();

      if (!id) return <SomethingWentWrong />;

      const { data, isLoading } = useOne({
        resource: resourceNames.staff,
        id,
        metaData: {
          fields,
        },
        queryOptions: {
          cacheTime: 0,
        },
      });
      const mainData = data?.data as Administrator;
      const originalStatus = mainData?.user?.verified ? 'ACTIVE' : 'INACTIVE';

      const { mutate: updateInfo } = useUpdate();
      const { mutate: updateStatus } = useCustomMutation({
        mutationOptions: {
          onSettled: (data) => {
            !!data?.data && navigation.list(resourceNames.staff, 'replace');
          },
        },
      });

      if (isLoading) return <Loading />;
      return (
        <FormBuilder
          resourceName={resourceNames.staff}
          isUpdate
          title={t('staff.edit.name')}
          onSubmit={async (data) => {
            const { phoneNumber, status } = data;
            const parsed = parsePhoneNumber(phoneNumber);
            if (!parsed.isValid()) {
              notif?.open?.({
                message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
                type: 'error',
              });
              return;
            }
            const cleaned = formatData({ ...data, phoneNumber: parsed.number });

            const emailExisted = await adminEmailExist(cleaned.emailAddress, id);
            if (emailExisted) {
              notif?.open?.({
                message: t('messages.emailExisted', 'Email address already existed'),
                type: 'error',
              });
              return;
            }

            const phoneExisted = await adminPhoneExist(parsed.number, id);
            if (phoneExisted) {
              notif?.open?.({
                message: t('messages.phoneExisted', 'Phone number already existed'),
                type: 'error',
              });
              return;
            }

            await updateInfo(
              {
                id,
                resource: resourceNames.staff,
                values: {
                  id,
                  ...cleaned,
                } as UpdateAdministratorInput,
                errorNotification: {
                  message: t('staff.edit.failed', {}, 'Failed to update staff'),
                  type: 'error',
                },
                successNotification: {
                  message: t('staff.edit.success', {}, 'Staff updated'),
                  type: 'success',
                },
              },
              {
                onSettled: () => {
                  if (originalStatus !== status) {
                    updateStatus({
                      method: 'post',
                      url: apiUrl,
                      values: {},
                      metaData: {
                        fields: ['id'],
                        operation: 'updateAdministratorAccountStatus',
                        variables: {
                          input: {
                            value: {
                              id,
                              verified: status === 'ACTIVE',
                            } as UpdateAdministratorAccountStatusInput,
                            type: 'UpdateAdministratorAccountStatusInput!',
                          },
                        },
                      },
                      errorNotification: {
                        message: t(
                          'staff.edit.updateStatusFailed',
                          {},
                          'Failed to update staff status'
                        ),
                        type: 'error',
                      },
                      successNotification: {
                        message: t('staff.edit.updateStatusSuccess', {}, 'Staff status updated'),
                        type: 'success',
                      },
                    });
                  } else navigation.list(resourceNames.staff, 'replace');
                },
              }
            );
          }}
          items={(form) => staffFormBuilder(form, mainData)}
        />
      );
    },
  },
  show: defineShowPage({
    component: (props) => {
      return <StaffShowPage queryResult={props.queryResult} />;
    },
  }),
});
