import React, { useEffect, useState } from 'react';
import { ShowPageWrapper, TableBuilder } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import {
  Button,
  Card,
  FieldGroup,
  IconButton,
  ImageViewer,
  Label,
} from '@scalingworks/react-admin-ui';
import { useForm } from '@refinedev/react-hook-form';
import { Form } from '@scalingworks/refine-react-admin';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { renderUploadInput } from '~/components/FormBuilder/UploadInput';
import { renderRadioButtons } from '~/components/FormBuilder/Radio';
import { Product, ProductList } from '~/api';
import { AiOutlinePlus } from 'react-icons/ai';
import { ImSpoonKnife } from 'react-icons/im';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import cloneDeep from 'lodash/cloneDeep';
import minBy from 'lodash/minBy';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import { Controller } from 'react-hook-form';
import { numeralThousandFormat } from '~/config/helper';
import { HiOutlineTrash } from 'react-icons/hi';
import { HexColorPicker } from 'react-colorful';
import { Props } from './props';
import { ProductModal } from './productModal';
import { productFields } from './type';
import { primaryGradient, secondaryGradient } from '~/config/constant';

export const CollectionCreatePage: React.FC<Props> = (props) => {
  const { onSubmit: onSubmitProps, data, isLoading } = props;

  // ====================== HOOKS
  const form = useForm();
  const [searchText, setSearchText] = useState<string>();
  const [openProduct, setOpenProduct] = useState(false);
  const apiUrl = useApiUrl();
  const t = useTranslate();

  const products = form?.watch('products') || [];
  const productIds = uniq(products.map((item: any) => item?.split('-split-')?.[0]));

  useEffect(() => {
    if (!isEmpty(data)) {
      const getProductList = uniqBy(flatMap(data?.productVariants?.items), 'product.id');
      const productIds = getProductList?.map(
        (sub) => `${sub?.product?.id}-split-${sub?.product?.name}`
      );
      form?.setValue('products', productIds);
      form?.setValue('name', data?.name);
      form?.setValue(
        'images',
        data?.assets?.map((subItem) => subItem?.source)
      );
    }
    form?.setValue('status', isEmpty(data) ? 'available' : data?.isPrivate ? 'hide' : 'available');
    const gradient = data?.gradient?.gradient || [];
    form?.setValue('primaryColor', isEmpty(gradient?.[0]) ? primaryGradient : gradient?.[0]);
    form?.setValue('secondaryColor', isEmpty(gradient?.[1]) ? secondaryGradient : gradient?.[1]);
  }, [data]);

  // ====================== API
  const { data: selectedProducts, isLoading: loadingOrderItem } = useCustom<ProductList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      operation: 'getProducts',
      fields: productFields,
      variables: {
        options: {
          value: {
            filter: {
              id: {
                in: productIds,
              },
            },
          },
          type: 'ProductListOptions',
        },
        search: searchText,
      },
    },
    queryOptions: {
      enabled: !!productIds && !isEmpty(productIds),
    },
  });

  // ====================== EVENTS
  const onSubmit = (data: any) => {
    onSubmitProps?.(data);
  };

  // ====================== VIEWS
  const renderItems = () => {
    if (isEmpty(products)) {
      return (
        <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
          {/* getColor */}
          <div className="flex flex-col items-center justify-center space-y-2">
            <ImSpoonKnife color="#B3B5BD" size={55} />
            <span className="text-smoke-600">{t('messages.categoryNoProduct')}</span>
          </div>
          <Button className="border-primary-500" onClick={() => setOpenProduct(true)}>
            <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
              <AiOutlinePlus className="!text-primary-500" />
              <span>{t('product.add.name')}</span>
            </div>
          </Button>
        </section>
      );
    }

    // render order items
    return (
      <React.Fragment>
        <TableBuilder
          searchInputProps={{
            onChange: (text) => {
              setSearchText(text);
            },
          }}
          columns={[
            {
              id: 'product',
              header: t('product.column.name'),
              cell: (data: Product) => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ImageViewer
                      src={data?.assets?.[0]?.source}
                      className="!w-14 !h-14 rounded-lg object-cover mr-2"
                      alt="Product"
                    />
                    <span>{data?.name}</span>
                  </div>
                );
              },
            },
            {
              id: 'inventory',
              header: t('product.column.inventory'),
              cell: (data: Product) => {
                let totalStock = 0;
                data?.variants?.forEach((sub) => {
                  totalStock += sub?.stockOnHand;
                });
                return <p>{numeralThousandFormat(totalStock)}</p>;
              },
            },
            {
              id: 'price',
              header: t('product.column.price'),
              cell: (data: Product) => {
                let lowestValue = minBy(data?.variants, 'price');
                return <p>{numeralThousandFormat(lowestValue?.price, true)}</p>;
              },
            },
            {
              id: 'action',
              header: '',
              cell: (data: Product) => {
                const onPressDelete = () => {
                  const combinedValue = `${data?.id}-split-${data?.name}`;
                  const cloneArr: any[] = cloneDeep(form?.watch('products'));
                  const filteredArr = cloneArr?.filter((subItem) => subItem !== combinedValue);
                  form?.setValue('products', filteredArr);
                };
                return (
                  <IconButton onClick={onPressDelete}>
                    <HiOutlineTrash className="text-error-300" size={30} />
                  </IconButton>
                );
              },
            },
          ]}
          data={selectedProducts?.data?.items}
          headerWrapperProps={{
            className: 'px-5 pt-4',
          }}
          loading={loadingOrderItem}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="overflow-y-scroll">
      <Form form={form} onSubmit={onSubmit}>
        <ProductModal
          open={openProduct}
          setOpen={(val) => {
            setOpenProduct(val);
          }}
          form={form}
        />
        <ShowPageWrapper
          resourceName={resourceNames.collection}
          title={t('collection.create.name')}
          extra={
            <Button
              onClick={form.handleSubmit(onSubmit)}
              variant="solid"
              size="md"
              className="mx-5"
              loading={isLoading}
            >
              {t('actions.confirm')}
            </Button>
          }
        >
          <div className="flex flex-col space-y-4">
            <Card>
              <Card.Header className="font-bold" bordered>
                <h3>{'General'}</h3>
              </Card.Header>
              <Card.Body>
                <section className="flex flex-col space-y-4 2xl:w-1/2">
                  {/* Name */}
                  <FormTextField
                    required
                    name="name"
                    label={t('collection.form.name')}
                    placeholder={t('collection.placeholder.name')}
                  />
                  {/* Upload Image */}
                  {renderUploadInput({
                    input: {
                      item: {
                        required: true,
                        title: t('collection.form.images'),
                        name: 'images',
                        placeholder: t('collection.placeholder.images'),
                        hint: t('messages.acceptedFileTypes', { files: 'JPG, PDF, PNG, TXT, DOC' }),
                      },
                      ...form,
                    },
                  })}
                  {/* Status */}
                  {renderRadioButtons({
                    input: {
                      item: {
                        title: t('collection.form.status'),
                        name: 'status',
                        options: ['available', 'hide'].map((status) => ({
                          label: t(`collection.status.${status}`),
                          value: status,
                        })),
                      },
                      ...form,
                    },
                  })}
                  {/* Color Picker - Primary*/}
                  <Controller
                    name="primaryColor"
                    control={form?.control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <FieldGroup className="my-4">
                          <Label>{t('collection.form.primary')}</Label>
                          <div className="w-full">
                            <HexColorPicker color={value} onChange={onChange} />
                          </div>
                        </FieldGroup>
                      );
                    }}
                  />
                  {/* Color Picker - Secondary*/}
                  <Controller
                    name="secondaryColor"
                    control={form?.control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <FieldGroup className="my-4">
                          <Label>{t('collection.form.secondary')}</Label>
                          <div className="w-full">
                            <HexColorPicker color={value} onChange={onChange} />
                          </div>
                        </FieldGroup>
                      );
                    }}
                  />

                  <FieldGroup>
                    <Label>{t('collection.form.preview')}</Label>
                    <div
                      className="flex items-center justify-center px-5 py-1"
                      style={{
                        height: 30,
                        borderRadius: 99999,
                        background: `linear-gradient(90deg, ${form?.watch(
                          'primaryColor'
                        )},  ${form?.watch('secondaryColor')})`,
                      }}
                    >
                      <p style={{ color: 'white', fontWeight: 500 }}>{`${
                        form?.watch('name') || t('collection.name')
                      }`}</p>
                    </div>
                  </FieldGroup>
                </section>
              </Card.Body>
            </Card>

            {/* Items */}
            <Card>
              <Card.Header className="flex flex-row items-center justify-between w-full" bordered>
                <h3 className="font-bold">{'Products'}</h3>
                {!isEmpty(products) && (
                  <Button
                    onClick={() => {
                      setOpenProduct(true);
                    }}
                    size="sm"
                    className="!px-4 border-primary-500"
                  >
                    {/* getColor */}
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus color="#8E24AA" />
                      <span>{t('actions.addMore')}</span>
                    </div>
                  </Button>
                )}
              </Card.Header>
              <Card.Body style={{ padding: 0 }}>{renderItems()}</Card.Body>
            </Card>
          </div>
        </ShowPageWrapper>
      </Form>
    </div>
  );
};
