import { ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';

import { type Banner, BannerStatus } from '~/api';
import { ActionButton, TriggerConfirmModal } from '~/components';
import { useState } from 'react';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';
import { formatDateRange } from '~/resources/helpers';
import { BannerCreatePage } from './create';
import { BannerShowPage } from './show';
import { BannerEditPage } from './edit';

const { defineFields, defineShowPage } = createHelpers<Banner>({
  resourceName: resourceNames.banner,
});

export const BannerStatusColor: Record<BannerStatus, string> = {
  [BannerStatus.Available]: 'success',
  [BannerStatus.Ended]: 'error',
  [BannerStatus.Upcoming]: 'warning',
};

export const bannerFields: ResourceField<Banner>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'status',
  'startDate',
  'endDate',
  'type',
  'rewardId',
  'url',
  'description',
  { products: ['id', 'name'] },
  { asset: ['id', 'source'] },
  { reward: ['id', 'name', 'pointCost', 'type'] },
];

export const bannerResource = createResource({
  name: resourceNames.banner,
  label: 'Banner',
  fields: defineFields(bannerFields),
  defaultValues: {} as any,
  defaultPageSize: 25,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowSearch: true,
  allowEdit: true,
  allowCreate: true,
  searchConfig: {
    placeholder: ({ t }) =>
      t('banners.placeholder.search', { fallback: `Search by Banner Name`, ns: 'common' }),
  },
  filterControls: {
    startDate: {
      type: 'daterange',
      config: {
        label: 'Start Date',
      },
    },
    endDate: {
      type: 'daterange',
      config: {
        label: 'End Date',
      },
    },
  },
  createConfig: {
    title: ({ t }) => t('banners.create.name', { fallback: `Create Banners`, ns: 'common' }),
  },
  filterConfig: {
    alwaysExpanded: true,
  },
  allowDelete: true,
  columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => [
    {
      id: 'name',
      header: t('banners.column.name', { fallback: `Name`, ns: 'common' }),
      cell: (data) => {
        const { id, name, asset } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            <div className="flex flex-row items-center">
              <ImageViewer
                src={asset?.source}
                className="!w-24 !h-14 rounded-lg object-cover mr-2"
                alt="Product"
              />
              <span>{name}</span>
            </div>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'period',
      header: t('banners.column.bannerPeriod', { fallback: `Banner Period`, ns: 'common' }),
      cell: (data) => {
        const { id, startDate, endDate } = data.row.original;

        const display = formatDateRange(startDate, endDate);

        return (
          <LinkToDetails resourceId={id}>
            <span>{display}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'status',
      header: t('banners.column.status', { fallback: `Status`, ns: 'common' }),
      cell: (data) => {
        const { id, status } = data?.row?.original;

        return (
          <LinkToDetails resourceId={id}>
            <Tag color={BannerStatusColor[status] as any}>
              {t(`banners.status.${status.toLowerCase()}`, {
                fallback: `${status}`,
                ns: 'common',
              }).toUpperCase()}
            </Tag>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'actions',
      header: () => <div />,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const { id: bannerId, name } = data.row.original;
        const t = useTranslate();
        const [showDel, setShowDel] = useState(false);

        return (
          <>
            <ActionButton
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateToEdit({ id: bannerId }),
                },
                {
                  label: t('actions.delete'),
                  name: 'delete',
                  onAction: () => invokeDelete({ id: bannerId }),
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          title={t('banners.delete', { name }, `Delete ${name}`)}
                          visible={showDel}
                          onOpenChange={setShowDel}
                          onPressConfirm={onAction}
                          description={
                            <span>
                              {t('warnings.deleteConfirmation', {
                                resource: t('banners.name.banner').toLowerCase(),
                              })}
                              <span className="font-semibold text-error-300">{` ${name}`}</span>
                              {'? '}
                              {t('warnings.cannotUndo')}
                            </span>
                          }
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          </>
        );
      },
    },
  ],
  create: {
    render: (helpers) => {
      return <BannerCreatePage />;
    },
  },
  edit: {
    render: (h) => {
      return <BannerEditPage />;
    },
  },
  show: defineShowPage({
    component: (props) => {
      return <BannerShowPage queryResult={props.queryResult} />;
    },
  }),
});
