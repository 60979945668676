import { AccessConfig } from '@scalingworks/refine-react-admin/src/types';
import { Permission } from '~/api';
import { resourceNames } from '~/resources/resource-names';

/**
 * NOTE:
 * we re-declare the permission array (resourceControl) using keyof typeof Vendure's Permissions enum here
 * So we dont need to keep typing `Permission.<>` in each element;
 *
 * it's string[] by default because internal couldnt access the ~api.ts
 */
type PermissionArray = (keyof typeof Permission)[];
type RC = Record<
  string,
  {
    list: PermissionArray;
    show: PermissionArray;
    edit: PermissionArray;
    create: PermissionArray;
    delete: PermissionArray;
  }
>;

export const createAccessConfig = (): AccessConfig => {
  const channelCode = import.meta.env.VITE_CHANNEL_CODE || '__default_channel__';

  return {
    channelCode,
    resourceControl: {
      // Order
      [resourceNames.order]: {
        list: ['ReadOrder'],
        show: ['ReadOrder'],
        create: ['ReadOrder', 'CreateOrder'],
        edit: ['ReadOrder', 'UpdateOrder'],
        delete: ['ReadOrder', 'DeleteOrder'],
      },

      // ======================> Products Group Resource
      ['productCatalouge']: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Product
      [resourceNames.product]: {
        list: ['ReadProduct'],
        show: ['ReadProduct'],
        create: ['ReadProduct', 'CreateProduct'],
        edit: ['ReadProduct', 'UpdateProduct'],
        delete: ['ReadProduct', 'DeleteProduct'],
      },
      [resourceNames.collection]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.modifierGroup]: {
        list: ['ReadFacet'],
        show: ['ReadFacet'],
        create: ['ReadFacet'],
        edit: ['ReadFacet'],
        delete: ['ReadFacet'],
      },
      // <====================== End Products Group Resource

      // Reservation (Backend update permission?)
      ['scheduling']: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.reservation]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.reservationCalendar]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // outlet
      [resourceNames.outlet]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Customer
      [resourceNames.customer]: {
        list: ['ReadCustomer'],
        show: ['ReadCustomer'],
        create: ['ReadCustomer', 'CreateCustomer'],
        edit: ['ReadCustomer', 'UpdateCustomer'],
        delete: ['ReadCustomer', 'DeleteCustomer'],
      },
      // ======================> Loyalty Group Resource
      ['loyalty']: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Reward
      [resourceNames.reward]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Promo Code
      [resourceNames.promoCode]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Point Transaction
      [resourceNames.pointTransaction]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Redemption History
      [resourceNames.redemptionHistory]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      // Membership Tiers
      [resourceNames.membershiptier]: {
        list: ['ReadMembershipTier'],
        show: ['ReadMembershipTier'],
        create: ['CreateMembershipTier'],
        edit: ['UpdateMembershipTier'],
        delete: ['DeleteMembershipTier'],
      },
      // <====================== End Loyalty Group Resource

      // ======================> Marketing Group Resource
      ['marketing']: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.discountPromotion]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.banner]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.notificationBlast]: {
        list: ['SuperAdmin', 'Authenticated'],
        show: ['SuperAdmin', 'Authenticated'],
        create: ['SuperAdmin', 'Authenticated'],
        edit: ['SuperAdmin', 'Authenticated'],
        delete: ['SuperAdmin', 'Authenticated'],
      },
      // <====================== End Marketing Group Resource
      [resourceNames.staff]: {
        list: ['ReadAdministrator'],
        show: ['ReadAdministrator'],
        create: ['ReadAdministrator', 'CreateAdministrator'],
        edit: ['ReadAdministrator', 'UpdateAdministrator'],
        delete: ['ReadAdministrator', 'DeleteAdministrator'],
      },
      [resourceNames.auditLog]: {
        list: ['ReadAuditLog'],
        show: ['ReadAuditLog'],
        create: ['ReadAuditLog'],
        edit: ['ReadAuditLog'],
        delete: ['ReadAuditLog'],
      },
      [resourceNames.shippingMethod]: {
        list: ['ReadShippingMethod'],
        show: ['ReadShippingMethod'],
        create: ['ReadShippingMethod', 'CreateShippingMethod'],
        edit: ['ReadShippingMethod', 'UpdateShippingMethod'],
        delete: ['ReadShippingMethod', 'DeleteShippingMethod'],
      },
      [resourceNames.setting]: {
        list: ['ReadSettings'],
        show: ['ReadSettings'],
        create: ['ReadSettings'],
        edit: ['ReadSettings'],
        delete: ['ReadSettings'],
      },
      [resourceNames.profile]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
      [resourceNames.report]: {
        list: ['Authenticated'],
        show: ['Authenticated'],
        create: ['Authenticated'],
        edit: ['Authenticated'],
        delete: ['Authenticated'],
      },
    } as RC,
  };
};
