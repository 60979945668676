import React, { useEffect, useState } from 'react';
import { Props } from './props';
import { useCustom, useTranslate } from '@refinedev/core';
import { Button, Popover, Skeleton } from '@scalingworks/react-admin-ui';
import { GQLClient } from '~/config/gql-client';
import { OrderSummaryDto } from '~/api';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { BsChevronDown } from 'react-icons/bs';

type RangeSelection = { label: string; date: [Date, Date] };

export const OrderDashboard: React.FC<Props> = (props) => {
  // const {  } = props || {};
  const t = useTranslate();

  // =================== VARIABLES
  const definedSelection: Record<string, RangeSelection> = {
    Today: {
      label: t('order.timeLine.today'),
      date: [startOfDay(new Date()), endOfDay(new Date())],
    },
    LastWeek: {
      label: t('order.timeLine.lastWeek'),
      date: [sub(new Date(), { days: 7 }), endOfDay(new Date())],
    },
    LastMonth: {
      label: t('order.timeLine.lastMonth'),
      date: [sub(new Date(), { months: 1 }), endOfDay(new Date())],
    },
    Last3Months: {
      label: t('order.timeLine.last3Months'),
      date: [sub(new Date(), { months: 3 }), endOfDay(new Date())],
    },
    LastYear: {
      label: t('order.timeLine.lastYear'),
      date: [sub(new Date(), { years: 1 }), endOfDay(new Date())],
    },
  };

  // =================== STATES
  const [range, setRange] = useState<RangeSelection>(definedSelection.Today);
  const [openRange, setOpenRange] = useState(false);

  // =================== HOOKS
  const {
    data,
    isLoading: loadingSummary,
    refetch,
  } = useCustom<OrderSummaryDto>({
    queryOptions: {
      enabled: !!range?.date,
    },
    url: GQLClient.apiUrl,
    method: 'get',
    metaData: {
      fields: [
        'totalSales',
        'cancellations',
        'completed',
        'refunds',
      ] as ResourceField<OrderSummaryDto>[],
      operation: 'orderSummary',
      variables: {
        input: {
          value: {
            startDate: range.date[0].toISOString(),
            endDate: range.date[1].toISOString(),
          },
          type: 'OrderSummaryInput!',
        },
      },
    },
  });
  const { totalSales, completed, cancellations, refunds } = data?.data || {};

  // =================== EFFECTS
  useEffect(() => {
    refetch();
  }, [range]);

  // =================== VIEWS
  const Row: React.FC<any> = (props) => {
    return (
      <div className="flex flex-row w-full items-center justify-between">{props?.children}</div>
    );
  };
  const Col: React.FC<any> = (props) => {
    return (
      <div
        className={`flex flex-col w-full p-2 px-10 border-b-2 xl:border-r-2 xl:border-b-0 xl:px-4 ${props.className}`}
      >
        {props?.children}
      </div>
    );
  };
  const Title: React.FC<any> = (props) => {
    return <h3 className="!text-smoke-100">{props?.children}</h3>;
  };
  const Value: React.FC<any> = (props) => {
    return (
      <h3 className={`text-white text-xl font-semibold ${props?.className}`}>{props?.children}</h3>
    );
  };
  const Loading: React.FC = () => <Skeleton heightClass="h-5" width="narrow" />;

  return (
    <section className="bg-smoke-900 rounded-lg text-smoke-100 flex flex-col items-center justify-center xl:flex-row xl:space-x-2 mb-8">
      <Col>
        <Row>
          <Title>{t('order.summary.summaryFor', undefined, 'Summary for')}</Title>
        </Row>
        <Popover.Root open={openRange} onOpenChange={setOpenRange}>
          <Popover.Trigger asChild>
            <button>
              <div className="flex flex-row items-center justify-between cursor-pointer">
                <Value>{range.label}</Value>
                <BsChevronDown color="white" />
              </div>
            </button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              align="end"
              sideOffset={0}
              className="p-0 !bg-smoke-900 shadow-smoke-800"
              style={{ minWidth: 160 }}
            >
              <div className="flex flex-col justify-center items-start space-y-2 p-4 bg-smoke-900 text-white rounded-lg">
                {Object.keys(definedSelection).map((key) => {
                  const active = range.label === definedSelection[key].label;
                  return (
                    <Button
                      className={`w-full text-white focus-visible:ring-0 hover:text-smoke-900 ${
                        active && '!text-smoke-900'
                      }`}
                      size="sm"
                      variant="plain"
                      onClick={() => {
                        setRange(definedSelection[key]);
                        setOpenRange(false);
                      }}
                      active={active}
                    >
                      {definedSelection[key].label}
                    </Button>
                  );
                })}
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </Col>

      {/* Total Sales */}
      <Col>
        <Row>
          <Title>{t('order.summary.totalSales', undefined, 'Total Sales')}</Title>
        </Row>
        {loadingSummary ? <Loading /> : <Value>RM {totalSales || 0}</Value>}
      </Col>

      <div className="xl:hidden w-full flex flex-row items-center justify-evenly">
        {/* Completed */}
        <div className="flex flex-col items-center flex-1 p-2 px-10 border-r">
          <Title>{t('order.summary.completed', undefined, 'Completed')}</Title>
          {loadingSummary ? <Loading /> : <Value>{completed || 0}</Value>}
        </div>

        {/* Cancellations */}
        <div className="flex flex-col items-center flex-1 p-2 px-10 border-r">
          <Title>{t('order.summary.cancellations', undefined, 'Cancellations')}</Title>
          {loadingSummary ? (
            <Loading />
          ) : (
            <Value className="!text-center w-full">{cancellations || 0}</Value>
          )}
        </div>

        {/* Refunded */}
        {/* <div className="flex flex-col items-center flex-1 p-2 px-10">
          <Title>{t('order.summary.refunds', undefined, 'Refunds')}</Title>
          {loadingSummary ? <Loading /> : <Value>{refunds || 0}</Value>}
        </div> */}
      </div>

      {/* XL SCREEN */}
      {/* Completed */}
      <Col className="hidden xl:block">
        <Row>
          <Title>{t('order.summary.completed', undefined, 'Completed')}</Title>
        </Row>
        {loadingSummary ? <Loading /> : <Value>{completed || 0}</Value>}
      </Col>

      {/* Cancellations */}
      <Col className="hidden xl:block">
        <Row>
          <Title>{t('order.summary.cancellations', undefined, 'Cancellations')}</Title>
        </Row>
        {loadingSummary ? <Loading /> : <Value>{cancellations || 0}</Value>}
      </Col>

      {/* Refunded */}
      {/* <Col className="hidden xl:block">
        <Row>
          <Title>{t('order.summary.refunds', undefined, 'Refunds')}</Title>
        </Row>
        {loadingSummary ? <Loading /> : <Value>{refunds || 0}</Value>}
      </Col> */}
    </section>
  );
};
