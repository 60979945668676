import { Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';

import { type DiscountPromotion, UpdateDiscountPromotionInput } from '~/api';
import { ActionButton, TriggerConfirmModal, UpdateGeneralStatus } from '~/components';
import { useState } from 'react';
import { resourceNames } from '~/resources/resource-names';
import { DiscountPromotionCreatePage } from './create';
import { useTranslate, useUpdate } from '@refinedev/core';
import { DiscountPromotionShowPage } from './show';
import { formatDateRange, isBetweenRange } from '~/resources/helpers';
import { Action } from '~/components/ActionButton/props';

const { defineFields, defineShowPage } = createHelpers<DiscountPromotion>({
  resourceName: resourceNames.discountPromotion,
});

export const statusColor: Record<string, string> = {
  true: 'text-success-500 bg-success-100 border-success-500',
  false: 'text-gray-500 bg-gray-100 border-gray-500',
};

export const mapDiscountPromotionStatus = (enabled: boolean): 'ACTIVE' | 'INACTIVE' => {
  return enabled ? 'ACTIVE' : 'INACTIVE';
};

export const discountPromotionFields: ResourceField<DiscountPromotion>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'amount',
  'enabled',
  'startDate',
  'endDate',
  'name',
  'type',
  { productVariants: ['id', 'name'] },
];

export const discountPromotionResource = createResource({
  name: resourceNames.discountPromotion,
  label: 'Discount Promotions',
  fields: defineFields(discountPromotionFields),
  defaultValues: {} as any,
  defaultPageSize: 25,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowSearch: true,
  searchConfig: {
    placeholder: ({ t }) =>
      t('discountPromotions.placeholder.search', {
        fallback: 'Search by Discount Promotions',
        ns: 'common',
      }),
  },
  filterControls: {
    startDate: {
      type: 'daterange',
      config: {
        label: 'Start Date',
      },
    },
    endDate: {
      type: 'daterange',
      config: {
        label: 'End Date',
      },
    },
  },
  createConfig: {
    title: ({ t }) =>
      t('discountPromotions.create.name', { fallback: 'Create Discount Promotions', ns: 'common' }),
  },
  filterConfig: {
    alwaysExpanded: true,
  },
  allowDelete: true,
  columns: ({ LinkToDetails, navigateToDetails, invokeDelete, t }) => [
    {
      id: 'name',
      header: t('discountPromotions.column.name', { fallback: 'Name', ns: 'common' }),
      cell: (data) => {
        const { id, name } = data.row.original;

        return <LinkToDetails resourceId={id}>{name}</LinkToDetails>;
      },
    },
    {
      id: 'period',
      header: t('discountPromotions.column.period', {
        fallback: 'Discount Promotion Period',
        ns: 'common',
      }),
      cell: (data) => {
        const { id, startDate, endDate } = data.row.original;

        const display = formatDateRange(startDate, endDate);

        return (
          <LinkToDetails resourceId={id}>
            <span>{display}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'product',
      header: t('discountPromotions.column.product', { fallback: 'Product', ns: 'common' }),
      cell: (data) => {
        const { id, productVariants } = data.row.original;

        return (
          <LinkToDetails resourceId={id}>
            <Tag color="purple">{productVariants?.length || 0}</Tag>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'status',
      header: t('discountPromotions.column.status', { fallback: 'Status', ns: 'common' }),
      cell: (data) => {
        const { id, enabled } = data?.row?.original;
        const status = mapDiscountPromotionStatus(enabled);

        return (
          <LinkToDetails resourceId={id}>
            <Tag className={`${statusColor[`${enabled}`]}`}>
              {t(`discountPromotions.status.${status.toLowerCase()}`, {
                fallback: status,
                ns: 'common',
              }).toUpperCase()}
            </Tag>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'actions',
      header: () => <div />,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const { id: discountPromotionId, name, enabled, startDate, endDate } = data.row.original;
        const t = useTranslate();
        const [openStatus, setOpenStatus] = useState(false);
        const { mutate: updateStatus, isLoading: updating } = useUpdate();
        const [showDel, setShowDel] = useState(false);

        return (
          <>
            {openStatus && (
              <UpdateGeneralStatus
                open
                setOpen={setOpenStatus}
                initialStatus={enabled ? 'ACTIVE' : 'INACTIVE'}
                loading={updating}
                onConfirm={(status) => {
                  updateStatus(
                    {
                      resource: resourceNames.discountPromotion,
                      id: discountPromotionId,
                      values: {
                        enabled: status === 'ACTIVE' ? true : false,
                      } as Partial<UpdateDiscountPromotionInput>,
                      errorNotification: {
                        message: t('discountPromotions.edit.success'),
                        type: 'error',
                      },
                      successNotification: {
                        message: t('discountPromotions.edit.failed'),
                        type: 'success',
                      },
                    },
                    { onSuccess: () => setOpenStatus(false) }
                  );
                }}
              />
            )}
            <ActionButton
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateToDetails({ id: discountPromotionId }),
                },
                {
                  label: t('actions.updateStatus'),
                  name: 'update-status',
                  onAction: () => setOpenStatus(true),
                },
                ...(!isBetweenRange(startDate, endDate)
                  ? [
                      {
                        label: t('actions.delete'),
                        name: 'delete',
                        onAction: () => invokeDelete({ id: discountPromotionId }),
                        render: (onAction) => {
                          return (
                            <button type="button">
                              <TriggerConfirmModal
                                title={t('discountPromotions.delete', { name }, `Delete ${name}`)}
                                visible={showDel}
                                onOpenChange={setShowDel}
                                onPressConfirm={onAction}
                                description={
                                  <span>
                                    {t('warnings.deleteConfirmation', {
                                      resource: `${t('discountPromotions.name').toLowerCase()} `,
                                    })}
                                    <span className="font-semibold text-error-300">{name}</span>
                                    {'? '}
                                    {t('warnings.cannotUndo')}
                                  </span>
                                }
                              />
                            </button>
                          );
                        },
                      } as Action,
                    ]
                  : []),
              ]}
            />
          </>
        );
      },
    },
  ],
  create: {
    render: (helpers) => {
      return <DiscountPromotionCreatePage />;
    },
  },
  show: defineShowPage({
    component: (props) => {
      return <DiscountPromotionShowPage queryResult={props.queryResult} />;
    },
  }),
});
