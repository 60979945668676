import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { getSdk, PromoCode, PromoCodeStatus, PromoCodeType } from '~/api';
import { useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  ActionButtonRefProps,
  ActionModalWithTrigger,
  RadioGroup,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { MaybeTranslationCallback } from '@scalingworks/refine-react-admin/src/types';
import { FilterControlRecord } from '@scalingworks/refine-react-admin/src/components/filter-controls.types';
import { dateFormatter, toCamelCaseWord } from '~/resources/helpers';
import dayjs from 'dayjs';
import { numeralThousandFormat } from '~/config/helper';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useCreate, useNavigation, useOne, useTranslate, useUpdate } from '@refinedev/core';
import { resourceNames } from '../../resource-names';
import { renderStatusTag, renderTextWithPrefix } from '../helpers';
import { PromoShow } from './show';
import { PromoCreate } from './create';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<PromoCode>({
    resourceName: resourceNames?.promoCode,
  });

const fields: ResourceField<PromoCode>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'type',
  'pointCost',
  'status',
  'runningId',
  'quantity',
  { membershipTiers: ['id', 'name'] },
];

export const promoCodeResource = createResource({
  name: resourceNames?.promoCode,
  label: 'Voucher Codes',
  fields: defineFields(fields),
  defaultValues: {} as any,
  defaultPageSize: 25,
  defaultSorter: [{ field: 'id', order: 'desc' }],
  allowCreate: true,
  allowSearch: true,
  createConfig: {
    title: ({ t }) =>
      t('promoCodes.create.name', { fallback: 'Create Voucher Code', ns: 'common' }),
  },
  searchConfig: {
    placeholder: ({ t }) =>
      t('promoCodes.placeholder.search', { fallback: 'Search by Voucher Name', ns: 'common' }),
  },
  filterConfig: {
    alwaysExpanded: true,
  },
  filterControls: {
    startAt: {
      type: 'daterange',
      config: {
        label: 'Start At',
      },
    },
    endAt: {
      type: 'daterange',
      config: {
        label: 'End At',
      },
    },
    type: {
      type: 'select',
      config: {
        options: [
          {
            label: 'All',
            value: 'all',
          },
          ...Object.keys(PromoCodeType).map((key) => ({
            label: toCamelCaseWord(key),
            // @ts-ignore
            value: PromoCodeType[key],
          })),
        ],
        label: 'Type',
        placeholder: 'Select Type',
      },
    },
  } as MaybeTranslationCallback<FilterControlRecord<PromoCode>>,
  dataProvider: {
    getList: ({ metaData, client, pagination, sort }) => {
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 25;

      const queryKey = metaData?.queryContext?.queryKey as any[];
      const searchField = queryKey?.[3]?.filters?.find(
        (subItem: any) => subItem?.field === 'search'
      );
      const startAtBegin = queryKey?.[3]?.filters?.find(
        (subItem: any) => subItem?.field === 'startAt' && subItem?.operator === 'gte'
      );
      const startAtEnd = queryKey?.[3]?.filters?.find(
        (subItem: any) => subItem?.field === 'startAt' && subItem?.operator === 'lte'
      );
      const endAtBegin = queryKey?.[3]?.filters?.find(
        (subItem: any) => subItem?.field === 'endAt' && subItem?.operator === 'gte'
      );
      const endAtEnd = queryKey?.[3]?.filters?.find(
        (subItem: any) => subItem?.field === 'endAt' && subItem?.operator === 'lte'
      );
      const typeField = queryKey?.[3]?.filters?.find((subItem: any) => subItem?.field === 'type');
      return getSdk(client)
        ?.getPromoCodes({
          input: {
            search: searchField?.value,
            type: typeField?.value === 'all' ? undefined : typeField?.value,
            startDateBegin: startAtBegin?.value,
            startDateEnd: startAtEnd?.value,
            endDateBegin: endAtBegin?.value,
            endDateEnd: endAtEnd?.value,
          },
          options: {
            skip: (current - 1) * pageSize,
            take: pageSize,
          },
        })
        ?.then((res) => {
          return {
            data: res?.getPromoCodes?.items,
            total: res?.getPromoCodes?.totalItems,
          };
        });
    },
    getOne: ({ client, id }) => {
      return getSdk(client)
        ?.PromoCode({
          promoCodeId: id as string,
        })
        ?.then((res) => {
          return {
            data: res?.promoCode,
          };
        });
    },
    create: async ({ client, variables }) => {
      let assetsUrl;
      if (!isEmpty(variables?.images)) {
        assetsUrl = await getSdk(client)?.createAssets({
          input: variables?.images?.map((subItem: any) => {
            return {
              file: subItem,
            };
          }),
        });
      }
      return getSdk(client)
        ?.CreatePromoCode({
          input: {
            name: variables?.name,
            type: variables?.type,
            description: variables?.description,
            imageUrls: assetsUrl?.createAssets?.map((subItem: any) => subItem?.source),
            voucherCode: variables?.code,
            quantity: parseInt(variables?.quantity),
            pointCost: parseInt(variables?.pointCost),
            cappedDiscount: !!variables?.cappedAt ? parseFloat(variables?.cappedAt) : undefined,
            entitledTierIds: variables?.membershipTiers,
            minimumSpendAmount: parseFloat(variables?.minimumSpending),
            voucherValue: parseFloat(variables?.value),
            startAt: dayjs(variables?.startDate)?.toISOString(),
            endAt: dayjs(variables?.endDate)?.toISOString(),
            redeemCriteria: variables?.redeemCriteria || '-',
            termCondition: variables?.termsConditions || '-',
          },
        })
        ?.then((res) => ({ data: res }));
    },
    deleteOne: ({ client, id }) => {
      return getSdk(client)
        ?.DeletePromoCode({
          deletePromoCodeId: id?.toString() || '',
        })
        ?.then((res) => ({
          data: res,
        }));
    },
    update: async ({ client, variables, id }) => {
      const formData = variables?.data;
      const promoCodeData = variables?.promoCodeData as PromoCode;
      const promoCodeId = id as string;

      let assetsUrl: any;
      if (!isEmpty(formData?.image) && typeof formData?.image?.[0] !== 'string') {
        assetsUrl = await getSdk(client)?.createAssets({
          input: formData?.image?.map((subItem: any) => {
            return {
              file: subItem,
            };
          }),
        });
      }

      return getSdk(client)
        ?.UpdatePromoCode({
          input: {
            id: promoCodeId,
            name: formData?.name || promoCodeData?.name,
            type: formData?.type || promoCodeData?.type,
            voucherCode: formData?.code || promoCodeData?.voucherCode,
            pointCost: parseInt(formData?.pointCost) || promoCodeData?.pointCost,
            cappedDiscount: parseFloat(formData?.cappedAt || 0) || promoCodeData?.cappedDiscount,
            entitledTierIds: formData?.membershipTiers,
            minimumSpendAmount:
              parseFloat(formData?.minimumSpending) || promoCodeData?.minimumSpendAmount,
            voucherValue: parseFloat(formData?.value) || promoCodeData?.voucherValue,
            startAt: formData?.startDate
              ? dayjs(formData?.startDate)?.toISOString()
              : promoCodeData?.startAt,
            endAt: formData?.endDate
              ? dayjs(formData?.endDate)?.toISOString()
              : promoCodeData?.endAt,
            status: formData?.status || promoCodeData?.status,
            quantity: parseInt(formData?.quantity || promoCodeData?.quantity),
            imageUrl: assetsUrl?.createAssets?.[0]?.source || promoCodeData?.imageUrls?.[0],
            description: formData?.description || promoCodeData?.description,
            redeemCriteria: formData?.redeemCriteria || promoCodeData?.redeemCriteria || '-',
            termCondition: formData?.termsConditions || promoCodeData?.termCondition || '-',
          },
        })
        ?.then((res) => ({
          data: res,
        }));
    },
  },
  columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
    return [
      {
        id: 'name',
        header: t('promoCodes.column.name', { fallback: 'Name', ns: 'common' }),
        cell: (data) => {
          const { id, name } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              <span>{name}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'code',
        header: t('promoCodes.column.code', { fallback: 'Code', ns: 'common' }),
        cell: (data) => {
          const { id, voucherCode } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              <span>{voucherCode}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'value',
        header: t('promoCodes.column.voucher', { fallback: 'Voucher Value', ns: 'common' }),
        cell: (data) => {
          const { id, type, voucherValue } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              <span>
                {`${
                  type === PromoCodeType?.FixedValue
                    ? 'MYR ' + numeralThousandFormat(voucherValue, true)
                    : numeralThousandFormat(voucherValue, true) + '%'
                }`}
              </span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'quantity',
        header: t('promoCodes.column.quantity', { fallback: 'Quantity', ns: 'common' }),
        cell: (data) => {
          const { id, quantity } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              <span>{numeralThousandFormat(quantity)}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'pointsCost',
        header: t('promoCodes.column.points', { fallback: 'Points Cost', ns: 'common' }),
        cell: (data) => {
          const { id, pointCost } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              {renderTextWithPrefix({
                text: `${numeralThousandFormat(pointCost)}`,
                prefixText: 'PTS',
                prefixPosition: 'right',
              })}
            </LinkToDetails>
          );
        },
      },
      {
        id: 'startAt',
        header: t('promoCodes.column.start', { fallback: 'Starts At', ns: 'common' }),
        cell: (data) => {
          const { id, startAt } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              <span>{dateFormatter(startAt)}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'endAt',
        header: t('promoCodes.column.end', { fallback: 'Ends At', ns: 'common' }),
        cell: (data) => {
          const { id, endAt } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              <span>{dateFormatter(endAt)}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'status',
        header: t('promoCodes.column.status', { fallback: 'Status', ns: 'common' }),
        cell: (data) => {
          const { id, status } = data.row.original as PromoCode;
          return (
            <LinkToDetails resourceId={id}>
              {renderStatusTag(status === PromoCodeStatus?.Active, resourceNames.promoCode)}
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const { id, status } = data?.row?.original as PromoCode;
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
          const [showUpdateModal, setShowUpdateModal] = useState(false);
          const [statusVal, setStatusVal] = useState<string>(status);
          const { mutate } = useUpdate();
          const t = useTranslate();

          useEffect(() => {
            setStatusVal(status);
          }, [data?.row?.original]);

          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: t('actions.updateStatus'),
                  name: 'edit',
                  onAction: () => navigateToEdit({ id }),
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <ActionModalWithTrigger
                          triggerText={t('actions.updateStatus')}
                          visible={showUpdateModal}
                          title={t('actions.updateStatus')}
                          renderBody={() => {
                            return (
                              <div>
                                <RadioGroup
                                  isField
                                  label={t('promoCodes.column.status')}
                                  options={[
                                    ...Object.keys(PromoCodeStatus).map((key) => ({
                                      label: t(`promoCodes.status.${key.toLowerCase()}`),
                                      // @ts-ignore
                                      value: PromoCodeStatus[key],
                                    })),
                                  ]}
                                  value={statusVal}
                                  onValueChange={setStatusVal}
                                />
                              </div>
                            );
                          }}
                          onOpenChange={(val) => {
                            const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                            actionButtonSetOpen?.(val);
                            setShowUpdateModal(val);
                          }}
                          onPressCancel={() => setStatusVal(status)}
                          onPressConfirm={() => {
                            mutate({
                              id,
                              resource: resourceNames?.promoCode,
                              values: {
                                data: {
                                  status: statusVal,
                                },
                                promoCodeData: data?.row?.original,
                              },
                            });
                          }}
                        />
                      </button>
                    );
                  },
                },
                {
                  label: t('actions.delete'),
                  name: 'delete',
                  onAction: () => invokeDelete({ id }),
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          visible={showDeleteConfirmation}
                          onOpenChange={(val) => {
                            const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                            setShowDeleteConfirmation(val);
                            actionButtonSetOpen && actionButtonSetOpen(val);
                          }}
                          onPressConfirm={onAction}
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ];
  },
  formatBeforeSubmit: (data) => {
    return data;
  },
  show: {
    component: (helpers) => {
      return <PromoShow helpers={helpers} />;
    },
  },
  create: {
    render: (helpers) => {
      const navigation = useNavigation();
      const { mutate } = useCreate();

      const onSubmit = async (formData: any) => {
        await mutate(
          {
            resource: resourceNames.promoCode,
            values: {
              ...formData,
            },
          },
          {
            onSuccess: () => {
              navigation?.goBack();
            },
          }
        );
      };
      return <PromoCreate onSubmit={onSubmit} />;
    },
  },
  edit: {
    render: (helpers) => {
      const { id } = useParams();
      const navigation = useNavigation();
      const { mutate } = useUpdate();
      if (!id) return <SomethingWentWrong />;

      const { data, isLoading } = useOne({
        resource: resourceNames?.promoCode,
        id,
        metaData: {
          fields,
        },
      });

      const promoCodeData = data?.data as PromoCode;

      const onSubmit = async (formData: any) => {
        await mutate(
          {
            id,
            resource: resourceNames.promoCode,
            values: {
              data: formData,
              promoCodeData,
            },
          },
          {
            onSuccess: () => {
              navigation?.goBack();
            },
          }
        );
      };
      return <PromoCreate onSubmit={onSubmit} promoCodeData={promoCodeData} />;
    },
  },
});
