import React, { useEffect, useState } from 'react';
import * as Primitive from '@radix-ui/react-radio-group';
import { Props } from './props';
import { FieldGroup, Label } from '@scalingworks/react-admin-ui';

export const RadioGroup: React.FC<Props> = (props) => {
  const { options, isField, label, ...restProps } = props;

  const [selected, setSelected] = useState(props.value || props.defaultValue);

  const mapOptions = () => {
    return options.map(({ label, value, extra, props }) => {
      const isSelected = value === selected;
      return (
        <div
          className={`flex flex-col space-y-2 w-full ${
            extra && 'border-solid rounded-md border-2 p-4'
          }`}
        >
          <div className="flex flex-row items-center w-full" key={value}>
            <Primitive.Item
              {...props}
              id={value}
              value={value}
              className={`h-6 w-6 rounded-full ${
                isSelected ? 'border-primary-500' : 'border-gray-300'
              } ${props?.className} `}
              style={{ borderWidth: isSelected ? 5 : 1 }}
            >
              <Primitive.Indicator />
            </Primitive.Item>
            <label
              htmlFor={value}
              className={`cursor-pointer text-sm select-none ml-3 ${
                props?.disabled && 'text-gray-300 cursor-default'
              }`}
            >
              {label}
            </label>
          </div>
          <section className="w-full">{extra}</section>
        </div>
      );
    });
  };

  const handleChange = (value: string) => {
    restProps?.onValueChange?.(value);
    setSelected(value);
  };

  const component = (
    <Primitive.Root {...restProps} onValueChange={handleChange} className="w-full">
      <div className="flex flex-col space-y-3 w-full">{mapOptions()}</div>
    </Primitive.Root>
  );

  if (isField) {
    return (
      <FieldGroup layout="horizontal">
        <Label showRequiredIndicator={props.required}>{label}</Label>
        {component}
      </FieldGroup>
    );
  }
  return component;
};
