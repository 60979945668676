import React, { useEffect } from 'react';
import { Form } from '@scalingworks/refine-react-admin';
import { Props } from './props';
import { FormTextField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-text-field';
import { useTranslate } from '@refinedev/core';
import { Card, Tag } from '@scalingworks/react-admin-ui';
import { BsGrid } from 'react-icons/bs';

export const ModifierGroupForm: React.FC<Props> = (props) => {
  const { form, actionType = 'create', initialValues } = props;
  const { name } = initialValues || {};

  // =================== HOOKS
  const t = useTranslate();

  // =================== VARIABLES
  const createMode = actionType === 'create';
  const editMode = actionType === 'update';

  // =================== VIEWS
  return (
    <section className="flex flex-col space-y-4">
      <Card>
        <Card.Header className="flex flex-row items-center justify-between font-bold border-b">
          <h3>{t('common.general', 'General')}</h3>
        </Card.Header>
        <Card.Body>
          <Form form={form}>
            <section className="flex flex-col space-y-4 xl:w-2/3">
              <FormTextField
                label={t('modifier.form.name', {}, 'Name')}
                placeholder={t('modifier.placeholder.name', {}, 'Please enter modifier name')}
                name="name"
                defaultValue={name}
                required
              />
            </section>
          </Form>
        </Card.Body>
      </Card>

      {createMode && (
        <section className="w-full text-center">
          <div className="p-8 flex flex-col items-center justify-center space-y-4">
            <BsGrid className="!text-primary-500" size={55} />
            <p className="text-smoke-600 w-96">{t('messages.modifierRedirect')}</p>
          </div>
        </section>
      )}
    </section>
  );
};
