import { ResourceField } from '@scalingworks/refine-react-admin';
import { ProductList } from '~/api';

export const productFields: ResourceField<ProductList>[] = [
  {
    items: [
      'id',
      'name',
      {
        variants: [
          'stockOnHand',
          'id',
          'name',
          'price',
          'currencyCode',
          'productId',
          { assets: ['source', 'id'] },
        ],
      },
      { assets: ['source'] },
    ],
  },
  'totalItems',
];
