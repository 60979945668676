import camelCase from 'lodash/camelCase';
import { CheckboxField } from '@scalingworks/react-admin-ui';
import split from 'lodash/split';
import isEmpty from 'lodash/isEmpty';
import { Controller } from 'react-hook-form';
import { InputProps } from '../props';

export const renderCheckBoxButtons = ({ input: { item, control } }: InputProps) => {
  const { title, name, required, options = [], props, defaultValue } = item;
  const formName = camelCase(name || title);

  return (
    <Controller
      name={formName}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <CheckboxField
            label={title}
            required={required}
            {...props}
            value={Array.isArray(value) ? value : split(value, ',')}
            onValue={(val) => {
              onChange(val?.filter((subItem) => !isEmpty(subItem)));
            }}
            options={options}
            renderError={
              !isEmpty(error) ? (
                <div className="mt-2">
                  <p className="text-error-300 text-sm mt-3">{`Sales channels are required`}</p>
                </div>
              ) : undefined
            }
          />
        );
      }}
    />
  );
};
