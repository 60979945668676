import { OccasionType, type Reservation } from '~/api';
import { FormItemProps } from '~/components/FormBuilder/props';
import { CustomerSelection, CustomerSelectionProps } from '~/components/SearchAndSelect';
import { CountryCode } from '../constant';
import { DayFieldProps, TextInputProps } from '@scalingworks/react-admin-ui';
import { useTranslate } from '@refinedev/core';

export const reservationFormBuilder = (reservation?: Reservation): FormItemProps[] => {
  const {
    customer,
    id,
    guestName,
    phoneNumber = '',
    diningDate,
    occasion,
    adultPax,
    kidPax,
    remark,
  } = reservation || {};
  const t = useTranslate();

  let label = `${customer?.firstName} ${customer?.lastName}`.trim();
  if (phoneNumber) label = `${label} (${phoneNumber})`;

  return [
    {
      title: t('reservations.form.reservedBy'),
      name: 'customerId',
      type: 'data-selection',
      dataSelectionProps: {
        selectionComponent: CustomerSelection,
        selectionProps: {
          initialOption: {
            label,
            value: customer?.id,
          },
          disabled: !!customer?.id,
        } as CustomerSelectionProps,
      },
      defaultValue: customer?.id,
      required: true,
    },
    {
      title: t('reservations.form.guestName'),
      name: 'guestName',
      defaultValue: guestName,
      placeholder: t('reservations.placeholder.guestName'),
      required: true,
    },
    {
      title: t('reservations.form.contact'),
      name: 'phoneNumber',
      type: 'phone',
      defaultValue: phoneNumber,
      placeholder: t('reservations.placeholder.contact'),
      required: true,
    },
    {
      title: t('reservations.form.date'),
      name: 'date',
      type: 'date-picker',
      placeholder: t('reservations.placeholder.date'),
      props: {
        min: new Date(),
      } as DayFieldProps,
      defaultValue: diningDate ? new Date(diningDate) : undefined,
      required: true,
    },
    {
      title: t('reservations.form.time'),
      name: 'time',
      type: 'time-picker',
      defaultValue: diningDate ? new Date(diningDate) : undefined,
      required: true,
    },
    {
      title: t('reservations.form.occasion'),
      name: 'occasion',
      type: 'checkbox',
      options: Object.keys(OccasionType).map((key) => ({
        label: t(`reservations.form.occasionSelection.${key.toLowerCase()}`),
        value: OccasionType[key as keyof typeof OccasionType],
      })),
      defaultValue: occasion,
      required: true,
    },
    {
      title: t('reservations.form.adultPax'),
      name: 'adultPax',
      type: 'number',
      defaultValue: adultPax || 1,
      required: true,
    },
    {
      title: t('reservations.form.kidPax'),
      name: 'kidPax',
      type: 'number',
      placeholder: t('reservations.placeholder.kidPax'),
      defaultValue: kidPax,
    },
    {
      title: t('reservations.form.remark'),
      name: 'remark',
      type: 'text',
      placeholder: t('reservations.placeholder.remark'),
      multiline: true,
      defaultValue: remark,
    },
  ];
};
