import { useTranslate } from '@refinedev/core';
import { Tabs } from '@scalingworks/react-admin-ui';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ShowPageWrapper } from '~/components';
import { ShippingMethodListing } from './shippingMethods';
import { PaymentSetting } from './payment';
import { CompanySetting } from './company';
import { PackagingSetting } from './packaging';

export const SettingListPage: React.FC = () => {
  // ====================== STATES

  // ====================== HOOKS
  const t = useTranslate();

  // ====================== VIEWS
  return (
    <section className="overflow-y-scroll">
      <ShowPageWrapper
        showBackButton={false}
        resourceName="setting"
        title={t('settings.name.setting', 'Setting')}
        titleClassName="!font-normal"
      >
        <Helmet>
          <title>{t('settings.name.setting', 'Setting')}</title>
        </Helmet>

        <Tabs.Root defaultValue="shippingMethod">
          <Tabs.List>
            <Tabs.Trigger value="shippingMethod">{t('settings.tab.deliveryZone')}</Tabs.Trigger>
            <Tabs.Trigger value="payment">{t('settings.tab.finance')}</Tabs.Trigger>
            <Tabs.Trigger value="packaging">{t('settings.tab.packaging')}</Tabs.Trigger>
            <Tabs.Trigger value="company">{t('settings.tab.policy')}</Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="shippingMethod">
            <ShippingMethodListing />
          </Tabs.Content>
          <Tabs.Content value="payment">
            <PaymentSetting />
          </Tabs.Content>
          <Tabs.Content value="packaging">
            <PackagingSetting />
          </Tabs.Content>
          <Tabs.Content value="company">
            <CompanySetting />
          </Tabs.Content>
        </Tabs.Root>
      </ShowPageWrapper>
    </section>
  );
};
