import React, { useEffect, useState } from 'react';
import { Card, ImageViewer } from '@scalingworks/react-admin-ui';
import isEmpty from 'lodash/isEmpty';
import { useNavigation } from '@refinedev/core';
import { FiArrowLeft } from 'react-icons/fi';
import QRCode from 'react-qr-code';
import { flexRow } from '../FormBuilder';
import { Divider } from '../Divider';
import { Props, ViewConfigProps, ViewItemProps } from './props';

export const paddingCard: React.CSSProperties = {
  paddingTop: 27,
  paddingBottom: 27,
  paddingLeft: 20,
  paddingRight: 20,
};

export const ViewBuilder: React.FC<Props> = (props) => {
  const { title, extra, queryResult, items, hideBackButton } = props;

  const navigateTo = useNavigation();
  const [windowSize, setWindowSize] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ width: window?.innerWidth, height: window?.innerHeight });
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const renderData = (viewConfig: ViewConfigProps) => {
    const renderTitle = <p style={{ flex: 1, color: '#667085' }}>{viewConfig?.title}</p>;
    const CHILD_FLEX = windowSize?.width < 1100 ? (windowSize?.width < 876 ? 1 : 2) : 6;
    if (viewConfig?.render)
      return viewConfig?.render({ flex: CHILD_FLEX, title: renderTitle, data: viewConfig?.data });
    switch (viewConfig?.type) {
      case 'text':
        return (
          <div style={{ ...flexRow }}>
            {renderTitle}
            <p style={{ flex: CHILD_FLEX, whiteSpace: 'pre-wrap' }}>
              {viewConfig?.data?.map((subItem, index) => {
                return `${subItem}${
                  index !== viewConfig?.data?.length - 1
                    ? index === viewConfig?.data?.length - 2
                      ? ' & '
                      : ', '
                    : ''
                }`;
              })}
            </p>
          </div>
        );
      case 'image':
        return (
          <div style={{ ...flexRow }}>
            {renderTitle}
            <div style={{ ...flexRow, flex: CHILD_FLEX, gap: 15 }}>
              {viewConfig?.data?.map((subItem, index) => {
                return (
                  <ImageViewer
                    src={(subItem as string)?.replace('assest', 'assets')}
                    width={80}
                    height={110}
                  />
                );
              })}
            </div>
          </div>
        );
      case 'tag':
        return (
          <div style={{ ...flexRow }}>
            {renderTitle}
            <div style={{ flex: CHILD_FLEX, ...flexRow, flexWrap: 'wrap', gap: 10 }}>
              {viewConfig?.data?.map((subItem, index) => {
                return (
                  <div className={`bg-gray-100 rounded-full px-3 py-0.5`}>
                    <p>{subItem}</p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      case 'qr':
        return (
          <div style={{ ...flexRow }}>
            {renderTitle}
            <div style={{ ...flexRow, flex: CHILD_FLEX, gap: 15 }}>
              {viewConfig?.data?.map((subItem, index) => {
                return <QRCode key={index} value={subItem} size={110} />;
              })}
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  const renderItems = (item: ViewItemProps) => {
    if (item?.render) return item?.render(item);
    return (
      <Card>
        <Card.Header bordered>
          {/* <div
          style={{
            ...flexRow,
            ...paddingCard,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        > */}
          <section className="flex flex-row items-center justify-between">
            <p className="font-medium">{item?.cardTitle}</p>
            {item?.cardHeaderExtra && item?.cardHeaderExtra()}
          </section>
        </Card.Header>
        <Divider haveMargin={false} />
        <div style={{ ...paddingCard }}>
          {item?.config?.map((subItem, index) => {
            return <div className={`${index !== 0 && 'mt-5'}`}>{renderData(subItem)}</div>;
          })}
        </div>
      </Card>
    );
  };

  const renderExtra = () => {
    return extra?.map((subItem) => {
      return (
        <Card>
          <div
            style={{
              ...flexRow,
              ...paddingCard,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="font-medium font-medium">{subItem?.cardTitle}</p>
            {subItem?.cardHeaderExtra && subItem?.cardHeaderExtra()}
          </div>
          <Divider haveMargin={false} />
          <div style={{ ...paddingCard }}>{subItem?.render()}</div>
        </Card>
      );
    });
  };

  return (
    <div className="max-w-screen-2xl mx-auto py-3 px-6">
      {/* Headers */}
      <div style={{ ...flexRow, justifyContent: 'space-between' }} className="pb-3">
        <span style={{ fontSize: 24, ...flexRow, alignItems: 'center', fontWeight: 'bold' }}>
          {!hideBackButton && (
            <button className="mr-2" onClick={() => navigateTo.goBack()}>
              <FiArrowLeft />
            </button>
          )}
          {title || 'Details'}
        </span>
      </div>
      <div className="flex flex-col gap-3">
        {items?.map(renderItems)}
        {!isEmpty(extra) && renderExtra()}
      </div>
    </div>
  );
};
