import React, { useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustomMutation, useGetLocale, useTranslate } from '@refinedev/core';
import { Button, Card, Skeleton, Tag } from '@scalingworks/react-admin-ui';
import {
  ActionButton,
  AddingVariant,
  DetailRenderer,
  DetailRendererProps,
  OrderCancellationModal,
  OrderFulfillmentModal,
  OrderLineProduct,
  OrderPriceSummary,
  PaymentMethodModal,
  ShowPageWrapper,
  SomethingWentWrong,
  VariantItem,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { OrderAssignCustomer, OrderStatusUpdate } from '~/components/Order';
import {
  formatFullName,
  dateFormatter,
  getOrderStatus,
  getOrderStatusTag,
  joinAddress,
  useDownload,
  canDownloadInvoice,
  getOrderFulfillmentSlot,
  getOrderActions,
  toCamelCaseWord,
} from '~/resources/helpers';
import { OrderStates, OrderStatus } from '~/config/types';
import { Action } from '~/components/ActionButton/props';
import head from 'lodash/head';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import {
  AddItemToOrderWithAddOnInput,
  AdminOrderProcess,
  CreateOrderLineInput,
  FulfillmentMethodType,
  Order,
  ProcessOrderInput,
  ProcessOrderResult,
  ProductList,
  ProductListOptions,
  ProductVariant,
} from '~/api';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import useProcessOrder, { useOrder, useProducts } from './hooks';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { AiOutlinePlus } from 'react-icons/ai';
import { AddItemModal } from '../add-item';
import {
  DayFormat,
  DineInPaymentMethodCode,
  FullDateFormat,
  FullDateTimeFormat,
} from '~/config/constant';

// TODO: how much points to earn
// Pending Backend API

export const OrderShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  // ======================= HOOKS
  const { data, isLoading, refetch: refetchOrder } = queryResult;
  const {
    id,
    code,
    state,
    customer,
    orderPlacedAt,
    shippingAddress,
    billingAddress,
    variants,
    dineInMetadata,
    customerSnapshot,
    fulfillments,
    customFields,
    payments = [],
    shippingLines,
    couponCodes,
    promotions,
    orderPlacementMetadata: orderMeta,
  } = data?.data || {};

  const orderFulfillment = head(fulfillments);
  const { id: customerId, firstName = '', lastName = '' } = customer || {};
  const customerName =
    firstName || lastName ? formatFullName(firstName, lastName) : customerSnapshot?.name || '-';
  const { remark, cancellationRemark, giftFee, giftOption, pointAwarded } = customFields || {};
  const extraInfo = orderFulfillment?.additionalProperties;
  const deliveryMethod = customFields?.shippingMethodCode;
  const shippingInfo = head(shippingLines);
  const orderStatus = getOrderStatus(state as OrderStates) as OrderStatus;
  const productIds = uniq(variants?.items?.map((item: ProductVariant) => item.productId));
  const latestPayment = payments?.[payments.length - 1];
  const isPrepared = [OrderStatus.Preparing, OrderStatus.Shipping, OrderStatus.Completed].includes(
    orderStatus
  );
  const isNotCompleted = [OrderStatus.Preparing, OrderStatus.Shipping].includes(orderStatus);

  // ======================= API
  const { data: products, isLoading: loadingProducts } = useProducts<ProductList>({
    variables: {
      options: {
        value: {
          filter: { id: { in: productIds } },
        } as ProductListOptions,
        type: 'ProductListOptions',
      },
    },
    queryOptions: { enabled: !isEmpty(productIds) },
  });

  const {
    data: lines,
    refetch: refetchLines,
    isLoading: loadingLines,
  } = useOrder<Order>({
    id: id!,
    queryOptions: { enabled: !isLoading && !!id },
  });
  const refetchAll = () => {
    refetchOrder();
    refetchLines();
  };

  const { processOrderPayment: processOrder, isLoading: processingOrder } = useProcessOrder();

  // NOTE: custom removeDraftOrderLine with addon
  const { mutate: removeOrderLine } = useCustomMutation({
    mutationOptions: {
      onSettled: (result) => {
        if (!!result?.data?.id) {
          refetchAll();
        }
      },
    },
  });

  const { mutate: addItemToOrder } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data) {
          setOpenAddItem(false);
          refetchAll();
        }
      },
    },
  });

  const { mutate: markAsDelivered } = useCustomMutation({
    mutationOptions: {
      onSettled: (result) => {
        if (!!result?.data?.id) {
          refetchAll();
        }
      },
    },
  });

  // ======================= HOOKS
  const t = useTranslate();
  const lang = useGetLocale()()?.toLowerCase() || 'en';
  const apiUrl = useApiUrl();
  const { downloadInvoice } = useDownload();

  // ======================= STATES
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openFulfillOrder, setOpenFulfillOrder] = useState(false);
  const [openAssignCustomer, setOpenAssignCustomer] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  // NOTE: paymentMethod code
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);

  // ======================= VARIABLES
  const isDineInDraft =
    orderStatus === OrderStatus.Draft && deliveryMethod === FulfillmentMethodType.DineIn;

  // ======================= EVENTS
  // Only applicable for draft dine in order
  const onAddLine = (raw: AddingVariant | VariantItem[]) => {
    const adding = raw as AddingVariant;
    if (!isDineInDraft) {
      // not suppose to happen
      console.warn(`Item is not Dine in & Draft`);
      return;
    }

    const productIds = Object.keys(adding);
    const orderLines: CreateOrderLineInput[] = [];

    for (const productId of productIds) {
      const { variantId, quantity, addon } = adding[productId];

      const line: CreateOrderLineInput = {
        item: { productVariantId: variantId, quantity },
        addons: addon?.map((extra) => ({
          modifierGroup: extra.groupName,
          modifierVariantIds: extra.modifierIds,
        })),
      };

      orderLines.push(line);
    }

    addItemToOrder({
      method: 'post',
      url: apiUrl,
      values: {},
      metaData: {
        fields: ['id'],
        operation: 'addItemToOrderWithAddOn',
        variables: {
          input: {
            value: {
              orderId: id,
              orderLines,
            } as AddItemToOrderWithAddOnInput,
            type: 'AddItemToOrderWithAddOnInput!',
          },
        },
      },
      errorNotification: {
        message: t('order.show.addItemFailed', {}, 'Failed to add item'),
        type: 'error',
      },
      successNotification: {
        message: t('order.show.addItemSuccess', {}, 'Item added'),
        type: 'success',
      },
    });
  };

  const onMarkAsDelivered = () => {
    markAsDelivered({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: [
          {
            operation: 'Fulfillment',
            fields: ['id'],
            fragment: true,
          },
        ],
        operation: 'transitionFulfillmentToState',
        variables: {
          id: {
            type: 'ID!',
            value: head(fulfillments)?.id,
          },
          state: {
            type: 'String!',
            value: 'Delivered',
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('order.notifications.delivered.failed'),
        type: 'error',
      },
      successNotification: {
        message: t('order.notifications.delivered.success'),
        type: 'success',
      },
    });
  };

  const onDeleteLine = (lineId: string) => {
    removeOrderLine({
      method: 'post',
      url: apiUrl,
      values: {},
      metaData: {
        fields: [
          {
            operation: 'Order',
            fields: ['id'] as ResourceField<Order>,
            fragment: true,
          },
        ],
        operation: 'removeDraftOrderLineWithModifier',
        variables: {
          orderId: {
            value: id,
            type: 'ID!',
          },
          orderLineId: {
            value: lineId,
            type: 'ID!',
          },
        },
      },
      errorNotification: {
        message: t('order.show.removeFailed', {}, 'Failed to remove item'),
        type: 'error',
      },
      successNotification: {
        message: t('order.show.removeSuccess', {}, 'Item removed'),
        type: 'success',
      },
    });
  };

  const onArrangePayment = () => {
    if (deliveryMethod === FulfillmentMethodType.DineIn) {
      onProcessOrderPayment(DineInPaymentMethodCode);
    } else {
      setOpenPaymentMethod(true);
    }
  };

  const onProcessOrderPayment = (paymentMethodCode: string) => {
    processOrder({
      t,
      orderId: id!,
      paymentMethodCode,
      mutateOptions: {
        onSuccess: () => {
          setOpenPaymentMethod(false);
          refetchAll();
        },
      },
    });
  };

  // ======================= VIEWS
  const actions: Action[] = getOrderActions({
    order: data?.data,
    t,
    options: {
      onArrangePayment,
      onDownloadInvoice: downloadInvoice,
      onUpdateStatus: () => setOpenUpdate(true),
      onCancel: () => setOpenCancel(true),
    },
  });

  // Order without customer, allow options to link
  if (!customerId) {
    actions.push({
      label: 'Assign Customer',
      name: 'assign-customer',
      onAction: () => setOpenAssignCustomer(true),
    });
  }

  const renderFulfillmentKey = () => {
    if (isEmpty(fulfillments)) return 'order.create.fulfillOrder';

    const orderState = getOrderStatus(state as OrderStates);
    const stateKey = {
      [OrderStatus.Preparing]: 'order.create.markAsShipped',
      [OrderStatus.Shipping]: 'order.create.markAsDelivered',
    };

    return (
      stateKey[orderState as OrderStatus.Preparing | OrderStatus.Shipping] ||
      'order.create.fulfillOrder'
    );
  };

  const fulfilmentRendererProps = (): DetailRendererProps => {
    let rendererProps: DetailRendererProps = { data: {} };
    const { table, adultPax, kidPax } = dineInMetadata || {};
    const { courier, driverName, driverContact } = extraInfo || {};
    const { date: slotDate, timeSlot, isPlacedNow } = orderMeta || {};
    const slotData = isPlacedNow
      ? dateFormatter(orderPlacedAt, FullDateTimeFormat)
      : getOrderFulfillmentSlot(timeSlot, slotDate);

    if (deliveryMethod === FulfillmentMethodType.Pickup) {
      rendererProps = {
        loading: isLoading,
        resource: 'order',
        data: {
          pickupSlot: slotData,
        },
      };
    }

    if (deliveryMethod === FulfillmentMethodType.Delivery) {
      rendererProps = {
        loading: isLoading,
        resource: 'order',
        data: {
          deliverySlot: slotData,
          shippingAddress: shippingAddress ? joinAddress(shippingAddress) : '-',
          courier,
          driver: driverName,
          driverContact,
        },
      };
    }

    if (deliveryMethod === FulfillmentMethodType.DineIn) {
      rendererProps = {
        loading: isLoading,
        resource: 'order',
        data: {
          table,
          adultPax,
          kidPax,
        },
      };
    }

    return rendererProps;
  };

  if (!isLoading && !id) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      {openPaymentMethod && (
        <PaymentMethodModal open setOpen={setOpenPaymentMethod} onConfirm={onProcessOrderPayment} />
      )}
      {openAssignCustomer && id && (
        <OrderAssignCustomer
          open={openAssignCustomer}
          setOpen={setOpenAssignCustomer}
          orderId={id}
          onCompleted={() => refetchOrder()}
        />
      )}
      {openFulfillOrder && (
        <OrderFulfillmentModal
          open={openFulfillOrder}
          setOpen={setOpenFulfillOrder}
          isDineInDraft={isDineInDraft}
          fulfillments={fulfillments}
          loadingProducts={loadingProducts}
          onDeleteLine={onDeleteLine}
          products={products?.data?.items}
          lines={lines?.data.lines?.filter((line) => !line.isAddOn)}
          method={deliveryMethod as FulfillmentMethodType}
          fulfillmentHandlerCode={shippingInfo?.shippingMethod?.fulfillmentHandlerCode}
          onCompleted={() => refetchOrder()}
        />
      )}
      {openAddItem && (
        <AddItemModal
          open={openAddItem}
          setOpen={setOpenAddItem}
          title={t('order.create.addItems', {}, 'Add Items')}
          onAddItem={onAddLine}
        />
      )}
      {openCancel && (
        <OrderCancellationModal
          orderId={id as string}
          lines={lines?.data.lines?.filter((line) => !line.isAddOn)}
          open={openCancel}
          setOpen={setOpenCancel}
          onCompleted={() => refetchOrder()}
        />
      )}
      {openUpdate && id && (
        <OrderStatusUpdate
          open={openUpdate}
          setOpen={setOpenUpdate}
          initialStatus={orderStatus}
          orderDeliveryMethod={deliveryMethod as FulfillmentMethodType}
          orderId={id}
          onCompleted={() => refetchAll()}
        />
      )}
      <ShowPageWrapper
        resourceName={resourceNames.order}
        loading={isLoading}
        title={id ? <span>#{code}</span> : null}
      >
        <section className="space-y-4 xl:grid xl:grid-cols-5 xl:gap-4">
          {/* LEFT */}
          <div className="xl:col-span-2">
            <div className="flex flex-col space-y-4">
              <Card className="z-0">
                <Card.Header className="font-bold" bordered>
                  <section className="flex flex-row items-center justify-between">
                    <h1>{t('common.general', undefined, 'General')}</h1>
                    <ActionButton
                      customTitle={t('common.action', undefined, 'ACTION').toUpperCase()}
                      actions={actions}
                    />
                  </section>
                </Card.Header>
                <Card.Body>
                  <DetailRenderer
                    loading={isLoading}
                    data={{
                      orderDate: orderPlacedAt,
                      customer: customerName,
                      billingAddress: billingAddress ? joinAddress(billingAddress) : '-',
                      status: t(`order.status.${orderStatus.toLowerCase()}`).toUpperCase(),
                      fulfillmentMethod: deliveryMethod,
                      remark,
                      ...(orderStatus === OrderStatus.Cancelled
                        ? { cancelReason: cancellationRemark }
                        : {}),
                      packagingOption: giftOption?.label?.[lang],
                      pointsEarned: pointAwarded,
                      ...(!isEmpty(latestPayment) ? { paymentMethod: latestPayment.method } : {}),
                    }}
                    resource={'order'}
                    render={{
                      orderDate: (date) => <span>{dateFormatter(date, FullDateTimeFormat)}</span>,
                      status: (status: OrderStatus) => (
                        <Tag className={getOrderStatusTag(orderStatus)}>
                          {status?.toUpperCase()}
                        </Tag>
                      ),
                      fulfillmentMethod: (method) => <span>{toCamelCaseWord(method)}</span>,
                      paymentMethod: (methodCode) => <span>{toCamelCaseWord(methodCode)}</span>,
                    }}
                  />
                </Card.Body>
              </Card>

              {isPrepared && (
                <Card>
                  <Card.Header
                    className="font-bold flex flex-row justify-between items-center"
                    bordered
                  >
                    {isLoading ? (
                      <Skeleton heightClass="h-5" width="narrow" />
                    ) : (
                      <h1>
                        {deliveryMethod
                          ? t(`order.create.fulfillmentMethod.${camelCase(deliveryMethod)}`)
                          : ''}
                      </h1>
                    )}
                    {isNotCompleted && (
                      <Button
                        onClick={
                          getOrderStatus(state as OrderStates) === OrderStatus.Shipping
                            ? onMarkAsDelivered
                            : () => setOpenFulfillOrder(true)
                        }
                        size="sm"
                        className="text-md whitespace-nowrap border-primary-500"
                      >
                        <span className="text-primary-500">{t(renderFulfillmentKey())}</span>
                      </Button>
                    )}
                  </Card.Header>
                  <Card.Body>
                    <DetailRenderer {...fulfilmentRendererProps()} />
                  </Card.Body>
                </Card>
              )}
            </div>
          </div>
          <div className="xl:col-span-3">
            <Card className="z-0">
              <Card.Header className="font-bold" bordered>
                <section className="flex flex-row items-center justify-between">
                  <h1>{t('order.name', undefined, 'Order')}</h1>
                  {isDineInDraft && (
                    <Button
                      size="sm"
                      className="text-md whitespace-nowrap border-primary-500"
                      onClick={() => setOpenAddItem(true)}
                    >
                      <div className="flex flex-row items-center space-x-2">
                        <AiOutlinePlus className="!text-primary-500" size={20} />
                        <span className="text-primary-500">
                          {t('actions.addItems', undefined, 'Add Item')}
                        </span>
                      </div>
                    </Button>
                  )}
                </section>
              </Card.Header>
              <Card.Body>
                {!loadingProducts && !isEmpty(products?.data?.items) && (
                  <OrderLineProduct
                    canModify={isDineInDraft}
                    onDelete={onDeleteLine}
                    lineProducts={products?.data?.items}
                    lines={lines?.data.lines?.filter((line) => !line.isAddOn)}
                  />
                )}
                <OrderPriceSummary
                  order={data?.data}
                  lines={lines?.data?.lines}
                  loading={isLoading || loadingLines}
                />
              </Card.Body>
            </Card>
          </div>
        </section>
      </ShowPageWrapper>
    </main>
  );
};
