import { useNavigation, useNotification, useTranslate, useUpdate } from '@refinedev/core';
import React, { useEffect, useState } from 'react';
import {
  ConflictCodeState,
  ConflictPostCodeModal,
  DeliveryFeeType,
  Loading,
  RadioGroup,
  ShippingMethodForm,
  ShowPageWrapper,
  SomethingWentWrong,
  TierRule,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';
import { useForm } from '@refinedev/react-hook-form';
import {
  Button,
  Card,
  Dialog,
  FieldGroup,
  IconButton,
  Label,
  NumberInput,
  Tag,
} from '@scalingworks/react-admin-ui';
import { Controller } from 'react-hook-form';
import { FormNumberField } from '@scalingworks/refine-react-admin/src/modules/form/components/form-number-field';
import { DefaultCurrency } from '~/config/constant';
import { AiOutlinePlus } from 'react-icons/ai';
import { Form } from '@scalingworks/refine-react-admin';
import {
  AssignShippingTierInput,
  ConfigurableOperation,
  CreateShippingTierInput,
  LanguageCode,
  UpdateShippingMethodInput,
  getSdk,
} from '~/api';
import { Post_Code_Arg } from '../setting-resource';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import difference from 'lodash/difference';
import remove from 'lodash/remove';
import { GQLClient } from '~/config/gql-client';
import { HiOutlineTrash } from 'react-icons/hi';

const Checker_Code = import.meta.env.VITE_POSTAL_CODE_CHECKER;
const Flat_Calc = import.meta.env.VITE_FLAT_RATE_CALC;
const Tier_Calc = import.meta.env.VITE_TIER_CALC;

export const ShippingMethodShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;
  const { isLoading, data } = queryResult;

  if (isLoading) return <Loading />;

  const shippingMethod = data?.data;
  const {
    id: shippingMethodId,
    name,
    code,
    checker,
    calculator,
    customFields,
  } = shippingMethod || {};
  const { tierRange } = customFields || {};

  const initFeeType = calculator?.code === Flat_Calc ? DeliveryFeeType.FLAT : DeliveryFeeType.TIER;
  const initFlatFee = +(calculator?.args.find((arg) => arg.name === 'rate')?.value || 0) / 100;
  const initPostCodes = JSON.parse(
    checker?.args?.find((arg) => arg.name === Post_Code_Arg)?.value || '[]'
  ) as string[];

  // ======================= HOOKS
  const client = GQLClient.getInstance();
  const navigateTo = useNavigation();
  const notif = useNotification();
  const t = useTranslate();
  const form = useForm<any>({
    defaultValues: {
      feeType: initFeeType,
      flat: {
        value: initFlatFee.toLocaleString(),
      },
      tier: {
        baseFee: initFlatFee,
        rules: tierRange?.map((tier) => ({ fee: tier.value, min: tier.minQuantity })) as TierRule[],
      },
    },
  });

  // ======================= API
  const { mutate: update, isLoading: updating } = useUpdate({});

  // ======================= STATES
  const [conflictCodeState, setConflictCodeState] = useState<ConflictCodeState>({
    open: false,
    conflictCodes: [],
  });

  // ======================= VARIABLES
  const feeType = form.watch('feeType') as DeliveryFeeType;
  const tierRules = (form.watch('tier.rules') as TierRule[]) || [];

  // ======================= EVENTS
  const notifError = (message: string) => {
    notif?.open?.({ message, type: 'error' });
  };

  const haveConflictCode = async (postalCode: string[]): Promise<boolean> => {
    // Check conflicting postcode
    const conflict = await getSdk(client).CheckConflictingPostcodes({ postcodes: postalCode });
    const conflictCodes = conflict.checkConflictingPostcodes;
    if (!isEmpty(conflictCodes)) {
      notif?.open?.({
        message: t('shippingMethods.warnings.conflict'),
        type: 'error',
      });
      setConflictCodeState({ open: true, conflictCodes });
      return true;
    }
    return false;
  };

  const onUpdate = async (data: any) => {
    const { name, postalCode: postalCodeRaw = [], feeType, flat, tier } = data;
    const { value = 0 } = flat || {};
    const { baseFee = 0 } = tier || {};
    const rawRules = tier?.rules || ([] as TierRule[]);
    const rules = orderBy(rawRules, ['min'], ['asc']) as TierRule[];

    const isFlat = feeType === DeliveryFeeType.FLAT;
    const isTier = feeType === DeliveryFeeType.TIER;

    const postalCode = (postalCodeRaw as number[]).map((code) => code.toString());
    const initCalcArgs = shippingMethod?.calculator?.args || [];
    let updatedCalcArgs = initCalcArgs;

    const isConflict = await haveConflictCode(difference(postalCode, initPostCodes));
    if (isConflict) return;

    if (isFlat) {
      updatedCalcArgs = [
        { name: 'rate', value: (+value * 100).toString() },
        // Fixed
        { name: 'includesTax', value: 'exclude' },
        { name: 'taxRate', value: '0' },
      ];
    }

    if (isTier) {
      let tierError: null | string = null;
      // No Rules
      if (isEmpty(rules)) {
        notifError(t('shippingMethods.warnings.noRule'));
        return;
      }

      // Check rules's min max conflict
      for (let i = 1; i < rules.length; i++) {
        const currentMin = rules[i].min;
        const prevMin = rules[i - 1].min;

        if (currentMin <= prevMin + 1) {
          tierError = t('shippingMethods.warnings.invalidFeeConfig');
          break;
        }
      }
      if (tierError) {
        notifError(tierError);
        return;
      }

      const createTiersInput = rules.map((rule, index) => {
        const nextRule = rules[index + 1];
        return {
          minQuantity: rule.min,
          maxQuantity: nextRule ? nextRule.min - 1 : 9999999,
          value: rule.fee,
        };
      }) as AssignShippingTierInput[];

      try {
        const tiers = await getSdk(client).AssignShippingTierToShippingMethod({
          shippingMethodId: shippingMethodId!,
          input: createTiersInput,
        });

        const tierIds = JSON.stringify(
          tiers.assignShippingTierToShippingMethod.map((tier) => tier.id)
        );

        updatedCalcArgs = [
          { name: 'modifiers', value: tierIds },
          { name: 'rate', value: (+baseFee * 100).toString() },
          // Fixed
          { name: 'modifierType', value: 'EXACT' },
          { name: 'calculationType', value: 'totalPrice' },
          { name: 'includesTax', value: 'exclude' },
          { name: 'taxRate', value: '0' },
        ];
      } catch {
        notifError(t('shippingMethods.warnings.configTierFailed', 'Failed to configure tier fee'));
      }
    }

    update(
      {
        resource: resourceNames.shippingMethod,
        id: shippingMethodId!,
        values: {
          translations: [{ name, languageCode: LanguageCode.En }],
          checker: {
            code: Checker_Code,
            arguments: [{ name: Post_Code_Arg, value: JSON.stringify(postalCode) }],
          },
          calculator: { code: isFlat ? Flat_Calc : Tier_Calc, arguments: updatedCalcArgs },
        } as UpdateShippingMethodInput,
        successNotification: {
          message: t('shippingMethods.update.success', 'Delivery zone updated'),
          type: 'success',
        },
        errorNotification: {
          message: t('shippingMethods.update.failed', 'Failed to update delivery zone'),
          type: 'error',
        },
      },
      {
        onSuccess: () => navigateTo.list(resourceNames.shippingMethod),
      }
    );
  };

  const onAddRule = () => {
    const previousTier = tierRules[tierRules.length - 1];
    // NOTE: +2 because min-max the max is (min + 1)
    // Hence +2 for next tier's min

    const previousMin = previousTier?.min;
    const currentMin = previousMin !== undefined ? previousMin + 2 : 0;
    const newRule = [...tierRules, { fee: 0, min: currentMin }];
    form.setValue('tier.rules', newRule);
  };

  const onRemoveRule = (indexToRemove: number) => {
    const rules = form.getValues('tier.rules') as TierRule[];
    const updatedRules = remove(rules, (_, idx) => idx !== indexToRemove);
    form.setValue('tier.rules', updatedRules);
  };

  const onChangeRuleMin = (value: number, index: number) => {
    const clonedRules = [...tierRules];

    clonedRules[index].min = value;
    form.setValue('tier.rules', clonedRules);
  };

  const onChangeRuleFee = (value: number, index: number) => {
    const clonedRules = [...tierRules];

    clonedRules[index].fee = value;
    form.setValue('tier.rules', clonedRules);
  };

  // ======================= VIEWS
  if (!isLoading && !shippingMethodId) return <SomethingWentWrong />;
  return (
    <section className="overflow-y-scroll">
      {conflictCodeState.open && (
        <ConflictPostCodeModal
          code={conflictCodeState?.conflictCodes}
          onOpenChange={(open) => setConflictCodeState((prev) => ({ ...prev, open }))}
          onClose={() => setConflictCodeState({ open: false, conflictCodes: [] })}
          onConfirm={() => {
            // remove the conflicting codes
            const codes = form.getValues('postalCode') as string[];
            const cleaned = difference(codes, conflictCodeState.conflictCodes);
            form.setValue('postalCode', cleaned);
            setConflictCodeState({ open: false, conflictCodes: [] });
          }}
        />
      )}
      <ShowPageWrapper
        resourceName={resourceNames.shippingMethod}
        title={name}
        extra={
          <Button
            loading={updating}
            onClick={form.handleSubmit(onUpdate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.update', {}, 'Update')}
          </Button>
        }
      >
        <section className="flex flex-col space-y-8">
          <ShippingMethodForm form={form} actionType="update" initialValues={shippingMethod} />

          <Form form={form}>
            <Card>
              <Card.Header bordered>
                <section className="font-bold">
                  {t('shippingMethods.name.deliveryFee', 'Delivery Fee')}
                </section>
              </Card.Header>
              <Card.Body>
                <div className="flex flex-col space-y-4 xl:w-4/5">
                  <Controller
                    name="feeType"
                    control={form.control}
                    defaultValue={initFeeType || DeliveryFeeType.FLAT}
                    render={({ field: { name, onChange, value } }) => (
                      <RadioGroup
                        name={name}
                        onValueChange={onChange}
                        value={value}
                        isField
                        label={t('shippingMethods.form.type', 'Type')}
                        options={[DeliveryFeeType.FLAT, DeliveryFeeType.TIER].map((value) => ({
                          label: t(`shippingMethods.type.${value.toLowerCase()}`, value),
                          value,
                        }))}
                        defaultValue={initFeeType || DeliveryFeeType.FLAT}
                      />
                    )}
                  />

                  {feeType === DeliveryFeeType.FLAT && (
                    <FormNumberField
                      name="flat.value"
                      label={t('shippingMethods.form.deliveryFee', 'Delivery Fee')}
                      placeholder={t('shippingMethods.placeholder.deliveryFee')}
                      suffix={DefaultCurrency}
                      defaultValue={initFlatFee.toLocaleString()}
                    />
                  )}

                  {feeType === DeliveryFeeType.TIER && (
                    <FieldGroup className="w-full">
                      <Label>{t('shippingMethods.form.deliveryFee', 'Delivery Fee')}</Label>
                      <section className="flex flex-col space-y-4 w-full">
                        <div className="flex flex-row items-center space-x-4">
                          <span className="">{t('shippingMethods.form.baseFee', 'Base Fee')}</span>
                          <Controller
                            name="tier.baseFee"
                            control={form.control}
                            render={({ field: { name, onChange, value } }) => (
                              <NumberInput
                                className="flex-1"
                                required
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeholder={t('shippingMethods.placeholder.baseFee')}
                                suffix={DefaultCurrency}
                              />
                            )}
                          />
                        </div>

                        {tierRules.map((rule, index) => (
                          <div
                            key={index}
                            className="rounded-lg bg-smoke-100 p-2 flex flex-col space-y-2 2xl:space-y-0 2xl:flex-row 2xl:items-center 2xl:space-x-2"
                          >
                            <div className="flex flex-row items-center space-x-2 flex-1">
                              <span className="w-1/2">
                                {t(
                                  'shippingMethods.form.moreThan',
                                  'When order subtotal is more than'
                                )}
                              </span>
                              <div className="w-1/2">
                                <NumberInput
                                  required
                                  value={rule.min.toLocaleString()}
                                  onValue={(value) => onChangeRuleMin(+value, index)}
                                  suffix={DefaultCurrency}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row items-center space-x-2 flex-1">
                              <span className="w-1/2">
                                {t('shippingMethods.form.deliveryFee', 'Delivery Fee')}
                              </span>
                              <div className="w-1/2">
                                <NumberInput
                                  required
                                  value={rule.fee.toLocaleString()}
                                  onValue={(value) => onChangeRuleFee(+value, index)}
                                  suffix={DefaultCurrency}
                                />
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-end">
                              <IconButton onClick={() => onRemoveRule(index)}>
                                <HiOutlineTrash size={25} className="!text-error-300" />
                              </IconButton>
                            </div>
                          </div>
                        ))}

                        <Button
                          onClick={onAddRule}
                          className="flex flex-row items-center justify-center space-x-2"
                        >
                          <AiOutlinePlus className="!text-primary-500" />
                          <span className="text-primary-500">
                            {t('actions.addRule', 'Add Rule')}
                          </span>
                        </Button>
                      </section>
                    </FieldGroup>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Form>
        </section>
      </ShowPageWrapper>
    </section>
  );
};
