import { DiscountType } from '~/api';

/**
 * @description Converting 100 -> 1
 * @param amount Raw amount from backend
 * @toLocale @default true
 * @serialize @default false
 */
export const parsePrice = (amount: number, toLocale = true, serialize = false): number | string => {
  const amt = serialize ? amount * 100 : amount / 100;

  if (!toLocale) return amt;
  return amt.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * @description
 * help to calc discounted price
 * based on backend DiscountType
 * @param price original price
 * @param type DiscountType from ~api
 * @param value number
 */
export const getDiscountPrice = (price: number, type: DiscountType, value: number): number => {
  switch (type) {
    case DiscountType.Fixed: {
      return Math.max(price - (value * 100), 0);
    }
    case DiscountType.Percentage: {
      return Math.max(price * (1 - value / 100), 0);
    }
  }
};
