import { createResourceListingPage } from '@scalingworks/refine-react-admin';
import React, { useRef, useState } from 'react';
import { HideShowProductReviewInput, ProductReview, ReviewStateEnum } from '~/api';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';
import { ActionButton, ActionButtonRefProps, Loading, TriggerConfirmModal } from '~/components';
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import { Tag } from '@scalingworks/react-admin-ui';

export const ProductReviews: React.FC<Props> = (props) => {
  const { loading, productId } = props;

  const ReviewStatusColor: Record<ReviewStateEnum, string> = {
    [ReviewStateEnum.Created]: 'text-success-500 bg-success-100 border-success-500',
    [ReviewStateEnum.Updated]: 'text-success-500 bg-success-100 border-success-500',
    [ReviewStateEnum.PendingModeration]: 'text-success-500 bg-success-100 border-success-500',
    [ReviewStateEnum.Hidden]: 'text-gray-500 bg-gray-100 border-gray-500',
  };

  // ==================== HOOKS
  const listing = createResourceListingPage<ProductReview>({
    title: '',
    showTitle: false,
    customHelmet: '',
    resourceName: resourceNames.productReviews,
    fields: [
      'id',
      'createdAt',
      'updatedAt',
      'rating',
      'reviewerNameIsPublic',
      'state',
      'orderId',
      'customerName',
      'remark',
      'customerId',
      {
        customer: ['firstName', 'lastName'],
      },
    ],
    allowCreate: false,
    allowSearch: true,
    searchConfig: { placeholder: (t) => 'Search by Ratings' },
    filterConfig: { alwaysExpanded: true },
    defaultPageSize: 10,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    tabs: {
      options: [
        // @ts-ignore
        {
          label: () => 'All',
          filterValue: { field: 'productId', operator: 'eq', value: productId },
        },
        {
          filterValue: [
            {
              field: 'state',
              operator: 'in',
              value: [
                ReviewStateEnum.Created,
                ReviewStateEnum.PendingModeration,
                ReviewStateEnum.Updated,
              ],
            },
            { field: 'productId', operator: 'eq', value: productId },
          ],
          label: () => 'Shown',
        },
        {
          filterValue: [
            { field: 'state', operator: 'eq', value: ReviewStateEnum.Hidden },
            { field: 'productId', operator: 'eq', value: productId },
          ],
          label: () => 'Hidden',
        },
      ],
    },
    columns: ({ LinkToDetails, invokeDelete, refetchData }) => [
      {
        id: 'customerName',
        header: 'Customer',
        cell: (data) => {
          const { customerName, reviewerNameIsPublic, customer } = data.row.original;

          return (
            <div>
              {customerName ? (
                <span>
                  {reviewerNameIsPublic
                    ? `${customer?.firstName} ${customer?.lastName}`
                    : customerName}
                </span>
              ) : (
                <span className="font-semibold text-error-300">Deleted User</span>
              )}
            </div>
          );
        },
      },
      {
        id: 'rating',
        header: 'Rating',
        cell: (data) => {
          const { rating } = data.row.original;
          return <span>{rating.toFixed(1)}</span>;
        },
      },
      {
        id: 'state',
        header: 'Status',
        cell: (data) => {
          const { state } = data.row.original;
          return (
            <Tag className={ReviewStatusColor[state as ReviewStateEnum]}>
              {(state === ReviewStateEnum.Hidden ? state : 'Shown').toUpperCase()}
            </Tag>
          );
        },
      },
      {
        id: 'remark',
        header: 'Remark',
        cell: (data) => {
          const { remark } = data.row.original;
          return <span>{remark ?? '-'}</span>;
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const { id, state } = data?.row?.original;
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
          const t = useTranslate();
          const apiUrl = useApiUrl();

          const { mutate } = useCustomMutation({
            mutationOptions: {
              onSettled: () => refetchData(),
            },
          });

          const onShowHideReview = () => {
            mutate({
              url: apiUrl,
              method: 'post',
              metaData: {
                fields: ['id'],
                operation: 'hideShowProductReview',
                variables: {
                  input: {
                    type: 'HideShowProductReviewInput!',
                    value: {
                      id,
                      shouldHide: state !== ReviewStateEnum.Hidden,
                    } as HideShowProductReviewInput,
                  },
                },
              },
              values: {},
              errorNotification: {
                message: t('product.details.update.failed', {}, 'Failed to update review'),
                type: 'error',
              },
              successNotification: {
                message: t('product.details.update.success', {}, 'Review updated successfully'),
                type: 'success',
              },
            });
          };

          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: `${state === ReviewStateEnum.Hidden ? 'Show' : 'Hide'} Review`,
                  name: 'showHideReview',
                  onAction: () => onShowHideReview(),
                },
                {
                  label: 'Delete',
                  name: 'delete',
                  onAction: () => invokeDelete({ id }),
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          visible={showDeleteConfirmation}
                          onOpenChange={(val) => {
                            const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                            setShowDeleteConfirmation(val);
                            actionButtonSetOpen && actionButtonSetOpen(val);
                          }}
                          onPressConfirm={onAction}
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
  });
  // ==================== VIEWS
  if (loading) return <Loading />;
  if (!productId) return null;
  return listing();
};
