import { useTranslate } from '@refinedev/core';
import { Tag } from '@scalingworks/react-admin-ui';

export const renderStatusTag = (isActive: boolean, resource: string) => {
  const color = isActive ? 'success' : 'gray';
  const t = useTranslate();

  return (
    <Tag className={`rounded-lg  text-${color}-500 bg-${color}-100 border-${color}-500`}>
      {t(`${resource}.status.${isActive ? 'active' : 'inactive'}`).toUpperCase()}
    </Tag>
  );
};

export const renderTextWithAffixes = ({
  text,
  affixText,
  position,
}: {
  text: string;
  affixText?: string;
  position?: 'left' | 'right';
}) =>
  `${(position === 'left' && affixText) || ''}${text || ''}${
    (position === 'right' && affixText) || ''
  }`.trim();

export const renderTextWithPrefix = ({
  text,
  prefixText,
  prefixPosition = 'left',
}: {
  text: string;
  prefixText: string;
  prefixPosition?: 'left' | 'right';
}) => {
  const renderPrefixText = (
    <p
      style={{
        fontWeight: 'normal',
        fontSize: 10,
        marginBottom: 1,
        marginRight: prefixPosition === 'left' ? 5 : 0,
        marginLeft: prefixPosition === 'right' ? 5 : 0,
      }}
    >
      {prefixText}
    </p>
  );

  return (
    <p
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
    >
      {prefixPosition === 'left' && renderPrefixText}
      {`${text || '-'}`.trim()}
      {prefixPosition === 'right' && renderPrefixText}
    </p>
  );
};
