import React, { useState } from 'react';
import {
  useCreate,
  useNavigation,
  useNotification,
  useOne,
  useTranslate,
  useUpdate,
} from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button } from '@scalingworks/react-admin-ui';

import { BannerForm, Loading, ShowPageWrapper, SomethingWentWrong } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { Asset, Banner, CreateBannerInput, UpdateBannerInput, getSdk } from '~/api';
import { GQLClient } from '~/config/gql-client';
import { useParams } from 'react-router-dom';
import { bannerFields } from '../banner-resource';

export const BannerEditPage: React.FC = () => {
  // ========================= HOOKS
  const t = useTranslate();
  const form = useForm();
  const navigateTo = useNavigation();
  const notif = useNotification();
  const { id: bannerId = '' } = useParams();

  // ========================= STATES
  const [handlingAsset, setHandlingAsset] = useState(false);

  // ========================= API
  const client = GQLClient.getInstance();
  const { mutate: updateBanner, isLoading: updating } = useUpdate();
  const { data: bannerData, isLoading: loadingBanner } = useOne<Banner>({
    id: bannerId,
    resource: resourceNames.banner,
    metaData: { fields: bannerFields },
  });

  // ========================= EVENTS
  const onUpdate = async (data: any) => {
    const { name, period, type, description, _url, rewardId, productId, image } = data;
    const startDate = new Date(period?.from).toISOString();
    const endDate = period?.to ? new Date(period.to).toISOString() : undefined;

    const shouldCreateAsset = !!image?.[0] && typeof image?.[0] !== 'string';
    let assetsData: Asset[] = [];
    // Note original image url, is a new File instead
    if (shouldCreateAsset) {
      setHandlingAsset(true);
      const assetRes = await getSdk(client).createAssets({ input: [{ file: image?.[0] }] });
      assetsData = assetRes?.createAssets as Asset[];

      if (!assetsData?.[0]?.id) {
        console.warn(`[Failed to get asset]`, assetsData);
        notif?.open?.({
          message: t('common.somethingWrong', 'Something went wrong. Please try again'),
          type: 'error',
        });
        setHandlingAsset(false);
        return;
      }
    }

    updateBanner(
      {
        id: bannerId,
        resource: resourceNames.banner,
        values: {
          name,
          assetId: assetsData?.[0]?.id || undefined,
          startDate,
          endDate,
          type,
          description,
          url: _url,
          rewardId,
          productIds: productId ? [productId] : undefined,
        } as UpdateBannerInput,
        errorNotification: {
          message: t('banner.update.failed', {}, 'Failed to update banner'),
          type: 'error',
        },
        successNotification: {
          message: t('banner.update.success', {}, 'Banner updated successfully'),
          type: 'success',
        },
      },
      {
        onSuccess: (data) => {
          navigateTo.list(resourceNames.banner);
        },
        onSettled: () => setHandlingAsset(false),
      }
    );
  };

  // ========================= VIEWS
  if (!bannerId) return <SomethingWentWrong />;
  if (loadingBanner) return <Loading />;
  return (
    <div className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.banner}
        title={t('banners.edit.name', {}, 'Edit Banner')}
        extra={
          <Button
            loading={updating || handlingAsset}
            onClick={form.handleSubmit(onUpdate)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.update', {}, 'Update')}
          </Button>
        }
      >
        <BannerForm form={form} actionType="update" initialValues={bannerData?.data} />
      </ShowPageWrapper>
    </div>
  );
};
