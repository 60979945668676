import React from 'react';
import { Props } from './props';
import startCase from 'lodash/startCase';
import isString from 'lodash/isString';
import { Skeleton, Table } from '@scalingworks/react-admin-ui';
import { useTranslate } from '@refinedev/core';

export const DetailRenderer: React.FC<Props> = (props) => {
  const { data, render, title, loading, resource, ignoreTranslations = false } = props;

  // ====================== HOOKS
  const t = useTranslate();

  // ====================== VIEWS
  const renderTitle = () => {
    if (isString(title)) {
      return (
        <section className="p-4">
          <h1 className="font-semibold">{title}</h1>
        </section>
      );
    }

    return title;
  };
  return (
    <Table borderlessRow compact>
      {renderTitle()}
      <Table.Tbody>
        <div>
          {Object.entries(data).map(([key, value]) => {
            const RenderComponent = render && render[key];
            return (
              <Table.Tr className="flex items-center" key={key}>
                <Table.Td>
                  <p className="text-smoke-600">
                    {ignoreTranslations ? key : t(`${resource}.form.${key}`)}:
                  </p>
                </Table.Td>
                <Table.Td>
                  {loading ? (
                    <Skeleton heightClass="h-5" width="medium" />
                  ) : RenderComponent ? (
                    RenderComponent(value)
                  ) : (
                    String(value || '-')
                  )}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </div>
      </Table.Tbody>
    </Table>
  );
};

export type DetailRendererProps = Props;
