import camelCase from 'lodash/camelCase';
import { Button, Checkbox, Dialog, Label, TextField } from '@scalingworks/react-admin-ui';
import { useEffect, useState } from 'react';
import { TimeRangePicker } from '../TimeRangePicker';
import { AiOutlinePlus } from 'react-icons/ai';
import { dateTimeString } from '~/resources/helpers';
import { Props } from './props';
import dayjs from 'dayjs';
import { TimeFormat2Digit } from '~/config/constant';
import { useTranslate } from '@refinedev/core';

export const OperationTimeRange: React.FC<Props> = (props) => {
  const { inputProps } = props;
  const {
    input: { item, register, setValue, watch, setError, formState, clearErrors },
  } = inputProps;

  const { title, name, defaultValue } = item;
  const { errors } = formState || {};
  const formName = name || title;
  const [isExpanded, setIsExpanded] = useState(defaultValue || false);
  const [is24Hour, setIs24Hour] = useState(false);
  const [open, setOpen] = useState(false);
  const [arraySize, setArraySize] = useState<number>(defaultValue?.length || 1);
  const emptyArray = Array.from({ length: arraySize });
  const t = useTranslate();

  useEffect(() => {
    setIsExpanded(!!defaultValue);
    if (
      defaultValue &&
      defaultValue?.length === 1 &&
      dayjs(dateTimeString(defaultValue?.[0]?.openingTime)).format(TimeFormat2Digit) ===
        dayjs()?.startOf('day').format(TimeFormat2Digit) &&
      dayjs(dateTimeString(defaultValue?.[0]?.closingTime)).format(TimeFormat2Digit) ===
        dayjs()?.endOf('day').format(TimeFormat2Digit)
    ) {
      setIs24Hour(true);
    }
  }, [defaultValue]);

  const onPressCheckbox = (e: boolean) => {
    emptyArray?.map((item, i) => {
      !e && setValue(camelCase(`${formName}${i}`), '');
    });
    setIsExpanded(e);
  };
  const onPressCheckbox24Hrs = (e: boolean) => {
    setOpen(e);
    is24Hour && !e && setIs24Hour(false);
  };
  const onPress24Hrs = () => {
    emptyArray?.map((e, i) => {
      clearErrors?.(camelCase(`${formName}${i}`));
      setValue(camelCase(`${formName}${i}`), '');
    });
    setOpen(false);
    setIs24Hour(true);
    setArraySize(1);
    setValue(camelCase(`${formName}0`), [
      dayjs()?.startOf('day').toISOString(),
      dayjs()?.endOf('day').toISOString(),
    ]);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div>
        <div
          style={{
            marginLeft: -3,
            marginBottom: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Checkbox className="mr-3" onCheckedChange={onPressCheckbox} checked={isExpanded} />
          {t(`outlet.openingDay.${formName.toLowerCase()}`)}
        </div>

        {isExpanded ? (
          <>
            {emptyArray?.map((e, i) => {
              const inputPropsTimeRange = {
                input: {
                  item: {
                    ...item,
                    name: `${formName}${i}`,
                    defaultValue: [
                      defaultValue?.[i]?.openingTime
                        ? dateTimeString(defaultValue?.[i]?.openingTime)
                        : undefined,
                      defaultValue?.[i]?.closingTime
                        ? dateTimeString(defaultValue?.[i]?.closingTime)
                        : undefined,
                    ],
                  },
                  register,
                  setValue,
                  watch,
                },
              };
              const previousSlot = watch(camelCase(`${formName}${i - 1}`));
              const inputCurrentSlot = camelCase(`${formName}${i}`);
              const currentSlot = watch(inputCurrentSlot);
              const isErrorSecondSlot =
                i > 0 && dayjs(currentSlot?.[0]).isBefore(dayjs(previousSlot?.[1]));
              const isErrorSameSlot =
                dayjs(currentSlot?.[0]).isAfter(dayjs(currentSlot?.[1])) &&
                // closing time !== 12:00 AM
                dayjs(currentSlot?.[1])?.format(TimeFormat2Digit) !==
                  dayjs()?.startOf('day')?.format(TimeFormat2Digit);
              if ((isErrorSecondSlot || isErrorSameSlot) && !errors?.[inputCurrentSlot])
                setError?.(inputCurrentSlot, {
                  message: t(`outlet.warnings.${isErrorSameSlot ? 'time' : 'nextPrevious'}`),
                });
              else if (errors?.[inputCurrentSlot] && !isErrorSameSlot && !isErrorSecondSlot) {
                clearErrors?.(inputCurrentSlot);
              }
              const error = errors?.[camelCase(`${formName}${i}`)];
              return (
                <div className="ml-5 mb-5">
                  {!is24Hour && (
                    <TimeRangePicker inputProps={inputPropsTimeRange} isRow disabled={is24Hour} />
                  )}
                  {error && (
                    <Label className="text-red-400 w-full">{error?.message?.toString()}</Label>
                  )}
                </div>
              );
            })}
            <div
              style={{
                marginLeft: 20,
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Checkbox
                className="mr-3"
                onCheckedChange={onPressCheckbox24Hrs}
                checked={is24Hour}
              />
              <Label>{t('outlet.operation')}</Label>
            </div>
            {!is24Hour && (
              <button onClick={() => setArraySize((prev) => prev + 1)}>
                <p
                  style={{
                    marginLeft: 20,
                    color: '#2970FF',
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    fontWeight: '700',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <AiOutlinePlus className="mr-1" size={16} />
                  {t('actions.addTimeslots').toUpperCase()}
                </p>
              </button>
            )}
          </>
        ) : null}
      </div>
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="z-20 absolute h-full w-full" />
          <Dialog.Content className="fixed top-60 z-20 self-center">
            <Dialog.Title className="w-64">
              {t('outlet.warnings.full', {
                day: t(`outlet.openingDay.${formName.toLowerCase()}`),
              })}
            </Dialog.Title>
            <div className="flex flex-row justify-end space-x-4 mt-3">
              <Button
                variant="default"
                className="text-error-400 border-error-400"
                onClick={() => setOpen(false)}
              >
                {t('actions.cancel')}
              </Button>
              <Button variant="solid" className={'bg-success-400'} onClick={onPress24Hrs}>
                {t('actions.confirm')}
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
