import React, { useEffect, useRef, useState } from 'react';
import { useForm } from '@refinedev/react-hook-form';
import {
  useApiUrl,
  useCreate,
  useCustom,
  useCustomMutation,
  useDelete,
  useNavigation,
  useTranslate,
} from '@refinedev/core';

import {
  FormBuilder,
  Loading,
  ModalWrapper,
  ModifierGroupForm,
  MultiProductSelection,
  ShowPageWrapper,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { LanguageCode, Product, ProductList, UpdateFacetValueInput } from '~/api';

import isEmpty from 'lodash/isEmpty';
import { AddItemModal } from '~/resources/order/add-item';
import { Button, Card, IconButton, ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { ResourceField, createResourceListingPage } from '@scalingworks/refine-react-admin';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { Props } from './props';
import { FaSitemap } from 'react-icons/fa6';
import { formBuilder } from '~/config/ProductResource';
import { FormInputs } from '../../products/product-resource';
import { parsePrice } from '~/resources/helpers';

export const ModifierGroupShowPage: React.FC<Props> = (props) => {
  const { queryResult } = props;

  const { data, isLoading, refetch: refetchModifierGroup } = queryResult;
  const { id: modifierGroupId, name: modifierGroupName } = data?.data || {};

  // ======================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const form = useForm();
  const navigateTo = useNavigation();

  // ======================= API
  const { mutate: updateModifierGroup, isLoading: updating } = useCustomMutation();
  const {
    data: modifiers,
    isLoading: loadingModifiers,
    refetch: refetchModifiers,
  } = useCustom<ProductList>({
    method: 'get',
    url: apiUrl,
    meta: {
      fields: [
        {
          items: [
            'id',
            'name',
            { assets: ['id', 'source'] },
            { variants: ['price', 'stockOnHand'] },
          ],
        },
      ] as ResourceField<ProductList>[],
      operation: 'getProductsByModifierGroup',
      variables: {
        id: {
          value: modifierGroupId,
          type: 'ID!',
        },
      },
    },
  });

  // const { data: products, isLoading: loadingProducts } = useCustom<ProductList>({
  //   method: 'get',
  //   url: apiUrl,
  //   meta: {
  //     fields: [{ items: ['id', 'name'] }] as ResourceField<ProductList>[],
  //     operation: 'getMainProductsByModifierId',
  //     variables: {
  //       id: {
  //         value: modifierGroupId,
  //         type: 'ID!',
  //       },
  //     },
  //   },
  // });

  const { mutate: createModifierProd, isLoading: creatingModifier } = useCreate<Product>();
  const { mutate: deleteModifierProd, isLoading: deletingModifier } = useDelete();

  // ======================= STATES
  const [openProduct, setOpenProduct] = useState(false);
  const [openModifier, setOpenModifier] = useState(false);
  const [deleteModifierId, setDeleteModifierId] = useState('');

  // ======================= VARIABLES
  const noModifiers = isEmpty(modifiers?.data?.items);

  // ======================= EVENTS
  const onUpdateInfo = (data: any) => {
    const { name } = data;

    updateModifierGroup(
      {
        method: 'post',
        url: apiUrl,
        values: {},
        meta: {
          operation: 'updateFacetValues',
          fields: ['id'],
          variables: {
            input: {
              value: [
                {
                  id: modifierGroupId,
                  translations: [
                    {
                      languageCode: LanguageCode.En,
                      name,
                    },
                  ],
                },
              ] as UpdateFacetValueInput[],
              type: '[UpdateFacetValueInput!]!',
            },
          },
        },
        errorNotification: {
          message: t('modifierGroup.edit.failed', {}, 'Failed to update modifier group info'),
          type: 'error',
        },
        successNotification: {
          message: t('modifierGroup.edit.success', {}, 'Updated modifier group info'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchModifierGroup();
          navigateTo.list(resourceNames.modifierGroup);
        },
      }
    );
  };

  const onDeleteModifier = () => {
    deleteModifierProd(
      {
        id: deleteModifierId,
        resource: resourceNames.product,
        errorNotification: {
          message: t('modifier.delete.failed', 'Failed to delete modifier'),
          type: 'error',
        },
        successNotification: {
          message: t('modifier.delete.success', 'Modifier deleted'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          setDeleteModifierId('');
          refetchModifierGroup();
          refetchModifiers();
        },
      }
    );
  };

  const onCreateModifier = (data: any) => {
    createModifierProd(
      {
        resource: resourceNames.product,
        values: {
          ...data,
          facetValueIds: [modifierGroupId],
          customFields: { type: 'MODIFIER' },
          enabled: true,
        } as FormInputs,
        errorNotification: {
          message: t('modifierGroup', 'Failed to create modifier'),
          type: 'error',
        },
        successNotification: {
          message: t('modifierGroup', 'Created modifier'),
          type: 'success',
        },
      },
      {
        onSuccess: () => {
          refetchModifierGroup();
          refetchModifiers();
          setOpenModifier(false);
        },
      }
    );
  };

  // ======================= EFFECTS

  // ======================= VIEWS

  const modifierListing = () => {
    return (
      <section className="flex flex-col space-y-4 py-4">
        {modifiers?.data.items?.map((mod: Product) => (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-2">
              <ImageViewer
                src={mod?.assets?.[0]?.source}
                className="!w-14 !h-14 rounded-lg object-cover mr-2"
                alt="Product"
              />
              <span>{mod.name}</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <span>MYR {parsePrice(mod.variants?.[0]?.price)}</span>
              <IconButton onClick={() => setDeleteModifierId(mod.id)}>
                <HiOutlineTrash className="!text-error-300" size={30} />
              </IconButton>
            </div>
          </div>
        ))}
      </section>
    );
  };

  if (isLoading) return <Loading />;
  if (!isLoading && !modifierGroupId) return <SomethingWentWrong />;
  return (
    <main className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.modifierGroup}
        loading={isLoading}
        title={modifierGroupName}
        extra={
          <Button
            loading={updating}
            onClick={form.handleSubmit(onUpdateInfo)}
            variant="solid"
            size="md"
            className="mx-5"
          >
            {t('actions.update', {}, 'Update')}
          </Button>
        }
      >
        {/* Product Configuration */}
        {openProduct && (
          <ModalWrapper
            open
            setOpen={setOpenProduct}
            title={t('modifierGroup.show.selectProduct', 'Select Product')}
            loading={true}
          >
            <section className="flex flex-col space-y-4 py-4">
              <MultiProductSelection
                isField
                fieldLabel={t('modifierGroup', 'Products')}
                className="h-32"
                onSelect={(ids) => console.log('ids', ids)}
              />
            </section>
          </ModalWrapper>
        )}
        {openModifier && (
          <ModalWrapper open setOpen={setOpenModifier} loading={true} hideButton>
            <FormBuilder
              resourceName={resourceNames.modifierGroup}
              onSubmit={onCreateModifier}
              title={t('modifier.create.modifier')}
              items={(formHook) => formBuilder({ formHook, type: 'Modifier' })}
              loading={creatingModifier}
            />
          </ModalWrapper>
        )}
        <TriggerConfirmModal
          renderTrigger={() => <></>}
          visible={!!deleteModifierId}
          onOpenChange={(open) => {
            if (!open) setDeleteModifierId('');
          }}
          onPressConfirm={onDeleteModifier}
          description={
            <span>{t('warnings.deleteConfirmation', { resource: t('modifier.name') })}</span>
          }
          loadingConfirm={deletingModifier}
        />
        <section className="flex flex-col space-y-8">
          {/* General Info Form */}
          <ModifierGroupForm actionType="update" initialValues={data?.data} form={form} />

          <Card>
            <Card.Header className="flex flex-row justify-between items-center font-bold border-b">
              <div className="flex flex-row items-center space-x-2">
                <h3>{t('modifier.name', 'Modifiers')}</h3>
              </div>
              {!noModifiers && (
                <Button
                  onClick={() => {
                    setOpenModifier(true);
                  }}
                  size="sm"
                  className="!px-4 border-primary-500"
                >
                  <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                    <AiOutlinePlus className="!text-primary-500" />
                    <span>{t('modifier.create.add', {}, 'Add Modifier')}</span>
                  </div>
                </Button>
              )}
            </Card.Header>
            <Card.Body>
              {loadingModifiers ? (
                <Loading />
              ) : noModifiers ? (
                <section className="flex flex-col items-center justify-center w-full min-h-full p-20 space-y-6">
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <FaSitemap className="!text-primary-500" size={55} />
                    <span className="text-smoke-600">
                      {t('modifier.empty', {
                        fallback: `Add Modifiers to ${modifierGroupName || ''} Modifier`,
                        ns: 'common',
                        name: modifierGroupName,
                      })}
                    </span>
                  </div>
                  <Button className="border-primary-500" onClick={() => setOpenModifier(true)}>
                    <div className="flex flex-row items-center justify-between space-x-2 text-primary-500">
                      <AiOutlinePlus className="!text-primary-500" />
                      <span>{t('modifier.create.add', 'Add Modifiers')}</span>
                    </div>
                  </Button>
                </section>
              ) : (
                modifierListing()
              )}
            </Card.Body>
          </Card>
        </section>
      </ShowPageWrapper>
    </main>
  );
};
