import { Control, Controller, FieldValues } from 'react-hook-form';

export type VariantItemProps = {
  title: string;
  key?: string;
  type: string;
  width?: string;
  prefix?: string;
};

export const variantFormBuilder = (isCreate?: boolean): VariantItemProps[] => {
  const mainConfig = [
    {
      title: 'Image',
      key: 'image',
      type: 'upload',
      width: '15%',
    },
    {
      title: 'Name',
      key: 'name',
      type: 'text',
      width: '25%',
    },
    {
      title: 'Price',
      key: 'price',
      type: 'number',
      width: '20%',
      prefix: 'RM',
    },
    {
      title: 'Quantity',
      key: 'stockOnHand',
      type: 'number',
      width: '10%',
    },
  ];
  return isCreate
    ? mainConfig
    : [
        ...mainConfig,
        {
          title: 'Enabled',
          key: 'enabled',
          type: 'switch',
          width: '10%',
        },
      ];
};

export const renderInvisibleId = ({
  key,
  control,
  defaultValue,
}: {
  key: string;
  control: Control<FieldValues, {}>;
  defaultValue: any;
}) => {
  const idKey = `${key}.id`;
  const controllerProps = {
    control,
    name: idKey,
    defaultValue,
  };
  return (
    <Controller
      {...controllerProps}
      render={() => {
        return <div style={{ display: 'none' }} />;
      }}
    />
  );
};
