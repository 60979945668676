import { Tag } from '@scalingworks/react-admin-ui';
import { OutletFulfillmentStatus, OutletStatus } from '~/api';

export const renderOutletStatusTag = (
  status: OutletStatus | OutletFulfillmentStatus,
  t: (key: string, options?: any, defaultMessage?: string | undefined) => string
) => {
  const isEnable = status === OutletStatus?.Active || status === OutletFulfillmentStatus?.Active;
  const color = isEnable ? 'success' : 'gray';

  return (
    <Tag className={`rounded-lg  text-${color}-500 bg-${color}-100 border-${color}-500`}>
      {t(`outlet.status.${status.toLowerCase()}`, {
        fallback: status.toUpperCase(),
        ns: 'common',
      })?.toUpperCase()}
    </Tag>
  );
};
