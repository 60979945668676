import { Card } from '@scalingworks/react-admin-ui';
import React from 'react';
import { FaFileCircleXmark } from 'react-icons/fa6';

export const ErrorPage: React.FC = () => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-start p-8">
      <Card className="w-full flex flex-col items-center p-12 space-y-2">
        <FaFileCircleXmark size={60} className="!text-smoke-500" />
        <h5>Page not found</h5>
        <p className="text-smoke-500">
          The page you are looking for does not exist or has been moved.
        </p>
      </Card>
    </div>
  );
};
