import React, { useRef, useState } from 'react';
import { ResourceField, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import {
  getSdk,
  type Product,
  LanguageCode,
  CreateProductMutationVariables,
  CreateProductOptionMutation,
  ProductCustomFields,
  ProductType,
} from '~/api';
import { numeralThousandFormat } from '~/config/helper';
import {
  ActionButton,
  FormBuilder,
  SomethingWentWrong,
  TriggerConfirmModal,
  VariantForm,
  ViewBuilder,
  flexRow,
  ActionButtonRefProps,
  Loading,
} from 'components';
import {
  useCreate,
  useDelete,
  useNavigation,
  useOne,
  useTranslate,
  useUpdate,
} from '@refinedev/core';
import { useParams } from 'react-router-dom';
import { formBuilder } from '~/config/ProductResource';
import kebabCase from 'lodash/kebabCase';
import {
  ProductOptionGroupFormInput,
  ProductVariantFormInput,
  RefProps as VariantFormRefProps,
} from '~/components/VariantForm/props';
import { variantFormBuilder } from '~/components/VariantForm/config';
import isEmpty from 'lodash/isEmpty';
import { FiChevronDown } from 'react-icons/fi';
import { Card, ImageViewer, Table } from '@scalingworks/react-admin-ui';
import { toCamelCaseWord } from '~/resources/helpers';
import map from 'lodash/map';
import take from 'lodash/take';
import { PRODUCT_DEEPLINK } from '~/config/constant';
import { resourceNames } from '../../resource-names';
import {
  createVariantInput,
  formatBackendPrice,
  getDifferenceById,
  getOptionsDifference,
  loopCreateOptionGroup,
  mergeOptions,
  renderStatusTag,
} from '../../helpers/product-helper';
import { ProductReviews } from './show/product-review';
import { BsChevronUp } from 'react-icons/bs';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Product>({
    resourceName: resourceNames.product,
  });

const defaultValues = {
  name: '',
  description: '',
};

export type FormInputs = {
  name: string;
  basePrice: string;
  quantity: string;
  description: string;
  enable: boolean;
  variants: ProductVariantFormInput[];
  optionGroups: ProductOptionGroupFormInput[];
  images: File[] | string[];
  shipping?: string[];
  categories?: string[];
  facetValueIds?: string[];
  modifiers?: string[];
  customFields?: ProductCustomFields;
};

const productFields: ResourceField<Product>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'description',
  'enabled',
  'slug',
  { collections: ['name', 'id'] },
  { facetValues: ['id', 'name'] },
  {
    variants: [
      'id',
      'price',
      'stockOnHand',
      'priceWithTax',
      'name',
      'currencyCode',
      'enabled',
      { options: ['id', 'name'] },
      { assets: ['source'] },
    ],
  },
  {
    optionGroups: ['id', 'name', 'code', { options: ['code', 'id', 'name'] }],
  },
  { assets: ['source'] },
  { customFields: [{ fulfillChannels: ['id', 'name'] }, 'type'] },
  { modifierGroups: ['group', { modifiers: ['id', 'name'] }] },
];

export const productResource = createResource({
  name: resourceNames.product,
  label: 'Products',
  // @ts-ignore
  fields: defineFields(productFields),
  defaultValues,
  createConfig: {
    title: ({ t }) =>
      t('product.create.name', {
        ns: 'common',
        fallback: 'Create Product',
      }),
  },
  formatBeforeSubmit: (data) => {
    return data;
  },
  dataProvider: {
    // Note: ignore for now since the promise
    // too complex
    // @ts-ignore
    create: async ({ client, variables }) => {
      const {
        basePrice,
        categories,
        description,
        enable,
        // handlingFee,
        images,
        name,
        quantity,
        shipping,
        variants,
        optionGroups: oldOptionGroup,
        customFields,
        facetValueIds = [],
        modifiers = [],
      } = variables as FormInputs;
      const slugName = kebabCase(name);
      const filteredOptionGroups = oldOptionGroup?.filter((obj) => obj?.options?.length > 0);

      let assetsUrl: any;
      if (!isEmpty(images) && typeof images?.[0] !== 'string') {
        assetsUrl = await getSdk(client)?.createAssets({
          input: images?.map((subItem: any) => {
            return {
              file: subItem,
            };
          }),
        });
      }

      // return;
      try {
        // 1) Create the product
        const createProductRes = await getSdk(client).createProduct({
          input: {
            translations: [
              {
                languageCode: LanguageCode?.En,
                name,
                description,
                slug: slugName,
              },
            ],
            enabled: enable,
            assetIds: !isEmpty(assetsUrl) ? assetsUrl?.createAssets?.[0]?.id : undefined,
            // NOTE: might need to refactor this data injection
            customFields: {
              ...customFields,
              ...(!isEmpty(shipping)
                ? {
                    fulfillChannelsIds: shipping?.filter((subItem) => !isEmpty(subItem)),
                  }
                : undefined),
            },
            facetValueIds: [
              ...(facetValueIds || []),
              ...(!isEmpty(categories)
                ? categories?.map((subItem) => subItem?.split('-split-')?.[0]) || []
                : []),
            ],
          },
        } as CreateProductMutationVariables);

        // 2) Loop async function to call api to create option groups and options.
        // 3) Each loop ends, link the created option group to the product

        // 5) Loop and get the ids from the created options from api, then merge it into the variants form value
        // 6) create variants and also link the options' ids.
        if (isEmpty(variants)) {
          await getSdk(client).createProductVariants({
            input: {
              productId: createProductRes?.createProduct?.id,
              sku: slugName,
              translations: [
                {
                  languageCode: LanguageCode?.En,
                  name,
                },
              ],
              price: formatBackendPrice({ price: parseFloat(basePrice) }),
              stockOnHand: parseInt(quantity),
              customFields: {
                enabled: true,
              },
              assetIds: !isEmpty(assetsUrl) ? assetsUrl?.createAssets?.[0]?.id : undefined,
            },
          });
        } else {
          const createOptionRes = await loopCreateOptionGroup({
            client,
            productId: createProductRes?.createProduct?.id,
            value: filteredOptionGroups,
            getSdk,
          });

          await getSdk(client).createProductVariants({
            input: await createVariantInput({
              innerVariants: variants?.map((subItem) => {
                return {
                  ...subItem,
                  price: formatBackendPrice({ price: parseFloat(subItem?.price) })?.toString(),
                  options: subItem.options,
                };
              }),
              productId: createProductRes?.createProduct?.id,
              optionResCombine: createOptionRes?.optionResCombine,
            }),
          });
        }

        if (!isEmpty(modifiers)) {
          const productId = createProductRes.createProduct.id;
          await getSdk(client).assignModifiersToProduct({
            input: {
              modifierIds: modifiers,
              productId,
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    // Note: ignore for now since the promise
    // handling too complex
    // @ts-ignore
    update: async ({ client, variables, id }) => {
      const { data, productData } = variables as any;
      const mainProductData = productData as Product;
      const {
        // basePrice,
        categories,
        description,
        enable,
        // handlingFee,
        images,
        name,
        // quantity,
        shipping,
        optionGroups: oldOptionGroup,
        variants,
        customFields,
        modifiers = [],
      } = data as FormInputs;
      const filteredOptionGroups = oldOptionGroup?.filter((obj) => obj?.options?.length > 0);

      // ================= PRODUCTS
      const slugName = kebabCase(name);

      let assetsUrl: any;
      if (!isEmpty(images) && typeof images?.[0] !== 'string') {
        assetsUrl = await getSdk(client)?.createAssets({
          input: images?.map((subItem: any) => {
            return {
              file: subItem,
            };
          }),
        });
      }

      getSdk(client)
        .updateProduct({
          input: {
            id: id as string,
            translations: [
              {
                languageCode: LanguageCode?.En,
                name,
                description,
                slug: slugName,
              },
            ],
            enabled: enable,
            assetIds: !isEmpty(assetsUrl) ? assetsUrl?.createAssets?.[0]?.id : undefined,
            customFields: !isEmpty(shipping)
              ? {
                  type: customFields?.type,
                  fulfillChannelsIds: shipping?.filter((subItem) => !isEmpty(subItem)),
                }
              : undefined,
            facetValueIds: !isEmpty(categories)
              ? categories?.map((subItem) => subItem?.split('-split-')?.[0])
              : undefined,
          },
        })
        .then((res) => {
          return {
            data: res.updateProduct,
          };
        });

      // ================= PRODUCT VARIANTS
      // DELETION
      /**
       * 1) Delete all not needed variant
       * 2) Delete all not needed options
       * 3) Delete all not needed option groups
       */
      // =================  Delete removed variants. Compare the form variants with api variants.
      const variantsDifference = getDifferenceById(mainProductData?.variants, variants);
      if (!isEmpty(variantsDifference)) {
        await getSdk(client).deleteProductVariants({
          ids: variantsDifference?.map((subItem) => subItem?.id),
        });
      }

      // =================  Delete removed options. Compare the form options with api options.
      const optionsDifference = getOptionsDifference(
        filteredOptionGroups,
        mainProductData?.optionGroups
      );
      if (!isEmpty(optionsDifference)) {
        for (var optionsDifferenceItem of optionsDifference) {
          await getSdk(client).deleteProductOption({
            deleteProductOptionId: optionsDifferenceItem?.id,
          });
        }
      }

      // =================  Delete removed options groups. Compare the form option groups with api option groups.
      const optionGroupsDifference = getDifferenceById(
        mainProductData?.optionGroups,
        filteredOptionGroups
      );
      if (!isEmpty(optionGroupsDifference)) {
        for (var optionGroupsDifferenceItem of optionGroupsDifference) {
          await getSdk(client).removeOptionGroupFromProduct({
            optionGroupId: optionGroupsDifferenceItem?.id,
            productId: mainProductData?.id,
          });
        }
      }

      // ADDITION
      /**
       * 1) Add new option groups
       * 2) Add new options
       * 3) Add new variants
       */
      // =================  Add new option groups. Get form option groups that does not have id.
      const newOptionGroups = filteredOptionGroups?.filter((subItem) => !subItem?.id);
      let optionGroupsWithId = filteredOptionGroups?.filter((subItem) => subItem?.id);
      let optionResCombine;
      if (!isEmpty(newOptionGroups)) {
        optionResCombine = await loopCreateOptionGroup({
          client,
          productId: mainProductData?.id,
          value: newOptionGroups,
          getSdk,
        });
        optionGroupsWithId?.push(optionResCombine?.response as ProductOptionGroupFormInput);
      }
      // =================  Add new options. Get form options that does not have id.
      const newOptions = filteredOptionGroups?.flatMap((obj) => {
        const parentId = obj?.id;
        return obj?.options
          ?.filter((option) => !option.id && parentId !== undefined)
          ?.map((option) => ({ parentId, name: option?.name }));
      });
      if (!isEmpty(newOptions)) {
        for (var newOptionsItem of newOptions) {
          const optionQueryResponse = await getSdk(client).createProductOption({
            input: {
              code: newOptionsItem?.name,
              productOptionGroupId: newOptionsItem?.parentId || '',
              translations: [
                {
                  languageCode: LanguageCode?.En,
                  name: newOptionsItem?.name,
                },
              ],
            },
          });
          const optionResponse: CreateProductOptionMutation['createProductOption'] & {
            parentId?: string;
          } = optionQueryResponse?.createProductOption;
          optionResponse['parentId'] = newOptionsItem?.parentId;
          mergeOptions({
            optionGroupsWithId,
            optionResponse,
          });
        }
      }

      // =================  Add new variants. Get form variants that does not have id.
      const flattenedOptions = optionGroupsWithId?.flatMap((group) => group.options);
      const newVariants = variants?.filter((subItem) => !subItem?.id);
      if (!isEmpty(newVariants)) {
        await getSdk(client).createProductVariants({
          input: await createVariantInput({
            innerVariants: newVariants?.map((subItem) => {
              return {
                ...subItem,
                price: formatBackendPrice({ price: parseFloat(subItem?.price) })?.toString(),
                options: subItem.options,
              };
            }),
            productId: mainProductData?.id,
            optionResCombine: flattenedOptions,
          }),
        });
      }

      // UPDATES
      // =================  Update old variants. Get form variants that have id.
      const oldVariants = variants?.filter((subItem) => subItem?.id);
      if (!isEmpty(oldVariants)) {
        for (var oldVariantsItem of oldVariants) {
          let variantAssetsUrl: any;
          if (!isEmpty(oldVariantsItem?.image) && typeof oldVariantsItem?.image?.[0] !== 'string') {
            variantAssetsUrl = await getSdk(client)?.createAssets({
              input:
                oldVariantsItem?.image?.map((subItem: any) => {
                  return {
                    file: subItem,
                  };
                }) || [],
            });
          }

          await getSdk(client).updateProductVariants({
            input: {
              id: oldVariantsItem?.id || '',
              price: formatBackendPrice({ price: parseFloat(oldVariantsItem?.price) }),
              stockOnHand: parseInt(`${oldVariantsItem?.stockOnHand}`),
              enabled: oldVariantsItem?.enabled,
              assetIds: !isEmpty(variantAssetsUrl)
                ? variantAssetsUrl?.createAssets?.[0]?.id
                : undefined,
            },
          });
        }
      }

      // =================  Update old option groups. Get form option groups that have id.
      const oldOptionGroups = filteredOptionGroups?.filter((subItem) => subItem?.id);
      if (!isEmpty(oldOptionGroups)) {
        for (var oldOptionGroupsItem of oldOptionGroups) {
          await getSdk(client).updateProductOptionGroup({
            input: {
              id: oldOptionGroupsItem?.id || '',
              code: oldOptionGroupsItem?.name,
              translations: [
                {
                  languageCode: LanguageCode?.En,
                  name: oldOptionGroupsItem?.name,
                },
              ],
            },
          });
        }
      }

      if (modifiers) {
        await getSdk(client).assignModifiersToProduct({
          input: {
            modifierIds: modifiers,
            productId: id as string,
          },
        });
      }
    },
  },
  filterControls: {
    createdAt: {
      type: 'daterange',
      config: {
        label: 'Date & time',
      },
    },
  },
  defaultPageSize: 25,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowSearch: true,
  allowDelete: true,
  filterConfig: {
    alwaysExpanded: true,
  },
  columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
    return [
      {
        id: 'name',
        header: t('product.column.product', { fallback: 'Product', ns: 'common' }),
        cell: (data) => {
          const { name = '', id, assets } = data.row.original as Product;
          return (
            <LinkToDetails resourceId={id} style={{ flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ImageViewer
                  src={assets?.[0]?.source}
                  className="!w-14 !h-14 rounded-lg object-cover mr-2"
                  alt="Product"
                />
                <span>{name}</span>
              </div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'categories',
        header: t('product.column.categories', { fallback: 'Categories', ns: 'common' }),
        cell: (data) => {
          const { collections, id } = data.row.original as Product;
          const maxItemsToShow = 3;
          const categoryNames = map(collections, 'name');
          let result;
          if (categoryNames.length <= maxItemsToShow) {
            result = <p>{categoryNames.join(', ')}</p>;
          } else {
            const remainingItems = categoryNames.length - maxItemsToShow;
            result = (
              <p>
                {take(categoryNames, maxItemsToShow).join(', ')}
                <span className="text-primary-600">{` + ${remainingItems} more`}</span>
              </p>
            );
          }

          return (
            <LinkToDetails resourceId={id}>
              <span>{result}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'inventory',
        header: t('product.column.inventory', { fallback: 'Inventory', ns: 'common' }),
        cell: (data) => {
          const { variants, id } = data.row.original as Product;
          const mainVariants = variants?.[0];
          return (
            <LinkToDetails resourceId={id}>
              <span>{numeralThousandFormat(mainVariants?.stockOnHand)}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'status',
        header: t('product.column.status', { fallback: 'Status', ns: 'common' }),
        cell: (data) => {
          const { id, enabled } = data.row.original as Product;
          return <LinkToDetails resourceId={id}>{renderStatusTag(enabled)}</LinkToDetails>;
        },
      },
      {
        id: 'price',
        header: t('product.column.price', { fallback: 'Price (MYR)', ns: 'common' }),
        cell: (data) => {
          const { variants, id } = data.row.original as Product;
          const lowestPrice = variants.reduce((minPrice, product) => {
            return product.price < minPrice ? product.price : minPrice;
          }, Infinity);

          return (
            <LinkToDetails resourceId={id}>
              <span>
                {numeralThousandFormat(
                  lowestPrice !== Infinity
                    ? formatBackendPrice({ price: lowestPrice, isDivide: true })
                    : 0,
                  true
                )}
              </span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const t = useTranslate();
          const { id: productId, name } = data?.row?.original as Product;
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: t('actions.edit', { fallback: 'Edit' }),
                  name: 'edit',
                  onAction: () => navigateToEdit({ id: productId }),
                },
                {
                  label: t('actions.delete', { fallback: 'Delete' }),
                  name: 'delete',
                  onAction: () => invokeDelete({ id: productId }),
                  render: (onAction) => {
                    return (
                      <button type="button">
                        <TriggerConfirmModal
                          visible={showDeleteConfirmation}
                          onOpenChange={(val) => {
                            const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                            setShowDeleteConfirmation(val);
                            actionButtonSetOpen && actionButtonSetOpen(val);
                          }}
                          onPressConfirm={onAction}
                        />
                      </button>
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ];
  },
  show: {
    component: (helpers) => {
      const productData = helpers?.queryResult?.data?.data as Product;
      const variantsData = productData?.variants;
      const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
      const [showReviews, setShowReviews] = useState(true);

      const { mutate } = useDelete();
      const { edit, list } = useNavigation();
      const t = useTranslate();

      let totalVal = 0;
      variantsData?.forEach((subItem) => {
        totalVal += subItem?.stockOnHand;
      });

      if (helpers?.queryResult?.isLoading) return <div />;
      return (
        <section>
          <ViewBuilder
            {...helpers}
            title={productData?.name}
            items={[
              {
                cardTitle: t('common.general'),
                cardHeaderExtra: () => {
                  return (
                    <div>
                      <ActionButton
                        customTitle={t('common.action').toUpperCase()}
                        actions={[
                          {
                            label: t('actions.edit'),
                            name: 'edit',
                            onAction: async () => {
                              await edit(resourceNames?.product, productData?.id);
                            },
                          },
                          {
                            label: t('actions.delete'),
                            name: 'delete',
                            onAction: async () => {
                              await mutate({
                                resource: resourceNames?.product,
                                id: productData?.id,
                              });
                              list(resourceNames?.product);
                            },
                            render: (onAction) => {
                              return (
                                <button type="button">
                                  <TriggerConfirmModal
                                    visible={showDeleteConfirmation}
                                    onOpenChange={setShowDeleteConfirmation}
                                    onPressConfirm={onAction}
                                  />
                                </button>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  );
                },
                config: [
                  {
                    title: t('product.column.name', { fallback: 'Name', ns: 'common' }),
                    data: [productData?.name],
                    type: 'text',
                  },
                  {
                    title: t('product.column.description', {
                      fallback: 'Description',
                      ns: 'common',
                    }),
                    data: [productData?.description],
                    type: 'text',
                  },
                  {
                    title: t('product.column.images', { fallback: 'Images', ns: 'common' }),
                    data: productData?.assets?.map((subItem) => subItem?.source) || [],
                    type: 'image',
                  },
                  {
                    title: t('product.column.fulfillmentMethod', {
                      fallback: 'Fulfillment Method',
                      ns: 'common',
                    }),
                    data: [
                      ...(productData?.customFields?.fulfillChannels?.map((subItem) =>
                        toCamelCaseWord(subItem?.name)
                      ) || []),
                    ],
                    type: 'text',
                  },
                  {
                    title: t('product.column.quantity', { fallback: 'Quantity', ns: 'common' }),
                    data: [numeralThousandFormat(totalVal)],
                    type: 'text',
                  },
                  {
                    title: t('product.column.status', { fallback: 'Status', ns: 'common' }),
                    data: [productData?.enabled],
                    render: ({ data, flex, title }) => {
                      return (
                        <div style={{ ...flexRow }}>
                          {title}
                          <div style={{ flex }}>{renderStatusTag(data?.[0])}</div>
                        </div>
                      );
                    },
                  },
                  {
                    title: t('product.column.categories', { fallback: 'Categories', ns: 'common' }),
                    data: productData?.collections?.map((subItem) => subItem?.name) || [],
                    type: 'tag',
                  },
                  {
                    title: t('product.column.modifiers', { fallback: 'Modifiers', ns: 'common' }),
                    data: productData?.modifierGroups?.flatMap((group) =>
                      group.modifiers.flatMap((modifier) => modifier.name)
                    ),
                    type: 'tag',
                  },
                  {
                    title: t('product.column.qrCode', { fallback: 'QR Code', ns: 'common' }),
                    data: [`${PRODUCT_DEEPLINK}${productData?.id}`],
                    type: 'qr',
                  },
                ],
              },
            ]}
            extra={
              isEmpty(variantsData)
                ? []
                : [
                    {
                      cardTitle: 'Variants',
                      render: () => {
                        return (
                          <Table>
                            <Table.Thead>
                              <Table.Tr>
                                {[
                                  t('product.variants.column.image', {
                                    fallback: 'Image',
                                    ns: 'common',
                                  }),
                                  t('product.variants.column.name', {
                                    fallback: 'Name',
                                    ns: 'common',
                                  }),
                                  t('product.variants.column.price', {
                                    fallback: 'Price (MYR)',
                                    ns: 'common',
                                  }),
                                  t('product.variants.column.quantity', {
                                    fallback: 'Quantity',
                                    ns: 'common',
                                  }),
                                  t('product.variants.column.enabled', {
                                    fallback: 'Enabled',
                                    ns: 'common',
                                  }),
                                ]?.map((subItem) => (
                                  <Table.Th>{subItem}</Table.Th>
                                ))}
                              </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                              {variantsData?.map((subItem, index) => {
                                return (
                                  <Table.Tr key={`${subItem?.name}_${index}`}>
                                    <Table.Td width={'15%'}>
                                      <img
                                        src={subItem?.assets?.[0]?.source as string}
                                        width={80}
                                        height={80}
                                      />
                                    </Table.Td>
                                    <Table.Td width={'25%'}>{subItem?.name}</Table.Td>
                                    <Table.Td width={'20%'}>
                                      {numeralThousandFormat(
                                        formatBackendPrice({
                                          price: subItem?.price,
                                          isDivide: true,
                                        }),
                                        true
                                      )}
                                    </Table.Td>
                                    <Table.Td width={'10%'}>
                                      {numeralThousandFormat(subItem?.stockOnHand)}
                                    </Table.Td>
                                    <Table.Td width={'10%'}>
                                      {renderStatusTag(subItem?.enabled)}
                                    </Table.Td>
                                  </Table.Tr>
                                );
                              })}
                            </Table.Tbody>
                          </Table>
                        );
                      },
                    },
                  ]
            }
          />
          <Card className="relative z-0 mx-6">
            <Card.Header
              className="font-bold cursor-pointer"
              bordered
              onClick={() => setShowReviews(!showReviews)}
            >
              <section className="flex flex-row items-center justify-between">
                <h1>{t('product.details.reviews', undefined, 'Reviews')}</h1>
                <div className="flex flex-row space-x-2">
                  <BsChevronUp
                    size={30}
                    className={`transition-transform ${showReviews ? 'rotate-180' : 'rotate-0'}`}
                  />
                </div>
              </section>
            </Card.Header>
            {showReviews && (
              <Card.Body className="px-0 py-0">
                <ProductReviews
                  productId={productData?.id}
                  loading={helpers?.queryResult?.isLoading}
                />
              </Card.Body>
            )}
          </Card>
        </section>
      );
    },
  },
  create: {
    render: (helpers) => {
      const navigation = useNavigation();
      const t = useTranslate();
      const { mutate } = useCreate({
        mutationOptions: {
          onSettled: () => {
            navigation?.goBack();
          },
        },
      });

      return (
        <FormBuilder
          resourceName={resourceNames.product}
          title={t('product.create.name')}
          extra={[
            {
              render: (renderProps) => {
                return <VariantForm {...renderProps} items={variantFormBuilder(true)} />;
              },
              validate: true,
              key: 'variants',
            },
          ]}
          onSubmit={async (data) => {
            await mutate({
              resource: resourceNames.product,
              values: data,
            });
          }}
          keyToWatch={['optionGroups']}
          keyToHide={['quantity', 'basePrice']}
          items={(formHook) => formBuilder({ formHook })}
        />
      );
    },
  },
  edit: {
    render(helpers) {
      const { id } = useParams();
      const t = useTranslate();
      const navigation = useNavigation();
      const variantFormRef = useRef<VariantFormRefProps>(null);
      if (!id) return <SomethingWentWrong />;

      const { data, isLoading } = useOne({
        resource: 'products',
        id,
        metaData: {
          fields: productFields,
        },
      });
      const mainData = data?.data as Product;
      const isCreatedNoVariant = !isEmpty(mainData?.variants) && isEmpty(mainData?.optionGroups);
      const { mutate } = useUpdate();
      if (isLoading) return <Loading />;
      return (
        <FormBuilder
          resourceName={resourceNames.product}
          isUpdate
          title={t('product.edit.withProduct', { name: mainData?.name })}
          onSubmit={async (data) => {
            try {
              mutate({
                id,
                resource: resourceNames.product,
                values: {
                  data,
                  productData: mainData,
                },
              });
              navigation?.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          items={(formHook) =>
            formBuilder({
              product: mainData,
              formHook,
              setProductVariants: variantFormRef?.current?.setProductVariants,
            })
          }
          keyToWatch={['optionGroups', 'variants']}
          keyToHide={['quantity', 'basePrice']}
          extra={[
            {
              render: (renderProps) => (
                <VariantForm
                  {...renderProps}
                  ref={variantFormRef}
                  isUpdate
                  isCreatedNoVariant={isCreatedNoVariant}
                  items={variantFormBuilder()}
                  data={mainData}
                />
              ),
              validate: true,
              key: 'variants',
            },
          ]}
        />
      );
    },
  },
  list: {
    tabs: {
      options: [
        {
          // @ts-ignore
          filterValue: [{ field: 'type', operator: 'eq', value: ProductType.Product }],
          label: ({ t }) => t(`product.tabs.all`, { fallback: 'All', ns: 'common' }),
        },
        {
          filterValue: [
            // @ts-ignore
            { field: 'enabled', operator: 'eq', value: true },
            // @ts-ignore
            { field: 'type', operator: 'eq', value: ProductType.Product },
          ],
          label: ({ t }) => t(`product.tabs.available`, { fallback: 'Available', ns: 'common' }),
        },
        {
          filterValue: [
            // @ts-ignore
            { field: 'enabled', operator: 'eq', value: false },
            // @ts-ignore
            { field: 'type', operator: 'eq', value: ProductType.Product },
          ],
          label: ({ t }) => t(`product.tabs.hidden`, { fallback: 'Hidden', ns: 'common' }),
        },
      ],
    },
  },
});
