import React from 'react';
import { Product } from '~/api';
import { IconButton, ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import head from 'lodash/head';
import flatten from 'lodash/flatten';
import { NumberToggle } from '~/components/NumberToggle';
import { parsePrice } from '~/resources/helpers';
import { CiSquareRemove, CiEdit } from 'react-icons/ci';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';
import isEmpty from 'lodash/isEmpty';

export const ViewProductItem: React.FC<Props> = (props) => {
  const { products = [], currentlyInCart = [], onEdit, onDelete } = props;

  // ======================= HELPERS
  const getProduct = (productId: string): Product | undefined => {
    return products.find((product) => product.id === productId);
  };

  // ======================= HOOKS
  const t = useTranslate();

  // ======================= VIEWS
  const Divider: React.FC<any> = (props) => (
    <div className={`border border-smoke-100 my-4 ${props.className}`} />
  );

  return (
    <section>
      {currentlyInCart.map((productDict, index) => {
        const productId = head(Object.keys(productDict)) || '';
        const { quantity, variantId, addon } = productDict[productId] || {};

        // current product & variant info
        const {
          name: productName,
          variants,
          assets,
          featuredAsset,
          modifierGroups = [],
        } = getProduct(productId) || {};
        const {
          name: variantName,
          price = 0,
          featuredAsset: pvFeatured,
          assets: pvAssets,
          currencyCode = 'MYR',
        } = variants?.find((variant) => variant.id === variantId) || {};
        // Get specific variant's featured OR first asset OR product's featured OR first asset
        const img =
          pvFeatured?.source ||
          head(pvAssets)?.source ||
          featuredAsset?.source ||
          head(assets)?.source ||
          '';

        return (
          <React.Fragment key={productId}>
            <div className="flex flex-row items-start w-full">
              <div className="flex flex-row items-start space-x-2">
                <NumberToggle
                  value={quantity.toString()}
                  disabled
                  hideToggle
                  className="!w-12 text-center !text-black !placeholder-opacity-100 !bg-white"
                />
                {img && (
                  <div className="w-20 flex items-center justify-center !-ml-1">
                    <ImageViewer
                      src={img}
                      className="rounded-lg object-cover !w-14 !h-14"
                      alt="Product"
                    />
                  </div>
                )}
              </div>
              {/* Name */}
              <section className="flex flex-col w-full">
                <div className="flex flex-row items-start justify-between w-full">
                  <h3 className="font-semibold text-lg">{productName}</h3>

                  {/* Action Panel */}
                  <div className="flex flex-row space-x-1 items-center">
                    <span>
                      {currencyCode} {parsePrice(price)}
                    </span>
                    <IconButton onClick={() => onEdit?.(productDict)}>
                      <CiEdit size={30} className="!text-black" />
                    </IconButton>
                    <IconButton onClick={() => onDelete?.(productDict)}>
                      <CiSquareRemove size={30} className="!text-error-300" />
                    </IconButton>
                  </div>
                </div>
                {/* Selected Variant */}
                <h3>{variantName}</h3>
                {!isEmpty(addon) && (
                  <>
                    <Divider className="!my-2" />
                    <h3 className="font-semibold text-primary-500 text-lg">
                      {t('order.create.addons', {}, 'Add ons')}
                    </h3>

                    {addon?.map(({ groupName, modifierIds }) => {
                      return (
                        <section className="!my-2">
                          <div className="flex flex-col space-y-1">
                            <h3 className="font-medium text-smoke-700">{groupName}: </h3>
                            {modifierIds.map((variantId) => {
                              const { modifiers } =
                                modifierGroups.find((m) => m.group === groupName) || {};
                              const allVariants = flatten(modifiers?.map((prod) => prod.variants));
                              const {
                                name,
                                price,
                                currencyCode = 'MYR',
                              } = allVariants?.find((pv) => pv.id === variantId) || {};

                              return (
                                <div className="flex flex-row items-center justify-between">
                                  <Tag
                                    className="max-w-min !text-sm !text-primary-500"
                                    color="purple"
                                  >
                                    {name}
                                  </Tag>
                                  <span className="text-smoke-700">
                                    + {currencyCode} {parsePrice(price)}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </section>
                      );
                    })}
                  </>
                )}
              </section>
            </div>
            {index <= products.length && <Divider />}
          </React.Fragment>
        );
      })}
    </section>
  );
};
