import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import { Label } from '@scalingworks/react-admin-ui';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { flexRow } from '..';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const renderRadioButtons = ({ input: { item, register, setValue, watch } }: InputProps) => {
  const { title, name, required, options = [], defaultValue, props } = item;
  const formName = camelCase(name || title);
  const watchValue = watch(formName);

  register(formName, { required });

  useEffect(() => {
    if (defaultValue && !watchValue) {
      setValue(formName, defaultValue);
    }
  }, [watchValue, defaultValue]);

  const renderCheckbox = () => {
    return map(options, (subItem, index) => {
      const { label, value } = subItem;
      const isSelected = value === watchValue;

      return (
        <div className={`flex items-center ${index !== 0 && 'mt-3'}`} key={`${value}-${index}`}>
          <RadioGroup.Item
            id={value}
            value={value}
            className={`h-6 w-6 rounded-full ${
              isSelected ? 'border-primary-500' : 'border-gray-300'
            }`}
            style={{
              borderWidth: isSelected ? 5 : 1,
            }}
          >
            <RadioGroup.Indicator className="RadioGroupIndicator" />
          </RadioGroup.Item>
          <label htmlFor={value} className={`text-sm select-none ml-3`}>
            {label}
          </label>
        </div>
      );
    });
  };

  return (
    <div style={{ ...flexRow }}>
      <Label showRequiredIndicator={required} className="self-start">
        {title}
      </Label>
      <RadioGroup.Root
        {...props}
        className="RadioGroupRoot"
        defaultValue={defaultValue}
        aria-label="View density"
        onValueChange={(value) => {
          setValue(formName, value);
        }}
        required={required}
      >
        <div className="flex-1 ml-3">{renderCheckbox()}</div>
      </RadioGroup.Root>
    </div>
  );
};
