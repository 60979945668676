import React, { useEffect, useState } from 'react';
import { Props } from './props';
import {
  Button,
  Checkbox,
  Dialog,
  Label,
  PhoneInput,
  SearchableSelect,
  TextInput,
} from '@scalingworks/react-admin-ui';
import { Address, OrderAddress } from '~/api';
import { useNotification, useTranslate } from '@refinedev/core';
import { parsePhoneNumber } from 'libphonenumber-js';

const states = [
  'Johor',
  'Kedah',
  'Kelantan',
  'Kuala Lumpur',
  'Labuan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Putrajaya',
  'Sabah',
  'Sarawak',
  'Selangor',
  'Terengganu',
];

export const AddressModalForm: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    title,
    confirmTitle,
    type = 'Address',
    initialValue,
    onSubmit,
    availablePostCodes,
    buttonLoading = false,
  } = props;

  // ==================== HOOKS
  const t = useTranslate();
  const notif = useNotification();

  // ==================== STATES
  const [addr, setAddr] = useState<Partial<OrderAddress> | Partial<Address>>({
    ...initialValue,
    // @ts-ignore
    countryCode: 'MY',
  });

  useEffect(() => {
    if (initialValue) {
      setAddr({
        ...initialValue,
        // @ts-ignore
        countryCode: 'MY',
      });
    }
  }, [initialValue]);

  // ==================== EVENTS
  const handleSubmit = () => {
    const parsed = parsePhoneNumber(addr.phoneNumber!);

    if (!parsed.isValid()) {
      notif?.open?.({
        message: t('messages.invalidPhoneFormat', {}, 'Invalid phone format'),
        type: 'error',
      });
      return;
    }

    if (availablePostCodes) {
      const validPostCodes = availablePostCodes.includes(addr.postalCode!);
      if (!validPostCodes) {
        notif?.open?.({
          message: t('messages.invalidPostalCode', {}, 'Sorry, we do not ship to this postcode.'),
          type: 'error',
        });
        return;
      }
    }

    onSubmit?.({ ...addr, phoneNumber: parsed.number });
  };

  const getDisabled = () => {
    const { fullName, phoneNumber, streetLine1, streetLine2, city, province, postalCode } = addr;

    if (fullName && phoneNumber && streetLine1 && streetLine2 && city && province && postalCode) {
      return false;
    }

    return true;
  };
  // ==================== VIEWS
  return (
    <Dialog.Root open={open} onOpenChange={setOpen} modal>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="absolute top-1/2 z-20">
          <Dialog.Title>{title || t('address.name.add', {}, 'Add New Address')}</Dialog.Title>
          <div className="flex flex-row items-start mt-4 space-x-4 justify-center">
            {/* <Label className="mt-2 text-smoke-700 hidden xl:block">
            {t('order.title', {}, 'Address')}
          </Label> */}
            <div className="flex flex-col space-y-2">
              <section className="flex flex-col space-y-2">
                <TextInput
                  placeholder={t('customer.address.form.fullName', {}, 'Full Name')}
                  value={addr?.fullName || undefined}
                  onValue={(fullName) => setAddr((prev) => ({ ...prev, fullName }))}
                />
                <PhoneInput
                  placeholder={t('customer.address.form.contact', {}, 'Contact')}
                  value={addr?.phoneNumber || undefined}
                  onValue={(phoneNumber) => setAddr((prev) => ({ ...prev, phoneNumber }))}
                />
              </section>
              <div className="border-b border-gray-100 !my-4" />

              <section className="flex flex-col space-y-2">
                <TextInput
                  placeholder={t('customer.address.form.streetOne', {}, 'Street Line 1')}
                  value={addr?.streetLine1 || undefined}
                  onValue={(streetLine1) => setAddr((prev) => ({ ...prev, streetLine1 }))}
                />
                <TextInput
                  placeholder={t('customer.address.form.streetTwo', {}, 'Street Line 2')}
                  value={addr?.streetLine2 || undefined}
                  onValue={(streetLine2) => setAddr((prev) => ({ ...prev, streetLine2 }))}
                />
                <TextInput
                  placeholder={t('customer.address.form.city', {}, 'City')}
                  value={addr?.city || undefined}
                  onValue={(city) => setAddr((prev) => ({ ...prev, city }))}
                />
                <div className="flex flex-row items-center space-x-2">
                  <SearchableSelect
                    className="flex-1"
                    allowFreeText
                    options={states.map((state) => ({ label: state, value: state }))}
                    value={addr.province || ''}
                    onValue={(province) => setAddr((prev) => ({ ...prev, province }))}
                    placeholder={t('customer.address.form.state', {}, 'State')}
                  />
                  <TextInput
                    className="flex-1"
                    value={addr.postalCode || ''}
                    onValue={(postalCode) => setAddr((prev) => ({ ...prev, postalCode }))}
                    placeholder={t('customer.address.form.postalCode', {}, 'Postal Code')}
                  />
                </div>
              </section>

              {type === 'Address' && (
                <div className="flex flex-row items-center space-x-2">
                  <div className="flex flex-row items-center space-x-2">
                    <Checkbox
                      id="defaultShip"
                      checked={(addr as Address)?.defaultShippingAddress || false}
                      onCheckedChange={(check: boolean) =>
                        setAddr((prev) => ({ ...prev, defaultShippingAddress: check }))
                      }
                    />
                    <Label htmlFor="defaultShip" className="cursor-pointer text-sm">
                      {t('customer.address.form.defaultShipping', {}, 'Default Shipping Address')}
                    </Label>
                  </div>
                  <div className="flex flex-row items-center space-x-2">
                    <Checkbox
                      id="defaultBill"
                      checked={(addr as Address)?.defaultBillingAddress || false}
                      onCheckedChange={(check: boolean) =>
                        setAddr((prev) => ({ ...prev, defaultBillingAddress: check }))
                      }
                    />
                    <Label htmlFor="defaultBill" className="cursor-pointer text-sm">
                      {t('customer.address.form.defaultBilling', {}, 'Default Billing Address')}
                    </Label>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row justify-end space-x-4 my-4">
            <Button
              variant="default"
              className="text-error-400 border-error-400"
              onClick={() => setOpen(false)}
            >
              {t('actions.cancel', {}, 'Cancel')}
            </Button>
            <Button
              disabled={getDisabled()}
              loading={buttonLoading}
              variant="solid"
              onClick={handleSubmit}
            >
              {confirmTitle || t('actions.confirm', {}, 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export type AddressModalFormProps = Props;
