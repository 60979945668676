import React, { useState } from 'react';
import { Props } from './props';
import { Button, Dialog, IconButton, Tag, TextInput } from '@scalingworks/react-admin-ui';
import { BsChevronRight } from 'react-icons/bs';
import { useApiUrl, useCustom, useList, useTranslate } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import {
  Collection,
  CollectionList,
  CollectionListOptions,
  Product,
  ProductList,
  SortOrder,
} from '~/api';
import { AddProductItem, AddVariantItem, AddingVariant, Loading, VariantItem } from '~/components';
import { HiArrowLeft } from 'react-icons/hi';
import { AddItemComponent } from './component';
import isEmpty from 'lodash/isEmpty';

type PageState = {
  page: 'collection' | 'product' | 'selected';
  params?: {
    collectionId: string;
    collectionName: string;
  };
};

export const productFields: ResourceField<ProductList>[] = [
  {
    items: [
      'id',
      'name',
      {
        optionGroups: ['id', 'code', 'name', { options: ['id', 'code', 'name', 'groupId'] }],
      },
      {
        variants: [
          'id',
          'name',
          'price',
          'currencyCode',
          'productId',
          { discountPromotions: [{ items: ['id', 'name', 'startDate', 'endDate'] }] },
        ],
      },
      { facetValues: ['id', 'name', 'code'] },
      { featuredAsset: ['source'] },
      { assets: ['source'] },
      {
        modifierGroups: [
          'group',
          { modifiers: [{ variants: ['id', 'name', 'price', 'stockOnHand'] }] },
        ],
      },
    ],
  },
  'totalItems',
];

export const AddItemModal: React.FC<Props> = (props) => {
  const { title = '', open, setOpen, onAddItem, useVariantOnly = false, discount } = props;

  // ===================== STATES
  const [shownCollection, setShownCollection] = useState<Collection[]>();
  const [shownProducts, setShownProducts] = useState<Product[]>();
  // for order's
  const [selected, setSelected] = useState<AddingVariant>({});
  // for discount promotion's
  const [variantItems, setVariantItems] = useState<VariantItem[]>([]);
  const [view, setView] = useState<PageState>({ page: 'collection' });

  // ===================== HOOKS
  const t = useTranslate();
  const { data: collections, isLoading } = useCustom<CollectionList>({
    method: 'get',
    url: useApiUrl(),
    metaData: {
      fields: [
        { items: ['id', 'description', 'name', { productVariants: ['totalItems'] }] },
        'totalItems',
      ] as ResourceField<CollectionList>[],
      operation: 'collections',
      variables: {
        options: {
          type: 'CollectionListOptions',
          value: {
            sort: { position: SortOrder.Asc },
          } as CollectionListOptions,
        },
      },
    },

    queryOptions: {
      onSettled: (data) => setShownCollection(data?.data.items),
    },
  });
  const { data: products, isLoading: loadingProducts } = useCustom<ProductList>({
    method: 'get',
    url: useApiUrl(),
    metaData: {
      operation: 'getProductsByCollection',
      variables: {
        collectionId: {
          value: view.params?.collectionId,
          type: 'String!',
        },
      },
      fields: productFields,
    },
    queryOptions: {
      enabled: view.page === 'product' && !!view.params?.collectionId,
      onSettled: (products) => {
        setShownProducts(products?.data?.items);
      },
    },
  });
  const { data: selectedProducts, isLoading: loadingSelected } = useCustom<ProductList>({
    method: 'get',
    url: useApiUrl(),
    metaData: {
      operation: 'getProducts',
      fields: productFields,
      variables: {
        options: {
          value: {
            filter: {
              id: {
                in: Object.keys(selected),
              },
            },
          },
          type: 'ProductListOptions',
        },
      },
    },
    queryOptions: {
      enabled: !!selected && !isEmpty(Object.keys(selected)),
    },
  });

  // ===================== EVENTS
  const filter = (search: string) => {
    if (view.page === 'collection') {
      const show = collections?.data?.items?.filter?.((col: Collection) =>
        col?.name.toLowerCase().includes(search)
      );
      setShownCollection(show);
    }
    if (view.page === 'product') {
      const show = products?.data?.items?.filter((prod: Product) =>
        prod?.name.toLowerCase().includes(search)
      );
      setShownProducts(show);
    }
  };

  // ===================== VIEWS
  const Divider = <div className="border border-smoke-100" />;
  const renderContent = () => {
    // =============== Collection
    if (view.page === 'collection') {
      if (isLoading) return <Loading />;

      if (!collections?.data.totalItems) return <AddItemComponent.NoCollection />;

      return shownCollection?.map((collection, index) => (
        <React.Fragment key={index}>
          <button
            className="py-2"
            onClick={() => {
              /**
               * Note: since we are using shown state to show products
               * might have a glimpse on old products
               * where isLoading === false
               * but shownProduct haven't update;
               * -> set the shownProduct to empty first
               * & dont render if empty
               */
              setShownProducts([]);
              setView({
                page: 'product',
                params: { collectionId: collection.id, collectionName: collection.name },
              });
            }}
          >
            <div className="flex flex-row items-center justify-between">
              <span className="text-lg flex flex-row items-center">
                {collection.name}{' '}
                <Tag className="mx-2" color="purple">
                  {collection?.productVariants?.totalItems}
                </Tag>{' '}
              </span>
              <BsChevronRight color="#777986" />
            </div>
          </button>
          {index <= shownCollection.length && Divider}
        </React.Fragment>
      ));
    }

    // =============== Products
    if (view.page === 'product') {
      if (loadingProducts) return <Loading />;

      if (!products?.data.totalItems) {
        return (
          <AddItemComponent.NoProductInCollection collectionName={view.params?.collectionName!} />
        );
      }
      if (isEmpty(shownProducts)) return null;

      if (useVariantOnly) {
        return (
          <AddVariantItem
            products={shownProducts}
            allowRemoved
            currentlySelected={variantItems}
            discount={discount}
            onAdding={(items) => {
              // NOTE: still set to selected to enable query
              const adding: AddingVariant = {};
              items.map(({ variantId, productId }) =>
                Object.assign(adding, {
                  [productId]: { variantId, quantity: 1 },
                } as AddingVariant)
              );
              setSelected(adding);

              setVariantItems(items);
            }}
          />
        );
      }
      return (
        <AddProductItem
          products={shownProducts}
          onAdding={(data) => setSelected(data)}
          currentlySelected={selected}
        />
      );
    }

    // =============== Products
    if (view.page === 'selected') {
      if (loadingSelected) return <Loading />;

      // not suppose to happen (already disabled)
      // but it'll happen when user clean all selected at edit screen
      if (isEmpty(selected))
        return <AddItemComponent.NoSelected onBack={() => setView({ page: 'collection' })} />;

      // for discount promotion assign products variants
      if (useVariantOnly) {
        const selectedVariantIds = Object.values(selected).map((s) => s.variantId);
        const selectedItem = selectedProducts?.data?.items?.filter((prod: Product) =>
          prod.variants.some((variant) => selectedVariantIds.includes(variant.id))
        );

        return (
          <AddVariantItem
            products={selectedItem}
            allowRemoved
            mode="edit"
            currentlySelected={variantItems}
            discount={discount}
            onAdding={(items) => {
              // NOTE: still set to selected to enable query
              const adding: AddingVariant = {};
              items.map(({ variantId, productId }) =>
                Object.assign(adding, {
                  [productId]: { variantId, quantity: 1 },
                } as AddingVariant)
              );
              setSelected(adding);

              setVariantItems(items);
            }}
          />
        );
      }

      // we are using the same component but products only
      // show those selected
      // and render it as editing

      // should not be shownProducts since shown are
      // only those from certain collection
      // but we don't want to fetch everything, only fetch those selected
      // with another api call so state will be lighter
      const selectedItem = selectedProducts?.data?.items?.filter((prod: Product) =>
        Object.keys(selected).includes(prod.id)
      );

      return (
        <AddProductItem
          products={selectedItem}
          currentlySelected={selected}
          onAdding={(adding) => setSelected(adding)}
          mode="edit"
        />
      );
    }
  };
  const getTitle = () => {
    if (view.page === 'collection') {
      return <span>{title || t('actions.addItems', {}, 'Add Items')}</span>;
    }

    if (view.page === 'product') {
      // Note: On back reset previous selected progress (if any)
      return (
        <div className="flex flex-row items-center justify-start">
          <IconButton onClick={() => setView({ page: 'collection' })}>
            <HiArrowLeft color="black" />
          </IconButton>
          <span className="font-semibold mx-2">{view.params?.collectionName}</span>
        </div>
      );
    }

    if (view.page === 'selected') {
      return (
        <div className="flex flex-row items-center justify-start">
          <IconButton onClick={() => setView({ page: 'collection' })}>
            <HiArrowLeft color="black" />
          </IconButton>
          <span>{t('order.create.selectedItem', {}, 'Selected Items')}</span>
        </div>
      );
    }

    return null;
  };

  const selectedCount = (useVariantOnly ? variantItems.length : Object.keys(selected).length) || 0;
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/2 z-20 w-2/3">
          <Dialog.Title className="flex flex-row items-center justify-between">
            {getTitle()}
            <section className="flex flex-row items-center justify-between">
              {view.page !== 'selected' && (
                <Button
                  size="sm"
                  className="text-primary-500 border-primary-500"
                  onClick={() => setView({ page: 'selected' })}
                  disabled={!selectedCount}
                >
                  <span>{t('order.create.selectedItems', {}, 'Selected')}</span>
                  {!!selectedCount && <span className="mx-1">({selectedCount})</span>}
                </Button>
              )}
            </section>
          </Dialog.Title>

          {/* Search */}
          {view.page !== 'selected' && (
            <TextInput
              onValue={filter}
              className="my-2"
              placeholder={
                view.page === 'collection'
                  ? t('order.create.searchByCollections', {}, 'Search by Collection Name')
                  : t('order.create.searchByProduct', {}, 'Search by Product Name')
              }
            />
          )}
          <div className="flex flex-col py-4 space-y-2">{renderContent()}</div>

          <section className="my-4 flex flex-row items-center justify-end space-x-2">
            <Button variant="plain" onClick={() => setOpen(false)}>
              {t('actions.cancel', {}, 'Cancel')}
            </Button>
            <Button
              onClick={() => onAddItem?.(useVariantOnly ? variantItems : selected)}
              disabled={!selected || isEmpty(selected)}
              variant="solid"
            >
              {t('actions.addItems', {}, 'Add Items')}
            </Button>
          </section>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
