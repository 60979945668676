import React from 'react';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';
import { parsePrice } from '~/resources/helpers';
import { Skeleton, Tag } from '@scalingworks/react-admin-ui';
import compact from 'lodash/compact';
import head from 'lodash/head';
import { SurchargeType } from '~/api';

const SERVICE_TAX_SKU = SurchargeType.ServiceCharge;

export const OrderPriceSummary: React.FC<Props> = (props) => {
  const { order, loading, lines } = props;
  const {
    shipping,
    subTotal,
    taxSummary,
    total,
    totalWithTax,
    totalLinePrice,
    discounts,
    customFields,
    currencyCode = 'MYR',
    couponCodes = [],
    surcharges = [],
  } = order || {};
  const { giftFee = 0 } = customFields || {};

  // ================= HOOKS
  const t = useTranslate();

  // ================= VARIABLES
  const lineSubTotal =
    lines
      ?.filter((line) => !line.isAddOn)
      ?.reduce((sum, cur) => (sum || 0) + cur.discountLinePriceWithAddon, 0) || 0;
  const totalDiscount = discounts?.reduce?.((sum, cur) => (sum || 0) + (cur.amount || 0), 0) || 0;
  const totalTax = taxSummary?.reduce?.((sum, cur) => (sum || 0) + cur.taxTotal, 0) || 0;
  const serviceCharge =
    head(surcharges.filter((charge) => charge.sku === SERVICE_TAX_SKU))?.price || 0;

  // ================= VIEWS
  const Row: React.FC<any> = (props) => {
    return (
      <div className={`flex flex-row items-center justify-between ${props.className}`}>
        {props?.children}
      </div>
    );
  };
  const Text: React.FC<any> = (props) => {
    return <h5 className="text-smoke-700">{props?.children}</h5>;
  };
  const Loading: React.FC<any> = (props) => (
    <Skeleton heightClass="h-5" width={props?.width || 'wide'} />
  );
  if (loading) {
    const length = 3;
    return (
      <div className="flex flex-col space-y-2">
        {Array.from({ length }).map((i) => (
          <Row key={i}>
            <Loading />
            <Loading width="narrow" />
          </Row>
        ))}
      </div>
    );
  }
  if (!order) return null;
  return (
    <div className="flex flex-col space-y-2">
      <Row>
        <Text>{t('order.details.subtotal', {}, 'Subtotal')}</Text>
        <Text>
          {currencyCode} {parsePrice(totalLinePrice)}
        </Text>
      </Row>
      {!!giftFee && (
        <>
          <Row className="mb-2">
            <Text>{t('order.details.packagingFee', {}, 'Packaging Fee')}</Text>
            <Text>{` ${currencyCode} ${parsePrice(giftFee)}`}</Text>
          </Row>
        </>
      )}

      <Row>
        <Text>{t('order.details.deliveryFee', {}, 'Delivery Fee')}</Text>
        <Text>
          {currencyCode} {parsePrice(shipping)}
        </Text>
      </Row>
      {!!totalDiscount && (
        <Row>
          <Text>{t('order.details.discounts', {}, 'Discounts')}</Text>
          <Text>
            {currencyCode} {parsePrice(totalDiscount)}
          </Text>
        </Row>
      )}
      {compact(couponCodes).length > 0 && (
        <Row>
          <div className="space-y-2 flex-wrap">
            {compact(couponCodes).map((code) => (
              <Tag key={code} color="green" className="mr-2">
                {code}
              </Tag>
            ))}
          </div>
        </Row>
      )}
      {!!serviceCharge && (
        <Row>
          <Text>{t('order.details.serviceCharge', {}, 'Service Charge')}</Text>
          <Text>
            {currencyCode} {parsePrice(serviceCharge)}
          </Text>
        </Row>
      )}
      <Row>
        <Text>{t('order.details.tax', {}, 'Tax')}</Text>
        <Text>
          {currencyCode} {parsePrice(totalTax)}
        </Text>
      </Row>
      <Row>
        <h3 className="font-bold">{t('order.details.total', {}, 'Total')}</h3>
        <h3 className="font-bold">
          {currencyCode} {parsePrice(totalWithTax)}
        </h3>
      </Row>
    </div>
  );
};

export type OrderPriceSummaryProps = Props;
