import React, { useEffect, useState } from 'react';
import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import { FormBuilder } from '~/components';
import { MembershipTier, PromoCodeType, getSdk } from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { FormItemProps } from '~/components/FormBuilder/props';
import isEmpty from 'lodash/isEmpty';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';

export const PromoCreate: React.FC<Props> = (props) => {
  const { onSubmit, promoCodeData } = props;
  const [membershipTiersData, setMembershipTiersData] = useState<MembershipTier[]>([]);
  const gqlClient = useGraphQLClient();
  const isUpdate = !isEmpty(promoCodeData);
  const t = useTranslate();

  useEffect(() => {
    getSdk(gqlClient)
      ?.getMembershipTiers({
        options: {
          skip: 0,
          take: 50,
        },
      })
      ?.then((subItem) => {
        setMembershipTiersData(subItem?.getMembershipTiers?.items);
        return subItem;
      });
  }, []);

  return (
    <div>
      <FormBuilder
        resourceName={resourceNames.promoCode}
        title={`${isUpdate ? t('promoCodes.update.update') : t('promoCodes.create.create')} ${
          isUpdate ? promoCodeData?.name : ''
        }`}
        isUpdate={isUpdate}
        items={({ register, setValue, watch }) => {
          const watchTypeField = watch('type');
          const upperPart: FormItemProps[] = [
            {
              name: 'name',
              title: t('promoCodes.form.name'),
              placeholder: t('promoCodes.placeholder.name'),
              required: true,
              defaultValue: promoCodeData?.name,
            },
            {
              name: 'code',
              title: t('promoCodes.form.code'),
              placeholder: t('promoCodes.placeholder.code'),
              required: true,
              defaultValue: promoCodeData?.voucherCode,
            },
            {
              name: 'images',
              title: t('promoCodes.form.image'),
              type: 'upload',
              placeholder: t('product.placeholder.images'),
              hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
              fileType: 'image',
              defaultValue: promoCodeData?.imageUrls,
            },
            {
              name: 'pointCost',
              title: t('promoCodes.form.point'),
              type: 'number',
              placeholder: t('promoCodes.placeholder.pointCost'),
              required: true,
              suffix: 'PTS',
              defaultValue: promoCodeData?.pointCost,
            },
            {
              name: 'quantity',
              title: t('promoCodes.form.quantity'),
              type: 'number',
              placeholder: t('promoCodes.placeholder.quantity'),
              required: true,
              defaultValue: promoCodeData?.quantity,
            },
            {
              name: 'type',
              title: t('promoCodes.form.type'),
              type: 'select',
              options: [
                ...Object.keys(PromoCodeType).map((key) => ({
                  label: toCamelCaseWord(key),
                  // @ts-ignore
                  value: PromoCodeType[key],
                })),
              ],
              placeholder: t('promoCodes.placeholder.type'),
              required: true,
              defaultValue: promoCodeData?.type ?? PromoCodeType.DiscountPercentage,
            },
            {
              name: 'value',
              title: t('promoCodes.form.voucher'),
              placeholder: '0.00',
              type: 'number',
              required: true,
              prefix: watchTypeField === PromoCodeType.FixedValue ? 'MYR' : undefined,
              suffix: watchTypeField === PromoCodeType.DiscountPercentage ? '%' : undefined,
              defaultValue: promoCodeData?.voucherValue,
            },
          ];
          const lowerPart: FormItemProps[] = [
            {
              name: 'minimumSpending',
              title: t('promoCodes.form.minSpend'),
              placeholder: '0.00',
              type: 'number',
              required: true,
              prefix: 'MYR',
              defaultValue: promoCodeData?.minimumSpendAmount,
            },
            {
              name: 'membershipTiers',
              title: t('promoCodes.form.membershipTiers'),
              type: 'select',
              multiSelect: true,
              options: membershipTiersData?.map((subItem) => ({
                label: subItem?.name,
                value: subItem?.id,
              })),
              placeholder: t('promoCodes.placeholder.membershipTiers'),
              defaultValue: promoCodeData?.membershipTiers?.map((subItem) => subItem?.id),
              required: true,
            },
            {
              name: 'startDate',
              title: t('promoCodes.form.start'),
              type: 'date-picker',
              required: true,
              placeholder: t('promoCodes.placeholder.start'),
              defaultValue: promoCodeData?.startAt,
            },
            {
              name: 'endDate',
              title: t('promoCodes.form.end'),
              type: 'date-picker',
              placeholder: t('promoCodes.placeholder.end'),
              required: true,
              defaultValue: promoCodeData?.endAt,
            },
            {
              name: 'description',
              title: t('promoCodes.form.description'),
              multiline: true,
              placeholder: t('promoCodes.placeholder.description'),
              required: true,
              defaultValue: promoCodeData?.description,
            },
            {
              name: 'redeemCriteria',
              title: t('promoCodes.form.criteria'),
              multiline: true,
              placeholder: t('promoCodes.placeholder.criteria'),
              required: true,
              defaultValue: promoCodeData?.redeemCriteria,
            },
            {
              name: 'termsConditions',
              title: t('promoCodes.form.tnc'),
              multiline: true,
              placeholder: t('promoCodes.placeholder.tnc'),
              required: true,
              defaultValue: promoCodeData?.termCondition,
            },
          ];

          return watchTypeField === PromoCodeType?.DiscountPercentage
            ? [
                ...upperPart,
                {
                  name: 'cappedAt',
                  title: t('promoCodes.form.cap'),
                  type: 'number',
                  prefix: 'MYR',
                  placeholder: '0.00',
                  required: true,
                  defaultValue: promoCodeData?.cappedDiscount,
                },
                ...lowerPart,
              ]
            : [...upperPart, ...lowerPart];
        }}
        onSubmit={(data) => {
          onSubmit?.(data);
        }}
      />
    </div>
  );
};
