import React, { useEffect, useState } from 'react';
import { Props } from './props';
import { Checkbox, ImageViewer, Tag, Tooltip } from '@scalingworks/react-admin-ui';
import { formatDateRange, getDiscountPrice, parsePrice } from '~/resources/helpers';
import { CiSquareRemove } from 'react-icons/ci';
import { useTranslate } from '@refinedev/core';
import head from 'lodash/head';
import compact from 'lodash/compact';
import { DiscountPromotion, DiscountType, ProductVariant } from '~/api';
import { DefaultCurrency } from '~/config/constant';
import { BsArrowRight, BsFillTagsFill } from 'react-icons/bs';
import clsx from 'clsx';
import dayjs from 'dayjs';

export type VariantItem = {
  variantId: string;
  productId: string;
};

const MaxRender = 2;

export const AddVariantItem: React.FC<Props> = (props) => {
  const {
    products,
    mode = 'create',
    currentlySelected = [],
    onAdding,
    allowRemoved = true,
    discount,
  } = props;

  // ======================== HOOKS
  const t = useTranslate();

  // ======================== STATES
  const [variants, setVariants] = useState<VariantItem[]>(currentlySelected);

  // ======================== VARIABLES
  const isEdit = mode === 'edit';
  const isCreate = mode === 'create';

  // ======================== EVENTS
  const handleRemoveVariant = (variantIds: string[]) => {
    const filtered = variants.filter((v) => !variantIds.includes(v.variantId));
    setVariants(filtered);
  };

  const onCheckChanged = (checked: boolean, variantId: string, productId: string) => {
    if (checked) {
      setVariants((prev) => [...prev, { variantId, productId }]);
    } else {
      handleRemoveVariant([variantId]);
    }
  };

  const handleSelectAllVariant = (
    checked: boolean,
    productId: string,
    variants: ProductVariant[]
  ) => {
    const variantIds = variants?.map((variant) => variant.id);
    const variantItems: VariantItem[] = variantIds.map((variantId) => ({ variantId, productId }));

    // add all variants
    if (checked) {
      setVariants((prev) => [
        ...prev.filter((item) => !variantIds.includes(item.variantId)),
        ...variantItems,
      ]);
    }
    // drop all variants
    else {
      handleRemoveVariant(variantIds);
    }
  };

  // ======================== EFFECTS
  useEffect(() => {
    // fire callback when configuring
    onAdding?.(compact(variants));
  }, [variants]);

  // ======================== VIEWS
  const renderPrice = (amt: number, currencyCode = DefaultCurrency) => {
    const OriPrice: React.FC<any> = (props) => (
      <span className={`text-smoke-700 ${props.className}`}>
        {amt ? `${currencyCode} ${parsePrice(amt)}` : ''}
      </span>
    );

    if (discount) {
      const { type, value } = discount;
      const typeDisplay = type === DiscountType.Fixed ? currencyCode : '%';
      const discounted = getDiscountPrice(amt, type, value);

      return (
        <div className="flex flex-row items-center space-x-2 text-smoke-700">
          <Tag color="green">
            <BsFillTagsFill size={25} className="transform scale-x-[-1]" />
            <span>
              -{value}
              {typeDisplay}
            </span>
          </Tag>

          <OriPrice className={'line-through !text-smoke-400'} />
          <BsArrowRight />
          <span>
            {currencyCode} {parsePrice(discounted)}
          </span>
        </div>
      );
    }

    return <OriPrice />;
  };

  const renderDiscountPromotions = (discountPromotions: DiscountPromotion[]) => {
    if (!discountPromotions.length) return null;

    // filter out past endDate discountPromotions
    const ongoing = discountPromotions.filter(
      (discountPromotion) =>
        !!discountPromotion.endDate && dayjs(discountPromotion.endDate).isAfter(dayjs())
    );
    const toRender = ongoing.slice(0, MaxRender) || [];
    const length = ongoing.length;

    const getDiscountPromotionInfo = (discountPromotion: DiscountPromotion) => {
      const { name, startDate, endDate } = discountPromotion;
      return `${name} ${formatDateRange(startDate, endDate)}`;
    };

    return (
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <div className="flex flex-row items-center justify-start space-x-1 w-8/10 overflow-hidden">
            {toRender?.map((discountPromotion: DiscountPromotion) => {
              return (
                <Tag className="!font-bold" key={discountPromotion.id}>
                  {getDiscountPromotionInfo(discountPromotion)}
                </Tag>
              );
            })}
            <Tooltip.Trigger>{length > MaxRender && <span>...</span>}</Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="z-50" sideOffset={5}>
                <div className="flex flex-col space-y-1">
                  {ongoing.slice(MaxRender).map((discountPromotion) => (
                    <span>{getDiscountPromotionInfo(discountPromotion)}</span>
                  ))}
                </div>
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </div>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  };

  const Divider = <div className="border border-smoke-100 w-full my-4" />;
  return (
    <section className="">
      {products?.map((product, index) => {
        const {
          id: productId = '',
          name: productName,
          featuredAsset,
          assets,
          variants: prodVariants = [],
        } = product;
        const prodImg = featuredAsset?.source || head(assets)?.source || '';
        const selectedVariantIds = variants.map((v) => v.variantId);
        let variantToRender: ProductVariant[] = prodVariants;
        // only render selected variants in edit mode; else render all
        if (isEdit) {
          variantToRender = prodVariants.filter((v) => selectedVariantIds.includes(v.id));
        }

        // selected variants for this product
        const selectedProdVariants = variants.filter((v) => v.productId === productId);
        const checked = selectedProdVariants.length === variantToRender.length;

        return (
          <React.Fragment key={productId}>
            <div className="flex flex-col space-y-2 w-full">
              <div className="flex flex-row items-center space-x-2">
                <h3 className="font-bold">{productName}</h3>
                {isCreate && (
                  <Checkbox
                    checked={checked}
                    onCheckedChange={(checked) =>
                      handleSelectAllVariant(checked as boolean, productId, variantToRender)
                    }
                  />
                )}
              </div>
              {variantToRender.map((variant) => {
                const {
                  id: variantId,
                  assets,
                  featuredAsset,
                  name: variantName,
                  price,
                  discountPromotions,
                  currencyCode = DefaultCurrency,
                } = variant;
                const pvDiscountPromotions = discountPromotions?.items || [];
                const variantImg = featuredAsset?.source || head(assets)?.source || '';
                const displayImg = variantImg || prodImg;
                const isChecked = selectedVariantIds.includes(variantId);

                return (
                  <div
                    className={clsx(
                      'flex flex-row items-start w-full rounded-lg p-2 select-none',
                      isEdit
                        ? ''
                        : `hover:bg-primary-50 cursor-pointer ${isChecked && 'bg-primary-50'}`
                    )}
                    onClick={() => {
                      isCreate && onCheckChanged(!isChecked, variantId, productId);
                    }}
                  >
                    <div className="flex flex-row items-start justify-start space-x-2 w-2/10">
                      {isCreate && (
                        <Checkbox
                          checked={isChecked}
                          onCheckedChange={(checked) =>
                            onCheckChanged(checked as boolean, variantId, productId)
                          }
                        />
                      )}
                      {displayImg && (
                        <div className="w-20 flex items-center justify-center !-ml-2">
                          <ImageViewer
                            src={displayImg}
                            className="rounded-lg object-cover !w-14 !h-14"
                            alt="Product"
                          />
                        </div>
                      )}
                    </div>
                    <section className="flex flex-1 flex-col space-y-2 w-4/5">
                      <div className="flex flex-row items-start justify-between w-full">
                        <h3 className="font-semibold">{variantName}</h3>
                        <div className="flex flex-row items-center space-x-2">
                          {/* Pricing */}
                          {renderPrice(price, currencyCode)}
                          {isEdit && allowRemoved && (
                            // getColor
                            <CiSquareRemove
                              size={35}
                              color="#D60345"
                              className="cursor-pointer self-end -mr-1"
                              onClick={() => handleRemoveVariant([variantId])}
                            />
                          )}
                        </div>
                      </div>
                      {renderDiscountPromotions(pvDiscountPromotions)}
                    </section>
                  </div>
                );
              })}
            </div>
            {index <= products.length && Divider}
          </React.Fragment>
        );
      })}
    </section>
  );
};
