import isString from 'lodash/isString';
import orderBy from 'lodash/orderBy';
import { Address, CreateAddressInput, OrderAddress, OutletAddress } from '~/api';

export const joinAddress = (address: Partial<Address | OrderAddress>): string => {
  const { fullName, phoneNumber, streetLine1, streetLine2, postalCode, city, province, country } =
    address;

  const addressFields = [
    fullName,
    phoneNumber,
    streetLine1,
    streetLine2,
    postalCode,
    city,
    province,
    isString(country) ? country : country?.name, // Assuming the `country` field contains an object with a `name` property
  ];

  const joinedAddress = addressFields
    .filter((field) => field) // Filter out empty fields
    .join(', ');

  return joinedAddress;
};

/**
 * @description sort user address where default billing and shipping at top index
 */
export const sortUserAddress = (addresses: Partial<Address>[]): Partial<Address>[] => {
  const sortedAddress = orderBy(
    addresses,
    ['defaultShippingAddress', 'defaultBillingAddress'],
    ['desc', 'desc']
  );

  return sortedAddress;
};

/**
 * @description transform customer address (Address) to CreateAddressInput
 */
export const custAddrToCreateInput = (addr: Address): CreateAddressInput => {
  const { fullName, phoneNumber, streetLine1, streetLine2, city, province, postalCode, country } =
    addr;
    
  return {
    fullName,
    phoneNumber,
    streetLine1,
    streetLine2,
    city,
    province,
    postalCode,
    countryCode: country.code,
  };
};

export const joinOutletAddress = (address?: Partial<OutletAddress>): string => {
  const { addressLineOne, addressLineTwo, postalCode, city, province, country } = address || {};

  const addressFields = [addressLineOne, addressLineTwo, postalCode, city, province, country];

  const joinedAddress = addressFields
    .filter((field) => field) // Filter out empty fields
    .join(', ');

  return joinedAddress;
};
