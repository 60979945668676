import { PhoneField, type PhoneFieldProps } from '@scalingworks/react-admin-ui';
import * as React from 'react';

import { getControlRules, useFormField } from '../form.lib';
import type { MakeFormFieldProps } from '../form.types';

export type FormPhoneFieldProps = MakeFormFieldProps<PhoneFieldProps>;

export const FormPhoneField = ({
	defaultValue,
	label,
	hideLabel,
	...props
}: FormPhoneFieldProps) => {
	const controller = useFormField({
		name: props.name,
		rules: getControlRules(props),
		defaultValue,
	});
	const error = controller.fieldState.error;

	return (
		<PhoneField
			status={error ? 'danger' : undefined}
			{...props}
			{...controller.field}
			onValue={controller.field.onChange}
			{...(hideLabel
				? {
						'aria-label': label,
				  }
				: {
						label,
				  })}
			value={controller.field.value || ''}
		/>
	);
};
