import React from 'react';
import { Props } from './props';
import { ModalWrapper } from '../ModalWrapper';
import { useApiUrl, useCustom, useCustomMutation, useTranslate } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { useForm } from '@refinedev/react-hook-form';
import {
  OutletFulfillmentType,
  OutletSettingList,
  OutletSettingListOptions,
  UpdateOutletSettingInput,
} from '~/api';
import { Loading } from '../Loading';
import { Controller } from 'react-hook-form';
import { NumberInput } from '@scalingworks/react-admin-ui';
import { camelCase } from 'lodash';

export const OutletSettingsModal: React.FC<Props> = (props) => {
  const { open, setOpen, type = OutletFulfillmentType.Delivery } = props;

  // ========================= HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();

  const { handleSubmit, control } = useForm<any>({
    refineCoreProps: {
      dataProviderName: undefined,
      resource: 'no-resource',
    },
    defaultValues: {},
    values: {},
  });

  // ========================= API
  const { data, isLoading } = useCustom<OutletSettingList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      fields: [
        {
          items: [
            'id',
            'lastCallTime',
            'preorderBuffer',
            'preparationTime',
            'timeSlotInterval',
            'type',
          ],
        },
      ] as ResourceField<OutletSettingList>[],
      operation: 'getOutletSettings',
      variables: {
        options: {
          value: {
            filter: {
              type: { eq: type },
            },
          } as OutletSettingListOptions,
          type: 'OutletSettingListOptions',
        },
      },
    },
  });

  const { isLoading: mutationLoading, mutate } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data?.id) {
          setOpen(false);
        }
      },
    },
  });

  // ========================= VARIABLES
  const outletSetting = data?.data?.items[0];

  // ========================= EVENTS
  const onConfirmSelection = async ({
    preparationTime,
    lastCallTime,
    timeSlotInterval,
    preorderBuffer,
  }: any) => {
    mutate({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: ['id'],
        operation: 'updateOutletSetting',
        variables: {
          input: {
            type: 'UpdateOutletSettingInput!',
            value: {
              type: outletSetting?.type,
              preparationTime: +(preparationTime ?? outletSetting?.preparationTime),
              lastCallTime: +(lastCallTime ?? outletSetting?.lastCallTime),
              timeSlotInterval: +(timeSlotInterval ?? outletSetting?.timeSlotInterval),
              preorderBuffer: +(preorderBuffer ?? outletSetting?.preorderBuffer),
            } as UpdateOutletSettingInput,
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('outlet.edit.failedSetting', {}, 'Failed to update outlet setting'),
        type: 'error',
      },
      successNotification: {
        message: t('outlet.edit.successSetting', {}, 'Outlet setting updated'),
        type: 'success',
      },
    });
  };

  // ========================= VIEWS
  const renderFormInput = ({ name, suffix }: { name: string; suffix?: string }) => {
    return (
      <div className="flex flex-row items-center mb-4">
        <div style={{ display: 'flex', flex: 1 }}>
          <p>{t(`outlet.settings.${name}`)}</p>
        </div>
        <div className="flex flex-row items-center gap-5" style={{ display: 'flex', flex: 2 }}>
          <Controller
            control={control}
            name={name}
            // @ts-ignore
            defaultValue={outletSetting[name]}
            render={({ field: { onChange, value } }) => {
              return (
                <NumberInput
                  value={value}
                  onValue={onChange}
                  useFormatNumber={false}
                  suffix={
                    <p className="text-gray-400" style={{ marginTop: 1 }}>
                      {suffix}
                    </p>
                  }
                  style={{
                    textAlign: 'right',
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      onConfirm={handleSubmit(onConfirmSelection)}
      title={t('outlet.settings.name', {
        fulfillment: t(`outlet.fulfillmentMethod.${camelCase(type)}`),
      })}
    >
      <section className="p-4">
        {isLoading || mutationLoading ? (
          <Loading />
        ) : (
          ['preparationTime', 'lastCallTime', 'timeSlotInterval', 'preorderBuffer'].map((name) =>
            renderFormInput({
              name,
              suffix: t(`common.unit.${name === 'preorderBuffer' ? 'days' : 'minutes'}`),
            })
          )
        )}
      </section>
    </ModalWrapper>
  );
};
